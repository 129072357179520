import { RESEAU_QUANTITY_BY_ROOM } from '@travauxlib/shared/src/features/Configurateur/utils/defaultConfigurationValues';
import {
  deposeChaudiereId,
  poseChaudiereId,
} from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/ids';
import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Plomberie } from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { poseChaudiereGaz } from './poseChaudiereGaz';
import { posePompeAChaleur } from './posePompeAChaleur';

export const chauffageGeneratedData = (
  plomberie: Plomberie,
  configuration: Configuration,
): GenerationData[] => {
  const result: GenerationData[] = [];

  const { chauffages } = plomberie;

  if (!chauffages) {
    return result;
  }

  for (const chauffage of chauffages || []) {
    if (chauffage.typeEnergie === 'Aucune') {
      if (chauffage.typeInstallation === 'Gaz') {
        result.push(
          ...poseChaudiereGaz({
            configuration,
            isOption: chauffage.isOption,
            locationUuid: chauffage.poseChaudiereLocationUuid!,
          }),
        );
      }

      if (chauffage.typeInstallation === 'PAC') {
        result.push(
          ...posePompeAChaleur(
            chauffage.posePompeAChaleurLocationUuid!,
            plomberie,
            configuration,
            chauffage.isOption,
          ),
        );
      }
    }

    if (chauffage.typeEnergie === 'Électrique') {
      if (chauffage.typeModif === 'Gaz') {
        result.push(
          ...poseChaudiereGaz({
            configuration,
            isOption: chauffage.isOption,
            locationUuid: chauffage.poseChaudiereLocationUuid!,
          }),
        );
      }

      if (chauffage.typeModif === 'PAC') {
        result.push(
          ...posePompeAChaleur(
            chauffage.posePompeAChaleurLocationUuid!,
            plomberie,
            configuration,
            chauffage.isOption,
          ),
        );
      }
    }

    if (chauffage.typeEnergie === 'Gaz') {
      if (chauffage.typeModif === 'Radiateur') {
        result.push({
          ouvrageLabel: 'Chaudière gaz à conserver',
          isOption: chauffage.isOption,
        });
      }

      if (chauffage.typeModifGaz === 'Gaz') {
        result.push({
          ouvrageLabel: 'Remplacement chaudière gaz',
          id: deposeChaudiereId(configuration),
          tagQuantity: 'skip',
          isOption: chauffage.isOption,
          quantity: 1,
          locationQuantities: {
            [chauffage.remplaceChaudiereLocationUuid!]: 1,
          },
        });

        result.push({
          ouvrageLabel: 'Remplacement chaudière gaz',
          id: poseChaudiereId(configuration),
          tagQuantity: 'skip',
          isOption: chauffage.isOption,
          quantity: 1,
          locationQuantities: {
            [chauffage.remplaceChaudiereLocationUuid!]: 1,
          },
        });
      }

      if (chauffage.typeModifGaz === 'PAC') {
        result.push(
          ...posePompeAChaleur(
            chauffage.posePompeAChaleurLocationUuid!,
            plomberie,
            configuration,
            chauffage.isOption,
          ),
        );
      }

      if (chauffage.typeModifGaz === 'Electrique' || chauffage.typeModifGaz === 'PAC') {
        result.push({
          ouvrageLabel: 'Dépose chaudière gaz',
          id: deposeChaudiereId(configuration),
          tagQuantity: 'only',
          quantity: 1,
          isOption: chauffage.isOption,
          locationQuantities: {
            [chauffage.deposeChaudiereLocationUuid!]: 1,
          },
        });
        result.push({
          ouvrageLabel: 'Dépose chaudière gaz',
          id: 'depose_reseau_cuivre_fonte',
          quantity: RESEAU_QUANTITY_BY_ROOM,
          isOption: chauffage.isOption,
          locationQuantities: {
            [chauffage.deposeChaudiereLocationUuid!]: RESEAU_QUANTITY_BY_ROOM,
          },
        });
      }
    }

    if (chauffage.typeEnergie === 'Fioul') {
      if (chauffage.typeModifFioul === 'Gaz') {
        result.push({
          ouvrageLabel: 'Dépose chaudière fioul',
          id: 'depose_chaudiere_fioul',
          tagQuantity: 'skip',
          quantity: 1,
          isOption: chauffage.isOption,
          locationQuantities: {
            [chauffage.deposeChaudiereFioulLocationUuid!]: 1,
          },
        });

        result.push({
          ouvrageLabel: 'Chaudière gaz',
          id: poseChaudiereId(configuration),
          tagQuantity: 'skip',
          quantity: 1,
          isOption: chauffage.isOption,
          locationQuantities: {
            [chauffage.deposeChaudiereFioulLocationUuid!]: 1,
          },
        });
      }

      if (chauffage.typeModifFioul === 'PAC') {
        result.push(
          ...posePompeAChaleur(
            chauffage.deposeChaudiereFioulLocationUuid!,
            plomberie,
            configuration,
            chauffage.isOption,
          ),
        );
      }

      if (chauffage.typeModifFioul === 'PAC' || chauffage.typeModifFioul === 'Electrique') {
        result.push({
          ouvrageLabel: 'Dépose chaudière fioul',
          id: 'depose_chaudiere_fioul',
          tagQuantity: 'skip',
          quantity: 1,
          isOption: chauffage.isOption,
          locationQuantities: {
            [chauffage.deposeChaudiereFioulLocationUuid!]: 1,
          },
        });
        result.push({
          ouvrageLabel: 'Dépose chaudière fioul',
          id: 'depose_reseau_cuivre_fonte',
          tagQuantity: 'skip',
          quantity: RESEAU_QUANTITY_BY_ROOM,
          isOption: chauffage.isOption,
          locationQuantities: {
            [chauffage.deposeChaudiereFioulLocationUuid!]: RESEAU_QUANTITY_BY_ROOM,
          },
        });
      }

      if (chauffage.cuveFioulADeposer) {
        result.push({
          ouvrageLabel: 'Dépose chaudière fioul',
          id: 'depose_cuve_fioul',
          tagQuantity: 'skip',
          isOption: chauffage.isOption,
          quantity: 1,
          locationQuantities: {
            [chauffage.deposeChaudiereFioulLocationUuid!]: 1,
          },
        });
      }
    }
  }

  return result;
};
