import { Devis } from '@travauxlib/shared/src/types';
import { TypeSuivi } from '@travauxlib/shared/src/types/api/common/TypeSuivi';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'withSuiviTp',
    fieldType: 'checkbox',
  },
] as const;

type Input = { devis: Devis };

// Exploits a security vulnerability of our signature implementation to "sign" the devis bypassing YouSign
// Maybe worth changing if/when patched
export class ExploitWeakSignatureImplem extends BaseStep<Input, void, typeof fields> {
  public name = 'WeakSign Exploit';

  public fields = fields;

  async execute({
    devis,
    withSuiviTp,
    seed,
    apiURL,
    request,
  }: ExecuteParams<Input, typeof fields>): Promise<void> {
    const weakSignBody = {
      typeSuivi: withSuiviTp ? TypeSuivi.TravauxPlannerWithService : TypeSuivi.ServiceHemea,
      customerEmail: `${seed}@example.hemea.com`,
      customerSignatureFirstName: 'Jesus',
      customerSignatureLastName: 'Godmode',
      customerBirthdate: '25/12/0001',
    };

    await request(`${apiURL}/devis-pro/${devis.token}/sign`, {
      method: 'POST',
      body: weakSignBody,
    });

    super.log('Pwned');
  }
}
