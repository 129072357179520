import { useMemo } from 'react';

import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

const minMatchCharLength = 3;

export const useSearchConsultationCompanies = (
  proCompanies: ProCompanyAdminView[],
  search: string = '',
): ProCompanyAdminView[] =>
  useMemo(() => {
    if (!search || search.length < minMatchCharLength) {
      return proCompanies;
    }

    const lowerSearch = search.toLowerCase();

    return proCompanies.filter(
      proCompany =>
        proCompany.name.toLowerCase().includes(lowerSearch) ||
        proCompany.email.toLowerCase().includes(lowerSearch),
    );
  }, [proCompanies, search]);
