import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';

import { Flow } from './Flow';

import { CreateAndFillConsultationInfo } from '../gdm_steps/Client/CreateAndFillConsultationInfo';
import { CreateConsultation } from '../gdm_steps/Client/CreateConsultation';
import { FillMandatoryPipedriveFields } from '../gdm_steps/Client/FillMandatoryPipedriveFields';
import { CreateArchiLead } from '../gdm_steps/Pro/CreateArchiLead';
import { CreateProLead } from '../gdm_steps/Pro/CreateProLead';
import { SearchProRegistration } from '../gdm_steps/Pro/SearchProRegistration';
import { SetProCompanyAbleForHemeaProjects } from '../gdm_steps/Pro/SetProCompanyAbleForHemeaProjects';
import { SetProRegistrationLabellisee } from '../gdm_steps/Pro/SetProRegistrationLabellisee';

// eslint-disable-next-line
export const createConsultation = (proCompanyType: CompanyType = CompanyType.Pro) =>
  new Flow()
    .next(new FillMandatoryPipedriveFields())
    .next(new CreateAndFillConsultationInfo())
    .next((proCompanyType === 'pro' ? new CreateProLead() : new CreateArchiLead()) as CreateProLead)
    .next(new SearchProRegistration())
    .next(new SetProRegistrationLabellisee())
    .next(new SetProCompanyAbleForHemeaProjects())
    .next(new CreateConsultation());
