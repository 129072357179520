import _flatMap from 'lodash/flatMap';
import _groupBy from 'lodash/groupBy';
import _partition from 'lodash/partition';
import _sum from 'lodash/sum';

import {
  ProDevisFactureLotView,
  DevisItemView,
} from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

type DevisItemWithLocation = {
  location: DevisLocation;
  item: DevisItemView;
};

const defaultLocationUuid = 'DEFAULT';
const defaultLocation: DevisLocation = {
  uuid: defaultLocationUuid,
  label: 'Autres prestations',
  surface: 0,
  hauteurSousPlafond: 0,
  longueurMurs: 0,
  surfaceMurs: 0,
  typeLocation: 'Autre',
} as DevisLocation;

const getAllItemsSplitByLocation = (
  lots: ProDevisFactureLotView[],
  locations: DevisLocation[],
): DevisItemWithLocation[] => {
  const allItems: DevisItemView[] = _flatMap(lots, lot => lot.items);

  return allItems.reduce((acc: DevisItemWithLocation[], item: DevisItemView) => {
    if (item.type !== 'ligne' || item.locations.length === 0) {
      return [...acc, { location: defaultLocation, item }];
    }

    const lignesSplitedByLocations = item.locations.map(l => {
      const prixHT = item.prixUnitaireHT * l.quantite;
      const montantTVA = roundToTwoDecimals((prixHT * item.tauxTVA) / 100);
      const prixTTC = roundToTwoDecimals(prixHT + montantTVA);
      const ligneUpdatedWithQuantityFromLocation = {
        ...item,
        prixHT: roundToTwoDecimals(prixHT),
        prixTTC,
        montantTVA,
        quantite: l.quantite,
        locations: [],
      };

      const matchingLocationOfDefault =
        locations.find(location => location.uuid === l.uuid) || defaultLocation;

      return {
        item: ligneUpdatedWithQuantityFromLocation,
        location: matchingLocationOfDefault,
      };
    });

    return [...acc, ...lignesSplitedByLocations];
  }, []);
};

const getLotsGroupedByLocations = (
  allItemsWithLocation: DevisItemWithLocation[],
  locations: DevisLocation[],
): ProDevisFactureLotView[] => {
  const itemsGroupedByLocations = _groupBy(allItemsWithLocation, i => i.location.uuid);

  return Object.entries(itemsGroupedByLocations).map(([locationUuid, itemsWithLocations]) => {
    const items = itemsWithLocations.map(itemWithLocation => itemWithLocation.item);
    const prixTotalHT = _sum(
      items.map(l =>
        l.type === 'ligne' && l.status === undefined ? l.prixUnitaireHT * l.quantite : 0,
      ),
    );

    const { label } = locations.find(l => l.uuid === locationUuid) || defaultLocation;

    return {
      uuid: locationUuid,
      locationUuid: locationUuid === defaultLocationUuid ? undefined : locationUuid,
      label,
      prixTotalHT,
      items,
    } as unknown as ProDevisFactureLotView;
  });
};

const lotsSortedByLocations = (
  lots: ProDevisFactureLotView[],
  locations: DevisLocation[],
): ProDevisFactureLotView[] => {
  const locationsUuids = locations.map(l => l.uuid);
  const [lotsWithKnownLocation, lotsWithUnknownLocation] = _partition(lots, lot =>
    locationsUuids.includes(lot.uuid || ''),
  );
  return [
    ...locations.map(location => lotsWithKnownLocation.find(lot => lot.uuid === location.uuid)),
    ...lotsWithUnknownLocation,
  ].filter(l => l !== undefined) as ProDevisFactureLotView[];
};

export const switchToLotsByLocations = (
  lots: ProDevisFactureLotView[],
  locations: DevisLocation[],
): ProDevisFactureLotView[] => {
  const allItemsWithLocation: DevisItemWithLocation[] = getAllItemsSplitByLocation(lots, locations);
  const lotsByLocations: ProDevisFactureLotView[] = getLotsGroupedByLocations(
    allItemsWithLocation,
    locations,
  );
  return lotsSortedByLocations(lotsByLocations, locations);
};
