import { OuvrageIdentifier } from '@travauxlib/shared/src/features/Configurateur/utils/ouvrages';
import { BaseDePrixLigne, TypeLocation, UnitValue } from '@travauxlib/shared/src/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';

import { Cuisine } from './Cuisine';
import { Elec } from './Elec';
import { MenuiseriesExterieures } from './MenuiseriesExterieures';
import { MenuiseriesInterieures } from './MenuiseriesInterieures';
import { Murs } from './Murs';
import { OuverturesDemolition } from './OuverturesDemolition';
import { Platrerie } from './Platrerie';
import { Plomberie } from './Plomberie';
import { SalleDeBain } from './SalleDeBain';
import { Sols } from './Sols';

export type { ConfigurationView as Configuration } from '@travauxlib/shared/src/types/api/common/ConfigurationView';

export type HubClosed = {
  [key in keyof Answers]?: boolean;
};

export type BaseDePrix = { [K in BaseDePrixIdentifier]: BaseDePrixLigne };

export type LocationOption = {
  value: string;
  label: string;
  centerIcon: React.ReactElement;
  typeLocation: TypeLocation;
};

export type Answers = {
  ouverturesDemolition?: OuverturesDemolition;
  cuisine?: Cuisine;
  murs?: Murs;
  menuiseriesInterieures?: MenuiseriesInterieures;
  menuiseriesExterieures?: MenuiseriesExterieures;
  sols?: Sols;
  plomberie?: Plomberie;
  elec?: Elec;
  platrerie?: Platrerie;
  salleDeBain?: SalleDeBain;
};

export type QuestionId = keyof Answers;

export enum ConfigurationStatus {
  Draft = 'draft',
  Submitted = 'submitted',
  Sent = 'sent',
}

export type Ligne = {
  designation: string;
  quantity: number;
  unite: UnitValue;
  lotLabel: string;
};

export type Lot = {
  label: string;
  lignes: Ligne[];
};

type CahierChargeIdentifier =
  | 'Modification ventilation'
  | "Plomberie existante à conserver salle d'eau"
  | 'Plomberie existante à conserver salle de bain'
  | 'Modification chauffage'
  | 'Modification chauffage collectif par la copro'
  | "Chauffage collectif, vanne d'arrêt individuelle présente"
  | 'Chaudière gaz à conserver'
  | 'Modification eau chaude'
  | 'Eau chaude sanitaire en collectif'
  | 'Remplacement fenêtres'
  | 'Volets roulants ou persiennes'
  | 'Plomberie existante à conserver'
  | 'Ouverture ou démolition de mur ou cloison'
  | 'Mise aux normes électrique';

export type MateriauLabel = 'PVC' | 'Alu' | 'Bois';

export type GenerationData = {
  ouvrageLabel: OuvrageIdentifier | CahierChargeIdentifier;
  isOption?: boolean;
  inProgress?: boolean;
  toCheck?: boolean;
  id?: BaseDePrixIdentifier;
  quantity?: number;
  locationQuantities?: {
    [K: string]: number;
  };
  tagQuantity?: 'skip' | 'only';
  hideTag?: boolean;
};

export enum TypeMobilier {
  DuCommerce = 'Meubles du commerce en kit',
  Cuisiniste = 'Cuisiniste',
}

export type ConfigurationPayload = {
  uuid?: string;
  projectUuid: string;
  status: ConfigurationStatus;
  answers?: Answers;
};
