import Booster from '@travauxlib/shared/src/components/DesignSystem/assets/Booster.svg?react';
import Calculate from '@travauxlib/shared/src/components/DesignSystem/assets/Calculate.svg?react';
import Dashboard from '@travauxlib/shared/src/components/DesignSystem/assets/Dashboard.svg?react';
import DocumentEdit from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentEdit.svg?react';
import Handshake from '@travauxlib/shared/src/components/DesignSystem/assets/Handshake.svg?react';
import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import Inventory from '@travauxlib/shared/src/components/DesignSystem/assets/Inventory.svg?react';
import Invoice from '@travauxlib/shared/src/components/DesignSystem/assets/Invoice.svg?react';
import LittleStars from '@travauxlib/shared/src/components/DesignSystem/assets/LittleStars.svg?react';
import ProjectIcon from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import Store from '@travauxlib/shared/src/components/DesignSystem/assets/Store.svg?react';

import { adminUrls } from 'utils/urls';

type Permissions = {
  canManageFacturation: boolean;
  isCDPL: boolean;
};

export const navItems = [
  {
    Icon: Dashboard,
    to: adminUrls.leadsUrl,
    label: 'Dashboard CDP',
  },
  {
    Icon: Inventory,
    to: adminUrls.activities,
    label: 'Activités',
  },
  {
    Icon: DocumentEdit,
    to: adminUrls.proRegistrations,
    label: 'Inscriptions pro',
  },
  {
    Icon: ProjectIcon,
    to: '/projects',
    label: 'Gestion de projet',
  },
  {
    Icon: Store,
    to: '/entreprises',
    label: 'Entreprises',
  },
  {
    Icon: Booster,
    to: '/leads',
    label: 'Leads',
  },
  {
    Icon: HandyMan,
    to: '/chantiers',
    label: 'Chantiers',
  },
  {
    Icon: Handshake,
    to: adminUrls.partnerUrl,
    label: 'Partenaires',
  },
  {
    Icon: Calculate,
    to: '/comparateur',
    label: 'Comparateur',
  },
  {
    Icon: LittleStars,
    to: '/trombinoscope',
    label: 'Trombi',
  },
  {
    Icon: Invoice,
    to: '/gestion-facturation',
    label: 'Gestion facturation',
    show: (permissions: Permissions) => permissions.canManageFacturation,
  },
  {
    Icon: Invoice,
    to: '/cdpl-facturation',
    label: 'Ma facturation',
    show: (permissions: Permissions) => permissions.isCDPL,
  },
];
