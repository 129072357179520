import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

import { defaultHSP } from '../defaultConfigurationValues';

const cloisonDistributionHydroIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'cloison_distribution_hydro_isolant_laine_minerale_45',
  [Gamme.confort]: 'cloison_distribution_hydro_isolant_laine_minerale_45',
  [Gamme.premium]: 'cloison_distribution_hydro_isolant_fibre_de_bois_45',
  [Gamme.exclusif]: 'cloison_distribution_hydro_isolant_fibre_de_bois_45',
};

const cloisonDistributionIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'cloison_distribution_isolant_laine_minerale_45',
  [Gamme.confort]: 'cloison_distribution_isolant_laine_minerale_45',
  [Gamme.premium]: 'cloison_distribution_phonique_fibre_de_bois_60',
  [Gamme.exclusif]: 'cloison_distribution_phonique_fibre_de_bois_60',
};

const doublageIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'doublage_colle_isolant_pse_100',
  [Gamme.confort]: 'doublage_sur_rail_isolant_laine_minerale_120_standard',
  [Gamme.premium]: 'doublage_sur_rail_isolant_fibre_de_bois_140_standard',
  [Gamme.exclusif]: 'doublage_sur_rail_isolant_fibre_de_bois_140_standard',
};

const fauxPlafondsIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'faux_plafonds_laine_minerale_45_standard',
  [Gamme.confort]: 'faux_plafonds_laine_minerale_45_standard',
  [Gamme.premium]: 'faux_plafonds_fibre_bois_100_acoustique',
  [Gamme.exclusif]: 'faux_plafonds_fibre_bois_100_acoustique',
};

const fauxPlafondsHydroIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'faux_plafonds_laine_minerale_45_hydro',
  [Gamme.confort]: 'faux_plafonds_laine_minerale_45_hydro',
  [Gamme.premium]: 'faux_plafonds_fibre_bois_100_hydro',
  [Gamme.exclusif]: 'faux_plafonds_fibre_bois_100_hydro',
};

export const platrerie = (configuration: Configuration): GenerationData[] => {
  const platrerie = configuration.answers?.platrerie;
  const gamme = platrerie?.gamme || configuration.mainGamme;
  const hauteurSousPlafond = configuration.hauteurSousPlafond || defaultHSP;

  if (!platrerie) {
    return [];
  }

  const result: GenerationData[] = [];

  for (let cloison of platrerie.cloisons || []) {
    const locationCloisons = configuration.locations.filter(l =>
      cloison?.locationsUuid?.includes(l.uuid),
    );
    for (let location of locationCloisons) {
      const quantities = cloison?.locationQuantities?.[location.uuid];
      const id = ['Cuisine', 'Salle de bain', "Salle d'eau"].includes(location.typeLocation)
        ? cloisonDistributionHydroIdForGamme[gamme]
        : cloisonDistributionIdForGamme[gamme];

      const quantity =
        quantities?.longueur && quantities.hauteur
          ? roundToOneDecimal(quantities.longueur * quantities.hauteur)
          : roundToOneDecimal(3 * hauteurSousPlafond);
      result.push({
        ouvrageLabel: 'Cloison placo',
        inProgress: !quantities?.longueur || !quantities?.hauteur,
        id,
        isOption: cloison.isOption,
        quantity,
        locationQuantities: {
          [location.uuid]: quantity,
        },
      });
    }
  }

  const isolations = platrerie.isolations || [];

  for (let isolation of isolations) {
    const locationsIsolation = configuration.locations.filter(l =>
      isolation.locationsUuid?.includes(l.uuid),
    );

    if (isolation.cible === 'Plafonds') {
      locationsIsolation.forEach(location => {
        result.push({
          ouvrageLabel: 'Faux plafond isolant',
          id: ['Salle de bain', "Salle d'eau"].includes(location.typeLocation)
            ? fauxPlafondsHydroIdForGamme[gamme]
            : fauxPlafondsIdForGamme[gamme],
          quantity: location.surface,
          isOption: isolation.isOption,
          locationQuantities: {
            [location.uuid]: location.surface,
          },
        });
      });
    }
    if (isolation.cible === 'Murs extérieurs') {
      if (isolation.surfaceFacade) {
        result.push({
          ouvrageLabel: 'ITE avec enduit minéral',
          id: 'isolation_exterieur_cale_cheville_pse_14',
          isOption: isolation.isOption,
          quantity: isolation.surfaceFacade,
        });
      } else {
        result.push({
          ouvrageLabel: 'ITE avec enduit minéral',
          inProgress: true,
          isOption: isolation.isOption,
          quantity: isolation.surfaceFacade,
        });
      }
    }
    if (isolation.cible === 'Murs') {
      const allLoccationHasQuantity = locationsIsolation.every(location => {
        const quantities = isolation.locationQuantities?.[location.uuid];
        return quantities?.hauteur && quantities?.longueur;
      });

      locationsIsolation.forEach(location => {
        const quantities = isolation.locationQuantities?.[location.uuid];

        const quantity =
          quantities?.hauteur && quantities?.longueur
            ? roundToOneDecimal(quantities.hauteur * quantities.longueur)
            : 0;

        result.push({
          inProgress: !allLoccationHasQuantity,
          ouvrageLabel: 'Doublage isolant',
          id: doublageIdForGamme[gamme],
          quantity,
          isOption: isolation.isOption,
          tagQuantity: allLoccationHasQuantity ? undefined : 'skip',
          locationQuantities: {
            [location.uuid]: quantity,
          },
        });
      });
    }

    if (isolation.typeIsolation === 'comblesPerdus') {
      result.push({
        ouvrageLabel: 'Isolation des combles perdus',
        id: 'isolation_combles_perdus_plancher_laine_220',
        tagQuantity: 'only',
        isOption: isolation.isOption,
        quantity: isolation.comblesPerdusSurfaceAIsoler,
      });
      result.push({
        ouvrageLabel: 'Isolation des combles perdus',
        id: 'pare_vapeur_air_combles',
        isOption: isolation.isOption,
        quantity: isolation.comblesPerdusSurfaceAIsoler,
      });
    } else if (isolation.typeIsolation === 'comblesAAmenager') {
      const quantity =
        isolation.comblesAAmenagerDimensions?.hauteur &&
        isolation.comblesAAmenagerDimensions?.longueur
          ? roundToOneDecimal(
              isolation.comblesAAmenagerDimensions.hauteur *
                isolation.comblesAAmenagerDimensions.longueur,
            )
          : 0;
      result.push({
        ouvrageLabel: 'Isolation des combles sous rampants',
        id: 'doublage_combles_rampants_standard',
        tagQuantity: 'only',
        isOption: isolation.isOption,
        quantity,
      });
      result.push({
        ouvrageLabel: 'Isolation des combles sous rampants',
        id: 'isolation_combles_rampants_bicouche_laine_260',
        isOption: isolation.isOption,
        quantity,
      });
    } else if (isolation.typeIsolation === 'comblesDejaCloisonnes') {
      locationsIsolation.forEach(location => {
        const quantities = isolation.locationQuantities?.[location.uuid];

        const quantity =
          quantities?.hauteur && quantities?.longueur
            ? roundToOneDecimal(quantities.hauteur * quantities.longueur)
            : 0;

        result.push({
          ouvrageLabel: 'Isolation des combles sous rampants',
          id: ['Salle de bain', "Salle d'eau"].includes(location.typeLocation)
            ? 'doublage_combles_rampants_hydrofuge'
            : 'doublage_combles_rampants_standard',
          tagQuantity: 'only',
          quantity,
          isOption: isolation.isOption,
          locationQuantities: {
            [location.uuid]: quantity,
          },
        });
        result.push({
          ouvrageLabel: 'Isolation des combles sous rampants',
          id: 'isolation_combles_rampants_bicouche_laine_260',
          isOption: isolation.isOption,
          quantity,
        });
      });
    }
  }

  return result;
};
