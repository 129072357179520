import React, { useCallback, useRef } from 'react';

import classNames from 'classnames';
import throttle from 'lodash/throttle';

import { FileDropzone } from './FileDropzone';
import { FileDropzonePreview } from './FileDropzonePreview';
import {
  FileDropzonePublicProps,
  FileListingTrackingProps,
  FileUploadProgress,
  FileUploadStatus,
} from './types';

import { InputErrorText } from '../Input/commons';

export const useFileDropzone = (): [
  React.FC<FileDropzonePublicProps & FileListingTrackingProps>,
  (event: ProgressEvent, fileIndex: number) => void,
  (fileIndex: number) => void,
  (fileIndex: number) => void,
] => {
  const [filesProgress, setFilesProgress] = React.useState<FileUploadProgress[]>();
  const [errorFiles, setErrorFiles] = React.useState<FileUploadProgress[]>();
  const filesProgressRef = useRef<FileUploadProgress[] | undefined>(undefined);

  filesProgressRef.current = filesProgress;

  const updateFileProgresses = (
    fileIndex: number,
    partialUpdate: Partial<FileUploadProgress>,
  ): void => {
    if (!filesProgressRef.current) {
      return;
    }
    const filesProgressCopy = [...filesProgressRef.current];
    const copy = filesProgressCopy[fileIndex];
    if (!copy) {
      return;
    }
    filesProgressCopy[fileIndex] = { ...copy, ...partialUpdate };
    setFilesProgress(filesProgressCopy);
    filesProgressRef.current = filesProgressCopy;
  };

  const onUploadProgress = (progressEvent?: ProgressEvent, fileIndex?: number): void => {
    if (!filesProgressRef.current || !progressEvent || fileIndex === undefined) {
      return;
    }
    const total = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
    updateFileProgresses(fileIndex, {
      status: total === 100 ? 'PROCESSING' : 'UPLOADING',
      percentage: total,
    });
  };

  const onUploadDone = (status: FileUploadStatus, fileIndex: number): void => {
    if (!filesProgressRef.current) {
      return;
    }
    updateFileProgresses(fileIndex, { status, percentage: 100 });
  };

  const onUploadSuccess = (fileIndex: number): void => onUploadDone('DONE', fileIndex);

  const onUploadError = (fileIndex: number): void => {
    onUploadDone('ERROR', fileIndex);
  };

  const initFileProgressAndHandleUploadFile = (files: File[]): void => {
    const createdFileProgresses = files.map((file, i) => ({
      status: 'READY' as FileUploadStatus,
      queuePosition: i,
      percentage: 0,
      file: file,
    }));
    setFilesProgress(createdFileProgresses);
  };

  const findErrorFiles = (): void => {
    if (!filesProgressRef.current) {
      return;
    }
    setErrorFiles(
      filesProgressRef.current?.filter(fileProgress => fileProgress?.status === 'ERROR'),
    );
    setFilesProgress(undefined);
  };

  const DropZoneComponent: React.FC<FileDropzonePublicProps & FileListingTrackingProps> = ({
    multiple,
    handleUploadFile,
    files,
    onRemoveFile,
    onClickFile,
    onlyPreview,
    sizeLimit,
    disabled,
    trackingDownloadProps,
    error,
    small,
    displayMode,
    controls,
    activeFileUuid,
  }) => {
    const handleUploadFileWithDoneHandler = (files: File[]): Promise<any> =>
      handleUploadFile(files).finally(() => findErrorFiles());

    const onClickRetry = (): Promise<any> => {
      if (!errorFiles) {
        return Promise.resolve();
      }
      const errorFilesToRetry = errorFiles.map(fileProgress => fileProgress.file);
      initFileProgressAndHandleUploadFile(errorFilesToRetry);
      return handleUploadFileWithDoneHandler(errorFilesToRetry);
    };

    const calculateProgress: () => number | undefined = useCallback(
      throttle(
        () =>
          filesProgress === undefined
            ? undefined
            : Math.floor(
                filesProgress
                  ?.map(fp => fp.percentage)
                  .reduce((acc, percentage) => acc + percentage, 0) / filesProgress?.length,
              ),
        500,
      ),
      [],
    );

    const currentFileProgressPercentage = calculateProgress();

    const shouldShowUpload = !onlyPreview && (multiple || !files?.length);
    return (
      <div className={classNames({ 'flex flex-wrap gap-xs': displayMode === 'pictures-wall' })}>
        {shouldShowUpload && (
          <>
            <FileDropzone
              disabled={disabled}
              onClickRetry={onClickRetry}
              progress={currentFileProgressPercentage}
              hasErrors={!!errorFiles && errorFiles.length !== 0}
              handleUploadFile={files => {
                initFileProgressAndHandleUploadFile(files);
                return handleUploadFileWithDoneHandler(files);
              }}
              error={error}
              sizeLimit={sizeLimit}
              multiple={multiple}
              small={small}
              displayMode={displayMode}
            />
            {error && <InputErrorText error={error} />}
          </>
        )}
        {files?.map(file => (
          <FileDropzonePreview
            file={file}
            onRemoveFile={onlyPreview ? undefined : onRemoveFile}
            key={file.uuid}
            trackingDownloadProps={trackingDownloadProps}
            displayMode={displayMode}
            small={small}
            controls={controls}
            onClickFile={onClickFile}
            isActive={activeFileUuid === file.uuid}
            disabled={disabled}
          />
        ))}
      </div>
    );
  };

  const memoizedDropZoneComponent = React.useMemo(
    () => DropZoneComponent,
    [filesProgress, errorFiles],
  );

  return [memoizedDropZoneComponent, onUploadProgress, onUploadSuccess, onUploadError];
};
