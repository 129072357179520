import _keyBy from 'lodash/keyBy';

import { BaseDePrixLigne } from '@travauxlib/shared/src/types';
import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { getDevisFromProject } from '@travauxlib/shared/src/utils/getDevisFromProject';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { lead?: LeadAdminView; configuration: Configuration };

export class CreateAutoGeneratedDevis extends BaseStep<Input, void, StepField[]> {
  public name: string = 'Create auto generated devis';

  public fields = [];

  async execute({ lead, apiURL, configuration, request }: ExecuteParams<Input>): Promise<void> {
    if (!lead) {
      super.error('Lead is undefined. Cannot create an auto generated devis !');
      return Promise.reject();
    }

    super.log('Creating configurateur...');

    const projectUuid = lead.project?.uuid;

    if (!projectUuid) {
      super.error('Project is undefined. Cannot create an auto generated devis !');
      return Promise.reject();
    }

    const baseDePrixData = await request<{ data: BaseDePrixLigne[] }>(
      `${apiURL}/client/base-de-prix`,
    );
    const baseDePrix = _keyBy(
      baseDePrixData.data || [],
      item => item.normalizedIdentifier,
    ) as BaseDePrix;

    const autoGeneratedDevis = getDevisFromProject({
      typologieProject: configuration,
      configurationData: { baseDePrix, configuration },
    });

    await request(`${apiURL}/admin/projects/${projectUuid}/devis`, {
      method: 'POST',
      body: { ...autoGeneratedDevis, projectUuid },
    });

    super.log('Devis auto generated created !');

    return;
  }
}
