import { useState } from 'react';

import { FormApi } from 'final-form';

import { FournitureReferenceScrapped } from '@travauxlib/shared/src/types';

type FormActionParams<T> = {
  form: FormApi<T, Partial<T>>;
  formKeyPrefix?: string;
};

type HandleReferenceScrappedParams<T> = {
  scrappedUrl: string;
  hasExistingReference: boolean;
  handleScrapFourniture?: (url: string) => Promise<FournitureReferenceScrapped>;
} & FormActionParams<T>;

type UseReferenceScraping<T> = {
  scrappedData: FournitureReferenceScrapped | undefined;
  isLoading: boolean;
  handleReferenceScrapped: (params: HandleReferenceScrappedParams<T>) => Promise<void>;
  updateFormWithScrapData: (data: FournitureReferenceScrapped, params: FormActionParams<T>) => void;
  clearScrappedData: () => void;
};

export const useReferenceScraping = <T>(): UseReferenceScraping<T> => {
  const [scrappedData, setScrappedData] = useState<FournitureReferenceScrapped | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const handleReferenceScrapped = async ({
    scrappedUrl,
    hasExistingReference,
    form,
    formKeyPrefix,
    handleScrapFourniture,
  }: HandleReferenceScrappedParams<T>): Promise<void> => {
    if (!handleScrapFourniture) {
      return;
    }

    try {
      setIsLoading(true);
      const scrappedReference = await handleScrapFourniture(scrappedUrl);

      if (hasExistingReference) {
        setScrappedData(scrappedReference);
      } else {
        updateFormWithScrapData(scrappedReference, { form, formKeyPrefix });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateFormWithScrapData = (
    data: FournitureReferenceScrapped,
    { form, formKeyPrefix }: FormActionParams<T>,
  ): void => {
    form.batch(() => {
      if (formKeyPrefix) {
        form.change(formKeyPrefix as keyof T, undefined);
      } else {
        form.restart({});
      }

      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          const formKey = (formKeyPrefix ? `${formKeyPrefix}.${key}` : key) as keyof T;
          form.change(formKey, value as T[keyof T]);
        }
      });
    });
  };

  const clearScrappedData = (): void => setScrappedData(undefined);

  return {
    scrappedData,
    isLoading,
    handleReferenceScrapped,
    updateFormWithScrapData,
    clearScrappedData,
  };
};
