import React from 'react';

import { useOutletContext, Navigate } from 'react-router';

import { ChantierTable } from 'features/Chantiers/components/ChantierTable';
import { ProjectContext } from 'features/Project/api/useProjectContext';

export const ProjectChantierListing: React.FC = () => {
  const { chantiers } = useOutletContext<ProjectContext>();

  if (!chantiers.length) {
    return <Navigate replace to=".." />;
  }

  return (
    <div className="pt-md sm-desktop:mx-xl">
      <ChantierTable chantiers={chantiers} />
    </div>
  );
};
