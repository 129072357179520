import React, { useState } from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

import { useAuth } from 'api/useAuth';
import { useUpdateAccount } from 'features/Partners/api/mutations';
import { PartnerAccount, PartnerAgency, PartnerOrganization } from 'types';
import { PartenairesTracking } from 'utils/tracking/PartenairesTracking';

import { AccountForm } from './AccountForm';
import { DisabledBadge } from './DisabledBadge';

import { useSignInToEstimateur } from '../api/useSignInToEstimateur';
import { getDisabledReasons } from '../utils/getDisabledReasons';

type Props = {
  account: PartnerAccount;
  allAgencies: PartnerAgency[];
  partner: PartnerOrganization;
  agency: PartnerAgency;
  defaultIsEditing?: boolean;
};

export const AccountItem: React.FC<Props> = ({
  account,
  allAgencies,
  partner,
  agency,
  defaultIsEditing,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(defaultIsEditing ?? false);
  const { updateAccount } = useUpdateAccount();
  const signInToEstimateur = useSignInToEstimateur(account);
  const disabledReasons = getDisabledReasons(partner, agency, account);
  const { adminUserProfile } = useAuth();

  return (
    <div
      className={classNames('min-h-[7.5rem] relative', isEditing ? 'col-span-full' : 'col-span-4')}
    >
      <Card state="outlined" className="!h-full" bodyClassNames="text-b1 !text-center !p-0">
        <div>
          <div className="!flex !items-center !justify-between bg-gray-300 px-xs mb-xs">
            {disabledReasons.length > 0 ? (
              <DisabledBadge
                label="Désactivé"
                disabledReasons={disabledReasons}
                labelInTooltip="Ce compte est désactivé car :"
              />
            ) : (
              <CheckCircle className="w-md h-md text-success" />
            )}
            <span>
              {account.firstName} {account.lastName}
            </span>
            <div className="shrink-0">
              <IconButton
                type="button"
                className="!mr-xs"
                onClick={() => setIsEditing(isEditing => !isEditing)}
              >
                <Edit />
              </IconButton>
              <IconButton
                data-testid="user-connect-estimateur"
                type="button"
                onClick={signInToEstimateur}
              >
                {account.isOrganizationManager ? <StarFull /> : <Person />}
              </IconButton>
            </div>
          </div>
          {isEditing ? (
            <>
              <AccountForm
                initialValues={account}
                cancelEditing={() => setIsEditing(false)}
                onSubmit={async values => {
                  PartenairesTracking.onComptePartenaireEdited({
                    'User Role': adminUserProfile.role,
                    'Compte Partenaire UUID': values.uuid,
                    'Agence Partenaire UUID': values.agencyUuid,
                    'Organisation Partenaire UUID': values.organizationUuid,
                  });
                  await updateAccount(values);
                  setIsEditing(false);
                }}
                agencies={allAgencies}
              />
            </>
          ) : (
            account.email
          )}
        </div>
      </Card>
    </div>
  );
};
