import React from 'react';

import dayjs from 'dayjs';

import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFilled.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { Person } from '@travauxlib/shared/src/types';

type Props = {
  person: Person;
};

export const HeaderCard: React.FC<Props> = ({ person }) => {
  const { firstName, lastName, arrivalDate, birthDate } = person;

  const officialPicture = person.officialPicture || 'https://picsum.photos/200/200';

  return (
    <Card bodyClassNames="flex">
      <div className="mr-md">
        <ImgLazy
          className="rounded-md"
          alt="main"
          url={officialPicture}
          width={74}
          height={74}
          imgixParams={{
            fit: 'crop',
            crop: 'top',
          }}
        />
      </div>
      <div>
        <h3 className="font-bold my-0">
          {firstName} {lastName}
        </h3>
        <div className="text-sm italic text-neutral-500">
          Chez hemea depuis le&nbsp;
          {dayjs(arrivalDate).format('LL')} ({dayjs(arrivalDate).fromNow(true)})
        </div>
        {birthDate && (
          <div className="flex items-center">
            <StarFull className="w-md h-md mr-xs" />
            <div>{dayjs().diff(dayjs(birthDate), 'year')} ans</div>
          </div>
        )}
      </div>
    </Card>
  );
};
