import React from 'react';

import { Field } from 'react-final-form';

import Appartement from '@travauxlib/shared/src/components/DesignSystem/assets/AppartementFilled.svg?react';
import House from '@travauxlib/shared/src/components/DesignSystem/assets/HouseFilled.svg?react';
import { ChipPicto } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/ChipPicto';
import { TypeBien } from '@travauxlib/shared/src/types';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

import { PrestationDelete } from './PrestationDelete';

type Props = {
  name: string;
  configuration: Configuration;
  prefix: string;
  title?: string;
  tooltip?: string | React.ReactElement;
  optionCheckbox?: React.ReactElement | undefined;
  onDelete?: () => void;
};

export const MultiLocationsPicker: React.FC<Props> = ({
  title = 'Pièces concernées',
  configuration,
  name,
  prefix,
  optionCheckbox,
  onDelete,
  tooltip,
}) => {
  const locationOptions = getLocationOptions(configuration);
  return (
    <>
      <div className="flex justify-between">
        <QuestionTitle
          prefix={prefix}
          title={title}
          description="Plusieurs choix possibles"
          tooltip={tooltip}
        />
        {onDelete && <PrestationDelete onDelete={onDelete} />}
      </div>
      {optionCheckbox}
      <Field<string[]>
        name={name}
        render={({ input: { value, onChange } }) => {
          const allSelected = value.length === locationOptions.length;
          return (
            <div className="flex flex-wrap gap-xs mb-md" id={name}>
              <ChipPicto
                isActive={allSelected}
                size="md"
                onClick={() =>
                  allSelected ? onChange([]) : onChange(locationOptions.map(o => o.value))
                }
                fullColor
                label="Toutes"
                leftIcon={
                  configuration.typeLogement === TypeBien.Appartement ? <Appartement /> : <House />
                }
              />
              {locationOptions.map(option => (
                <ChipPicto
                  key={option.value}
                  isActive={value.includes(option.value)}
                  size="md"
                  onClick={() =>
                    value.includes(option.value)
                      ? onChange(value.filter(val => val !== option.value))
                      : onChange([...value, option.value])
                  }
                  fullColor
                  label={option.label}
                  leftIcon={option.centerIcon}
                />
              ))}
            </div>
          );
        }}
      />
    </>
  );
};
