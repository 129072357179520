import { useQuery } from '@tanstack/react-query';

import { FOURNITURES_KEY } from '@travauxlib/shared/src/features/Fournitures/api/buildUseFournitures';
import { FournituresWithLocation } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const useFournituresByDeal = (
  projectUuid: string,
  dealUuid: string,
): {
  isLoading: boolean;
  fournitures: FournituresWithLocation[];
} => {
  const { data, isLoading } = useQuery<{ data: FournituresWithLocation[] }>({
    queryKey: [FOURNITURES_KEY, projectUuid, dealUuid],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/deals/${dealUuid}/fournitures`),
  });

  return { isLoading, fournitures: data?.data ?? [] };
};
