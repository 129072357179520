import _sum from 'lodash/sum';

import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { getPriceWithCorrectGammeFourniture } from '@travauxlib/shared/src/utils/getPriceWithCorrectGammeFourniture';

import { getAllGeneratedLines } from './getAllGeneratedLines';

export const getTotalPriceConfiguration = ({
  baseDePrix,
  configuration,
  withTVA,
}: {
  baseDePrix: BaseDePrix;
  configuration: Configuration;
  withTVA: boolean;
}): number => {
  const allPrices = getAllGeneratedLines(baseDePrix, configuration)
    .filter(l => !l.isOption)
    .map(l => {
      const { quantite, prixUnitaireHT: originalPrixUnitaireHT, tauxTVA } = l;
      const gamme = l.gamme || configuration.mainGamme;
      const prixUnitaireHT = getPriceWithCorrectGammeFourniture(originalPrixUnitaireHT, l, gamme);
      if (withTVA) {
        return quantite * prixUnitaireHT * (1 + tauxTVA / 100);
      }
      return quantite * prixUnitaireHT;
    });
  return Math.round(_sum(allPrices));
};
