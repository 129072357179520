import React from 'react';

import dayjs from 'dayjs';

import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/EyeFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ProInsuranceView } from '@travauxlib/shared/src/types/api/common/ProInsuranceView';

import { insurancesType } from 'utils/constants';

type Props = {
  insurance: ProInsuranceView;
  dirty?: boolean;
  handleDelete: (insuranceId: string) => void;
  index: number;
};

export const Assurance: React.FC<Props> = ({ insurance, handleDelete, index, dirty }) => (
  <div key={index} className="flex flex-wrap -mx-md !flex !items-center mb-md">
    <div className="tablet:w-2/12 tablet:px-md relative">
      <ButtonLink
        href={insurance.fileUrl}
        target="_blank"
        rel="noopener noreferrer"
        leftIcon={<Eye />}
      >
        Voir
      </ButtonLink>
    </div>
    <div className="tablet:w-3/12 tablet:px-md relative">
      <DropdownField
        id="insurance-type"
        label="Type d'assurance"
        placeholder=""
        name={`insurances.${index}.label`}
        options={insurancesType}
      />
    </div>
    <div className="tablet:w-3/12 tablet:px-md relative">
      <DatePickerField
        id={`date-debut-validite-${index}`}
        name={`insurances.${index}.dateDebutValidite`}
        label="Début de validité"
      />
    </div>
    <div className="tablet:w-3/12 tablet:px-md relative">
      <DatePickerField
        id={`date-fin-validite-${index}`}
        name={`insurances.${index}.dateFinValidite`}
        label="Fin de validité"
        shouldBeDisabled={(date: Date) =>
          !!insurance.dateDebutValidite && dayjs(insurance.dateDebutValidite).isAfter(date)
        }
      />
    </div>
    <div className="tablet:w-1/12 tablet:px-md relative">
      {insurance.isDeleted ? (
        <div className="!text-danger !text-center">Déjà supprimée</div>
      ) : (
        !dirty && (
          <IconButton
            variant="black"
            data-testid="delete"
            onClick={() => handleDelete(insurance.id)}
          >
            <DeleteFilled />
          </IconButton>
        )
      )}
    </div>
  </div>
);
