import React from 'react';

import { useNavigate } from 'react-router';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

type Props = {
  token: string;
};

export const DevisAction: React.FC<Props> = ({ token }) => {
  const navigate = useNavigate();

  const items: ActionListItem[] = [
    {
      type: 'action',
      label: 'Voir le devis',
      onClick: () => window.open(`${APP_CONFIG.appURL}/pro/devis/${token}`, '_blank'),
    },
    {
      type: 'action',
      label: 'Accéder aux modalités hemea',
      onClick: () => navigate(`/modalites/devis/${token}`),
    },
  ];

  return (
    <>
      <ActionList
        items={items}
        trigger={(toggle, referenceProps) => (
          <IconButton
            onClick={toggle}
            {...referenceProps}
            data-testid="open-devis-action"
            className="!h-full"
          >
            <DotsMenu />
          </IconButton>
        )}
      />
    </>
  );
};
