import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ApiParameters } from '@travauxlib/shared/src/features/ProCompanyUsersControlPannel/types';
import { ProUser } from '@travauxlib/shared/src/types';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { PRO_COMPANY_KEY } from '../api/useProCompany';

const handleCreateNewProUserAccount = async ({ slug, user }: ApiParameters): Promise<ProUser> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/users`;

  return request(requestURL, {
    method: 'POST',
    body: user,
  });
};

export const useCreateNewProUserAccount = (): {
  createNewProUserAccount: typeof handleCreateNewProUserAccount;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createNewProUserAccount, isPending } = useMutation({
    mutationFn: handleCreateNewProUserAccount,
    onError: err => {
      if (err instanceof Error) {
        const message = isConflict(err)
          ? 'Un utilisateur avec cet email existe déjà'
          : `Erreur lors de la création du user: ${err.message}`;
        toast.error(message);
      }
    },
    onSuccess: () => queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY] }),
  });
  return { createNewProUserAccount, isLoading: isPending };
};
