import React from 'react';

import Dropzone from 'react-dropzone';
import { Field } from 'react-final-form';

import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

type Props = {
  slug: string;
};

export const Banner: React.FC<Props> = ({ slug }) => {
  const { uploadPhoto } = useUpdateProCompany();
  return (
    <Field name="bannerImageUrl">
      {({ input: { value: bannerImageUrl, onChange } }) => (
        <div>
          <h3>Banner (1900x500)</h3>
          <div className="!flex">
            <div className="!flex-grow">
              <Dropzone
                accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
                onDrop={async ([file]: File[]) => {
                  const photo = await uploadPhoto({
                    slug,
                    file,
                  });
                  onChange(photo.path);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    data-testid="dropzone"
                    {...getRootProps()}
                    className="!flex !justify-center !items-center !flex-col"
                  >
                    <div>
                      {bannerImageUrl ? (
                        <img
                          src={`${APP_CONFIG.baseImgixUrl}/${bannerImageUrl}`}
                          alt="Banner de l'entreprise"
                          className="h-auto max-w-full max-w-[500px] max-h-[300px]"
                        />
                      ) : (
                        <div>Pas encore de banner</div>
                      )}
                      <input {...getInputProps()} />
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            {bannerImageUrl && (
              <div className="!flex !items-center">
                <IconButton variant="black" onClick={() => onChange('')} className="!m-sm">
                  <DeleteFilled />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      )}
    </Field>
  );
};
