import React, { useState } from 'react';

import _partition from 'lodash/partition';
import { useParams } from 'react-router';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { FileDropzonePreview } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/FileDropzonePreview';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { DownloadableFileWithTag } from '@travauxlib/shared/src/types';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';
import { isURLAnImage } from '@travauxlib/shared/src/utils/urls';

import { useProjectContext } from 'features/Project/api/useProjectContext';

import { DownloadAllButton } from './components/DownloadAllButton';
import { ImagesViewer } from './components/ImagesViewer';

type FileWithImgixUrl = DownloadableFileWithTag & { imgixUrl: string };

export const ProjectDocumentsViewer: React.FC = () => {
  const { uuid, tag } = useParams<{ uuid: string; tag: DocumentTag }>();
  const [photoIndex, setPhotoIndex] = useState<number | undefined>(undefined);
  const { isLoading, projectContext } = useProjectContext(uuid as string);

  if (isLoading || !projectContext) {
    return <Loader />;
  }

  const taggedFiles = tag
    ? projectContext.project.files.filter(f => f.tag === tag)
    : projectContext.project.files;

  const [imageDocuments, otherDocuments] = _partition(
    taggedFiles,
    ({ name, imgixUrl }) => isURLAnImage(name) && imgixUrl,
  ) as [FileWithImgixUrl[], DownloadableFileWithTag[]];

  return (
    <div className="pb-xl">
      <ButtonLink
        className="mb-md"
        variant="secondary"
        leftIcon={<ChevronLeft />}
        to={`/projects/${uuid}`}
      >
        Retour au projet
      </ButtonLink>
      {imageDocuments.length > 0 && (
        <div className="mb-xl">
          <h2>Photos</h2>
          <div className="grid grid-cols-12 gap-md mb-md">
            {imageDocuments.map((image, index) => (
              <Card
                className="col-span-6 tablet:col-span-3 sm-desktop:col-span-2 hover:opacity-50"
                bodyClassNames="truncate"
                image={{
                  alt: `photo ${image.name}`,
                  url: image.imgixUrl,
                  sizes: '100%',
                  className: 'h-[10rem] w-full',
                  imgixParams: { fit: 'clip' },
                  width: 284,
                }}
                imageVariant="top-full"
                key={image.uuid}
                role="button"
                onClick={() => setPhotoIndex(index)}
              >
                {image.name}
              </Card>
            ))}
          </div>
          <DownloadAllButton
            zipName="images"
            urlsToDownload={imageDocuments.map(image => image.downloadUrl)}
          />
          <ImagesViewer images={imageDocuments} index={photoIndex} setIndex={setPhotoIndex} />
        </div>
      )}
      {otherDocuments.length > 0 && (
        <>
          <h2>Documents</h2>
          <div className="flex flex-wrap mb-md">
            {otherDocuments?.map(document => (
              <div className="mr-md last:mr-0 truncate" key={document.uuid}>
                <FileDropzonePreview key={document.uuid} file={document} displayMode="standard" />
              </div>
            ))}
          </div>
          <DownloadAllButton
            zipName="documents"
            urlsToDownload={otherDocuments.map(document => document.downloadUrl)}
          />
        </>
      )}
    </div>
  );
};
