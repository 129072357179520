import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';

import { poseChaudiereId } from './ids';

import { RESEAU_QUANTITY_BY_ROOM } from '../defaultConfigurationValues';

export const poseChaudiereGaz = ({
  configuration,
  locationUuid,
  isOption,
}: {
  configuration: Configuration;
  locationUuid: string;
  isOption?: boolean;
}): GenerationData[] => [
  {
    ouvrageLabel: 'Chaudière gaz',
    id: poseChaudiereId(configuration),
    quantity: 1,
    tagQuantity: 'only',
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  },
  {
    ouvrageLabel: 'Chaudière gaz',
    id: 'reseau_alimentation_chauffage',
    tagQuantity: 'skip',
    quantity: RESEAU_QUANTITY_BY_ROOM,
    isOption,
    locationQuantities: {
      [locationUuid]: RESEAU_QUANTITY_BY_ROOM,
    },
  },
];
