import dayjs from 'dayjs';
import { v4 as uuidV4 } from 'uuid';

import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { Configuration, TypeMobilier } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  Etat,
  FinitionMursPlafondsEnum,
  MursPlafonds,
  TypeRevetement,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Murs';
import { Options } from '@travauxlib/shared/src/utils/request';

const answers: Configuration['answers'] = {
  elec: {
    refections: [
      {
        typeRefection: 'Complète',
        locationQuantities: {
          '2dd5675b-5b3a-40f8-88fc-db22fc7bc502': {
            tableauxElectriques: 1,
          },
        },
      },
    ],
  },
  murs: {
    poseMurs: [
      {
        typePose: TypeRevetement.CredenceCuisine,
      },
      {
        typePose: TypeRevetement.PapierPeint,
        locationQuantities: {
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1': 1,
        },
      },
    ],
    peintures: [
      {
        etatMurs: Etat.Bon,
        etatPlafonds: Etat.Bon,
        mursPlafonds: MursPlafonds.MursEtPlafonds,
        locationsUuid: '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1',
        finitionMursPlafonds: FinitionMursPlafondsEnum.PeintureComplete,
      },
    ],
    deposeMurs: [
      {
        typeDepose: TypeRevetement.CredenceCuisine,
      },
    ],
  },
  sols: {
    sols: [
      {
        existant: 'Parquet massif',
        souhaite: 'Rénovation de parquet',
        locationsUuid: [
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1',
          'f0bb9510-f84c-4025-bc89-5607ba43a0a6',
          '7d9f933a-6e03-4363-bcf6-03c8b1ee8c14',
        ],
      },
      {
        existant: 'Carrelage',
        souhaite: 'Carrelage',
        locationsUuid: [
          '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f',
          '2dd5675b-5b3a-40f8-88fc-db22fc7bc502',
          'd192d56c-e20b-4b68-8157-6871aec5a241',
        ],
      },
    ],
  },
  cuisine: {
    refections: [
      {
        typeMobilier: TypeMobilier.DuCommerce,
        projetDeMobilier: true,
        deplacementEquipements: true,
      },
    ],
    remplacements: [],
  },
  platrerie: {
    isolations: [
      {
        cible: 'Murs',
        locationsUuid: [
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1',
          '7d9f933a-6e03-4363-bcf6-03c8b1ee8c14',
        ],
        locationQuantities: {
          '7d9f933a-6e03-4363-bcf6-03c8b1ee8c14': {
            hauteur: 2.7,
            longueur: 3,
          },
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1': {
            hauteur: 2.7,
            longueur: 2,
          },
        },
      },
    ],
  },
  plomberie: {
    chauffages: [
      {
        isOption: false,
        typeModif: 'Electrique',
        typeEnergie: 'Gaz',
        locationQuantities: {
          '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f': {
            deposeRadiateursEau: 1,
          },
          '7d9f933a-6e03-4363-bcf6-03c8b1ee8c14': {
            deposeRadiateursEau: 1,
          },
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1': {
            deposeRadiateursEau: 1,
          },
          'f0bb9510-f84c-4025-bc89-5607ba43a0a6': {
            deposeRadiateursEau: 1,
          },
        },
        deposeChaudiereLocationUuid: '2dd5675b-5b3a-40f8-88fc-db22fc7bc502',
      },
    ],
    eauChaudes: [
      {
        isOption: false,
        typeEnergie: 'Gaz',
        typeModifGaz: 'Electrique',
        chaudiereLocationUuid: '2dd5675b-5b3a-40f8-88fc-db22fc7bc502',
      },
    ],
    ventilations: [
      {
        locationsUuid: [
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1',
          '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f',
        ],
        typeVentilation: 'Remplacement de bouches',
      },
    ],
    hasModifVentilation: true,
  },
  salleDeBain: {
    prestations: {
      '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f': [
        {
          deplacementEquipements: true,
          typeWC: 'suspendu',
        },
      ],
      'd192d56c-e20b-4b68-8157-6871aec5a241': [
        {
          laveMains: true,
          typeWC: 'aposer',
        },
      ],
    },
  },
  ouverturesDemolition: {
    ouverturesMur: [
      {
        locationsUuid: ['2dd5675b-5b3a-40f8-88fc-db22fc7bc502'],
        typeOuverture: 'Cloison',
        locationQuantities: {
          '2dd5675b-5b3a-40f8-88fc-db22fc7bc502': {
            hauteur: 2.7,
            longueur: 2,
          },
        },
      },
    ],
  },
  menuiseriesExterieures: {
    fenetres: [
      {
        typeMateriau: 'pvc',
        locationQuantities: {
          '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f': {
            fenetres: 1,
          },
        },
      },
    ],
    ajoutFenetres: true,
  },
  menuiseriesInterieures: {
    portes: [
      {
        typePrestation: 'Remplace',
        locationQuantities: {
          '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1': {
            remplace: 1,
          },
          '2dd5675b-5b3a-40f8-88fc-db22fc7bc502': {
            remplace: 1,
          },
          '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f': {
            remplace: 1,
          },
        },
      },
    ],
  },
};
export const idealConfiguration = {
  typeLogement: 'appartement',
  rooms: 'T3',
  floor: 3,
  hasElevator: true,
  locations: [
    {
      uuid: '9095cdb1-1fee-44aa-a1d2-a6d8a917dbc1',
      typeLocation: 'Chambre',
      label: 'Chambre N°1',
      surface: 9,
    },
    {
      uuid: 'd192d56c-e20b-4b68-8157-6871aec5a241',
      typeLocation: 'WC',
      label: 'WC N°1',
      surface: 2,
    },
    {
      uuid: 'f0bb9510-f84c-4025-bc89-5607ba43a0a6',
      typeLocation: 'Bureau',
      label: 'Bureau N°1',
      surface: 9,
    },
    {
      uuid: '2dd5675b-5b3a-40f8-88fc-db22fc7bc502',
      typeLocation: 'Cuisine',
      label: 'Cuisine N°1',
      surface: 6,
    },
    {
      uuid: '3efed62d-ca0f-41e0-ac3a-7b9ed86ea62f',
      typeLocation: "Salle d'eau",
      label: "Salle d'eau N°1",
      surface: 4,
    },
    {
      uuid: '7d9f933a-6e03-4363-bcf6-03c8b1ee8c14',
      typeLocation: 'Salon',
      label: 'Salon N°1',
      surface: 12,
    },
  ],
  budgetTravaux: 50000,
  hauteurSousPlafond: 2.7,
  address: {
    address_components: [
      {
        long_name: '10',
        short_name: '10',
        types: ['street_number'],
      },
      {
        long_name: 'Rue Briquet',
        short_name: 'Rue Briquet',
        types: ['route'],
      },
      {
        long_name: 'Paris',
        short_name: 'Paris',
        types: ['locality', 'political'],
      },
      {
        long_name: 'Département de Paris',
        short_name: 'Département de Paris',
        types: ['administrative_area_level_2', 'political'],
      },
      {
        long_name: 'Île-de-France',
        short_name: 'IDF',
        types: ['administrative_area_level_1', 'political'],
      },
      {
        long_name: 'France',
        short_name: 'FR',
        types: ['country', 'political'],
      },
      {
        long_name: '75018',
        short_name: '75018',
        types: ['postal_code'],
      },
    ],
    formatted_address: '10 Rue Briquet, 75018 Paris, France',
    place_id:
      'EiMxMCBSdWUgQnJpcXVldCwgNzUwMTggUGFyaXMsIEZyYW5jZSIwEi4KFAoSCV-jdk5CbuZHEVFC2Iu08HyZEAoqFAoSCZtnN1JCbuZHEUrtmdIXPS7X',
    geometry: {
      location: {
        lat: 48.8832834,
        lng: 2.3442443,
      },
      viewport: {
        east: 2.345593280291502,
        west: 2.342895319708498,
        north: 48.88463238029149,
        south: 48.8819344197085,
      },
      location_type: 'RANGE_INTERPOLATED',
    },
  },
  mainGamme: Gamme.confort,
  stadeAchat: 'proprietaire',
  utilisationLogement: 'Résidence principale',
  dpe: 'D',
  debutTravaux: dayjs().add(3, 'week').format('YYYY-MM-DD'),
  typeTravaux: 'renovation_complete',
  isRenovationEnergetique: true,
  status: 'draft' as const,
  surfaceTotale: 42,
  comments: 'configurateur created by godmode',
  answers,
  cadrageProjet: [],
};

export const apiCreateConfigurateur = (
  apiURL: string,
  projectUuid: string,
  request: (url: string, options: Options) => Promise<unknown>,
): Promise<unknown> => {
  const configurationUuid = uuidV4();

  return request(`${apiURL}/admin/configuration/${configurationUuid}`, {
    method: 'PUT',
    body: {
      ...idealConfiguration,
      uuid: configurationUuid,
      projectUuid,
    },
  });
};
