import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ChaleurLead } from '@travauxlib/shared/src/types/api/domain/client/ChaleurLead';

import { Lead } from 'types';

type Props = {
  children: React.ReactNode;
  active: boolean;
  chaleurLead: ChaleurLead;
  lead: Lead;
  handleUpdateAdressable: (payload: { lead: Lead; chaleurLead: ChaleurLead }) => void;
  disabled?: boolean;
  loading?: boolean;
};

export const AdressableButton: React.FC<Props> = ({
  active,
  chaleurLead,
  children,
  handleUpdateAdressable,
  lead,
  disabled,
  loading,
}) => (
  <Button
    type="button"
    variant={active ? 'primary' : 'secondary'}
    onClick={() => handleUpdateAdressable({ lead, chaleurLead })}
    disabled={disabled}
    loading={loading}
  >
    {children}
  </Button>
);
