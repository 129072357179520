import React from 'react';

import Blueprint from '@travauxlib/shared/src/components/DesignSystem/assets/Blueprint.svg?react';
import Box from '@travauxlib/shared/src/components/DesignSystem/assets/Box.svg?react';
import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import Chat from '@travauxlib/shared/src/components/DesignSystem/assets/Chat.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/ConstructionFilled.svg?react';
import Draw from '@travauxlib/shared/src/components/DesignSystem/assets/Draw.svg?react';
import DrawFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DrawFilled.svg?react';
import Eco from '@travauxlib/shared/src/components/DesignSystem/assets/EcoFilled.svg?react';
import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/EuroFilled.svg?react';
import PhotoCamera from '@travauxlib/shared/src/components/DesignSystem/assets/PhotoCamera.svg?react';
import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import Style from '@travauxlib/shared/src/components/DesignSystem/assets/Style.svg?react';
import { ClientActionType } from '@travauxlib/shared/src/types';

export const actionIconMapper: { [K in ClientActionType]: React.ReactNode } = {
  [ClientActionType.TakeRdv]: <Calendar />,
  [ClientActionType.AddDpe]: <Eco />,
  [ClientActionType.AddPlans]: <Blueprint />,
  [ClientActionType.AddPhotos]: <PhotoCamera />,
  [ClientActionType.SeeGuides]: <Chat />,
  [ClientActionType.AddInspirations]: <Style />,
  [ClientActionType.SeeArchiDevis]: <Quote />,
  [ClientActionType.SeeArchiDevisTs]: <Quote />,
  [ClientActionType.CompleteFournitures]: <Box />,
  [ClientActionType.SeeProDevis]: <Quote />,
  [ClientActionType.SeeProDevisTs]: <Draw />,
  [ClientActionType.AddProvisions]: <Euro />,
  [ClientActionType.ValidateInitialAdp]: <Euro />,
  [ClientActionType.SeeSuiviChantier]: <Construction />,
  [ClientActionType.ValidateReceptionChantier]: <DrawFilled />,
  [ClientActionType.ValidateLeveeReserves]: <DrawFilled />,
};
