import React from 'react';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import EnvelopeFilled from '@travauxlib/shared/src/components/DesignSystem/assets/EnvelopeFilled.svg?react';
import PersonFilled from '@travauxlib/shared/src/components/DesignSystem/assets/PersonFilled.svg?react';
import PhoneFilled from '@travauxlib/shared/src/components/DesignSystem/assets/PhoneFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ClampedText } from '@travauxlib/shared/src/components/DesignSystem/components/ClampedText';
import { Divider } from '@travauxlib/shared/src/components/DesignSystem/components/Divider';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tab } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs/types';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { ProjectContext } from 'features/Project/api/useProjectContext';

import { HeaderActions } from './HeaderActions';

import { useFournitures } from '../features/ProjectFourniture/api/useFournitures';
import { useOpenClientContactModal } from '../hooks/useOpenClientContactModal';

const baseTabs = [
  {
    label: 'Information',
    to: 'information',
  },
  {
    label: 'Cadrage',
    to: 'cadrage',
  },
  {
    label: 'Consultation',
    to: 'consultation',
  },
  {
    label: 'Devis',
    to: 'devis',
  },
  {
    label: 'Activités',
    to: 'activities',
  },
];

type Props = {
  projectContext: ProjectContext;
};

export const Header: React.FC<Props> = ({ projectContext }) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const { project, clientAccounts, chantiers, lead } = projectContext;
  const [firstCreatedClientAccount] = clientAccounts;
  const { fournitures } = useFournitures(project.uuid);
  const openClientAccountModal = useOpenClientContactModal();

  return (
    <div className="bg-neutral-0 sm-desktop:-mx-xl -mx-md -mt-md sm-desktop:px-xl pl-md pr-sm sm-desktop:min-h-[9.5rem]">
      <div className="sm-desktop:pt-md pt-xs">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-md">
            <ClampedText
              className="text-b1 tablet:text-h3 font-bold text-neutral-800"
              maxLines={isTabletOrMobile ? 2 : 1}
              text={project.title}
              tooltipPosition="bottom"
            />
          </div>
          <HeaderActions projectContext={projectContext} />
        </div>
        <div className="flex mt-xxs text-b1 font-medium text-neutral-700">
          <div className="flex items-center">
            <PersonFilled className="sm-desktop:w-lg sm-desktop:h-lg mr-xxs w-md h-md" />
            <span className="text-sm tablet:text-b1">
              {firstCreatedClientAccount ? firstCreatedClientAccount.firstName : 'Aucun'}{' '}
              {firstCreatedClientAccount ? firstCreatedClientAccount.lastName : 'Aucun'}
            </span>
            <IconButton
              className="sm-desktop:hidden ml-xxs"
              size="xs"
              onClick={() => openClientAccountModal(project.uuid)}
            >
              <ChevronRight />
            </IconButton>
          </div>
          <div className="hidden sm-desktop:flex items-center">
            <Divider orientation="vertical" className="grow-0" />
            {firstCreatedClientAccount?.phoneNumber && (
              <>
                <div className="flex items-center">
                  <PhoneFilled className="w-lg h-lg mr-xxs" />
                  <span>{firstCreatedClientAccount.phoneNumber}</span>
                </div>
                <Divider orientation="vertical" className="grow-0" />
              </>
            )}
            <div className="flex items-center">
              <EnvelopeFilled className="w-lg h-lg mr-xxs" />
              <span>{firstCreatedClientAccount ? firstCreatedClientAccount.email : 'Aucun'}</span>
            </div>
          </div>
          {lead?.lostReason && (
            <>
              <Divider orientation="vertical" className="grow-0 mx-xs tablet:mx-sm" />
              <Tag
                className="font-medium"
                variant="error"
                label="Perdu"
                size={isTabletOrMobile ? 'sm' : 'md'}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex text-b1 font-bold sm-desktop:flex-row flex-col-reverse sm-desktop:pt-md pt-xxs">
        <Tabs
          items={[
            ...baseTabs,
            ...(fournitures.length
              ? [
                  {
                    label: 'Fournitures',
                    to: 'fournitures',
                  },
                ]
              : []),
            ...(chantiers?.length
              ? [
                  {
                    label: 'Chantier',
                    to: chantiers.length === 1 ? `chantier/${chantiers[0].slug}` : 'chantier',
                    isActive: location => location.pathname.includes('chantier'),
                  } as Tab,
                ]
              : []),
          ]}
          size={isTabletOrMobile ? 'sm' : 'md'}
          hideContainerBottomBorder
          className="flex-1"
        />
      </div>
    </div>
  );
};
