import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';
import { ChantierAdmin } from 'types';

const handleReopenChantier = async (chantier: ChantierAdmin): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/reopen`;

  await request(requestURL, {
    method: 'POST',
  });
};

export const useReopenChantier = (): { reopenChantier: typeof handleReopenChantier } => {
  const { query } = useChantierQuery(
    handleReopenChantier,
    'de la réouverture du chantier',
    'Le chantier a bien été mis à jour.',
  );

  return { reopenChantier: query };
};
