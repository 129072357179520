import React from 'react';

import dayjs from 'dayjs';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import { RowAction } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';

import { Consultation } from 'types';

import { useFreeProFromConsultation } from '../api/useFreeProFromConsultation';
import { useTriggerRDV } from '../api/useTriggerRDV';

export const useGetConsultationActions = () => {
  const triggerRDV = useTriggerRDV();
  const freeProFromConsultation = useFreeProFromConsultation();

  return ({
    deal,
    meeting,
    proCompany,
    consultationInfo,
    consultationOffer,
  }: Consultation): RowAction[] => {
    const isValidMeeting =
      meeting && dayjs(meeting.startAt).isAfter(dayjs()) && !meeting.canceledAt;

    const canFreeProFromConsultation =
      !consultationOffer.isProFreedFromConsultation && deal?.status !== 'lost';

    const canAskForAdditionalRDV =
      !consultationOffer.withRdv &&
      !deal?.needsRDV &&
      !isValidMeeting &&
      consultationOffer.isAccepted &&
      proCompany.archiType === 'interne';

    return [
      {
        label: 'Annuler la consultation',
        action: () =>
          freeProFromConsultation({
            consultationOfferUuid: consultationOffer.uuid,
            projectUuid: consultationInfo.projectUuid,
          }),
        icon: <CrossCircle />,
        hide: !canFreeProFromConsultation,
      },
      {
        label: 'Proposer un RDV post-consultation',
        action: () => triggerRDV(consultationOffer.uuid),
        icon: <Calendar />,
        hide: !canAskForAdditionalRDV,
      },
    ];
  };
};
