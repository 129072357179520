import React, { useCallback } from 'react';

import { Form } from 'react-final-form';

import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProUser } from '@travauxlib/shared/src/types';
import { required, requiredValidEmail } from '@travauxlib/shared/src/utils/form';

import { useOpenConfirmDeleteUserModal } from './hooks/useOpenConfirmDeleteUserModal';

export type AddOrUpdateUserProps = {
  proCompanyName?: string;
  proUser?: Omit<ProUser, 'id'>;
  onSubmit: (user: ProUser, mode: 'create' | 'edit') => Promise<unknown>;
  archiveUser?: ({ user, slug }: { user: ProUser; slug?: string }) => Promise<unknown>;
  handleClose?: () => void;
  slug?: string;
};

export const AddOrUpdateUser: React.FC<AddOrUpdateUserProps> = ({
  proCompanyName,
  onSubmit,
  handleClose,
  proUser,
  archiveUser,
  slug,
}) => {
  const openConfirmArchiveUserModal = useOpenConfirmDeleteUserModal();

  const submitAndClose = useCallback(
    async (user: ProUser) => {
      await onSubmit(
        proUser ? { ...user, uuid: proUser?.uuid || '' } : user,
        proUser ? 'edit' : 'create',
      );
      handleClose?.();
    },
    [onSubmit, handleClose],
  );

  return (
    <Form<ProUser> onSubmit={submitAndClose}>
      {({ handleSubmit, submitting }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          title={proUser ? 'Modifier un utilisateur' : 'Inviter un utilisateur'}
          validateAction={{
            label: proUser ? 'Modifier' : 'Inviter',
            type: 'submit',
            disabled: submitting,
            loading: submitting,
          }}
          cancelAction={
            proUser &&
            archiveUser && {
              label: 'Archiver',
              onClick: () =>
                openConfirmArchiveUserModal({
                  proUser,
                  archiveUser,
                  slug,
                }),
              disabled: submitting,
              loading: submitting,
              rightIcon: <DeleteFilled />,
            }
          }
        >
          {proCompanyName && (
            <div className="mb-md">
              <Input
                id="pro-company-name"
                disabled
                onChange={() => {}}
                label="Entreprise"
                value={proCompanyName}
              />
            </div>
          )}
          <div className="py-md">
            <InputField
              id="email"
              label="Email de la personne invitée"
              name="email"
              className="pt-md tablet:pt-0"
              validate={requiredValidEmail}
              initialValue={proUser?.email}
            />
          </div>
          <div className="pb-md">
            <InputField
              label="Nom complet de la personne invitée"
              id="fullName"
              name="fullName"
              className="pt-md tablet:pt-0"
              validate={required}
              initialValue={proUser?.fullName}
            />
          </div>
          <div className="pb-md">
            <InputField
              label="Téléphone MOBILE de la personne invitée"
              id="phoneNumber"
              name="phoneNumber"
              className="pt-md tablet:pt-0"
              validate={required}
              initialValue={proUser?.phoneNumber}
            />
          </div>
          <h5 className="font-bold mb-sm">Droits de l'utilisateur</h5>
          <CheckboxField
            className="mb-md"
            label="Administrateur"
            id="isProCompanyAdmin"
            name="isProCompanyAdmin"
            initialValue={proUser?.isProCompanyAdmin ?? false}
          />
        </ModalContent>
      )}
    </Form>
  );
};
