import React from 'react';

import { Size, Variant } from './types';

import AlertCircleFilled from '../../assets/AlertCircleFilled.svg?react';
import CheckCircleFilled from '../../assets/CheckCircleFilled.svg?react';
import InfoCircleFilled from '../../assets/InfoCircleFilled.svg?react';
import WarningFilled from '../../assets/WarningFilled.svg?react';

export const textClassNamesBySize: { [key in Size]: string } = {
  sm: 'text-sm',
  md: 'text-b2',
};

export const textClassNamesByVariant: { [key in Variant]: string } = {
  info: 'text-info-800',
  success: 'text-success-800',
  warning: 'text-warning-800',
  error: 'text-error-800',
  light: 'text-neutral-600',
};

export const iconClassNamesBySize: { [key in Size]: string } = {
  sm: 'w-md h-md',
  md: 'w-xmd h-xmd',
};

export const iconClassNamesByVariant: { [key in Variant]: string } = {
  info: 'text-info-800',
  success: 'text-success-800',
  warning: 'text-warning-800',
  error: 'text-error-800',
  light: 'text-neutral-700',
};

export const iconByVariant: {
  [key in Variant]: React.ReactElement;
} = {
  info: <InfoCircleFilled />,
  success: <CheckCircleFilled />,
  warning: <AlertCircleFilled />,
  error: <WarningFilled />,
  light: <InfoCircleFilled />,
};
