import React from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import EditCircle from '@travauxlib/shared/src/components/DesignSystem/assets/EditCircle.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { ComparisonStatus } from '@travauxlib/shared/src/types';
import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

type Props = {
  ligne: ProDevisFactureLigneView & {
    compare?: ProDevisFactureLigneView;
    comparisonStatus?: ComparisonStatus;
  };
  lotIndex: number;
  itemIndex: number;
};

const Content: React.FC<Props> = ({ ligne, lotIndex, itemIndex }) => {
  if (ligne.comparisonStatus === 'added') {
    return <PlusCircle data-testid="added" className="w-lg h-lg text-success-800" />;
  }
  if (ligne.comparisonStatus === 'missing') {
    return <CrossCircle data-testid="missing" className="w-lg h-lg text-error-800" />;
  }
  if (ligne.comparisonStatus === 'modified') {
    return <EditCircle data-testid="modified" className="w-lg h-lg text-info-800" />;
  }
  return (
    <span data-testid="unchanged">
      {lotIndex + 1}.{itemIndex + 1}
    </span>
  );
};

export const LeftIndicator: React.FC<Props> = props => (
  <span className="text-neutral-600 grow-0 shrink-0 w-xl mr-xs">
    <Content {...props} />
  </span>
);
