import React, { useEffect } from 'react';

import { Outlet } from 'react-router';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { Configurateur } from 'features/Configurateur';
import { ConfigurateurDevis } from 'features/Configurateur/features/ConfigurateurDevis';
import { ConfigurateurCuisine } from 'features/Configurateur/features/Cuisine';
import { ConfigurateurElec } from 'features/Configurateur/features/Elec';
import { ConfigurateurHub } from 'features/Configurateur/features/Hub';
import { ConfigurateurMenuiseriesExterieures } from 'features/Configurateur/features/MenuiseriesExterieures';
import { ConfigurateurMenuiseriesInterieures } from 'features/Configurateur/features/MenuiseriesInterieures';
import { ConfigurateurMurs } from 'features/Configurateur/features/Murs';
import { ConfigurateurOuverturesDemolition } from 'features/Configurateur/features/OuverturesDemolition';
import { ConfigurateurPlatrerie } from 'features/Configurateur/features/Platrerie';
import { ConfigurateurPlomberie } from 'features/Configurateur/features/Plomberie';
import { ConfigurateurSalleDeBain } from 'features/Configurateur/features/SalleDeBain';
import { ConfigurateurSols } from 'features/Configurateur/features/Sols';
import { ProjectFournitures } from 'features/Project/features/ProjectPage/features/ProjectFourniture';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { RedirectToNewProject } from './components/RedirectToNewProject';
import { RedirectToProjectOfDevis } from './components/RedirectToProjectOfDevis';
import { ProjectDocumentsViewer } from './features/ProjectDocumentsViewer';
import { ProjectListing } from './features/ProjectListing';
import { ProjectPage } from './features/ProjectPage';
import { ProjectActivities } from './features/ProjectPage/features/Activities/Loadable';
import { ProjectAction } from './features/ProjectPage/features/ProjectAction';
import { ProjectCadrage } from './features/ProjectPage/features/ProjectCadrage';
import { ProjectChantier } from './features/ProjectPage/features/ProjectChantier';
import { ProjectChantierListing } from './features/ProjectPage/features/ProjectChantierListing';
import { ProjectConsultation } from './features/ProjectPage/features/ProjectConsultation';
import { ConsultationInfo } from './features/ProjectPage/features/ProjectConsultation/components/ConsultationInfo';
import { ConsultationOffer } from './features/ProjectPage/features/ProjectConsultation/components/ConsultationOffer';
import { ProjectDevis } from './features/ProjectPage/features/ProjectDevis';
import { CreateEditReference } from './features/ProjectPage/features/ProjectFourniture/components/CreateEditReference';
import { ProjectFournitureLayout } from './features/ProjectPage/features/ProjectFourniture/components/ProjectFournitureLayout';
import { ProjectInfos } from './features/ProjectPage/features/ProjectInfos';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onProjetViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.project} />
      <Outlet />
    </>
  );
};

export const Project = {
  Index,
  ProjectPage,
  ProjectInfos,
  ProjectCadrage,
  ProjectConsultation,
  ProjectFournitures,
  ProjectFournitureLayout,
  CreateEditReference,
  ProjectChantier,
  ProjectChantierListing,
  ProjectDevis,
  ProjectAction,
  ProjectListing,
  ProjectActivities,
  ProjectDocumentsViewer,
  ConsultationInfo,
  ConsultationOffer,
  Configurateur,
  ConfigurateurOuverturesDemolition,
  ConfigurateurHub,
  ConfigurateurCuisine,
  ConfigurateurMurs,
  ConfigurateurMenuiseriesInterieures,
  ConfigurateurMenuiseriesExterieures,
  ConfigurateurSols,
  ConfigurateurPlomberie,
  ConfigurateurElec,
  ConfigurateurPlatrerie,
  ConfigurateurSalleDeBain,
  ConfigurateurDevis,
  RedirectToNewProject,
  RedirectToProjectOfDevis,
};
