import React from 'react';

import dayjs from 'dayjs';

import { AcquisitionMetadata } from '@travauxlib/shared/src/types/api/common/AcquisitionMetadata';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  metadata: AcquisitionMetadata;
};

export const Metadata: React.FC<Props> = ({ metadata }) => {
  const displayFirstClickMetadata = !dayjs(metadata.firstSessionDate).isSame(
    dayjs(metadata.lastSessionDate),
    'minute',
  );

  return (
    <table className="table">
      <tbody>
        {displayFirstClickMetadata && (
          <tr>
            <th colSpan={2}>Last click</th>
          </tr>
        )}
        <tr>
          <td className="w-[10rem]">Source</td>
          <td>{metadata.lastSessionReferrer}</td>
        </tr>
        <tr>
          <td>Première page</td>
          <td>{metadata.lastSessionTargetUrl}</td>
        </tr>
        <tr>
          <td>Page de conversion</td>
          <td>{metadata.conversionPage}</td>
        </tr>
        <tr>
          <td>Bouton de conversion</td>
          <td>{metadata.conversionButton}</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{dateTimeFormat(metadata.lastSessionDate)}</td>
        </tr>
      </tbody>
      {metadata.lastSessionAffiliate && (
        <tbody>
          <tr>
            <th colSpan={2}>Last click affiliate</th>
          </tr>
          <tr>
            <td>Source</td>
            <td>{metadata.lastSessionAffiliate.source}</td>
          </tr>
          <tr>
            <td>Campaign</td>
            <td>{metadata.lastSessionAffiliate.campaign}</td>
          </tr>
          <tr>
            <td>Subcampaign</td>
            <td>{metadata.lastSessionAffiliate.subcampaign}</td>
          </tr>
        </tbody>
      )}
      {displayFirstClickMetadata && (
        <tbody>
          <tr>
            <th colSpan={2}>First click</th>
          </tr>
          <tr>
            <td>Source</td>
            <td>{metadata.firstSessionReferrer}</td>
          </tr>
          <tr>
            <td>Première page</td>
            <td>{metadata.firstSessionTargetUrl}</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{dateTimeFormat(metadata.firstSessionDate)}</td>
          </tr>
        </tbody>
      )}
      {metadata.firstSessionAffiliate && (
        <tbody>
          <tr>
            <th colSpan={2}>First click affiliate</th>
          </tr>
          <tr>
            <td>Source</td>
            <td>{metadata.firstSessionAffiliate.source}</td>
          </tr>
          <tr>
            <td>Campaign</td>
            <td>{metadata.firstSessionAffiliate.campaign}</td>
          </tr>
          <tr>
            <td>Subcampaign</td>
            <td>{metadata.firstSessionAffiliate.subcampaign}</td>
          </tr>
        </tbody>
      )}
    </table>
  );
};
