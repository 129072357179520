import React from 'react';

import classNames from 'classnames';
import _clamp from 'lodash/clamp';

import MinusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/MinusSymbol.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';

import { IconButton } from '../Buttons/IconButton';
import { InlineAlert } from '../InlineAlert';
import { NumberInput } from '../NumberInput';

type CustomCounterProps = {
  min: number;
  max: number;
  onChange: (newValue?: number) => void;

  testId?: string;
  canEditInput?: boolean;
  disabled?: boolean;
  error?: string;
  label?: string;
  value?: number;
  className?: string;
  classNameContainer?: string;
};

export type Props = CustomCounterProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const Counter: React.FC<Props> = ({
  canEditInput = true,
  disabled,
  error,
  testId = 'counter',
  label,
  max,
  min,
  onChange,
  value,
  className,
  classNameContainer,
}) => (
  <div className={classNameContainer}>
    {label && (
      <div className={classNames(className, 'text-neutral-600 text-b1 mb-xxs')}>{label}</div>
    )}
    <div
      className={classNames('flex w-[9rem] border rounded-xxs h-xxl items-center justify-center', {
        'bg-neutral-100': disabled,
      })}
    >
      <div className="px-xs">
        <IconButton
          data-testid={`decrement-${testId}`}
          disabled={min === value || disabled}
          onClick={() => onChange(value ? value - 1 : 0)}
          size="md"
        >
          <MinusSymbol />
        </IconButton>
      </div>

      {canEditInput ? (
        <NumberInput
          disabled={disabled}
          id="input-counter"
          data-testid={`input-${testId}`}
          onBlur={() => {
            if (value) {
              onChange(_clamp(value, min, max));
            }
          }}
          max={max}
          renderingOptions={{
            containerClassName: classNames('rounded-none !pr-0 min-w-xxl min-h-xxl', {
              'border-error-800': error,
            }),
            inputClassName: 'mr-0 text-center font-bold text-b1 !pl-[5px] !py-[10px]',
          }}
          //eslint-disable-next-line
          style={{ MozAppearance: 'textfield' }}
          onChange={n => onChange(n)}
          value={value}
        />
      ) : (
        <div
          data-testid={`input-${testId}`}
          className="py-[11px] border-x px-xs min-w-xxl text-center"
        >
          {value}
        </div>
      )}
      <div className="px-xs">
        <IconButton
          disabled={max === value || disabled}
          onClick={() => onChange(value ? value + 1 : 1)}
          size="md"
          data-testid={`increment-${testId}`}
        >
          <PlusSymbol />
        </IconButton>
      </div>
    </div>
    {error && (
      <div className="mt-xxs">
        <InlineAlert variant="error">{error}</InlineAlert>
      </div>
    )}
  </div>
);
