import classNames from 'classnames';

import AlertCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AlertCircle.svg?react';
import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import Clock from '@travauxlib/shared/src/components/DesignSystem/assets/Clock.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import {
  HistoryConflictStatus,
  SuiviChantierCycleHistory,
} from '@travauxlib/shared/src/features/SuiviChantier/types';

export const getCycleConfig = (
  cycle: SuiviChantierCycleHistory,
): {
  progression: number;
  montantDemande: React.ReactElement;
  renderMontantPercu: () => React.ReactElement;
} => {
  const getProgression = (): number => {
    const etapes = cycle.etapes;
    return etapes.client?.pourcentage ?? etapes.tp?.pourcentage ?? etapes.pro.pourcentage;
  };

  const getMontantPercu = (): number => Math.max(cycle.etapes.client?.montantALiberer ?? 0, 0);

  const statusConfig: Record<
    HistoryConflictStatus,
    {
      icon: React.ReactElement;
      iconColor: string;
      montantPercu: React.ReactElement;
    }
  > = {
    pending: {
      icon: <Clock />,
      iconColor: 'text-info-400',
      montantPercu: <span className="text-info-400">En attente</span>,
    },
    rejected: {
      icon: <CrossCircle />,
      iconColor: 'text-error-400',
      montantPercu: <EURCurrency amount={getMontantPercu()} />,
    },
    agreed: {
      icon: <CheckCircle />,
      iconColor: 'text-success-400',
      montantPercu: <EURCurrency amount={getMontantPercu()} />,
    },
    disputed: {
      icon: <AlertCircle />,
      iconColor: 'text-warning-400',
      montantPercu: <EURCurrency amount={getMontantPercu()} />,
    },
    canceled: {
      icon: <CrossCircle />,
      iconColor: 'text-error-400',
      montantPercu: <span className="text-error-400">Annulé</span>,
    },
  };

  const renderMontantPercu = (): React.ReactElement => {
    const { icon, iconColor, montantPercu } = statusConfig[cycle.status];

    return (
      <div className="flex items-center">
        <div className={classNames('w-md h-md flex mr-xxs', iconColor)}>{icon}</div>
        <div className="font-medium">{montantPercu}</div>
      </div>
    );
  };

  return {
    progression: getProgression(),
    montantDemande: <EURCurrency amount={cycle.etapes.pro.montantALiberer} />,
    renderMontantPercu,
  };
};
