import React from 'react';

import _orderBy from 'lodash/orderBy';
import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { PrestationSalleDeBain } from '@travauxlib/shared/src/types/api/domain/configurateur/SalleDeBain';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { FormControl } from 'features/Configurateur/components/FormControl';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { yesNoForm } from 'features/Configurateur/utils/forms';

type TypeDeWcSouhaiteProps = {
  prefix: string;
  name: string;
};

export const TypeDeWcSouhaite: React.FC<TypeDeWcSouhaiteProps> = ({ prefix, name }) => (
  <>
    <QuestionTitle prefix={prefix} title="Type de WC souhaité ?" />
    <ChipListField
      name={`${name}.typeWC`}
      renderingOptions={{
        containerClassName: 'mb-md flex gap-md',
      }}
      options={[
        {
          label: 'Suspendu',
          value: 'suspendu',
        },
        {
          label: 'À poser',
          value: 'aposer',
        },
        {
          label: 'Aucun',
          value: 'aucun',
        },
      ]}
    />
  </>
);
type Props = {
  configuration: Configuration;
};
export const RefectionCompletePiecesDeau: React.FC<Props> = ({ configuration }) => {
  const relevantLocations = _orderBy(
    configuration.locations.filter(l =>
      ["Salle d'eau", 'Salle de bain', 'WC'].includes(l.typeLocation),
    ),
    l => l.typeLocation,
  );

  return (
    <>
      {relevantLocations.map((l, index) => (
        <Accordion
          key={l.uuid}
          className="mb-md"
          title={`Réfection complète ${getLocationLabel(configuration, l)} ?`}
          prefix={`${index + 1}`}
          defaultExpanded
        >
          <FieldArray<PrestationSalleDeBain> name={`prestations.${l.uuid}`}>
            {({ fields }) => (
              <div>
                {fields.map((name, index) => {
                  const value = fields.value[index];

                  return (
                    <div key={name}>
                      {l.typeLocation === 'WC' && (
                        <>
                          <FormControl
                            className="mb-md"
                            prefix="1"
                            title="Lave-main à créer ?"
                            {...yesNoForm}
                            optionCheckbox={
                              <OptionCheckbox fields={fields} value={value} index={index} />
                            }
                            onDelete={() => fields.remove(index)}
                            name={`${name}.laveMains`}
                          />
                          {value?.laveMains !== undefined && (
                            <TypeDeWcSouhaite prefix="2" name={name} />
                          )}
                        </>
                      )}
                      {l.typeLocation === "Salle d'eau" && (
                        <>
                          <FormControl
                            className="mb-md"
                            prefix="1"
                            title="Déplacements des équipements sanitaires ?"
                            optionCheckbox={
                              <OptionCheckbox fields={fields} value={value} index={index} />
                            }
                            onDelete={() => fields.remove(index)}
                            {...yesNoForm}
                            tooltip={
                              <div>
                                Changer la disposition d'une pièce d'eau implique de déplacer les
                                équipements sanitaires et donc des modifications de réseau de
                                plomberie et électriques.
                                <br />
                                <br />
                                Cela implique également la création d'un doublage sur les murs où on
                                dispose le mobilier pour passer les réseaux et faciliter le travail
                                d'aménagement.
                                <br />
                                <br />
                                Primo : Miroir simple, barre de douche douchette, receveur résine
                                <br />
                                Confort : Miroir lumineux, colonne de douche, receveur résine
                                <br />
                                Premium & Exclusif : Miroir lumineux, robinetterie encastrée,
                                Receveur à carreler, carrelage niche découpe à 45°
                              </div>
                            }
                            name={`${name}.deplacementEquipements`}
                          />
                          {value?.deplacementEquipements !== undefined && (
                            <TypeDeWcSouhaite prefix="2" name={name} />
                          )}
                        </>
                      )}
                      {l.typeLocation === 'Salle de bain' && (
                        <>
                          <FormControl
                            className="mb-md"
                            prefix="1"
                            title="Déplacements des équipements sanitaires ?"
                            onDelete={() => fields.remove(index)}
                            optionCheckbox={
                              <OptionCheckbox fields={fields} value={value} index={index} />
                            }
                            {...yesNoForm}
                            tooltip={
                              <div>
                                Changer la disposition d'une pièce d'eau implique de déplacer les
                                équipements sanitaires et donc des modifications de réseau de
                                plomberie et électriques.
                                <br />
                                <br />
                                Cela implique également la création d'un doublage sur les murs où on
                                dispose le mobilier pour passer les réseaux et faciliter le travail
                                d'aménagement. Type d'équipement selon les gammes :
                                <br />
                                <br />
                                Primo : Miroir simple, barre de baignoire douchette
                                <br />
                                Confort : Miroir lumineux, colonne de baignoire
                                <br />
                                Premium & Exclusif : Miroir lumineux, robinetterie encastrée
                              </div>
                            }
                            name={`${name}.deplacementEquipements`}
                          />
                          {value.deplacementEquipements !== undefined && (
                            <>
                              <div className="flex justify-between">
                                <QuestionTitle prefix="2" title="Baignoire ou douche à poser ?" />
                                <PrestationDelete onDelete={() => fields.remove(index)} />
                              </div>
                              <ChipListField
                                renderingOptions={{
                                  containerClassName: 'mb-md flex flex-wrap gap-xs',
                                }}
                                options={[
                                  {
                                    label: 'Baignoire',
                                    value: 'Baignoire',
                                  },
                                  {
                                    label: 'Douche',
                                    value: 'Douche',
                                  },
                                ]}
                                name={`${name}.baignoireOuDouche`}
                              />
                            </>
                          )}
                          {value?.baignoireOuDouche !== undefined && (
                            <TypeDeWcSouhaite prefix="3" name={name} />
                          )}
                        </>
                      )}
                      <PrestationsSeparator />
                    </div>
                  );
                })}
                <AddPrestationButton fields={fields} />
              </div>
            )}
          </FieldArray>
        </Accordion>
      ))}
    </>
  );
};
