import React from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { DatePicker as DatePickerComponent } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';

export const formatTime = (value?: number): string => {
  if (value === undefined) {
    return '';
  }
  return value < 10 ? `0${value}` : value.toString();
};

export type Props = {
  label?: string;
  value?: Dayjs;
  onChange: (date?: Dayjs) => void;
  shouldBeDisabled?: (date: Date) => boolean;
  id: string;
  disabled?: boolean;
  fixedFloating?: boolean;
};

export const DateTimePicker: React.FC<Props> = ({
  value,
  onChange,
  label,
  shouldBeDisabled,
  id,
  fixedFloating,
  ...restProps
}) => (
  <div>
    <FormLabel label={label} />
    <div className="!flex !flex-wrap">
      <div className="!flex !items-center">
        <div className="max-w-[5rem]">
          <NumberInput
            label="Heure"
            id={`${id}-hours`}
            data-testid="hours"
            type="number"
            value={formatTime(dayjs(value).hour())}
            onChange={(v: any) =>
              onChange(value ? dayjs(value).hour(v) : dayjs().hour(v).minute(0))
            }
            {...restProps}
          />
        </div>
        <div className="ml-xs sm-desktop:ml-md max-w-[5rem]">
          <NumberInput
            id={`${id}-minutes`}
            label="Minutes"
            type="number"
            data-testid="minutes"
            value={formatTime(dayjs(value).minute())}
            onChange={(v: any) =>
              onChange(value ? dayjs(value).minute(v) : dayjs().minute(v).hour(0))
            }
            {...restProps}
          />
        </div>
      </div>
      <div className="!flex !items-center ml-xs sm-desktop:ml-md">
        <div className="max-w-[7rem]">
          <DatePickerComponent
            id={`${id}-day`}
            label="Jour"
            value={value ? dayjs(value) : undefined}
            shouldBeDisabled={shouldBeDisabled}
            fixedFloating={fixedFloating}
            onChange={v => {
              if (!v) {
                return onChange(v);
              }
              onChange(
                dayjs(value).set('date', v.date()).set('month', v.month()).set('year', v.year()),
              );
            }}
            {...restProps}
          />
        </div>
      </div>
    </div>
  </div>
);
