import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

type Props = {
  slug: string;
  closeForm: () => void;
  initialValues: ProCompanyAdminView;
};

export const EditableNote: React.FC<Props> = ({ closeForm, initialValues, slug }) => {
  const { update } = useUpdateProCompany();
  return (
    <Form<ProCompanyAdminView>
      initialValues={initialValues}
      onSubmit={async settings => {
        await update({ settings, slug });
        closeForm();
      }}
    >
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit} className="!mb-sm">
          <TextareaField
            className="mb-md"
            id="internalComment"
            name="internalComment"
            label="Commentaire en interne"
            rows={3}
            autoFocus
            onBlur={handleSubmit}
          />
          <div className="!flex !justify-around">
            <Button type="submit" disabled={!dirty || submitting}>
              Valider
            </Button>
            <Button variant="secondary" type="button" onClick={closeForm}>
              Annuler
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
