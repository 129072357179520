import React from 'react';

import { Form } from 'react-final-form';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';

import { useUpdateProject } from 'features/Project/api/useUpdateProject';

type FormProps = {
  title: string;
};

type Props = {
  project: ProjectView;
  handleClose: () => void;
};

const RenameProjectModal: React.FC<Props> = ({ project, handleClose }) => {
  const updateProject = useUpdateProject();

  return (
    <Form<FormProps>
      initialValues={{
        title: project.title,
      }}
      onSubmit={async values => {
        await updateProject({ ...project, title: values.title });
        handleClose();
      }}
    >
      {({ handleSubmit }) => (
        <ModalContent
          isScrollable
          title="Renommer le projet"
          validateAction={{
            label: 'Valider',
            onClick: handleSubmit,
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
        >
          <InputField name="title" id="title" />
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenRenameProjectModal = (): ((project: ProjectView) => void) => {
  const openModal = useOpenModal();

  return project =>
    openModal(RenameProjectModal, {
      closable: true,
      project,
    });
};
