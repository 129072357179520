import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { GenerationData } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';

const coloneBaignoireIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'barre_baignoire_mitigeur',
  [Gamme.confort]: 'colonne_baignoire',
  [Gamme.premium]: 'barre_baignoire_mitigeur_encastre',
  [Gamme.exclusif]: 'barre_baignoire_mitigeur_encastre',
};

export const poseBaignoire = ({
  locationUuid,
  hasRaccordsPlomberie,
  isOption,
  gamme,
}: {
  locationUuid: string;
  hasRaccordsPlomberie: boolean;
  isOption?: boolean;
  gamme: Gamme;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel: 'Baignoire',
    id: 'baignoire',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Baignoire',
    id: coloneBaignoireIdForGamme[gamme],
    isOption,
    tagQuantity: 'only',
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Baignoire',
    id: 'pare_bain',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Baignoire',
    id: 'nattes_etancheite',
    isOption,
    quantity: 2,
    locationQuantities: {
      [locationUuid]: 2,
    },
  });

  if (hasRaccordsPlomberie) {
    result.push({
      ouvrageLabel: 'Baignoire',
      id: 'ec_ef_evacuation_element_cuivre',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  return result;
};
