import dayjs from 'dayjs';

import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import { AdminAccountTransactionView } from '@travauxlib/shared/src/types/api/admin/AdminAccountTransactionView';
import { SignatureMarketplaceView } from '@travauxlib/shared/src/types/api/admin/SignatureMarketplaceView';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import { FluxFinancierHistoriqueFilterType, HistoryItem } from '../types';

export const signatureToHistoryItem = (
  signature: SignatureMarketplaceView,
  transactions: AdminAccountTransactionView[],
  handleOpenModal: (signatureUuid: string, hasTransactions: boolean) => void,
  isAllowedToCancelSignature: boolean,
): HistoryItem => {
  const hasTransactions = transactions.length !== 0;

  return {
    key: signature.uuid,
    date: signature.dateSignature,
    author: signature.customerName,
    amount:
      signature.prixTotalTTC +
      signature.montantTravauxPlannerTTC +
      signature.montantServiceHemeaTTC,
    label: 'Devis signé',
    filterType: FluxFinancierHistoriqueFilterType.Signature,
    cancellationInfo: signature.deletedAt
      ? {
          date: toCalendarFormatForDateTime(dayjs(signature.deletedAt)),
          author: signature.deletedBy,
        }
      : undefined,
    Icon: Quote,
    variant: 'primary',
    actions: [
      {
        label: 'Afficher le devis',
        variant: 'primary',
        onClick: () => {
          window.open(`${APP_CONFIG.appURL}/pro/devis/${signature.devisToken}?isAuthor`, '_blank');
        },
      },
      {
        label: 'Annuler la signature',
        disabled: !isAllowedToCancelSignature,
        disabledMessage: 'La signature ne peut pas être annulée',
        onClick: () => handleOpenModal(signature.uuid, hasTransactions),
      },
    ],
  };
};
