import React, { ChangeEvent } from 'react';

import classNames from 'classnames';

import {
  iconSuffixClassName,
  InputErrorText,
  InputHelperText,
  makeInputContainerClassnames,
  makeInputLabelClassnames,
  makeInputMainClassnames,
  textSuffixClassName,
} from './commons';
import { DenseSizes } from './types';

type CustomInputProps = {
  id: string;
  value: string;
  onChange?: (currentValue: string) => void;
  nativeOnChange?: (event: ChangeEvent) => void;
  label?: string | React.ReactElement;
  helperText?: string | React.ReactElement;
  prefix?: string;
  suffix?: React.ReactNode | string;
  error?: string;
  className?: string;
  renderingOptions?: {
    hideErrorText?: boolean;
    hideHelperText?: boolean;
    inputClassName?: string;
    containerClassName?: string;
    suffixClassName?: string;
  };
  dense?: DenseSizes;
};

export type Props = CustomInputProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'prefix' | 'label'>;

export const Input: React.FC<Props> = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  helperText,
  suffix,
  prefix,
  error,
  disabled,
  className,
  renderingOptions,
  hidden,
  dense,
  nativeOnChange,
  ...rest
}) => {
  const IconSuffix = typeof suffix === 'object' && suffix;
  const textSuffix = typeof suffix === 'string' && suffix;
  const hasPrefix = !!prefix;
  const hasLabel = !!label;

  return (
    <div className={className}>
      <div
        className={classNames(
          { [makeInputContainerClassnames({ disabled, error, small: dense === 'sm' })]: !hidden },
          renderingOptions?.containerClassName,
        )}
      >
        {prefix && (
          <span
            className={classNames(
              'pl-md pt-[20px] pr-0 pb-[5px]',
              'text-b2 flex items-center text-neutral-700',
            )}
          >
            {prefix}
          </span>
        )}
        <input
          className={classNames(
            makeInputMainClassnames({ disabled, hasPrefix, hasLabel, dense }),
            renderingOptions?.inputClassName,
          )}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={event =>
            nativeOnChange ? nativeOnChange(event) : onChange?.(event.target.value)
          }
          disabled={disabled}
          hidden={hidden}
          {...rest}
        />
        {IconSuffix && (
          <div
            className={classNames(iconSuffixClassName, 'ml-xs', {
              '!w-[22px]': dense === 'sm',
            })}
          >
            {IconSuffix}
          </div>
        )}
        {textSuffix && (
          <span
            className={classNames(textSuffixClassName, 'ml-xs', renderingOptions?.suffixClassName)}
          >
            {textSuffix}
          </span>
        )}
        {label && (
          <label className={makeInputLabelClassnames({ value, disabled, hasPrefix })} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
      {!renderingOptions?.hideErrorText && <InputErrorText error={error} />}
      {!renderingOptions?.hideHelperText && <InputHelperText helperText={helperText} />}
    </div>
  );
};
