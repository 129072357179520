import _sum from 'lodash/sum';

import { TypeBien } from '@travauxlib/shared/src/types';
import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

const getSizeProjectId = (totalPrice: number): BaseDePrixIdentifier => {
  if (totalPrice <= 40000) {
    return 'amenee_repli_materiel_chantier_moyen';
  }
  if (totalPrice <= 150000) {
    return 'amenee_repli_materiel_chantier_long';
  }
  return 'amenee_repli_materiel_chantier_tres_long';
};

const RATIO_DECHETTERIE_QUANTITY = 0.002;

export const installation = (
  configuration: Configuration,
  demolitionPrice: number,
  totalPrice: number,
): GenerationData[] => {
  const result: GenerationData[] = [];
  const gamme = configuration.mainGamme;
  if (configuration.typeLogement === TypeBien.Appartement) {
    result.push({
      ouvrageLabel: 'Installations et nettoyages',
      quantity: 1,
      id: 'protection_parties_communes',
    });
  }
  if (configuration.hasElevator) {
    result.push({
      ouvrageLabel: 'Installations et nettoyages',
      quantity: 1,
      id: 'protection_ascenseur',
    });
  }
  result.push({
    ouvrageLabel: 'Installations et nettoyages',
    quantity: 1,
    id: getSizeProjectId(totalPrice),
  });
  result.push({
    ouvrageLabel: 'Installations et nettoyages',
    quantity: _sum(configuration.locations.map(l => l.surface)),
    id: 'nettoyage_fin_chantier',
  });

  if (demolitionPrice > 0) {
    const quantityDechetterie = roundToOneDecimal(demolitionPrice * RATIO_DECHETTERIE_QUANTITY);
    const id =
      configuration.hasElevator || !configuration.floor || configuration.floor <= 2
        ? 'dechetterie_acces_facile'
        : 'dechetterie_acces_difficile';

    result.push({
      ouvrageLabel: 'Installations et nettoyages',
      id,
      quantity: quantityDechetterie,
    });
  }

  const locationsSol = configuration.answers?.sols?.sols?.flatMap(s => s.locationsUuid) || [];
  const locationsMursOuPlafonds = [
    ...(configuration.answers?.murs?.deposeMurs?.flatMap(d =>
      Object.keys(d.locationQuantities || {}),
    ) || []),
    ...(configuration.answers?.murs?.poseMurs?.flatMap(p =>
      Object.keys(p.locationQuantities || {}),
    ) || []),
    ...(configuration.answers?.murs?.peintures?.flatMap(p => p.locationsUuid) || []),
  ];

  for (let location of configuration.locations) {
    if (locationsSol.includes(location.uuid)) {
      if (locationsMursOuPlafonds.includes(location.uuid)) {
        result.push({
          ouvrageLabel: 'Installations et nettoyages',
          quantity: location.surface,
          id:
            gamme === Gamme.primo || gamme === Gamme.confort
              ? 'protection_legere'
              : 'protection_resistante',
          locationQuantities: {
            [location.uuid]: location.surface,
          },
        });
      }
    } else {
      result.push({
        ouvrageLabel: 'Installations et nettoyages',
        quantity: location.surface,
        id: 'protection_resistante',
        locationQuantities: {
          [location.uuid]: location.surface,
        },
      });
    }
  }
  return result;
};
