import React from 'react';

import { Form } from 'react-final-form';

import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { useUpdateClientAccount } from 'features/Project/api/useUpdateClientAccount';
import { ClientAccountFormFields } from 'features/Project/features/ProjectPage/components/ClientAccountFormFields';

import { useOpenClientContactModal } from './useOpenClientContactModal';

type Props = {
  projectUuid: string;
  clientAccount: ClientAccount;
  handleClose: () => void;
};

type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const EditClientAccountModal: React.FC<Props> = ({ projectUuid, clientAccount, handleClose }) => {
  const openClientContactModal = useOpenClientContactModal();
  const updateClientAccount = useUpdateClientAccount();

  return (
    <Form<FormProps>
      initialValues={{ ...clientAccount }}
      onSubmit={async values =>
        await updateClientAccount({
          ...values,
          uuid: clientAccount.uuid,
          projectUuid,
        })
      }
    >
      {({ handleSubmit }) => (
        <ModalContent
          isScrollable
          title="Modifier les informations"
          validateAction={{
            label: 'Enregistrer les modifications',
            onClick: () => {
              handleSubmit();
              handleClose();
              openClientContactModal(projectUuid);
            },
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: () => {
              handleClose();
              openClientContactModal(projectUuid);
            },
          }}
        >
          <form onSubmit={handleSubmit}>
            <ClientAccountFormFields isInEditForm />
          </form>
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenEditClientAccountModal = (): ((
  projectUuid: string,
  clientAccount: ClientAccount,
) => void) => {
  const openModal = useOpenModal();

  return (projectUuid, clientAccount) =>
    openModal(EditClientAccountModal, {
      closable: true,
      projectUuid,
      clientAccount,
    });
};
