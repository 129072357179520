import React from 'react';

import { useParams } from 'react-router';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { CircularProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Popover } from '@travauxlib/shared/src/components/DesignSystem/components/Popover';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { calculatePercentage } from '@travauxlib/shared/src/utils/math';

import { useChantier } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantier';

type Props = {
  name: string;
  totalDejaEncaisse: number;
  popoverTitle: string;
  tauxMontant: number;
  montantTotal: number;
};

export const PaymentStatus: React.FC<Props> = ({
  name,
  totalDejaEncaisse,
  popoverTitle,
  tauxMontant,
  montantTotal,
}) => {
  const { slug } = useParams<{ slug: string }>();
  const { chantier } = useChantier(slug!);

  const totalAEncaisser = montantTotal - totalDejaEncaisse;
  const progressValue = calculatePercentage(totalDejaEncaisse, montantTotal);

  return (
    <div
      role="section"
      aria-label={name}
      className="px-md py-sm flex bg-neutral-0 sm-desktop:ml-md rounded-md sm-desktop:w-[20.5rem]"
    >
      <CircularProgress value={progressValue} variant="primary" size="md" />
      <div className="ml-xs w-full">
        <div className="text-b1 font-bold flex items-center">
          {name}
          <Popover
            content={
              <div>
                <div className="text-b1 font-bold">{popoverTitle}</div>
                <div className="flex justify-between grow font-normal">
                  <span>Taux signé</span>
                  <span>{tauxMontant} %</span>
                </div>
                <Link variant="darkmode" inline target="_blank" to={chantier?.lemonwayAccountUrl}>
                  Voir le compte
                </Link>
              </div>
            }
            position="top-end"
            label="Fermer"
            trigger={(onClose, referenceProps) => (
              <span
                {...referenceProps}
                data-testid="details"
                onClick={onClose}
                className="flex items-center ml-xxs w-md text-neutral-600"
              >
                <HelpCircle />
              </span>
            )}
          />
        </div>
        <div className="text-neutral-600 text-b2 flex justify-between grow">
          <span>Déjà payé</span>
          <EURCurrency forceFullPattern amount={totalDejaEncaisse} data-testid={`${name}_paid`} />
        </div>
        <div className="text-neutral-600 text-b2 flex justify-between grow">
          <span>À encaisser</span>
          <EURCurrency forceFullPattern amount={totalAEncaisser} data-testid={`${name}_left`} />
        </div>
      </div>
    </div>
  );
};
