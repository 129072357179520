import { HybridChantier } from '@travauxlib/shared/src/types';
import { TypeSuivi } from '@travauxlib/shared/src/types/api/common/TypeSuivi';

export type FactureItem = {
  name: string;
  date: string;
  montant: number;
  axonautInvoiceId?: number;
};

export const getChantierFacturesItems = (chantier: HybridChantier): FactureItem[] => {
  if (!('proCompany' in chantier)) {
    return [];
  }

  return chantier.signatures
    .filter(s => s.axonautTravauxPlannerInvoiceId)
    .map(s => ({
      name: s.typeSuivi === TypeSuivi.TravauxPlannerWithService ? 'TP + Service hemea' : 'TP',
      date: s.dateSignature,
      montant:
        s.typeSuivi === TypeSuivi.TravauxPlannerWithService
          ? s.montantTravauxPlannerTTC + s.montantServiceHemeaTTC
          : s.montantTravauxPlannerTTC,
      axonautInvoiceId: s.axonautTravauxPlannerInvoiceId,
    }))
    .concat(
      chantier.signatures
        .filter(s => s.axonautArchiInterneInvoiceId)
        .map(s => ({
          name: 'Archi interne',
          date: s.dateSignature,
          montant: s.prixTotalTTC,
          axonautInvoiceId: s.axonautArchiInterneInvoiceId,
        })),
    )
    .concat(
      chantier.signatures
        .filter(s => s.axonautServiceHemeaInvoiceId)
        .map(s => ({
          name: 'Service hemea',
          date: s.dateSignature,
          montant: s.montantServiceHemeaTTC,
          axonautInvoiceId: s.axonautServiceHemeaInvoiceId,
        })),
    )
    .concat(
      chantier.signatures
        .filter(s => s.axonautCommissionInvoiceId)
        .map(s => ({
          name: 'Commission hemea',
          date: s.dateSignature,
          montant: s.montantCommissionTTC,
          axonautInvoiceId: s.axonautCommissionInvoiceId,
        })),
    );
};
