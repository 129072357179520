import _groupBy from 'lodash/groupBy';
import _sum from 'lodash/sum';

import { UnitValue } from '@travauxlib/shared/src/types';
import {
  BaseDePrix,
  Configuration,
  QuestionId,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';
import { units } from '@travauxlib/shared/src/utils/units';

import { lineGenerators } from './lineGenerators';

const resolveUnit = (unit?: UnitValue): string => {
  if (!unit) {
    return '';
  }
  const matching = units[unit];
  return matching.unitaire ? ' U' : ` ${matching.label}`;
};

type Item = {
  label: string;
  quantity: number;
  unit: string;
  isOption?: boolean;
  inProgress?: boolean;
  toCheck?: boolean;
};

export const getToggleCardItems = (
  baseDePrix: BaseDePrix,
  questionId: QuestionId,
  configuration: Configuration,
): Item[] => {
  const generateLines = lineGenerators[questionId];

  return Object.entries(
    _groupBy(
      generateLines(configuration).flatMap(
        ({ id, quantity, ouvrageLabel, inProgress, tagQuantity, hideTag, toCheck, isOption }) => {
          if (hideTag) {
            return [];
          }
          const baseDePrixLigne = id ? baseDePrix[id] : undefined;
          return [
            {
              id,
              ouvrage: ouvrageLabel,
              quantity,
              unite: baseDePrixLigne?.unite,
              inProgress,
              toCheck,
              isOption,
              tagQuantity,
            },
          ];
        },
      ),
      ligne => [ligne.ouvrage, ligne.isOption],
    ),
  ).map(([, lignes]) => {
    const label = lignes[0].ouvrage;
    const inProgress = lignes.some(l => l.inProgress);
    const isOption = lignes.some(l => l.isOption);

    const toCheck = lignes.some(l => l.toCheck);
    const lignesWithIds = lignes.filter(l => l.id);
    const hasOnlyQuantity = lignes.find(l => l.tagQuantity === 'only');
    const matchingLignes = hasOnlyQuantity
      ? lignesWithIds.filter(l => l.tagQuantity === 'only')
      : lignesWithIds.filter(l => l.tagQuantity !== 'skip');

    const quantity = roundToOneDecimal(_sum(matchingLignes.map(l => l.quantity)));

    const unit = matchingLignes[0]?.unite;

    return {
      label,
      quantity,
      isOption,
      unit: resolveUnit(unit),
      inProgress,
      toCheck,
    };
  });
};
