import React, { useState } from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';

import { FilterCriteria, useProCompanies } from 'features/Entreprises/api/useProCompanies';

import { Search } from './components/Search';
import { Table } from './components/Table';

type Props = {
  initialFormValues?: FilterCriteria;
  enableCompanyTypeAndStatus?: boolean;
  renderActions?: (proCompany: ProCompanyAdminView) => React.ReactElement;
};

const defaultInitialFormValues: FilterCriteria = {
  status: ['Activées', 'Labellisées'],
  companyTypes: [CompanyType.Pro, CompanyType.Archi, CompanyType.Mandataire],
  acceptsProjects: 'indifferent',
  enPause: 'indifferent',
  validAssurance: 'indifferent',
  kyc2: 'indifferent',
};

export const SearchCompany: React.FC<Props> = ({
  initialFormValues,
  enableCompanyTypeAndStatus,
  renderActions,
}) => {
  const initialValues = {
    ...defaultInitialFormValues,
    ...initialFormValues,
  };
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>(initialValues);
  const { proCompanies, isLoading } = useProCompanies(filterCriteria);

  return (
    <>
      <Search
        initialFormValues={initialFormValues}
        enableCompanyTypeAndStatus={enableCompanyTypeAndStatus}
        setFilterCriteria={setFilterCriteria}
      />
      {isLoading ? <Loader /> : <Table proCompanies={proCompanies} renderActions={renderActions} />}
    </>
  );
};
