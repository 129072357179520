import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Plomberie } from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { posePompeAChaleurId } from './ids';

import { RESEAU_QUANTITY_BY_ROOM } from '../defaultConfigurationValues';

export const posePompeAChaleur = (
  locationUuid: string,
  plomberie: Plomberie,
  configuration: Configuration,
  isOption?: boolean,
): GenerationData[] => [
  {
    ouvrageLabel: 'Pompe à chaleur air/eau',
    id: posePompeAChaleurId(plomberie, configuration),
    quantity: 1,
    tagQuantity: 'only',
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  },
  {
    ouvrageLabel: 'Pompe à chaleur air/eau',
    id: 'raccordement_electrique_pompe_a_chaleur',
    quantity: 1,
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  },
  {
    ouvrageLabel: 'Pompe à chaleur air/eau',
    id: 'reseau_alimentation_chauffage',
    quantity: RESEAU_QUANTITY_BY_ROOM,
    isOption,
    locationQuantities: {
      [locationUuid]: RESEAU_QUANTITY_BY_ROOM,
    },
  },
];
