import React, { useState } from 'react';

import { Field, Form } from 'react-final-form';

import Warning from '@travauxlib/shared/src/components/DesignSystem/assets/Warning.svg?react';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';

import { ChantierAdmin } from 'types';

import { proceedTracking } from './utils';

import {
  UpdateChantierThresholdsPayload,
  useUpdateChantierThresholds,
} from '../../api/useUpdateChantierThresholds';

type OwnProps = {
  chantier: ChantierAdmin;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

const hasThresholdBeenIncreasedOrDeleted = (initialValue?: number, newValue?: number): boolean => {
  if (!!initialValue && !newValue) {
    return true;
  }

  if (!!initialValue && !!newValue && initialValue < newValue) {
    return true;
  }

  return false;
};

export const shouldShowWarningModal = (
  chantier: ChantierAdmin,
  payload: UpdateChantierThresholdsPayload,
): boolean => {
  if (!chantier.montantGele) {
    return false;
  }

  if (chantier.pv.receptionChantier?.hasReserves || !chantier.receptionChantierThreshold) {
    // current threshold is leveeReserves
    return hasThresholdBeenIncreasedOrDeleted(
      chantier.leveeReservesThreshold,
      payload.leveeReservesThreshold,
    );
  }

  // current threshold is receptionChantier

  return hasThresholdBeenIncreasedOrDeleted(
    chantier.receptionChantierThreshold,
    payload.receptionChantierThreshold,
  );
};

export const ModifyThresholdModal: React.FC<Props> = ({ chantier, handleClose }) => {
  const [thresholdsToSubmit, setThresholdsToSubmit] = useState<UpdateChantierThresholdsPayload>();
  const { updateChantierThresholds } = useUpdateChantierThresholds();

  return thresholdsToSubmit ? (
    <Form
      initialValues={{
        isClientOk: false,
      }}
      initialValuesEqual={() => true}
      onSubmit={async () => {
        await updateChantierThresholds(thresholdsToSubmit);
        proceedTracking(chantier, thresholdsToSubmit);
        handleClose();
        return;
      }}
    >
      {({ handleSubmit }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Confirmer',
            type: 'submit',
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: () => setThresholdsToSubmit(undefined),
          }}
          title={
            <div className="w-full flex items-center">
              <ColoredCircle variant="warning">
                <Warning />
              </ColoredCircle>
              Libération de fonds
            </div>
          }
        >
          La modification des seuils va entraîner une libération de fonds immédiate d'une partie ou
          de l'intégralité du montant gelé.
          <br />
          L'accord du client est indispensable pour poursuivre.
          <br />
          <CheckboxField
            validate={isClientOk => (isClientOk ? undefined : 'Accord du client obligatoire')}
            id="isClientOk"
            name="isClientOk"
            label="Je confirme avoir un accord écrit du client pour la modification du seuil."
          />
        </ModalContent>
      )}
    </Form>
  ) : (
    <Form<UpdateChantierThresholdsPayload>
      onSubmit={async payload => {
        if (shouldShowWarningModal(chantier, payload)) {
          setThresholdsToSubmit(payload);
        } else {
          await updateChantierThresholds(payload);
          proceedTracking(chantier, payload);
          handleClose();
        }
      }}
      initialValues={chantier}
    >
      {({ handleSubmit, submitting }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            disabled: submitting,
            loading: submitting,
            label: 'Confirmer',
            type: 'submit',
          }}
        >
          <div className="flex flex-col gap-md grow">
            <Field
              name="receptionChantierThreshold"
              render={({ input: { value, onChange } }) => (
                <div className="flex items-center justify-between w-full">
                  <Checkbox
                    className="mr-xs"
                    id="receptionChantierThresholdActive"
                    checked={!!value}
                    disabled={!value}
                    label="Seuil réception chantier actif"
                    onChange={checked => {
                      if (!checked) {
                        onChange(null);
                      }
                    }}
                  />
                  <NumberInput
                    id="receptionChantierThreshold"
                    value={value}
                    onChange={onChange}
                    max={100}
                    label="Seuil réception chantier"
                  />
                </div>
              )}
            />
            <Field
              name="leveeReservesThreshold"
              render={({ input: { value, onChange } }) => (
                <div className="flex items-center justify-between w-full">
                  <Checkbox
                    disabled={!value}
                    className="mr-xs"
                    id="leveeReservesThresholdActive"
                    checked={!!value}
                    label="Seuil levée réserves actif"
                    onChange={checked => {
                      if (!checked) {
                        onChange(null);
                      }
                    }}
                  />
                  <NumberInput
                    id="leveeReservesThreshold"
                    value={value}
                    onChange={onChange}
                    max={100}
                    label="Seuil levée réserves"
                  />
                </div>
              )}
            />
          </div>
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenModifyThresholdModal = (): ((payload: { chantier: ChantierAdmin }) => void) => {
  const openModal = useOpenModal();

  const open = async ({ chantier }: { chantier: ChantierAdmin }): Promise<void> => {
    openModal(ModifyThresholdModal, {
      chantier,
      title: 'Modifier les seuils de libération des fonds',
      closable: true,
    });
  };

  return open;
};
