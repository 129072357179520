import React from 'react';

import { useNavigate } from 'react-router';

import { parametresBaseUrl } from '@travauxlib/shared/src//utils/urls';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ProCompanyUsersComponentsProps } from '@travauxlib/shared/src/features/ProCompanyUsersControlPannel/types';
import { ProUser } from '@travauxlib/shared/src/types';

import { useOpenHandleProCompanyUserModal } from '../../hooks/useOpenAddOrUpdateProUserModal';
import { useOpenConfirmRestoreUserModal } from '../../hooks/useOpenConfirmRestoreUserModal';

export const ProCompanyUserTable: React.FC<ProCompanyUsersComponentsProps> = ({
  currentUser,
  proUsers,
  onSubmit,
  archiveProUserAccount,
  updateProUser,
  handleSignInAsPro,
  slug,
}) => {
  const navigate = useNavigate();
  const openHandleProCompanyUserModal = useOpenHandleProCompanyUserModal();
  const openHandleConfirmRestoreModal = useOpenConfirmRestoreUserModal(updateProUser);

  const handleOpenEditUserModal = (proUser: ProUser): void => {
    proUser.archived
      ? openHandleConfirmRestoreModal({ proUser, updateProUser, slug })
      : openHandleProCompanyUserModal({
          onSubmit,
          proUser,
          archiveUser: archiveProUserAccount,
          slug,
        });
  };

  return (
    <Table<ProUser>
      columnConfigs={[
        { name: 'Nom complet', columnKey: 'fullName' },
        { name: 'Email', columnKey: 'email' },
        { name: 'Téléphone', columnKey: 'phoneNumber' },
        {
          name: 'Rôle',
          columnKey: 'isProCompanyAdmin',
          renderValue: isProCompanyAdmin => (
            <Tag
              size="md"
              variant={isProCompanyAdmin ? 'success' : 'light'}
              label={isProCompanyAdmin ? 'Administrateur' : 'Utilisateur'}
            />
          ),
        },
        {
          headless: true,
          hideSort: true,
          renderValue: (_, proUser) => {
            if (handleSignInAsPro) {
              return (
                <div className="!text-right">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={event => {
                      event.stopPropagation();
                      handleSignInAsPro(proUser.id || 0);
                    }}
                  >
                    Se connecter à son compte
                  </Button>
                </div>
              );
            } else {
              return <></>;
            }
          },
        },
      ]}
      onRowClick={proUser => {
        if (proUser !== currentUser) {
          handleOpenEditUserModal(proUser);
        } else {
          navigate(parametresBaseUrl);
        }
      }}
      items={currentUser ? [currentUser, ...proUsers] : proUsers}
    />
  );
};
