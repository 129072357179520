import React from 'react';

import { Form } from 'react-final-form';
import { useNavigate } from 'react-router';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { stringifyParams } from '@travauxlib/shared/src/utils/urls';

export type FormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  uuid?: string;
};

type Props = {
  initialValues: FormValues;
};

export const LeadForm: React.FC<Props> = ({ initialValues }) => {
  const navigate = useNavigate();

  return (
    <Form<FormValues>
      initialValues={initialValues}
      onSubmit={values => {
        navigate(
          {
            search: `?${stringifyParams(values)}`,
          },
          { replace: true },
        );
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Card className="mb-lg overflow-visible">
            <AutoSubmitForm debounce={800} />
            <div className="grid grid-cols-2 gap-xs md-desktop:grid-cols-4">
              <div>
                <InputField name="firstName" id="firstName" label="Prénom" />
              </div>
              <div>
                <InputField name="lastName" id="lastName" label="Nom" />
              </div>
              <div>
                <InputField name="email" id="email" label="Email" />
              </div>
              <div>
                <InputField id="phoneNumber" name="phoneNumber" label="Téléphone" />
              </div>
            </div>
          </Card>
        </form>
      )}
    </Form>
  );
};
