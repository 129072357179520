import { Person, PersonRole } from '@travauxlib/shared/src/types';
import { personRoleOptions } from '@travauxlib/shared/src/utils/constants';

import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  {
    name: 'role',
    options: personRoleOptions,
    fieldType: 'dropdown',
    initialValue: PersonRole.TeamLeadPartenariat,
  },
  {
    name: 'departement',
    fieldType: 'input',
  },
] as const;

export class CreateEmployeeStep extends BaseStep<
  {},
  { employee: Person },
  typeof fields,
  { employee: Person }
> {
  public name: string = 'Create employee';

  public fields = fields;

  async execute({
    seed,
    departement,
    apiURL,
    adminURL,
    request,
    role,
  }: ExecuteParams<{}, typeof fields>): Promise<{ employee: Person }> {
    super.log('Creating employee...');

    const person = {
      firstName: 'John',
      lastName: `CDP_${seed}`,
      email: `john.CDP_${seed}@example.com`,
      jobTitle: 'osef',
      arrivalDate: '2020-10-01',
      role: role || 'team_lead_partenariat',
      config: {
        leadAssignment: {
          budgets: [
            '10000-30000',
            '30000-50000',
            '50000-100000',
            '100000-250000',
            '+250000',
            '0-10000',
          ],
          acceptPaid: true,
          acceptHemea: true,
          acceptPartner: true,
          availability: true,
          departments: departement
            ? [departement]
            : [
                '01',
                '31',
                '33',
                '38',
                '42',
                '64',
                '69',
                '73',
                '74',
                '75',
                '77',
                '78',
                '81',
                '82',
                '91',
                '92',
                '93',
                '94',
                '95',
              ],
        },
      },
    };

    const employee = await request<Person>(`${apiURL}/admin/employees/persons`, {
      method: 'POST',
      body: person,
    });

    super.log(`Employee created : ${adminURL}/trombinoscope/${employee.uuid}`);

    super.setCreatedEntities({ employee });

    return { employee };
  }
}
