import React, { useEffect } from 'react';

import { Outlet } from 'react-router';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { ListHeader } from './components/ListHeader';
import { CheckStatuses } from './features/CheckStatuses';
import { ClubPro as ClubProSearch } from './features/ClubPro';
import { Entreprise as EditEntreprise } from './features/Entreprise';
import { ClubPro } from './features/Entreprise/components/ClubPro';
import { Consultation } from './features/Entreprise/components/Consultation';
import { Devis } from './features/Entreprise/components/Devis';
import { GlobalInfos } from './features/Entreprise/components/GlobalInfos';
import { InsurancesComponent } from './features/Entreprise/components/Insurances';
import { Payment } from './features/Entreprise/components/Payment';
import { Settings } from './features/Entreprise/components/Settings';
import { SiteVitrine } from './features/Entreprise/components/SiteVitrine';
import { ProjectListing } from './features/Entreprise/components/SiteVitrine/ProjectListing';
import { ReviewListing } from './features/Entreprise/components/SiteVitrine/ReviewListing';
import { ProCompanyUsersControlPannelContainer } from './features/Entreprise/components/UsersControlPannelContainer';
import { SearchCompany } from './features/SearchCompany';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onEntreprisesViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.entreprises} />
      <Outlet />
    </>
  );
};

const Search: React.FC = () => (
  <>
    <ListHeader />
    <Outlet />
  </>
);

export const Entreprises = {
  Index,
  Search,
  EditEntreprise,
  ProCompanyUsersControlPannelContainer,
  SiteVitrine,
  Settings,
  Payment,
  Consultation,
  ClubPro,
  InsurancesComponent,
  ClubProSearch,
  SearchCompany,
  CheckStatuses,
  GlobalInfos,
  Devis,
  ProjectListing,
  ReviewListing,
};
