import { cuisine } from '@travauxlib/shared/src/features/Configurateur/utils/cuisine/lineGenerators';
import { elec } from '@travauxlib/shared/src/features/Configurateur/utils/elec/lineGenerators';
import { menuiseriesExterieures } from '@travauxlib/shared/src/features/Configurateur/utils/menuiseriesExterieures/lineGenerators';
import { menuiseriesInterieures } from '@travauxlib/shared/src/features/Configurateur/utils/menuiseriesInterieures/lineGenerators';
import { murs } from '@travauxlib/shared/src/features/Configurateur/utils/murs/lineGenerators';
import { ouverturesDemolition } from '@travauxlib/shared/src/features/Configurateur/utils/ouverturesDemolition/lineGenerators';
import { platrerie } from '@travauxlib/shared/src/features/Configurateur/utils/platrerie/lineGenerators';
import { plomberie } from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/lineGenerators';
import { salleDeBain } from '@travauxlib/shared/src/features/Configurateur/utils/salleDeBain/lineGenerators';
import { sols } from '@travauxlib/shared/src/features/Configurateur/utils/sols/lineGenerators';
import {
  Configuration,
  GenerationData,
  QuestionId,
} from '@travauxlib/shared/src/types/api/domain/configurateur';

export const lineGenerators: {
  [K in QuestionId]: (configuration: Configuration) => GenerationData[];
} = {
  ouverturesDemolition,
  murs,
  sols,
  plomberie,
  elec,
  menuiseriesInterieures,
  menuiseriesExterieures,
  platrerie,
  salleDeBain,
  cuisine,
};
