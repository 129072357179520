import React from 'react';

import Spinner from '@travauxlib/shared/src/components/DesignSystem/assets/Spinner.svg?react';

import { FileDropzonePublicProps } from './types';

import { ProgressBar } from '../ProgressBar';

type Props = { progress?: number; displayMode: FileDropzonePublicProps['displayMode'] };

const computeProgressWording = (progress?: number): string => {
  if (!progress) {
    return 'Téléchargement en cours ...';
  }
  if (progress < 100) {
    return `Téléchargement... ${progress}%`;
  }
  return 'Traitement en cours ...';
};

export const DropZoneProgress: React.FC<Props> = ({ progress, displayMode }) => {
  if (displayMode === 'pictures-wall') {
    return <Spinner className="animate-spin size-lg text-primary" data-testid="loading-icon" />;
  }

  return (
    <>
      {!!progress && <ProgressBar value={progress} size="sm" variant="primary" />}
      <div className="text-xs mt-xs sm-desktop:text-b2 text-neutral-600 font-medium text-center">
        {computeProgressWording(progress)}
      </div>
    </>
  );
};
