import React from 'react';

import dayjs from 'dayjs';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Person } from '@travauxlib/shared/src/types';
import { personRoleToLabel } from '@travauxlib/shared/src/utils/constants';

import { useIsAllowed } from 'hooks/useIsAllowed';

type Props = {
  person: Person;
};

export const InformationCard: React.FC<Props> = ({ person }) => {
  const {
    firstName,
    lastName,
    nickname,
    birthDate,
    email,
    mobilePhoneNumber,
    jobDescription,
    jobTitle,
    role,
  } = person;
  const roleLabel = role && personRoleToLabel[role];
  const isAllowedToManagePerson = useIsAllowed('Person', 'manage');

  return (
    <Card className="h-full">
      <div className="flex justify-between items-center">
        <h4 className="mb-0 font-bold">Informations</h4>
        {isAllowedToManagePerson && (
          <IconButtonLink to={`/trombinoscope/${person.uuid}/edit`} size="sm">
            <Edit />
          </IconButtonLink>
        )}
      </div>

      <ul className="list-none pl-0">
        <li className="text-b2 my-xs">
          <strong>Poste &nbsp; </strong>
          {jobTitle ?? 'Pas de titre'}
        </li>
        <li className="text-b2 my-xs">
          <strong>Description &nbsp; </strong>
          {jobDescription ?? 'Pas de description'}
        </li>
        <li className="text-b2 my-xs">
          <strong>Rôle &nbsp; </strong>
          {roleLabel ?? 'Pas de rôle'}
        </li>
      </ul>

      <hr />
      <ul className="list-none pl-0">
        <li className="text-b2 my-xs">
          <strong>Full Name</strong> &nbsp; {firstName} {lastName}
          {nickname && <span> alias {nickname}</span>}
        </li>
        <li className="text-b2 my-xs">
          <strong>Mobile</strong> &nbsp; {mobilePhoneNumber ?? 'N/A'}
        </li>
        <li className="text-b2 my-xs">
          <strong>Email</strong> &nbsp; {email ?? 'N/A'}
        </li>
        {birthDate && (
          <li className="text-b2 my-xs flex">
            <strong>Anniversaire</strong>
            &nbsp; {dayjs(birthDate).format('LL')}
          </li>
        )}
      </ul>
    </Card>
  );
};
