import { S3FileView } from './S3FileView';

export enum ChantierPvType {
  ReceptionChantier = 'receptionChantier',
  LeveeReserves = 'leveeReserves',
}

export type ChantierPvView = {
  uuid: string;
  chantierUuid: string;
  hasReserves: boolean;
  pvType: ChantierPvType;
  rejectionReason?: String;
  dateSignature?: string;
  createdAt: string;
  updatedAt: string;
  validatedAt?: string;
  rejectedAt?: string;
  archivedAt?: string;
  isPending: boolean;
  isValidated: boolean;
  isRejected: boolean;
  documents: S3FileView[];
};
