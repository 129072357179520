import { useParams } from 'react-router';

import { PartnerAgency } from 'types';

import { usePartner } from './usePartner';

export const useAgency = (): PartnerAgency | undefined => {
  const { agencyUuid } = useParams();
  const partner = usePartner();

  return partner?.agencies.find(a => a.uuid === agencyUuid);
};
