import {
  EndChantierEventProperties,
  EndChantierThresholdType,
} from '@travauxlib/shared/src/utils/tracking';

import { UpdateChantierThresholdsPayload } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useUpdateChantierThresholds';
import { ChantierAdmin } from 'types';
import { FinChantier } from 'utils/tracking/FInChantierTracking';

export const proceedTracking = (
  chantier: ChantierAdmin,
  payload: UpdateChantierThresholdsPayload,
): void => {
  type ThresholdKey = keyof Pick<
    ChantierAdmin,
    'receptionChantierThreshold' | 'leveeReservesThreshold'
  >;

  const trackThresholdChange = (
    thresholdKey: ThresholdKey,
    thresholdType: EndChantierThresholdType,
  ): void => {
    const oldValue = chantier[thresholdKey];
    const newValue = payload[thresholdKey];

    const hasChanged = oldValue !== newValue;

    if (hasChanged) {
      FinChantier.onThresholdModified({
        [EndChantierEventProperties.EndChantierThresholdType]: thresholdType,
        [EndChantierEventProperties.EndChantierThresholdOldValue]: oldValue ?? 0,
        [EndChantierEventProperties.EndChantierThresholdNewValue]: newValue ?? 0,
      });
    }
  };

  trackThresholdChange('receptionChantierThreshold', EndChantierThresholdType.ReceptionChantier);
  trackThresholdChange('leveeReservesThreshold', EndChantierThresholdType.LeveeReserves);
};
