import React, { useState } from 'react';

import classNames from 'classnames';
import { Outlet } from 'react-router';

import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { DesktopSidebar } from './DesktopSidebar';
import { Header } from './Header';
import { MobileSidebar } from './MobileSidebar';

export const Navigation: React.FC = () => {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <div>
      <Header openSidebar={() => setShowMobileSidebar(true)} />
      <div className="sm-desktop:flex mt-3xl sm-desktop:mt-0">
        {isTabletOrMobile ? (
          <MobileSidebar
            showMobileSidebar={showMobileSidebar}
            setShowMobileSidebar={setShowMobileSidebar}
          />
        ) : (
          <DesktopSidebar />
        )}
        <div
          className={classNames(
            'w-full mx-auto sm-desktop:px-xl px-md pt-md bg-neutral-100 min-h-dvh shrink',
            showMobileSidebar ? 'overflow-y-hidden fixed' : 'overflow-auto',
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
