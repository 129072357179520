import { useMemo } from 'react';

import _partition from 'lodash/partition';

import { FournituresWithLocation } from '@travauxlib/shared/src/types';

import { Totals } from '../types';

type Result = {
  fournituresWithRef: FournituresWithLocation[];
  fournituresWithoutRef: FournituresWithLocation[];
} & Totals;

export const useFournituresByTabAndTotals = (allFournitures: FournituresWithLocation[]): Result => {
  const { ref: fournituresWithRef, withoutRef: fournituresWithoutRef } = useMemo(
    () =>
      allFournitures.reduce(
        (acc, element) => {
          const [fournituresWithRef, fournituresWithoutRef] = _partition(
            element.fournitures,
            'reference',
          );

          const fournituresWithLocationAndRef =
            fournituresWithRef.length > 0 ? [{ ...element, fournitures: fournituresWithRef }] : [];

          const fournituresWithLocationAndWithoutRef =
            fournituresWithoutRef.length > 0
              ? [{ ...element, fournitures: fournituresWithoutRef }]
              : [];

          return {
            ref: [...acc.ref, ...fournituresWithLocationAndRef],
            withoutRef: [...acc.withoutRef, ...fournituresWithLocationAndWithoutRef],
          };
        },
        {
          ref: [],
          withoutRef: [],
        },
      ),
    [allFournitures],
  );

  const withRefCount = fournituresWithRef.flatMap(f => f.fournitures).length;
  const withoutRefCount = fournituresWithoutRef.flatMap(f => f.fournitures).length;
  const total = withRefCount + withoutRefCount;

  return { fournituresWithRef, fournituresWithoutRef, withRefCount, withoutRefCount, total };
};
