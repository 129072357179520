import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';
import { ChantierAdmin } from 'types';

const handleToggleRelanceProvisionChantier = async (chantier: ChantierAdmin): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/config`;

  await request(requestURL, {
    method: 'PATCH',
    body: { enableRelanceProvision: !chantier.enableRelanceProvision },
  });
};

export const useToggleRelanceProvision = (): {
  toggleRelanceProvision: typeof handleToggleRelanceProvisionChantier;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery(
    handleToggleRelanceProvisionChantier,
    'de la mise à jour de la config du chantier',
    'La config du chantier a bien été mise à jour.',
  );

  return { toggleRelanceProvision: query, isLoading };
};
