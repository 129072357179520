import React from 'react';

import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { addPlurielToWords } from '@travauxlib/shared/src/utils/wording';

import { Totals } from '../types';

export type Props = {
  totals: Totals;
  completionPercentage: number;
};

export const ProgressReference: React.FC<Props> = ({ totals, completionPercentage }) => {
  const { withRefCount, total } = totals;
  const [reference, completee] = addPlurielToWords(total, ['référence', 'complétée']);

  return (
    <div className="sm-desktop:mt-sm">
      <div className="text-neutral-700 text-b2 sm-desktop:text-b1 mb-xxs">
        <span className="font-bold text-neutral-800 mr-xxs">
          {withRefCount}/{total}
        </span>
        {reference} {completee}
      </div>
      <ProgressBar
        value={completionPercentage}
        variant={withRefCount === total ? 'success' : 'primary'}
      />
    </div>
  );
};
