import React from 'react';

import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';

import { SearchCompany } from 'features/Entreprises/features/SearchCompany';
import { Consultation, ConsultationInfo } from 'types';

import { ConsultationActions } from './ConsultationActions';

type Props = {
  companyTypes?: CompanyType[];
  consultationInfo: ConsultationInfo;
  consultations: Consultation[];
  project: ProjectView;
};

export const ConsultationManual: React.FC<Props> = ({
  consultationInfo,
  companyTypes,
  consultations,
  project,
}) => (
  <SearchCompany
    initialFormValues={{
      acceptsProjects: 'hemea',
      companyTypes,
    }}
    enableCompanyTypeAndStatus={false}
    renderActions={proCompany => (
      <ConsultationActions
        proCompany={proCompany}
        consultationInfo={consultationInfo}
        consultations={consultations}
        project={project}
      />
    )}
  />
);
