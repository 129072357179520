import { apiCreateLead, APIResponse } from '../../api/lead';
import { BaseStep, ExecuteParams } from '../BaseStep';

const fields = [
  { name: 'firstNameLead' },
  { name: 'lastNameLead' },
  { name: 'affiliateToken' },
  { name: 'phoneNumber' },
  { name: 'departement' },
] as const;

type Input = { seed: string };

export class CreateLeadStep extends BaseStep<
  Input,
  { email?: string; token: string; clientUuid: string },
  typeof fields,
  {
    lead: APIResponse;
  }
> {
  public name: string = 'Create Lead';

  public fields = fields;

  public async execute({
    seed,
    firstNameLead,
    lastNameLead,
    affiliateToken,
    departement,
    phoneNumber,
    apiURL,
    request,
  }: ExecuteParams<Input, typeof fields>): Promise<{
    email?: string;
    token: string;
    clientUuid: string;
  }> {
    const ret = await apiCreateLead(
      apiURL,
      { seed, firstNameLead, lastNameLead, affiliateToken, departement, phoneNumber },
      request,
    );

    if (ret.createdClientAccount) {
      super.log(`Created client account with email : ${ret.createdClientAccount.email}`);
    } else {
      super.log(`Linked existing account with email : ${ret.existingAccountEmail}`);
    }

    super.setCreatedEntities({ lead: ret });

    return Promise.resolve({
      clientUuid: ret.createdClientAccount.uuid,
      email: ret.createdClientAccount?.email ?? ret.existingAccountEmail,
      token: ret.leadToken,
    });
  }
}
