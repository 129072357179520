import React, { useState } from 'react';

import classNames from 'classnames';
import Dropzone, { Accept } from 'react-dropzone';

import Upload from '@travauxlib/shared/src/components/DesignSystem/assets/UploadFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { truncate, formatFileSize } from '../../utils/format';
import { InputErrorText } from '../DesignSystem/components/Input/commons';

export type Props = {
  alreadyUploaded?: boolean;
  sizeLimit?: number;
  disabled?: boolean;
  alignLeft?: boolean;
  onChange: (value: File & { preview: string }) => void;
  onReject?: (rejectionMessage: string) => void;
  label?: string;
  showIcon?: boolean;
  truncated?: boolean;
  error?: string;
  id?: string;
  isUploading?: boolean;
  fullwidth?: boolean;
  accept?: Accept;
};

export const DEFAULT_SIZE_LIMIT = 8000000;

const getRejectionMessage = (size: number, sizeLimit: number): string =>
  size > sizeLimit
    ? `Votre fichier a une taille trop importante (${formatFileSize(
        size,
      )}). La taille maximum autorisée est ${formatFileSize(sizeLimit)}.`
    : "Le type de fichier n'est pas correct.";

export const FileUpload: React.FC<Props> = ({
  alreadyUploaded,
  sizeLimit = DEFAULT_SIZE_LIMIT,
  disabled,
  label,
  showIcon,
  alignLeft,
  error,
  truncated,
  id,
  onChange,
  onReject,
  isUploading: isUploadingProps,
  fullwidth,
  accept,
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  return (
    <div className="flex flex-col whitespace-nowrap">
      <div className={classNames('!flex', { '!flex-row-reverse': !alignLeft }, 'h-fit')}>
        <Dropzone
          accept={accept}
          multiple={false}
          maxSize={sizeLimit}
          onDrop={() => setIsUploading(true)}
          onDropAccepted={files => {
            setIsUploading(false);
            return onChange(
              Object.assign(files[0], {
                preview: URL.createObjectURL(files[0]),
              }),
            );
          }}
          onDropRejected={([{ file }]) => {
            setIsUploading(false);
            return onReject && onReject(getRejectionMessage(file.size, sizeLimit));
          }}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => {
            // This is maybe a bit hacky: should we do a forwardRef on button instead ?
            const { ref, ...rest } = getRootProps();
            return (
              <Button
                variant="secondary"
                data-testid="upload"
                disabled={disabled}
                role="button"
                fullwidth={fullwidth}
                {...rest}
                className={classNames('flex items-center', {
                  '!inline-block !truncate': truncated,
                })}
                //eslint-disable-next-line
                style={truncated ? { maxWidth: '160px' } : {}}
                loading={isUploading || isUploadingProps}
              >
                <label htmlFor={id} className="!cursor-pointer !mb-0">
                  {label ? truncate(label, 10) : alreadyUploaded ? 'Remplacer' : 'Ajouter'}
                </label>
                <input id={id} data-testid="input" {...getInputProps()} />
                {showIcon && (
                  <div className="inline-block float-right">
                    <Upload className="w-md h-md" />
                  </div>
                )}
              </Button>
            );
          }}
        </Dropzone>
      </div>
      {error && <InputErrorText error={error} />}
    </div>
  );
};
