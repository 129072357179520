import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { DownloadableFile, UploadFilesPayload } from '@travauxlib/shared/src/types';

import { Props as FileDropzoneProps, FileDropzone as RawFileDropzone } from './FileDropZoneWrapper';

type WrapperProps = {
  uploadFiles: (props: UploadFilesPayload) => Promise<DownloadableFile[]>;
};

const FileDropzoneWrapper: React.FC<WrapperProps & FieldRenderProps<DownloadableFile[]>> = ({
  input: { value, onChange, multiple },
  meta,
  uploadFiles,
  canRemoveFile,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <div>
      <RawFileDropzone
        value={value}
        uploadFiles={uploadFiles}
        onChange={onChange}
        multiple={multiple}
        error={touched && error}
        onRemoveFile={
          canRemoveFile
            ? fileToRemove => onChange(value.filter(file => file.uuid !== fileToRemove.uuid))
            : undefined
        }
        {...rest}
      />
    </div>
  );
};
type Props = {
  name: string;
  canRemoveFile?: boolean;
  onClickFile?: (file: DownloadableFile) => void;
} & Omit<FileDropzoneProps, 'value' | 'onChange'> &
  UseFieldConfig<DownloadableFile[]>;

export const FileDropzoneField: React.FC<Props> = props => (
  <Field component={FileDropzoneWrapper} {...props} />
);
