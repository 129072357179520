import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { useFormState } from 'react-final-form';

import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { requiredFieldsForConsultation } from '@travauxlib/shared/src/features/Configurateur/utils/constants';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { TypologieProject } from '@travauxlib/shared/src/types';

type Props<K extends keyof TypologieProject> = {
  field: K;
  title: string;
  icon: React.ReactElement;
  label: string | React.ReactElement;
  suffix?: string;
  isFieldRequired?: boolean;
};

export function NumberInputChoice<K extends keyof TypologieProject>({
  field,
  title,
  icon,
  label,
  suffix,
  isFieldRequired,
}: Props<K>): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);
  const { values } = useFormState();
  const { [field]: value, disabled, hideUnfilled } = values;
  const isRequired = isFieldRequired || requiredFieldsForConsultation.includes(field);
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  if (!value && hideUnfilled) {
    return null;
  }

  if (!isOpen) {
    return (
      <div className="flex mb-xs items-start justify-start">
        <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">{icon}</div>
        <div
          onClick={() => {
            if (!disabled) {
              setIsOpen(true);
            }
          }}
          className={classNames('flex items-center gap-xs', !disabled && 'cursor-pointer')}
        >
          <span>{title} : </span>
          {value !== undefined ? (
            label
          ) : (
            <InlineAlert
              children={
                isRequired ? (
                  <span className="text-error-800">Obligatoire</span>
                ) : (
                  <span className="normal-case text-info-800">À définir</span>
                )
              }
              variant={isRequired ? 'error' : 'info'}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex mb-xs items-start justify-start">
      <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">{icon}</div>
      <div ref={containerRef}>
        <NumberInputField
          dense="sm"
          autoFocus
          id={field}
          name={field}
          value={value}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setIsOpen(false);
            }
          }}
          suffix={suffix}
        />
      </div>
    </div>
  );
}
