import React, { useState } from 'react';

import SearchEmptyState from '@travauxlib/shared/src/components/DesignSystem/assets/SearchEmptyState.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { FileUpload } from '@travauxlib/shared/src/components/FileUpload';

import { useAdvanceStatusCommissions } from 'features/GestionFacturation/api/useAdvanceStatusCommissions';
import { useUpdateCommission } from 'features/GestionFacturation/api/useUpdateCommission';
import { useUploadFactureCommission } from 'features/GestionFacturation/api/useUploadFactureCommission';
import { PartnerCommission } from 'types';

import { useOpenEditCommissionModal } from './hooks/useOpenEditCommissionModal';
import { commonTableColumns } from './utils/commonTableColumns';
import { shouldDisableTableRow } from './utils/shouldDisableTableRow';

type Props = {
  title: string;
  commissions: PartnerCommission[];
  ctaLabel?: string;
};

export const CommissionTable: React.FC<Props> = ({ commissions, title, ctaLabel }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { updateCommission } = useUpdateCommission();
  const uploadFactureCommission = useUploadFactureCommission();
  const { advanceStatusCommissions, isPending } = useAdvanceStatusCommissions();
  const openEditCommissionModal = useOpenEditCommissionModal();

  const handleClickItem = (item: PartnerCommission): void => {
    const isChecked = selectedItems.includes(item.uuid);
    if (isChecked) {
      setSelectedItems(selectedItems.filter(uuid => uuid !== item.uuid));
    } else {
      setSelectedItems([...selectedItems, item.uuid]);
    }
  };

  const checkboxColumn = {
    hideSort: true,
    name: 'Sélectionnée',
    renderValue: (_: unknown, item: PartnerCommission) => (
      <Checkbox
        onClick={e => {
          e.stopPropagation();
        }}
        data-testid="select-item"
        disabled={shouldDisableTableRow(item)}
        checked={selectedItems.includes(item.uuid)}
      />
    ),
  };

  const documentColumn = {
    name: 'Facture',
    columnKey: 'factureUrl' as const,
    renderValue: (factureUrl: string | undefined, item: PartnerCommission) => {
      const handleUpload = (file: File): void => {
        uploadFactureCommission({ commissionUuid: item.uuid, file });
      };
      if (shouldDisableTableRow(item)) {
        return (
          <>
            <InlineAlert variant="error">Facture manquante</InlineAlert>
            <FileUpload onChange={handleUpload} />
          </>
        );
      }

      if (!factureUrl) {
        return <FileUpload onChange={handleUpload} />;
      }

      return (
        <>
          <a href={factureUrl} target="_blank">
            Facture
          </a>
          <FileUpload onChange={handleUpload} />
        </>
      );
    },
  };

  return (
    <Card className="!rounded-md mb-md !overflow-visible" state="elevated">
      <div className="text-h4 font-bold mb-md">{title}</div>
      {commissions.length === 0 ? (
        <EmptyState description="Aucune commission" illustration={<SearchEmptyState />} />
      ) : (
        <div>
          <div className="mb-md">
            <Table
              disableRow={shouldDisableTableRow}
              columnConfigs={[
                ...(ctaLabel ? [checkboxColumn] : []),
                ...commonTableColumns,
                ...(commissions[0].status === 'to_bill' ? [documentColumn] : []),
              ]}
              rowActions={item =>
                ['pending', 'to_send'].includes(item.status)
                  ? [
                      {
                        label: 'Modifier',
                        action: () => {
                          openEditCommissionModal({ commission: item, isCreate: false });
                        },
                      },
                      {
                        label: item.archived ? 'Désarchiver' : 'Archiver',
                        action: () => {
                          updateCommission({ ...item, archived: !item.archived });
                        },
                      },
                      {
                        label: 'Dupliquer',
                        action: async () => {
                          openEditCommissionModal({ commission: item, isCreate: true });
                        },
                      },
                    ]
                  : []
              }
              itemsPerPage={50}
              items={commissions}
              onRowClick={handleClickItem}
            />
          </div>
          {ctaLabel && (
            <div className="text-right">
              <Button
                disabled={selectedItems.length === 0}
                type="button"
                loading={isPending}
                onClick={() => {
                  advanceStatusCommissions(selectedItems);
                  setSelectedItems([]);
                }}
              >
                {ctaLabel}
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
