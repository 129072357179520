import React from 'react';

import { useNavigate } from 'react-router';

import Checked from '@travauxlib/shared/src/components/DesignSystem/assets/Checked.svg?react';
import ContactPage from '@travauxlib/shared/src/components/DesignSystem/assets/ContactPage.svg?react';
import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import ManageAccounts from '@travauxlib/shared/src/components/DesignSystem/assets/ManageAccounts.svg?react';
import Pipedrive from '@travauxlib/shared/src/components/DesignSystem/assets/Pipedrive.svg?react';
import ProjectError from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectError.svg?react';
import ProjectUpdated from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectUpdated.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { getProjectURL, useSignInAsClient } from 'api/useSignInAsClient';
import { useCloseLead } from 'features/Project/api/useCloseLead';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { useReopenLead } from 'features/Project/api/useReopenLead';

import { CloseLeadModal } from './CloseLeadModal';
import { Comments } from './Comments';
import { ReopenedLeadModal } from './ReopenedLeadModal';

import { useOpenClientContactModal } from '../hooks/useOpenClientContactModal';
import { useOpenRenameProjectModal } from '../hooks/useOpenRenameProjectModal';

type Props = {
  projectContext: ProjectContext;
};

export const HeaderActions: React.FC<Props> = ({ projectContext }) => {
  const handleSignIn = useSignInAsClient();
  const { closeLead } = useCloseLead();
  const { reopenLead } = useReopenLead();
  const navigate = useNavigate();
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();
  const openClientContactModal = useOpenClientContactModal();
  const { project, clientAccounts, lead } = projectContext;
  const [firstCreatedClientAccount] = clientAccounts;
  const openRenameProjectModal = useOpenRenameProjectModal();

  const pipedriveActions = (isLeadClosed: boolean): ActionListItem[] => [
    isLeadClosed
      ? {
          type: 'action',
          disabled: !project.leadId,
          onClick: () => {
            if (!!project.leadId) {
              reopenLead({ leadId: project.leadId }).then(() => {
                openModal(ReopenedLeadModal, {
                  closable: true,
                });
              });
            }
          },
          label: 'Réouvrir le projet',
          icon: <ProjectUpdated />,
        }
      : {
          type: 'action',
          onClick: () => {
            if (!!project.leadId) {
              openModal(CloseLeadModal, {
                closable: true,
                onCloseLead: closeLead,
                leadId: project.leadId,
              });
            }
          },
          label: 'Marquer comme perdu',
          icon: <ProjectError />,
        },
    {
      type: 'action',
      onClick: () => window.open(project.pipedriveDealUrl, '_blank'),
      label: 'Accéder à Pipedrive',
      icon: <Pipedrive />,
    },
  ];

  const dotsMenuItems: ActionListItem[] = [
    {
      type: 'action',
      onClick: () => navigate('actions'),
      label: 'Valider une action du client',
      icon: <Checked />,
    },
    {
      type: 'action',
      onClick: () => openClientContactModal(projectContext.project.uuid),
      label: 'Gestion des contacts',
      icon: <ContactPage />,
    },
    {
      type: 'action',
      onClick: () => openRenameProjectModal(projectContext.project),
      label: 'Renommer le projet',
      icon: <Edit />,
    },
  ];

  return (
    <div className="flex ml-md items-center">
      <Comments project={project} />
      <Button
        className="sm-desktop:ml-md sm-desktop:mr-xs ml-xs mr-xxs whitespace-nowrap"
        onClick={() => handleSignIn(firstCreatedClientAccount.uuid, getProjectURL(project.uuid))}
        leftIcon={<ManageAccounts />}
        children={isTabletOrMobile ? undefined : <>Accéder à l'espace client</>}
        size={isTabletOrMobile ? 'sm' : 'md'}
      />
      <ActionList
        items={
          project.pipedriveDealId
            ? pipedriveActions(!!lead?.lostReason).concat(dotsMenuItems)
            : dotsMenuItems
        }
        small={isTabletOrMobile}
        trigger={(toggle, referenceProps) => (
          <Button
            onClick={toggle}
            variant="tertiary"
            leftIcon={<DotsMenu />}
            size={isTabletOrMobile ? 'sm' : 'md'}
            {...referenceProps}
            data-testid="project-tertiary-actions"
          />
        )}
      />
    </div>
  );
};
