import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxListField } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList/Field';
import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { ProCompanyStatus } from '@travauxlib/shared/src/types/api/domain/procompany/ProCompanyStatus';
import { gammeOptions } from '@travauxlib/shared/src/utils/constants';
import { departments } from '@travauxlib/shared/src/utils/departments';

import { companyTypes, labellisationStatusesById, profile } from 'utils/constants';

type OwnProps = {
  selectedRegions?: string[];
  enableCompanyTypeAndStatus?: boolean;
  onReset: () => void;
};

const statusesOptions = Object.values(ProCompanyStatus)
  .filter(v => v !== ProCompanyStatus.nonLabellisee)
  .map(id => ({
    label: labellisationStatusesById[id].label,
    value: labellisationStatusesById[id].label,
  }));

export const CommonFields: React.FC<OwnProps> = ({
  enableCompanyTypeAndStatus = true,
  onReset,
}) => (
  <>
    <div className="flex flex-wrap sm-desktop:-mx-md">
      <div className="sm-desktop:w-9/12 sm-desktop:px-md relative">
        <div className="flex flex-wrap sm-desktop:-mx-md mb-md">
          <div className="sm-desktop:flex sm-desktop:w-full mb-xs sm-desktop:mb-0">
            <div className="sm-desktop:w-10/12 mb-xs sm-desktop:mb-0 sm-desktop:px-md relative">
              <InputField
                id="contactSearch"
                placeholder="Nom de l'entreprise, contact, téléphone, e-mail..."
                name="contactSearch"
                autoFocus
                label="Rechercher"
                type="text"
              />
            </div>
            <div className="sm-desktop:w-2/12 sm-desktop:px-md relative">
              <CheckboxListField
                label="Type d'entreprise"
                id="company-type"
                name="companyTypes"
                options={companyTypes}
                disabled={!enableCompanyTypeAndStatus}
              />
            </div>
          </div>
          <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
            <DropdownMultiField
              id="departements"
              label="Départements"
              name="departements"
              options={departments}
            />
          </div>
          <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
            <NumberInputField
              name="tailleChantier"
              id="taille-chantier"
              label="Taille du chantier"
            />
          </div>

          <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
            <DropdownMultiField
              id="profile"
              name="profile"
              label="Profil"
              options={profile.map(value => ({ value, label: value }))}
            />
          </div>

          <div className="w-full mb-xs sm-desktop:mb-0 sm-desktop:w-3/12 sm-desktop:px-md relative">
            <DropdownMultiField id="gammes" name="gammes" label="Gammes" options={gammeOptions} />
          </div>
        </div>
        <div className="flex flex-wrap sm-desktop:-mx-md justify-end">
          <div className="sm-desktop:w-3/12 sm-desktop:px-md relative flex items-center justify-end">
            <Button variant="secondary" type="button" onClick={onReset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div className="sm-desktop:w-3/12 sm-desktop:px-md relative">
        <CheckboxListField
          label="Statut"
          id="status"
          name="status"
          options={statusesOptions}
          disabled={!enableCompanyTypeAndStatus}
        />
      </div>
    </div>
  </>
);
