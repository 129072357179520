import classNames from 'classnames';

import { BadgeSizes } from './types';
export const MAX_NOTIF = 99;
export const MAX_NOTIF_SM = 9;

export const variantsClassNames = {
  light: 'text-neutral-800 bg-neutral-0 outline outline-1 outline-neutral-300',
  primary: 'text-neutral-0 bg-primary-400',
  error: 'text-neutral-0 bg-error-800',
};

export const getClassNamesBySize = (value: number): { [K in BadgeSizes]: string } => ({
  md: value > MAX_NOTIF ? 'w-xl h-lg' : 'w-lg h-lg',
  sm: classNames('h-md', value > MAX_NOTIF_SM ? 'w-max px-xxs' : 'w-md'),
  xs: 'w-xs h-xs',
});
