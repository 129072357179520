import React from 'react';

import classNames from 'classnames';

import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { PicturesWallContent } from './PicturesWallContent';
import { FileDropzonePublicProps } from './types';

import Upload from '../../assets/UploadFilled.svg?react';

type Props = {
  disabled?: boolean;
  small?: boolean;
  id?: string;
  displayMode: FileDropzonePublicProps['displayMode'];
};

const getWording = (isMobile: boolean, isTabletOrMobile: boolean): string => {
  if (isMobile) {
    return 'Ajouter depuis mon téléphone';
  }
  if (isTabletOrMobile) {
    return 'Ajouter depuis ma tablette';
  }
  return 'Parcourir sur mon ordinateur';
};

export const DropZoneInitial: React.FC<Props> = ({ disabled, small, id, displayMode }) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const isMobile = useIsBelowBreakpoint('tablet');

  if (displayMode === 'pictures-wall') {
    return <PicturesWallContent small={small} disabled={disabled} />;
  }

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          'h-lg w-lg shrink-0',
          disabled ? 'text-neutral-300' : 'text-neutral-600 ',
        )}
      >
        <Upload />
      </div>
      <div className="ml-xs">
        <div
          className={classNames(
            'font-medium hidden sm-desktop:block',
            disabled ? 'text-neutral-300' : 'text-neutral-600 ',
            small ? 'text-sm mb-xxs' : 'text-b2 mb-xs',
          )}
        >
          Glisser et déposer un ou plusieurs fichiers
        </div>
        <label
          htmlFor={id}
          className={classNames(
            'text-b2 font-medium',
            disabled ? 'text-neutral-300' : 'text-primary cursor-pointer',
          )}
        >
          {getWording(isMobile, isTabletOrMobile)}
        </label>
      </div>
    </div>
  );
};
