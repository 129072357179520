import React from 'react';

import classNames from 'classnames';

import { ChantierStatusDetails } from '@travauxlib/shared/src/features/Chantiers/utils/ChantierStatusDetails';
import { ChantierStatus as ChantierStatusType } from '@travauxlib/shared/src/types/api/admin/ChantierStatus';

type Props = {
  status: ChantierStatusType;
};

export const ChantierStatus: React.FC<Props> = ({ status }) => {
  const { icon, indicatorIcon, label, style } = ChantierStatusDetails[status];

  return (
    <span
      className={classNames(
        'text-b2 b rounded-sm py-3xs px-xs text-gray-700 border-1 border-neutral-300 items-center flex',
        style,
      )}
    >
      {indicatorIcon} {icon}
      Chantier : {label}
    </span>
  );
};
