import React from 'react';

import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

export type Props = {
  referenceLink?: string;
};

export const FournitureReferenceLink: React.FC<Props> = ({ referenceLink }) => {
  if (!referenceLink) {
    return null;
  }
  return (
    <div className="flex justify-center mt-md items-center">
      <Link
        variant="secondary"
        icon={<OpenTab />}
        target="_blank"
        href={referenceLink}
        className="text-sm"
      >
        Voir le site du fournisseur
      </Link>
    </div>
  );
};
