import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { ProDevisAdminView } from '@travauxlib/shared/src/types/api/admin/ProDevisAdminView';
import { devisFactureStatuses } from '@travauxlib/shared/src/utils/constants';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { DevisAction } from './DevisAction';

type Props = {
  devisList: ProDevisAdminView[];
};

export const DevisList: React.FC<Props> = ({ devisList }) => {
  if (devisList.length === 0) {
    return (
      <div>Cette entreprise ne dispose d'aucun devis non archivé portant sur un deal hemea.</div>
    );
  }

  return (
    <Card state="filled" className="mb-md">
      <Table
        itemsPerPage={50}
        columnConfigs={[
          {
            name: 'Création',
            columnKey: 'createdAt',
            renderValue: (createdAt: string) => (
              <div data-testid="chantier-row">{formatFrenchDate(createdAt)}</div>
            ),
          },
          {
            name: 'Client',
            columnKey: 'customerName',
          },
          { name: 'Numero', columnKey: 'numero' },
          {
            name: 'Titre du devis',
            columnKey: 'title',
            maxCharsForScreenSize: {
              'md-desktop': 35,
              'lg-desktop': 35,
            },
          },
          {
            name: 'Status',
            columnKey: 'status',
            renderValue: (status: DevisStatuses) => (
              <Tag
                size="md"
                variant={devisFactureStatuses[status].level}
                label={devisFactureStatuses[status].label}
              />
            ),
          },
          {
            name: 'Total',
            columnKey: 'prixTotalHT',
            renderValue: (prixTotalHT: number) => (
              <strong>
                <EURCurrency amount={prixTotalHT} forceFullPattern />
              </strong>
            ),
          },
          {
            name: 'Total TTC',
            columnKey: 'prixTotalTTC',
            renderValue: (prixTotalTTC: number) => (
              <strong>
                <EURCurrency amount={prixTotalTTC} forceFullPattern />
              </strong>
            ),
          },
          {
            name: 'date debut',
            columnKey: 'dateDebut',
            renderValue: (dateDebut: string) => <>{formatFrenchDate(dateDebut)}</>,
          },
          {
            name: 'date fin',
            columnKey: 'dateFin',
            renderValue: (dateFin: string) => <>{formatFrenchDate(dateFin)}</>,
          },
          {
            name: 'Action',
            columnKey: 'token',
            hideSort: true,
            renderValue: (token: string) => (
              <div className="flex items-center">
                <DevisAction token={token} />
              </div>
            ),
          },
        ]}
        items={devisList}
        defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
      />
    </Card>
  );
};
