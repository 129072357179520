import dayjs from 'dayjs';

import Brand from '@travauxlib/shared/src/components/DesignSystem/assets/BrandFilled.svg?react';
import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import { HybridChantier } from '@travauxlib/shared/src/types';
import { AppelDeProvisionType } from '@travauxlib/shared/src/types/api/domain/chantier/AppelDeProvision';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import {
  FluxFinancierHistoriqueFilterType,
  HistoryItem,
  Variant,
  VariantHistotyItemCta,
} from '../types';

type ProvisionTypeInfo = {
  label: string;
  Icon: React.FC;
  variant: Variant;
};

const getInfoForAppelDeProvision = (
  appelDeProvision: HybridChantier['appelsDeProvision'][number],
): ProvisionTypeInfo => {
  if ('type' in appelDeProvision) {
    if (appelDeProvision.type === AppelDeProvisionType.TravauxPlanner) {
      return {
        label: 'Appel de provision pour prestation TP',
        Icon: Brand,
        variant: 'beige',
      };
    }
    if (appelDeProvision.type === AppelDeProvisionType.ServiceHemea) {
      return {
        label: 'Appel de provision pour le service hemea',
        Icon: Brand,
        variant: 'beige',
      };
    }
  }
  return {
    label: 'Appel de provision pour le chantier',
    Icon: HandyMan,
    variant: 'beige',
  };
};

export const appelDeProvisionToHistoryItem = (
  appelDeProvision: HybridChantier['appelsDeProvision'][number],
): HistoryItem => {
  const { label, Icon, variant } = getInfoForAppelDeProvision(appelDeProvision);

  return {
    key: appelDeProvision.uuid,
    date: appelDeProvision.dateEffet,
    author: appelDeProvision.createdBy,
    amount: appelDeProvision.montant,
    label,
    filterType: FluxFinancierHistoriqueFilterType.Provision,
    cancellationInfo: appelDeProvision.deletedAt
      ? {
          date: toCalendarFormatForDateTime(dayjs(appelDeProvision.deletedAt)),
          author: appelDeProvision.deletedBy,
        }
      : undefined,
    Icon,
    variant,
    actions: [
      ...(appelDeProvision.factureAcompteToken
        ? [
            {
              label: 'Afficher la facture',
              variant: 'primary' as VariantHistotyItemCta,
              onClick: () => {
                window.open(
                  `${APP_CONFIG.appURL}/factures/${appelDeProvision.factureAcompteToken}`,
                  '_blank',
                );
              },
            },
          ]
        : []),
    ],
  };
};
