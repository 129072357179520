import { ProjectStatus } from 'types';

export const statusFilterOptions: { label: string; value: ProjectStatus }[] = [
  { label: 'Nouveau', value: ProjectStatus.New },
  { label: 'Étude de projet', value: ProjectStatus.EtudeProjet },
  { label: 'Consultation archi envoyée', value: ProjectStatus.ConsultationArchiSent },
  { label: 'Consultation archi acceptée', value: ProjectStatus.ConsultationArchiValidated },
  { label: 'Consultation pro envoyée', value: ProjectStatus.ConsultationProSent },
  { label: 'Consultation pro acceptée', value: ProjectStatus.ConsultationProValidated },
  { label: 'Proposition archi envoyée', value: ProjectStatus.PrestationArchiSent },
  { label: 'Prestation archi validée', value: ProjectStatus.PrestationArchiValidated },
  { label: 'Devis pro envoyé', value: ProjectStatus.DevisProSent },
  { label: 'Deal gagné', value: ProjectStatus.DealWon },
  { label: 'Deal perdu', value: ProjectStatus.DealLost },
];
