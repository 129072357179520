import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProUser } from '@travauxlib/shared/src/types';
import {
  ProProjectView,
  ProReviewView,
} from '@travauxlib/shared/src/types/api/admin/ProCompanyProfileAdminView';
import { ProInsuranceView } from '@travauxlib/shared/src/types/api/common/ProInsuranceView';
import { isConflict, request } from '@travauxlib/shared/src/utils/request';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { PRO_COMPANIES_KEY } from 'features/Entreprises/api/useProCompanies';
import { ProCompany } from 'types';
import { apiUrlsBuilder } from 'utils/urls';

import { PRO_COMPANY_KEY } from './useProCompany';

const handleUpdate = async ({
  slug,
  globalInfos,
  settings,
  insurances,
  reviewsDetails,
  payment,
}: {
  slug: string;
  globalInfos?: object;
  settings?: object;
  insurances?: ProInsuranceView[];
  reviewsDetails?: ProReviewView;
  payment?: object;
}): Promise<ProCompany> => {
  const body = {
    globalInfos,
    settings,
    insurances: insurances?.map(insurance => ({
      ...insurance,
      dateDebutValidite: formatFrenchDate(insurance.dateDebutValidite),
      dateFinValidite: formatFrenchDate(insurance.dateFinValidite),
    })),
    reviewsDetails,
    payment,
  };
  const requestURL = apiUrlsBuilder.proCompanies.patchProCompany(slug);
  return request(requestURL, { body, method: 'PATCH' });
};

const handleUploadInsurance = ({
  slug,
  formData,
}: {
  slug: string;
  formData: FormData;
}): Promise<ProCompany> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/insurances`;
  return request(requestURL, {
    method: 'POST',
    body: formData,
  });
};

const handleDeleteInsurance = ({
  slug,
  insuranceId,
}: {
  slug: string;
  insuranceId: number;
}): Promise<ProCompany> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/insurances/${insuranceId}`;
  return request(requestURL, {
    method: 'DELETE',
  });
};

const handleUploadPhoto = async ({
  slug,
  file,
}: {
  slug: string;
  file: File;
}): Promise<{ path: string }> => {
  const formData = new FormData();
  formData.append('photo', file);
  return request(apiUrlsBuilder.proCompanies.photos(slug), {
    method: 'POST',
    body: formData,
  });
};

const handleActivateOptymeaAccount = ({ slug }: { slug: string }): Promise<ProCompany> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/activate-optymea-account`;
  return request(requestURL, { method: 'POST' });
};

const handleCreateWallet = ({
  slug,
  withMockedLemonway = false,
}: {
  slug: string;
  withMockedLemonway?: boolean;
}): Promise<ProCompany> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/createLemonwayWallet?withMockedLemonway=${withMockedLemonway}`;
  return request(requestURL, {
    method: 'PUT',
  });
};

const handleUpdateReview = async ({
  slug,
  review,
}: {
  slug: string;
  review: ProReviewView;
}): Promise<string> => {
  const { reviewId } = review;
  if (reviewId) {
    await request(`${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/reviews/${reviewId}`, {
      method: 'PUT',
      body: review,
    });
  } else {
    await request(`${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/reviews`, {
      method: 'POST',
      body: review,
    });
  }
  return slug;
};

const handleDeleteReview = async ({
  slug,
  reviewId,
}: {
  slug: string;
  reviewId: number;
}): Promise<string> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/reviews/${reviewId}`;
  await request(requestURL, { method: 'DELETE' });
  return slug;
};

const handleUpdateProject = async ({
  slug,
  project,
}: {
  slug: string;
  project: ProProjectView;
}): Promise<string> => {
  const projectId = project.id;
  if (projectId) {
    await request(`${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/projects/${projectId}`, {
      method: 'PUT',
      body: project,
    });
  } else {
    await request(`${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/projects`, {
      method: 'POST',
      body: project,
    });
  }
  return slug;
};

const handleDeleteProject = async ({
  slug,
  projectId,
}: {
  slug: string;
  projectId: number;
}): Promise<string> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/projects/${projectId}`;
  await request(requestURL, { method: 'DELETE' });
  return slug;
};

const handleArchiveProUser = async ({
  user,
  slug,
}: {
  user: ProUser;
  slug: string;
}): Promise<string> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/user/${user.uuid}`;
  await request(requestURL, { method: 'DELETE' });
  return slug;
};

const handleUpdateProUser = async ({
  updatedProUser,
  slug,
}: {
  updatedProUser: ProUser;
  slug: string;
}): Promise<string> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/user/${updatedProUser.uuid}`;
  await request(requestURL, { method: 'PUT', body: updatedProUser });
  return slug;
};

export const useUpdateProCompany = (): {
  update: typeof handleUpdate;
  isUpdating: boolean;
  uploadInsurance: typeof handleUploadInsurance;
  deleteInsurance: typeof handleDeleteInsurance;
  uploadPhoto: typeof handleUploadPhoto;
  activateOptymeaAccount: typeof handleActivateOptymeaAccount;
  createWallet: typeof handleCreateWallet;
  isCreatingWallet: boolean;
  updateReview: typeof handleUpdateReview;
  deleteReview: typeof handleDeleteReview;
  updateProject: typeof handleUpdateProject;
  deleteProject: typeof handleDeleteProject;
  archiveProUser: typeof handleArchiveProUser;
  updateProUser: typeof handleUpdateProUser;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: update, isPending: isUpdating } = useMutation({
    mutationFn: handleUpdate,
    onSuccess: (proCompany: ProCompany) => {
      toast.success('Entreprise mise à jour avec succès');
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY, proCompany.slug] });
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANIES_KEY] });
    },
    onError: (error: Error) => {
      if (isConflict(error)) {
        toast.error("L'email est déjà utilisé par une autre entreprise");
      } else {
        toast.error("Erreur lors de la mise à jour de l'entreprise");
      }
    },
  });

  const { mutateAsync: uploadInsurance } = useMutation({
    mutationFn: handleUploadInsurance,
    onSuccess: (proCompany: ProCompany) => {
      toast.success('Assurance ajoutée');
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY, proCompany.slug] });
    },
    onError: () => {
      toast.error("Erreur lors de l'ajout de l'assurance");
    },
  });

  const { mutateAsync: deleteInsurance } = useMutation({
    mutationFn: handleDeleteInsurance,
    onSuccess: (proCompany: ProCompany) => {
      toast.success('Assurance supprimée');
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY, proCompany.slug] });
    },
    onError: () => {
      toast.error("Erreur lors de la supression de l'assurance");
    },
  });

  const { mutateAsync: uploadPhoto } = useMutation({ mutationFn: handleUploadPhoto });

  const { mutateAsync: activateOptymeaAccount } = useMutation({
    mutationFn: handleActivateOptymeaAccount,
    onSuccess: (proCompany: ProCompany) => {
      toast.success('Le compte a bien été activé');
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY, proCompany.slug] });
    },
    onError: () => {
      toast.error("Erreur lors de l'activation du compte");
    },
  });

  const { mutateAsync: createWallet, isPending: isCreatingWallet } = useMutation({
    mutationFn: handleCreateWallet,
    onSuccess: (proCompany: ProCompany) => {
      toast.success('Wallet LemonWay créé avec succés');
      queryClient.invalidateQueries({ queryKey: [PRO_COMPANY_KEY, proCompany.slug] });
    },
    onError: (error: AxiosError) => {
      toast.error(
        `Une erreur est survenue lors de la création du compte lemonway : ${error.response?.data}`,
      );
    },
  });

  const { mutateAsync: updateReview } = useMutation({
    mutationFn: handleUpdateReview,
    onSuccess: (slug: string) => {
      toast.success('Avis mis à jour avec succès');
      queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: deleteReview } = useMutation({
    mutationFn: handleDeleteReview,
    onSuccess: (slug: string) => {
      toast.success('Avis supprimé avec succès');
      queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: updateProject } = useMutation({
    mutationFn: handleUpdateProject,
    onSuccess: (slug: string) => {
      toast.success('Projet mis à jour avec succès');
      queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: deleteProject } = useMutation({
    mutationFn: handleDeleteProject,
    onSuccess: (slug: string) => {
      toast.success('Projet supprimé avec succès');
      queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: archiveProUser } = useMutation({
    mutationFn: handleArchiveProUser,
    onSuccess: (slug: string) => {
      toast.success('Utilisateur archivé avec succès');
      queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: updateProUser } = useMutation({
    mutationFn: handleUpdateProUser,
    onSuccess: (slug: string) => {
      toast.success('Mise à jour réalisée avec succès');
      queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY, slug] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  return {
    update,
    isUpdating,
    uploadInsurance,
    deleteInsurance,
    uploadPhoto,
    activateOptymeaAccount,
    createWallet,
    isCreatingWallet,
    updateReview,
    deleteReview,
    updateProject,
    deleteProject,
    archiveProUser,
    updateProUser,
  };
};
