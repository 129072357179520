import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

type Props = {
  index: number;
  lot: Pick<ProDevisFactureLotView, 'uuid' | 'label' | 'prixTotalHT'>;
  lotId: string;
};

export const LotComponent: React.FC<Props> = ({ index, lot, lotId }) => (
  <div className="hover:bg-neutral-100 px-md">
    <a href={`#${lotId}`} className="!no-underline">
      <div className="py-xxs flex text-neutral-800 items-baseline">
        <div className="flex-shrink-0 pr-xs text-neutral-700 text-sm">{`${index + 1}.0`}</div>
        <div className="text-left p-0 block flex-grow truncate text-b2" title={lot.label}>
          {lot.label}
        </div>
        <div className="pl-md text-right whitespace-nowrap">
          <EURCurrency amount={lot.prixTotalHT} />
        </div>
      </div>
    </a>
  </div>
);
