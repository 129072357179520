import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { Optional } from 'utility-types';

import { Props as InputProps } from '.';
import { NumberInput as RawNumberInput } from '.';

const NumberInputWrapper: React.FC<FieldRenderProps<number> & { id: string }> = ({
  input: { value, onChange, onFocus, onBlur },
  meta,
  onChange: onChangeFromProps,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);
  return (
    <RawNumberInput
      value={value}
      onChange={value => {
        onChange(value);
        onChangeFromProps?.(value);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error && touched ? error : undefined}
      {...rest}
    />
  );
};

type Props = {
  name: string;
} & Optional<Omit<InputProps, 'value'>, 'min' | 'max' | 'fractionDigits' | 'onChange'> &
  UseFieldConfig<number>;

export const NumberInputField: React.FC<Props> = props => (
  <Field component={NumberInputWrapper} {...props} />
);
