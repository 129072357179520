import React from 'react';

import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

import { UserType } from '../types';
import { addedFournituresWording, missingFournituresWording } from '../utils/constants';

export type Props = {
  activeTab: 'added' | 'missing';
  userType: UserType;
};

export const EmptyStateFourniture: React.FC<Props> = ({ activeTab, userType }) => {
  const emptyStateWordingByTab =
    activeTab === 'added' ? addedFournituresWording : missingFournituresWording;
  const emptyStateWordingByUser =
    emptyStateWordingByTab[userType === 'client' ? userType : 'other'];

  return (
    <EmptyState
      className="p-md mb-xxl sm-desktop:mb-0"
      description={emptyStateWordingByUser.description}
      title={emptyStateWordingByUser.title}
      illustration={emptyStateWordingByUser.illustration}
      iconIllustrationSize="md"
    />
  );
};
