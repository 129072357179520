import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';

export type TypeWC = 'suspendu' | 'aposer' | 'aucun';

export enum DeposeTypePiecesDeauElement {
  Douche = 'Douche',
  CabineDeDouche = 'Cabine de douche',
  Baignoire = 'Baignoire',
  Lavabo = 'Lavabo',
  WC = 'WC',
  Bidet = 'Bidet',
}
export enum PoseTypePiecesDeauElement {
  Douche = 'Douche',
  Baignoire = 'Baignoire',
  MeubleSimpleVasque = 'Meuble simple vasque',
  MeubleDoubleVasque = 'Meuble double vasque',
  LaveMain = 'Lave-main',
  WCAPoser = 'WC à poser',
  WCSuspendu = 'WC suspendu',
}

export type DeposePiecesDeauElement = {
  isOption?: boolean;
  typeDepose?: DeposeTypePiecesDeauElement;
  locationUuid?: string;
};

export type PosePiecesDeauElement = {
  isOption?: boolean;
  typePose?: PoseTypePiecesDeauElement;
  raccordsPlomberie?: boolean;
  locationUuid?: string;
};

export type PrestationSalleDeBain = {
  isOption?: boolean;
  laveMains?: boolean;
  deplacementEquipements?: boolean;
  typeWC: TypeWC;
  baignoireOuDouche?: 'Baignoire' | 'Douche';
};

export type SalleDeBain = {
  gamme?: Gamme;
  prestations?: {
    [K: string]: PrestationSalleDeBain[];
  };
  posePiecesDeauElements?: PosePiecesDeauElement[];
  deposePiecesDeauElements?: DeposePiecesDeauElement[];
};
