import { useMemo } from 'react';

import { AllTypeLocation, Fourniture, FournituresWithLocation } from '@travauxlib/shared/src/types';

type FournitureDetails =
  | {
      locationLabel: string;
      locationType: AllTypeLocation;
      fourniture: Fourniture;
    }
  | undefined;

export const useFournitureDetails = (
  fournituresWithLocation: FournituresWithLocation[],
  fournitureUuid: string | undefined,
): FournitureDetails =>
  useMemo(() => {
    if (!fournituresWithLocation?.length || !fournitureUuid) {
      return undefined;
    }

    for (const fournitureWithLocation of fournituresWithLocation) {
      const foundFourniture = fournitureWithLocation.fournitures.find(
        f => f.uuid === fournitureUuid,
      );

      if (foundFourniture) {
        return {
          locationLabel: fournitureWithLocation.locationName,
          locationType: fournitureWithLocation.locationType,
          fourniture: foundFourniture,
        };
      }
    }

    return undefined;
  }, [fournituresWithLocation, fournitureUuid]);
