import _capitalize from 'lodash/capitalize';

import { UnitLabel } from '@travauxlib/shared/src/types';

const architectePrefixes = {
  ce: 'cette',
  le: 'la',
  mon: 'ma',
  du: 'de la',
  un: 'une',
};

const pluralprefixes = {
  ce: 'ces',
  le: 'les',
  mon: 'mes',
  du: 'des',
  un: 'des',
};

type Props = {
  isArchitecte?: boolean;
  plural?: boolean;
  capitalize?: boolean;
  prefix?: keyof typeof architectePrefixes;
};

const getPrefix = (
  prefix: keyof typeof architectePrefixes,
  isArchitecte?: boolean,
  plural?: boolean,
): string => {
  if (plural) {
    return pluralprefixes[prefix];
  }
  return isArchitecte ? architectePrefixes[prefix] : prefix;
};

export const devisOrProposition = ({ isArchitecte, plural, capitalize, prefix }: Props): string => {
  const proposition = plural ? 'propositions' : 'proposition';
  const result = isArchitecte ? proposition : 'devis';
  const prefixedResult = prefix ? `${getPrefix(prefix, isArchitecte, plural)} ${result}` : result;

  return capitalize ? _capitalize(prefixedResult) : prefixedResult;
};

const labelToAddPlural: UnitLabel[] = ['unité', 'heure', 'jour', 'forfait'];

export const getPluralizedUnitLabel = (unitLabel: UnitLabel, quantite: number): String => {
  if (labelToAddPlural.includes(unitLabel) && quantite > 1) {
    return `${unitLabel}s`;
  }
  return unitLabel;
};

export const addPlurielToWords = <T>(toCheck: T[] | number, words: string[]): string[] => {
  const count = Array.isArray(toCheck) ? toCheck.length : toCheck;
  const plural = count > 1 ? 's' : '';
  return words.map(word => `${word}${plural}`);
};
