import { GenerationData } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Plomberie } from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

export const ventilationsGeneratedData = (plomberie: Plomberie): GenerationData[] => {
  const result: GenerationData[] = [];

  const ventilations = plomberie.ventilations || [];

  if (plomberie.hasModifVentilation === null) {
    result.push({
      ouvrageLabel: 'Modification ventilation',
      inProgress: true,
    });
  }

  for (let ventilation of ventilations) {
    const isOption = ventilation.isOption;
    if (ventilation.typeVentilation === 'Ne sait pas') {
      result.push({
        ouvrageLabel: 'Modification ventilation',
        toCheck: true,
      });
    }
    const locationsUuid = ventilation.locationsUuid;
    if (!locationsUuid?.length) {
      continue;
    }
    if (ventilation.typeVentilation === 'VMC') {
      if (locationsUuid.length === 1) {
        result.push({
          ouvrageLabel: 'VMC simple flux',
          id: 'vmc_simple_flux_2_bouches',
          isOption,
          quantity: 1,
          tagQuantity: 'skip',
          locationQuantities: {
            [locationsUuid[0]]: 1,
          },
        });
      } else if (locationsUuid.length < 3) {
        result.push({
          ouvrageLabel: 'VMC simple flux',
          id: 'vmc_simple_flux_2_bouches',
          isOption,
          quantity: 1,
          tagQuantity: 'skip',
        });
      } else {
        result.push({
          ouvrageLabel: 'VMC simple flux',
          id: 'vmc_simple_flux_3_bouches',
          isOption,
          quantity: 1,
          tagQuantity: 'skip',
        });
      }
    } else {
      const id =
        ventilation.typeVentilation === 'Remplacement de bouches'
          ? 'remplacement_bouche_vmc'
          : 'extracteur_air';
      const ouvrageLabel =
        ventilation.typeVentilation === 'Remplacement de bouches'
          ? 'Remplacement de bouches VMC'
          : 'Aérateur';
      locationsUuid.forEach(uuid => {
        result.push({
          id,
          isOption,
          ouvrageLabel,
          quantity: 1,
          locationQuantities: {
            [uuid]: 1,
          },
        });
      });
    }
  }

  return result;
};
