import React from 'react';

import { useOutletContext } from 'react-router';

import { ProCompanyProfileAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyProfileAdminView';

import { Project } from './Project';

export const ProjectListing: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompanyProfileAdminView }>();
  const {
    company: { slug },
    projects,
  } = proCompany;
  return (
    <>
      <Project initialValues={{ photos: [], title: '' }} slug={slug} />
      {projects.map((project, index) => (
        <Project
          key={project.id!}
          index={index}
          initialValues={project}
          slug={slug}
          projectId={project.id}
        />
      ))}
    </>
  );
};
