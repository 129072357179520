import React, { useState } from 'react';

import CircleCheck from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import {
  ButtonLink,
  IconButtonLink,
} from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { PersonRole } from '@travauxlib/shared/src/types';
import { personRoleToLabel } from '@travauxlib/shared/src/utils/constants';

import { usePersons } from 'features/Trombinoscope/api/usePersons';

export const EditPersonListing: React.FC = () => {
  const [displayArchived, setDisplayArchived] = useState<boolean>(false);
  const { persons } = usePersons();

  const filteredPersons = persons.filter(({ archived }) => displayArchived || !archived);
  return (
    <div className="container w-full text-center">
      <h1>{filteredPersons.length} personnes</h1>
      <div className="flex flex-col gap-md sm-desktop:flex-row justify-between">
        <ButtonLink size="sm" leftIcon={<PlusSymbol />} to="/trombinoscope/new">
          Ajouter un nouvel employé
        </ButtonLink>
        <Button
          size="sm"
          type="button"
          onClick={() => setDisplayArchived(displayArchived => !displayArchived)}
        >
          {displayArchived ? 'Cacher les archivés' : 'Afficher les archivés'}
        </Button>
      </div>
      <Table
        className="text-left"
        wrapLines
        columnConfigs={[
          {
            name: 'Nom',
            columnKey: 'lastName',
          },
          {
            name: 'Prénom',
            columnKey: 'firstName',
          },
          {
            name: 'Archivé',
            columnKey: 'archived',
            renderValue: archived => <>{archived && <CircleCheck height={24} />}</>,
          },
          {
            name: 'Role',
            columnKey: 'role',
            renderValue: (role: PersonRole) => <>{personRoleToLabel[role]}</>,
          },

          {
            name: 'Action',
            columnKey: 'uuid',
            renderValue: uuid => (
              <IconButtonLink data-testid="edit-svg" to={`/trombinoscope/${uuid}/edit`} size="sm">
                <Edit />
              </IconButtonLink>
            ),
          },
        ]}
        items={filteredPersons}
        defaultSort={{ columnKey: 'lastName', order: 'asc' }}
      />
    </div>
  );
};
