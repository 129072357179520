import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';

export enum TypeRemplacement {
  PlanDeTravail = 'Plan de travail',
  Evier = 'Evier',
}

export enum TypeMobilier {
  DuCommerce = 'Meubles du commerce en kit',
  Cuisiniste = 'Cuisiniste',
}

export type Refection = {
  isOption?: boolean;
  deplacementEquipements?: boolean;
  projetDeMobilier?: boolean;
  typeMobilier?: TypeMobilier;
  hasCuisiniste?: boolean;
};
export type Remplacement = {
  isOption?: boolean;
  typeRemplacements?: TypeRemplacement[];
};

export type Cuisine = {
  gamme?: Gamme;
  refections: Refection[];
  remplacements: Remplacement[];
};
