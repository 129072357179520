import React, { useState } from 'react';

import Filter from '@travauxlib/shared/src/components/DesignSystem/assets/Filter.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { FournituresWithLocation } from '@travauxlib/shared/src/types';

import { RoomsFilter } from '../RoomsFilter';

export type Props = {
  fournitures: FournituresWithLocation[];
  selectedRooms: string[];
  availableRooms: string[];
  onRoomToggle: (room: string, checked: boolean) => void;
};

export const FilterRoomsMobile: React.FC<Props> = ({
  fournitures,
  selectedRooms,
  onRoomToggle,
  availableRooms,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        leftIcon={<Filter />}
        onClick={() => setShowFilter(true)}
      >
        Filtrer
      </Button>
      <SimpleModal
        isOpen={showFilter}
        handleClose={() => setShowFilter(false)}
        title="Filtrer par localisation"
        validateAction={{ label: 'Valider', onClick: () => setShowFilter(false) }}
      >
        <RoomsFilter
          availableRooms={availableRooms}
          fournitures={fournitures}
          selectedRooms={selectedRooms}
          onRoomToggle={onRoomToggle}
        />
      </SimpleModal>
    </>
  );
};
