import dayjs from 'dayjs';

import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import { AppelDePaiementView } from '@travauxlib/shared/src/types/api/admin/AppelDePaiementView';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import { appelDePaiementStatusThatCanBeUpdated } from 'features/Chantiers/types';
import { ChantierAdmin } from 'types';

import { FluxFinancierHistoriqueFilterType, HistoryItem, VariantHistotyItemCta } from '../types';

export const appelDePaiementToHistoryItem = ({
  appelDePaiement,
  chantier,
  handleOpenModalCancelAppelDePaiement,
  isLoading,
  handleOpenModifyAppelDePaiementModal,
  isAllowedToCancelAppelDePaiement,
  isAllowedToModifyAppelDePaiement,
  isAllowedToValidateAppelDePaiement,
  handleOpenValidateAppelDePaiementModal,
}: {
  appelDePaiement: AppelDePaiementView;
  chantier: ChantierAdmin;
  handleOpenModalCancelAppelDePaiement: (
    appelDePaiement: AppelDePaiementView,
    chantierSlug: string,
  ) => void;
  isLoading: boolean;
  handleOpenModifyAppelDePaiementModal: (
    appelDePaiement: AppelDePaiementView,
    chantier: ChantierAdmin,
  ) => void;
  isAllowedToCancelAppelDePaiement: boolean;
  isAllowedToModifyAppelDePaiement: boolean;
  isAllowedToValidateAppelDePaiement: boolean;
  handleOpenValidateAppelDePaiementModal: (
    appelDePaiementUuid: string,
    chantier: ChantierAdmin,
  ) => void;
}): HistoryItem => {
  const { slug, soldeCompteSequestre } = chantier;

  return {
    key: appelDePaiement.uuid,
    date: appelDePaiement.createdAt,
    author: appelDePaiement.createdBy,
    amount: appelDePaiement.montant,
    label: 'Appel de paiement',
    filterType: FluxFinancierHistoriqueFilterType.Paiement,
    pendingInfo:
      appelDePaiement.status === 'pending'
        ? {
            label: 'En attente',
            tooltipMessage:
              "L'appel de paiement sera automatiquement envoyé au client dès que le compte séquestre sera suffisamment provisionné",
          }
        : undefined,
    cancellationInfo: appelDePaiement.deletedAt
      ? {
          date: toCalendarFormatForDateTime(dayjs(appelDePaiement.deletedAt)),
          author: appelDePaiement.deletedBy,
        }
      : undefined,

    validationInfo: appelDePaiement.validatedAt
      ? {
          date: toCalendarFormatForDateTime(dayjs(appelDePaiement.validatedAt)),
          author: appelDePaiement.validatedBy,
        }
      : undefined,
    Icon: HandyMan,
    variant: 'beige',
    actions: [
      ...(appelDePaiementStatusThatCanBeUpdated.includes(appelDePaiement.status)
        ? [
            {
              label: 'Modifier',
              variant: 'primary' as VariantHistotyItemCta,
              disabled: !chantier.enableSuiviRenforce && !isAllowedToModifyAppelDePaiement,
              disabledMessage: !isAllowedToModifyAppelDePaiement
                ? "Vous n'avez pas l'autorisation"
                : 'Le suivi financier renforcé doit etre activé',
              onClick: () => handleOpenModifyAppelDePaiementModal(appelDePaiement, chantier),
            },
          ]
        : []),
      ...(appelDePaiement.status === 'initiated'
        ? [
            {
              label: 'Valider à la place du client',
              variant: 'primary' as VariantHistotyItemCta,
              disabled:
                isLoading ||
                soldeCompteSequestre < appelDePaiement.montant ||
                !isAllowedToValidateAppelDePaiement,
              disabledMessage: isLoading
                ? undefined
                : soldeCompteSequestre < appelDePaiement.montant
                  ? 'Le solde du compte séquestre est insuffisant.'
                  : 'Permission requise : Autorisé à valider les appels de paiement à la place du client',
              onClick: () => {
                handleOpenValidateAppelDePaiementModal(appelDePaiement.uuid, chantier);
              },
            },
          ]
        : []),
      ...(appelDePaiementStatusThatCanBeUpdated.includes(appelDePaiement.status) &&
      isAllowedToCancelAppelDePaiement
        ? [
            {
              label: 'Annuler',
              onClick: () => handleOpenModalCancelAppelDePaiement(appelDePaiement, slug),
            },
          ]
        : []),
    ],
  };
};
