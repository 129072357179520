import BathroomDuoColor from '@travauxlib/shared/src/components/DesignSystem/assets/BathroomDuoColor.svg?react';
import Cuisine from '@travauxlib/shared/src/components/DesignSystem/assets/Cuisine.svg?react';
import Demolition from '@travauxlib/shared/src/components/DesignSystem/assets/Demolition.svg?react';
import Electricite from '@travauxlib/shared/src/components/DesignSystem/assets/Electricity.svg?react';
import Menuiseries from '@travauxlib/shared/src/components/DesignSystem/assets/Menuiseries.svg?react';
import Peinture from '@travauxlib/shared/src/components/DesignSystem/assets/Peinture.svg?react';
import Platrerie from '@travauxlib/shared/src/components/DesignSystem/assets/Platrerie.svg?react';
import Plomberie from '@travauxlib/shared/src/components/DesignSystem/assets/Plomberie.svg?react';
import Sols from '@travauxlib/shared/src/components/DesignSystem/assets/Sols.svg?react';
import WindowDuoColor from '@travauxlib/shared/src/components/DesignSystem/assets/WindowDuoColor.svg?react';
import { QuestionId } from '@travauxlib/shared/src/types/api/domain/configurateur';

export const formData: {
  [K in QuestionId]: {
    title: string;
    Icon: React.FC<{ className?: string }>;
    editLink: string;
  };
} = {
  ouverturesDemolition: {
    title: 'Ouvertures & démolitions',
    editLink: 'ouvertures-demolitions',
    Icon: Demolition,
  },
  cuisine: {
    title: 'Cuisine',
    editLink: 'cuisine',
    Icon: Cuisine,
  },
  murs: {
    title: 'Revêtements de murs & plafonds',
    editLink: 'revetements-murs',
    Icon: Peinture,
  },
  menuiseriesInterieures: {
    title: 'Menuiseries intérieures',
    editLink: 'menuiseries-interieures',
    Icon: Menuiseries,
  },
  menuiseriesExterieures: {
    title: 'Menuiseries extérieures',
    editLink: 'menuiseries-exterieures',
    Icon: WindowDuoColor,
  },
  sols: {
    title: 'Revêtements de sol',
    editLink: 'revetements-sol',
    Icon: Sols,
  },
  plomberie: {
    title: 'Plomberie & CVC',
    editLink: 'plomberie-cvc',
    Icon: Plomberie,
  },
  elec: {
    title: 'Électricité',
    editLink: 'electricite',
    Icon: Electricite,
  },
  platrerie: {
    title: 'Plâtrerie & isolation',
    editLink: 'platrerie-isolation',
    Icon: Platrerie,
  },
  salleDeBain: {
    title: "Pièces d'eau",
    editLink: 'salle-de-bain',
    Icon: BathroomDuoColor,
  },
};
