import React from 'react';

import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';

import { Left, Right } from './types';

import { Avatar } from '../Avatar';
import { Button } from '../Buttons/Button';
import { IconButton } from '../Buttons/IconButton';
import { CardState } from '../Card';
import { Checkbox } from '../Checkbox';
import { ColoredCircle } from '../ColoredCircle';
import { RadioButton } from '../RadioButton';
import { Tag } from '../Tag';
import { Toggle } from '../Toggle';

export const containerClassNamesByState: { [K in CardState]: string } = {
  outlined: 'hover:border-neutral-600 active:border-neutral-800',
  filled:
    '!shadow-none !rounded-none border-b border-neutral-300 hover:bg-neutral-100 active:bg-neutral-200',
  elevated: '',
};

type Payload = {
  element: Left | Right;
  ref?: React.ForwardedRef<HTMLButtonElement>;
  value?: string | boolean;
  disabled?: boolean;
};

export const displayElement = ({ element, value, disabled, ref }: Payload): React.ReactNode => {
  switch (element.type) {
    case 'image':
      return <ImgLazy {...element} width={48} height={48} />;
    case 'avatar':
      return <Avatar {...element} disabled={disabled} />;
    case 'icon':
      return <div className="flex items-center size-lg">{element.icon}</div>;
    case 'iconBackground':
      return (
        <ColoredCircle className="relative" size="sm" disabled={disabled} {...element}>
          {element.icon}
          {element.active && (
            <div className="absolute bg-error-800 h-xs w-xs rounded-full right-0 bottom-0" />
          )}
        </ColoredCircle>
      );
    case 'iconButton':
      return (
        <IconButton
          {...element}
          type="button"
          className="-mr-xxs"
          ref={ref}
          disabled={disabled}
          onClick={event => {
            event.stopPropagation();
            element.onClick?.();
          }}
        >
          {element.icon}
        </IconButton>
      );
    case 'button':
      return (
        <Button
          {...element}
          size="sm"
          ref={ref}
          disabled={disabled}
          onClick={event => {
            event.stopPropagation();
            element.onClick?.();
          }}
        >
          {element.label}
        </Button>
      );
    case 'checkbox':
      return (
        <Checkbox
          {...element}
          onChange={() => {}}
          onClick={event => event.stopPropagation()}
          checked={!!value}
          value={!!value}
          disabled={disabled}
        />
      );
    case 'toggle':
      return <Toggle {...element} checked={!!value} value={!!value} disabled={disabled} />;
    case 'radio':
      return (
        <RadioButton
          {...element}
          onChange={() => {}}
          onClick={event => event.stopPropagation()}
          checked={value === element.value}
          disabled={disabled}
        />
      );
    case 'tag':
      return <Tag {...element} size="md" />;
    case 'jsx':
      return element.children;
  }
};
