import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  DeposePiecesDeauElement,
  DeposeTypePiecesDeauElement,
} from '@travauxlib/shared/src/types/api/domain/configurateur/SalleDeBain';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

type Props = {
  configuration: Configuration;
};

export const DeposePiecesDeauElements: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Eléments individuels de pièces d'eau à déposer ?"
    prefix="2"
    defaultExpanded
  >
    <FieldArray<DeposePiecesDeauElement> name="deposePiecesDeauElements">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle prefix="1" title="Type d'élément à déposer ?" />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeDepose`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Douche',
                      value: DeposeTypePiecesDeauElement.Douche,
                    },
                    {
                      label: 'Cabine de douche',
                      value: DeposeTypePiecesDeauElement.CabineDeDouche,
                    },
                    {
                      label: 'Baignoire',
                      value: DeposeTypePiecesDeauElement.Baignoire,
                    },
                    {
                      label: 'Lavabo',
                      value: DeposeTypePiecesDeauElement.Lavabo,
                    },
                    {
                      label: 'WC',
                      value: DeposeTypePiecesDeauElement.WC,
                    },
                    {
                      label: 'Bidet',
                      value: DeposeTypePiecesDeauElement.Bidet,
                    },
                  ]}
                />
                {value.typeDepose && (
                  <>
                    <QuestionTitle title="Pièce concernée ?" prefix="2" />
                    <ChipPictoVerticalListFieldConfigurateur
                      locationOptions={getLocationOptions(configuration)}
                      name={`${name}.locationUuid`}
                    />
                  </>
                )}
                <PrestationsSeparator />
              </div>
            );
          })}

          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
