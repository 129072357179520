import React from 'react';

import Clock from '@travauxlib/shared/src/components/DesignSystem/assets/Clock.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFilled.svg?react';
import StarHalf from '@travauxlib/shared/src/components/DesignSystem/assets/StarHalf.svg?react';
import StateChecked from '@travauxlib/shared/src/components/DesignSystem/assets/StateChecked.svg?react';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import {
  ChantierPvType,
  ChantierPvView,
} from '@travauxlib/shared/src/types/api/common/ChantierPvView';

export const PvStatusTag: React.FC<{ pv: ChantierPvView }> = ({ pv }) => {
  const { hasReserves, isValidated, isRejected, archivedAt, pvType } = pv;
  const isTabletOrMobile = useIsTabletOrMobile();
  if (hasReserves) {
    return (
      <Tag
        className="mr-xs"
        icon={<StarHalf />}
        label={isTabletOrMobile ? undefined : 'Validé avec réserves'}
        variant="success"
      />
    );
  }
  if (isValidated && pvType === ChantierPvType.ReceptionChantier) {
    return (
      <Tag
        className="mr-xs"
        icon={<StarFull />}
        label={isTabletOrMobile ? undefined : 'Validé sans réserves'}
        variant="success"
      />
    );
  }

  if (isValidated) {
    return (
      <Tag
        className="mr-xs"
        icon={<StateChecked />}
        label={isTabletOrMobile ? undefined : 'Document validé'}
        variant="success"
      />
    );
  }

  if (isRejected) {
    return (
      <Tag
        className="mr-xs"
        icon={<CrossCircle />}
        label={isTabletOrMobile ? undefined : 'Document refusé'}
        variant="warning"
      />
    );
  }

  if (archivedAt) {
    return (
      <Tag
        className="mr-xs"
        icon={<Clock />}
        label={isTabletOrMobile ? undefined : 'Document supprimé'}
        variant="warning"
      />
    );
  }

  return (
    <Tag
      className="mr-xs"
      icon={<Clock />}
      label={isTabletOrMobile ? undefined : 'En attente de validation'}
      variant="info"
    />
  );
};
