import { createAdressableLead } from './createAdressableLead';
import { createEmployee } from './createEmployee';
import { createLead } from './createLead';
import { createLeadAndChantier } from './createLeadAndChantier';
import { createLeadAndChantierProAndArchi } from './createLeadAndChantierProAndArchi';
import { createLeadAndCompleteConfigurateur } from './createLeadAndCompleteConfigurateur';
import { createLeadAndCompleteConfigurateurAndConsultation } from './createLeadAndCompleteConfigurateurAndConsultation';
import { createLeadAndCompleteConfigurateurAndGenerateDevis } from './createLeadAndCompleteConfigurateurAndGenerateDevis';
import { createLeadAndConsultation } from './createLeadAndConsultation';
import { createLeadAndDevis } from './createLeadAndDevis';
import { createLeadAndSendDevis } from './createLeadAndSendDevis';
import { createLeadAndSignDevis } from './createLeadAndSignDevis';
import { createLeadAndSignDevisAndGenDevisTS } from './createLeadAndSignDevisAndGenDevisTS';
import { createLeadAndStartChantier } from './createLeadAndStartChantier';
import { createLeadAndSuiviChantier } from './createLeadAndSuiviChantier';
import { createLeadAndSuiviChantierAdvanced } from './createLeadAndSuiviChantierAdvanced';
import { createLeadAndSuiviChantierAdvancedWithPV } from './createLeadAndSuiviChantierAdvancedWithPV';
import { createPartenaire } from './createPartenaire';
import { createProClient } from './createProClient';
import { createProWithClientAndDevis } from './createProWithClientAndDevis';
import { createProWithoutSubscriptionProClient } from './createProWithoutSubAndProClient';
import { createProWithoutSubscription } from './createProWithoutSubscription';
import { Flow } from './Flow';

export const godModeFlows: { [k: string]: () => Flow<any, any> } = {
  create_adressable_lead: createAdressableLead,
  create_lead: createLead,
  create_lead_consultation: createLeadAndConsultation,
  create_lead_chantier_pro_archi: createLeadAndChantierProAndArchi,
  create_lead_consultation_offer: createLeadAndChantier,
  create_lead_and_devis: createLeadAndDevis,
  create_lead_send_devis: createLeadAndSendDevis,
  create_lead_sign_devis: createLeadAndSignDevis,
  create_lead_start_chantier: createLeadAndStartChantier,
  create_lead_suivi_chantier: createLeadAndSuiviChantier,
  create_lead_suivi_chantier_advanced: createLeadAndSuiviChantierAdvanced,
  create_lead_suivi_chantier_advanced_with_pv: createLeadAndSuiviChantierAdvancedWithPV,
  create_lead_complete_configurateur: createLeadAndCompleteConfigurateur,
  create_lead_complete_configurateur_consultation:
    createLeadAndCompleteConfigurateurAndConsultation,
  create_lead_complete_configurateur_devis: createLeadAndCompleteConfigurateurAndGenerateDevis,
  create_partenaire: createPartenaire,
  create_pro_client: createProClient,
  create_pro_without_subscription: createProWithoutSubscription,
  create_pro_without_subscription_pro_client: createProWithoutSubscriptionProClient,
  create_lead_and_sign_devis_and_devis_ts: createLeadAndSignDevisAndGenDevisTS,
  create_pro_and_client_and_devis: createProWithClientAndDevis,
  create_employee: createEmployee,
};
