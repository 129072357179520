import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const tpInterneBuilder = new AbilityBuilder<Action, Subject, Flag>();

tpInterneBuilder.can('validateAppelDePaiement', 'Chantier');
tpInterneBuilder.can('open', 'Chantier');
tpInterneBuilder.can('close', 'Chantier');
tpInterneBuilder.can('collectServiceHemea', 'Chantier');
tpInterneBuilder.can('toggleRelance', 'Chantier');
tpInterneBuilder.can('changeAmountAppelDePaiement', 'Chantier');
tpInterneBuilder.can('libererFonds', 'Chantier');
tpInterneBuilder.can('createAppelPaiement', 'Chantier');
tpInterneBuilder.can('manage', 'Fourniture', ['isAssignedTo']);

export const tpInterne = tpInterneBuilder.build();
