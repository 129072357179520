import React, { useState } from 'react';

import { FournituresWithLocation } from '@travauxlib/shared/src/types';

import { FilterRoomsDesktop } from './Desktop/FilterRoomsDesktop';
import { DisplayedCountFournitures } from './DisplayedCountFournitures';
import { EmptyStateFourniture } from './EmptyStateFourniture';
import { FournitureCardsByLocation } from './FournitureCardsByLocation';
import { FilterRoomsMobile } from './Mobile/FilterRoomsMobile';
import { ProgressReference } from './ProgressReference';

import { EditAddReferenceActionProps, Totals, UserType } from '../types';

type Props = {
  allFournitures: FournituresWithLocation[];
  fournituresByTab: FournituresWithLocation[];
  isAddedReferencesTab: boolean;
  editOrAddReferenceAction?: EditAddReferenceActionProps;
  userType: UserType;
  totals: Totals;
  completionPercentage: number;
  tabs: React.ReactNode;
};

export const FournitureCardsAndFilter: React.FC<Props> = ({
  allFournitures,
  fournituresByTab,
  isAddedReferencesTab,
  editOrAddReferenceAction,
  userType,
  totals,
  completionPercentage,
  tabs,
}) => {
  const roomsByTab = fournituresByTab.map(f => f.locationName);
  const [selectedRooms, setSelectedRooms] = useState<string[]>(
    allFournitures.map(f => f.locationName),
  );

  const handleRoomToggle = (room: string, checked: boolean): void => {
    if (checked) {
      setSelectedRooms([...selectedRooms, room]);
    } else {
      setSelectedRooms(selectedRooms.filter(r => r !== room));
    }
  };

  const fournituresFilteredByLocation = fournituresByTab.filter(({ locationName }) =>
    selectedRooms.includes(locationName),
  );

  const displayedFournitureCount = fournituresFilteredByLocation.flatMap(
    ({ fournitures }) => fournitures,
  ).length;

  return (
    <>
      <div className="flex gap-md max-h-full">
        <div className="flex flex-col grow max-h-full">
          {tabs}
          <div className="px-md sm-desktop:hidden flex items-center justify-between gap-xxs mb-md">
            <DisplayedCountFournitures displayedCount={displayedFournitureCount} />
            <FilterRoomsMobile
              fournitures={allFournitures}
              availableRooms={roomsByTab}
              selectedRooms={selectedRooms}
              onRoomToggle={handleRoomToggle}
            />
          </div>
          {displayedFournitureCount === 0 ? (
            <EmptyStateFourniture
              activeTab={isAddedReferencesTab ? 'added' : 'missing'}
              userType={userType}
            />
          ) : (
            <div className="max-h-full overflow-auto">
              <FournitureCardsByLocation
                editOrAddReferenceAction={editOrAddReferenceAction}
                fournitures={fournituresFilteredByLocation}
                userType={userType}
              />
            </div>
          )}
        </div>
        <FilterRoomsDesktop
          fournitures={allFournitures}
          selectedRooms={selectedRooms}
          availableRooms={roomsByTab}
          onRoomToggle={handleRoomToggle}
          totals={totals}
          completionPercentage={completionPercentage}
          displayedCount={displayedFournitureCount}
        />
      </div>
      <div className="fixed bottom-0 right-0 w-full bg-neutral-0 p-md !pt-xs border-t border-neutral-300 sm-desktop:hidden">
        <ProgressReference totals={totals} completionPercentage={completionPercentage} />
      </div>
    </>
  );
};
