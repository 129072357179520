import { request } from '@travauxlib/shared/src/utils/request';

import { useChantierQuery } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';
import { ChantierAdmin } from 'types';

const handleToggleEnableSuiviRenforceChantier = async (chantier: ChantierAdmin): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/suivi-renforce/${!chantier.enableSuiviRenforce}`;

  await request(requestURL, {
    method: 'PATCH',
  });
};

export const useToggleEnableSuiviRenforce = (): {
  toggleEnableSuiviRenforce: typeof handleToggleEnableSuiviRenforceChantier;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery(
    handleToggleEnableSuiviRenforceChantier,
    'de la mise à jour de la config du chantier',
    'La config du chantier a bien été mise à jour.',
  );

  return { toggleEnableSuiviRenforce: query, isLoading };
};
