import React from 'react';

import classNames from 'classnames';
import _sum from 'lodash/sum';
import { useFormState } from 'react-final-form';

import SquareFoot from '@travauxlib/shared/src/components/DesignSystem/assets/SquareFoot.svg?react';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { LocalisationSurfaceModalContent } from './LocalisationSurfaceModalContent';

type Props = {
  isModalOpen: boolean;
  setActiveModal: (modal: undefined | 'localisations' | 'surfaces') => void;
  isRequired: boolean;
};

export const LocalisationsSurfacesChoice: React.FC<Props> = ({
  isModalOpen,
  setActiveModal,
  isRequired,
}) => {
  const { values } = useFormState<ProjectView & { disabled: boolean; hideUnfilled: boolean }>();
  const { disabled, locations = [], hideUnfilled } = values;

  if ((locations.length === 0 || locations.some(l => !!l.surface)) && hideUnfilled) {
    return null;
  }

  return (
    <>
      <SimpleModal
        isOpen={isModalOpen}
        title="Surface à rénover"
        validateAction={{
          label: 'Valider',
          onClick: () => {
            setActiveModal(undefined);
          },
        }}
      >
        <LocalisationSurfaceModalContent />
      </SimpleModal>
      <div className="flex mb-xs items-start justify-start">
        <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">
          <SquareFoot />
        </div>
        <div
          onClick={() => {
            if (!disabled) {
              setActiveModal(locations.length === 0 ? 'localisations' : 'surfaces');
            }
          }}
          className={classNames('flex items-center gap-xs', !disabled && 'cursor-pointer')}
        >
          <span>Surface à rénover :</span>
          {locations.length !== 0 && locations.every(l => !!l.surface) ? (
            <>{roundToTwoDecimals(_sum(locations.map(l => l.surface)))} m²</>
          ) : isRequired ? (
            <InlineAlert variant="error">Obligatoire</InlineAlert>
          ) : (
            <InlineAlert variant="info">À définir</InlineAlert>
          )}
        </div>
      </div>
    </>
  );
};
