import _capitalize from 'lodash/capitalize';

import {
  Configuration,
  GenerationData,
  MateriauLabel,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';

const idFenetres = (
  surface: number,
  count: number,
  materiau: 'pvc' | 'alu' | 'bois',
): [BaseDePrixIdentifier, BaseDePrixIdentifier] => {
  if (surface <= 2) {
    return ['depose_fenetre_petite', `fenetre_1_vantail_${materiau}_tres_petite`];
  }
  if (surface <= 4) {
    return ['depose_fenetre_petite', `fenetre_1_vantail_${materiau}_petite`];
  }
  if (surface <= 7) {
    return ['depose_fenetre_moyenne', `fenetre_1_vantail_${materiau}_moyenne`];
  }
  if (surface <= 20) {
    return ['depose_fenetre_moyenne', `fenetre_2_vantaux_${materiau}_moyenne`];
  }
  if (count === 1) {
    return ['depose_fenetre_grande', `fenetre_2_vantaux_${materiau}_grande`];
  }
  return ['depose_fenetre_moyenne', `fenetre_2_vantaux_${materiau}_moyenne`];
};

export const menuiseriesExterieures = (configuration: Configuration): GenerationData[] => {
  const menuiseriesExterieures = configuration.answers?.menuiseriesExterieures;
  if (!menuiseriesExterieures) {
    return [];
  }
  const result: GenerationData[] = [];

  for (const porteEntree of menuiseriesExterieures.porteEntrees || []) {
    const locationPorte = configuration.locations.find(l => l.uuid === porteEntree?.locationUuid);

    if (locationPorte) {
      if (porteEntree?.typePrestation === 'Remplacement') {
        result.push({
          ouvrageLabel: "Remplacement porte d'entrée",
          tagQuantity: 'skip',
          id: 'depose_porte_entree',
          quantity: 1,
          isOption: porteEntree.isOption,
          locationQuantities: {
            [locationPorte.uuid]: 1,
          },
        });
        result.push({
          ouvrageLabel: "Remplacement porte d'entrée",
          tagQuantity: 'skip',
          quantity: 1,
          id: 'porte_entree_1_vantail',
          isOption: porteEntree.isOption,
          locationQuantities: {
            [locationPorte.uuid]: 1,
          },
        });
      } else {
        result.push({
          ouvrageLabel: "Blindage porte d'entrée",
          id: 'blindage_porte_entree',
          tagQuantity: 'skip',
          quantity: 1,
          isOption: porteEntree.isOption,
          locationQuantities: {
            [locationPorte.uuid]: 1,
          },
        });
      }
    }
  }

  if (menuiseriesExterieures.ajoutFenetres === null) {
    result.push({
      ouvrageLabel: 'Remplacement fenêtres',
      inProgress: true,
    });
  }

  const fenetres = menuiseriesExterieures.fenetres || [];

  for (let fenetre of fenetres) {
    const isOption = fenetre.isOption;
    for (let [uuid, quantities] of Object.entries(fenetre.locationQuantities || {})) {
      const materiau = fenetre.typeMateriau!;
      const location = configuration.locations.find(l => l.uuid === uuid);
      if (location && quantities) {
        const quantitiesFenetres = quantities.fenetres;
        const quantitiesPorteFenetres = quantities.porteFenetres;
        const quantitiesBaieVitres = quantities.baieVitrees;
        const materiauLabel = materiau === 'pvc' ? 'PVC' : (_capitalize(materiau) as MateriauLabel);
        if (quantitiesFenetres) {
          idFenetres(location.surface, quantitiesFenetres, materiau).forEach(id => {
            result.push({
              ouvrageLabel: `Remplacement fenêtres - ${materiauLabel}`,
              id,
              isOption,
              tagQuantity: id.startsWith('depose') ? 'only' : undefined,
              quantity: quantitiesFenetres,
              locationQuantities: {
                [location.uuid]: quantitiesFenetres,
              },
            });
          });
        }
        if (quantitiesPorteFenetres) {
          const porteFenetreId =
            location.surface <= 20 && quantitiesPorteFenetres === 2
              ? 'porte_fenetre_1_vantail'
              : 'porte_fenetre_2_vantaux';
          ['depose_porte_fenetre' as const, `${porteFenetreId}_${materiau}` as const].forEach(
            id => {
              result.push({
                ouvrageLabel: `Remplacement porte-fenêtre - ${materiauLabel}`,
                id,
                isOption,
                tagQuantity: id.startsWith('depose') ? 'only' : undefined,
                quantity: quantitiesPorteFenetres,
                locationQuantities: {
                  [location.uuid]: quantitiesPorteFenetres,
                },
              });
            },
          );
        }
        if (quantitiesBaieVitres) {
          ['depose_baie_vitree' as const, `baie_vitree_coulissante_${materiau}` as const].forEach(
            id => {
              result.push({
                ouvrageLabel: `Remplacement Baie vitrée - ${materiauLabel}`,
                id,
                isOption,
                tagQuantity: id.startsWith('depose') ? 'only' : undefined,
                quantity: quantitiesBaieVitres,
                locationQuantities: {
                  [location.uuid]: quantitiesBaieVitres,
                },
              });
            },
          );
        }
      }
    }
  }

  for (const fenetresToit of menuiseriesExterieures.fenetresToits || []) {
    for (let [uuid, quantity] of Object.entries(fenetresToit?.locationQuantities || {})) {
      if (quantity) {
        result.push({
          ouvrageLabel: 'Fenêtre de toit',
          id: 'fenetre_toit_114_118',
          tagQuantity: 'only',
          quantity,
          isOption: fenetresToit.isOption,
          locationQuantities: {
            [uuid]: quantity,
          },
        });
        result.push({
          ouvrageLabel: 'Fenêtre de toit',
          id: 'fenetre_toit_chevetre_114_118',
          quantity,
          isOption: fenetresToit.isOption,
          locationQuantities: {
            [uuid]: quantity,
          },
        });
      }
    }
  }

  if (menuiseriesExterieures.ajoutVolets === null) {
    result.push({
      ouvrageLabel: 'Volets roulants ou persiennes',
      inProgress: true,
    });
  }

  const volets = menuiseriesExterieures.volets || [];

  for (let volet of volets) {
    const isOption = volet.isOption;
    for (let [uuid, quantities] of Object.entries(volet.locationQuantities || {})) {
      if (quantities?.persiennes) {
        result.push({
          ouvrageLabel: 'Remplacement de persienne',
          id: 'depose_persienne',
          isOption,
          tagQuantity: 'only',
          quantity: quantities.persiennes,
          locationQuantities: {
            [uuid]: quantities.persiennes,
          },
        });
        result.push({
          ouvrageLabel: 'Remplacement de persienne',
          id: 'persienne_fenetre',
          isOption,
          quantity: quantities.persiennes,
          locationQuantities: {
            [uuid]: quantities.persiennes,
          },
        });
      }
      if (quantities?.fenetres) {
        result.push({
          ouvrageLabel: 'Remplacement de volet roulant',
          id: 'depose_volet_roulant',
          isOption,
          tagQuantity: 'only',
          quantity: quantities.fenetres,
          locationQuantities: {
            [uuid]: quantities.fenetres,
          },
        });
        result.push({
          ouvrageLabel: 'Remplacement de volet roulant',
          id: 'volets_roulants_fenetre',
          isOption,
          quantity: quantities.fenetres,
          locationQuantities: {
            [uuid]: quantities.fenetres,
          },
        });
      }
      if (quantities?.baiesVitrees) {
        result.push({
          ouvrageLabel: 'Remplacement de volet roulant',
          id: 'depose_volet_roulant',
          isOption,
          quantity: quantities.baiesVitrees,
          tagQuantity: 'only',
          locationQuantities: {
            [uuid]: quantities.baiesVitrees,
          },
        });
        result.push({
          ouvrageLabel: 'Remplacement de volet roulant',
          id: 'volets_roulants_baie_vitree',
          isOption,
          quantity: quantities.baiesVitrees,
          locationQuantities: {
            [uuid]: quantities.baiesVitrees,
          },
        });
      }
    }
  }

  return result;
};
