import React from 'react';

import classNames from 'classnames';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';

import { DropdownOption } from './types';

import { IconButton } from '../Buttons/IconButton';

type Props<T> = {
  selectedOptions: DropdownOption<T>[];
  value: T[];
  disabled?: boolean;
  onChange: (newValue: Array<T>) => void;
  combobox?: boolean;
};

export function SelectedOptions<T>({
  selectedOptions,
  value,
  onChange,
  disabled,
  combobox,
}: Props<T>): React.ReactElement | null {
  if (!selectedOptions.length) {
    return null;
  }

  if (combobox) {
    return (
      <div
        className={classNames(
          'px-xs h-[1.5rem] shrink-0 rounded bg-gray-200 mr-xs flex items-center rounded',
        )}
      >
        <div className="text-b2 pr-xxs mb-[1px]">
          {selectedOptions.length} sélectionné{selectedOptions.length > 1 && 's'}
        </div>
        <IconButton
          data-testid="delete-all"
          disabled={disabled}
          onClick={e => {
            e.stopPropagation();
            onChange([]);
          }}
          size="xs"
        >
          <CrossSymbol className={classNames(disabled && 'text-neutral-300')} />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      {selectedOptions.map((option, index) => (
        <div
          //eslint-disable-next-line
          style={{
            order: index,
          }}
          className={classNames(
            'px-xs h-[1.5rem] shrink-0 rounded bg-gray-200 mr-xs flex items-center rounded',
          )}
          key={option.label}
        >
          <div className="text-b2 pr-xxs mb-[1px]">{option.shortLabel || option.label}</div>
          <IconButton
            data-testid="delete"
            disabled={disabled}
            onClick={e => {
              e.stopPropagation();
              onChange(value.filter(v => v !== option.value));
            }}
            size="xs"
          >
            <CrossSymbol className={classNames(disabled && 'text-neutral-300')} />
          </IconButton>
        </div>
      ))}
    </>
  );
}
