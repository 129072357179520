import React from 'react';

import dayjs from 'dayjs';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { useDevisSignes, NB_MONTHS } from '../../api/useDevisSignes';

type Props = {
  proCompany: ProCompanyAdminView;
};

export const LastDevis: React.FC<Props> = ({ proCompany: { slug } }) => {
  const devisSignes = useDevisSignes(slug);
  if (!devisSignes) {
    return null;
  }

  return (
    <>
      <h3>Devis signés les {NB_MONTHS} derniers mois</h3>
      <table>
        <tbody>
          {devisSignes.data.map(devisSigne => (
            <tr key={devisSigne.id}>
              <th>Signé le {dayjs(devisSigne.dateSignature).format('DD/MM/YYYY')}</th>
              <td>
                {devisSigne.title}
                <br />
                <br />
                Date de début : {devisSigne.dateDebut}
                <br />
                Date de fin : {devisSigne.dateFin}
                <br />
                Client : {devisSigne.customerName}
                <br />
                Prix total TTC : <EURCurrency amount={devisSigne.prixTotalTTC} />
                <br />
                Adresse : {devisSigne.address.address} - {devisSigne.address.postalCode}{' '}
                {devisSigne.address.city}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
