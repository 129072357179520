import React from 'react';

import capitalize from 'lodash/capitalize';

import { useDownloadAsZip } from '@travauxlib/shared/src/api/useDownloadAsZip';
import DocumentCanceled from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentCanceled.svg?react';
import DocumentFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentFilled.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/DownloadFilled.svg?react';
import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { Popover } from '@travauxlib/shared/src/components/DesignSystem/components/Popover';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { PvStatusTag } from '@travauxlib/shared/src/features/Chantiers/components/Pv/PvStatusTag';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import {
  ChantierPvType,
  ChantierPvView,
} from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { noop } from '@travauxlib/shared/src/utils/noop';
import { slugify } from '@travauxlib/shared/src/utils/slugify';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useIsAllowed } from 'hooks/useIsAllowed';
import { ChantierAdmin } from 'types';

import { useOpenModifyThresholdModal } from './ModifyThresholdModal';

type Props = {
  chantier: ChantierAdmin;
};

const EmptyPvBloc: React.FC<{ pvType: ChantierPvType }> = ({ pvType }) => {
  const documentName = capitalize(PvDocumentName[pvType]);
  const isTabletOrMobile = useIsTabletOrMobile();
  return (
    <div className="mb-md">
      <div className="font-medium mb-xs">{documentName}</div>
      <div className="rounded-xs border flex items-center bg-gray">
        <div className="m-xs">
          <ColoredCircle variant="light">
            <DocumentFilled />
          </ColoredCircle>
        </div>
        <div className="flex-grow">
          <div className="text-b2">Document vide</div>
        </div>
        <div>
          <Tag
            className="mr-xs"
            icon={<DocumentCanceled />}
            label={isTabletOrMobile ? undefined : 'Non transmis'}
            variant="light"
          />
        </div>
        <IconButton disabled onClick={noop}>
          <Download />
        </IconButton>
      </div>
    </div>
  );
};

const PvLabel: React.FC<{ pv: ChantierPvView }> = ({ pv }) => {
  if (pv.rejectedAt) {
    return (
      <div className="flex items-center text-sm text-neutral-700">
        <span>Envoyé le {formatFrenchDate(pv.createdAt)} | </span>
        <div className="inline-flex items-center">
          <span className="mr-xs">Refusé le {formatFrenchDate(pv.rejectedAt)}</span>
          <Popover
            title="Motif du refus"
            content={<div className="text-b2">"{pv.rejectionReason}"</div>}
            position="right-end"
            label="Fermer"
            trigger={(toggle, referenceProps) => (
              <IconButton size="xs" {...referenceProps} onClick={toggle}>
                <HelpCircle />
              </IconButton>
            )}
          />
        </div>
      </div>
    );
  }
  if (pv.validatedAt) {
    return (
      <div className="flex items-center text-sm text-neutral-700">
        <span>
          Envoyé le {formatFrenchDate(pv.createdAt)} | Validé le {formatFrenchDate(pv.validatedAt)}
        </span>
      </div>
    );
  }
  return (
    <div className="flex items-center text-sm text-neutral-700">
      <span>
        {' '}
        {pv.documents.length} document(s) ajouté(s) | Envoyé le {formatFrenchDate(pv.createdAt)}
      </span>
    </div>
  );
};

const PvBloc: React.FC<{ pvs: ChantierPvView[] }> = ({ pvs }) => {
  const { downloadAsZip, isLoading } = useDownloadAsZip();

  const documentName = capitalize(PvDocumentName[pvs[0].pvType]);
  const showVersion = pvs.length > 1;
  return (
    <div className="mb-md">
      <div className="font-medium mb-xs">{documentName}</div>
      <div className="rounded-xs border">
        {pvs.map((pv, index) => (
          <div key={pv.uuid} className="border-b last:border-b-0 flex items-center">
            <div className="m-xs">
              <ColoredCircle variant="beige">
                <DocumentFilled />
              </ColoredCircle>
            </div>
            <div className="flex-grow">
              <div className="text-b2">
                Procès-verbal {showVersion && <span>Version {pvs.length - index}</span>}
              </div>
              <PvLabel pv={pv} />
            </div>
            <div>
              <PvStatusTag pv={pv} />
            </div>
            <IconButton
              disabled={isLoading}
              onClick={() =>
                downloadAsZip({
                  zipName: slugify(documentName),
                  urls: pv.documents.map(d => d.downloadUrl),
                })
              }
            >
              <Download />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export const FinDeChantier: React.FC<Props> = ({ chantier }) => {
  const openModifyThresholdModal = useOpenModifyThresholdModal();
  const disableThresholdModification = !useIsAllowed('Chantier', 'useAdvancedActions');

  return (
    <div>
      {chantier.pv.receptionChantier ? (
        <PvBloc
          pvs={chantier.allPvs.filter(pv => pv.pvType === ChantierPvType.ReceptionChantier)}
        />
      ) : (
        <EmptyPvBloc pvType={ChantierPvType.ReceptionChantier} />
      )}
      {chantier.pv.receptionChantier?.hasReserves && (
        <>
          {chantier.pv.leveeReserves ? (
            <PvBloc
              pvs={chantier.allPvs.filter(pv => pv.pvType === ChantierPvType.LeveeReserves)}
            />
          ) : (
            <EmptyPvBloc pvType={ChantierPvType.LeveeReserves} />
          )}
        </>
      )}
      <div className="flex justify-center">
        <Button
          disabled={disableThresholdModification}
          disabledMessageTooltip="Permission requise: Autorisé à modifier les seuils de libérations des chantiers"
          onClick={() => openModifyThresholdModal({ chantier })}
        >
          Modifier les seuils de libération des fonds
        </Button>
      </div>
    </div>
  );
};
