import {
  AlertVariant,
  BrandVariant,
  ExtendedPaletteVariants,
  NeutralVariant,
} from '@travauxlib/shared/src/types/designSystem';

export enum FluxFinancierHistoriqueFilterType {
  Paiement = 'paiement',
  Provision = 'provision',
  Signature = 'signature',
  All = 'all',
}

export enum FluxFinancierEtatAvancementFilterType {
  Refuse = 'rejected',
  Corrige = 'disputed',
  Valide = 'agreed',
  All = 'all',
}

export type HistoryItemAction = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  disabledMessage?: string;
  variant?: VariantHistotyItemCta;
};

export type Variant =
  | AlertVariant
  | BrandVariant
  | Extract<NeutralVariant, 'light'>
  | Extract<ExtendedPaletteVariants, 'beige'>;

export type HistoryItem = {
  key: string;
  date: string;
  author?: string;
  amount: number;
  label: string;
  Icon: React.FC;
  variant: Variant;
  filterType: FluxFinancierHistoriqueFilterType;
  actions: HistoryItemAction[];
  cancellationInfo?: {
    date: string;
    author?: string;
  };
  pendingInfo?: {
    label: string;
    tooltipMessage: string;
  };
  validationInfo?: {
    date: string;
    author?: string;
  };
  isIgnored?: boolean;
  isUserVisible?: boolean;
};

export type VariantHistotyItemCta = 'primary' | 'secondary';
