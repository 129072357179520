import { Action, Subject, Flag } from './types';

import { AbilityBuilder } from '../AbilityBuilder';

const customerCareBuilder = new AbilityBuilder<Action, Subject, Flag>();

customerCareBuilder.can('open', 'Chantier');
customerCareBuilder.can('close', 'Chantier');
customerCareBuilder.can('createAppelPaiement', 'Chantier');
customerCareBuilder.can('collectServiceHemea', 'Chantier');
customerCareBuilder.can('toggleRelance', 'Chantier');
customerCareBuilder.can('changeInternalAffectation', 'Chantier');
customerCareBuilder.can('activateSuiviRenforce', 'Chantier');
customerCareBuilder.can('libererFonds', 'Chantier');
customerCareBuilder.can('validateAppelDePaiement', 'Chantier');
customerCareBuilder.can('useAdvancedActions', 'Chantier');
customerCareBuilder.can('cancelAppelDePaiement', 'Chantier');

export const customerCare = customerCareBuilder.build();
