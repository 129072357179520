import React from 'react';

import Dropzone from 'react-dropzone';
import { Form } from 'react-final-form';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';
import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProReviewView } from '@travauxlib/shared/src/types/api/admin/ProCompanyProfileAdminView';
import { required } from '@travauxlib/shared/src/utils/form';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';

const ratingOptions = Array(10)
  .fill(0)
  .map((_, index) => ({ value: index + 1, label: `${index + 1}` }));

type Props = {
  initialValues?: ProReviewView;
  reviewId?: number;
  slug: string;
};

export const Review: React.FC<Props> = ({ reviewId, initialValues, slug }) => {
  const { uploadPhoto, updateReview, deleteReview } = useUpdateProCompany();
  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (review, form) => {
        await updateReview({ review: review as any, slug });
        form.restart();
      }}
    >
      {({ handleSubmit, values, form }) => (
        <form data-testid="review" className="container" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-md mb-md">
            <div className="w-8/12 px-md relative mb-md ">
              <InputField
                id="customerName"
                label="Nom de l'utilisateur"
                type="text"
                name="customerName"
                validate={required}
              />
            </div>
            <div className="w-4/12 px-md relative">
              <DropdownField
                label="Note"
                id="rating"
                name="rating"
                options={ratingOptions}
                validate={required}
              />
            </div>
            <div className="w-4/12 px-md relative mb-md">
              <DatePickerField
                label="Date"
                parse={parseDateInput}
                id="dateTravaux"
                name="dateTravaux"
                validate={required}
              />
            </div>
            <div className="w-4/12 px-md relative">
              <InputField
                label="Type de projet"
                id="project-type"
                placeholder="Rénovation complète d'un appartement, rénovation d'une cuisine, ..."
                type="text"
                name="projectType"
                validate={required}
              />
            </div>
            <div className="w-4/12 px-md relative">
              <InputField
                label="Budget"
                id="review-budget"
                type="text"
                name="budget"
                validate={required}
              />
            </div>
            <div className="w-full px-md relative">
              <TextareaField
                label="Description"
                validate={required}
                id="review-description"
                name="description"
                rows={4}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-md">
            <div className="w-4/12 px-md relative">Photo de l&apos;utilisateur</div>
            <div className="w-8/12 px-md relative">
              <div className="!flex">
                <div className="!mr-md">
                  <img
                    src={values.customerImageUrl}
                    alt=""
                    className="max-w-[100px] rounded-full"
                  />
                </div>
                <div className="!flex !flex-col">
                  {values.customerImageUrl && (
                    <IconButton
                      type="button"
                      variant="black"
                      className="mr-sm mb-sm"
                      onClick={() => form.change('customerImageUrl', undefined)}
                    >
                      <DeleteFilled />
                    </IconButton>
                  )}
                  <Dropzone
                    onDrop={async files => {
                      const { path } = await uploadPhoto({
                        slug,
                        file: files[0],
                      });
                      form.change('customerImageUrl', `${APP_CONFIG.baseImgixUrl}/${path}`);
                      toast.success('Photo créée avec succès, pensez à sauvegarder !');
                    }}
                  >
                    {({ getRootProps, getInputProps }) => {
                      const { ref, ...rest } = getRootProps();
                      return (
                        <Button className="w-[200px]" size="sm" leftIcon={<PlusCircle />} {...rest}>
                          Remplacer la photo
                          <input {...getInputProps()} />
                        </Button>
                      );
                    }}
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>
          <div className="!flex !flex-row-reverse">
            <Button type="submit" leftIcon={<CheckSymbol />}>
              Sauvegarder
            </Button>
            {reviewId && (
              <Button
                variant="secondary"
                onClick={() => deleteReview({ reviewId, slug })}
                leftIcon={<DeleteFilled />}
              >
                Supprimer l&apos;avis
              </Button>
            )}
          </div>
          <hr />
        </form>
      )}
    </Form>
  );
};
