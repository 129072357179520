import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';

import { poseChauffeEauId } from './ids';

export const poseChauffeEauElectrique = ({
  configuration,
  locationUuid,
  isOption,
}: {
  configuration: Configuration;
  locationUuid: string;
  isOption?: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel: 'Chauffe-eau électrique',
    id: poseChauffeEauId(configuration),
    quantity: 1,
    isOption,
    tagQuantity: 'only',
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Chauffe-eau électrique',
    id: 'ec_ef_evacuation_element_cuivre',
    quantity: 1,
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Chauffe-eau électrique',
    id: 'sortie_de_cable',
    quantity: 1,
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  return result;
};
