import { requiredFieldsForConsultation } from '@travauxlib/shared/src/features/Configurateur/utils/constants';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';
import {
  nonEmptyArrayRequired,
  required,
  requiredValidGoogleMapAddress,
} from '@travauxlib/shared/src/utils/form';

import { ConsultationInfo } from 'types';

export const checkRequiredFields = (project: ProjectView): boolean => {
  const hasAllRequiredFieldFilled = requiredFieldsForConsultation.every(
    field => project[field] && project[field] !== 'N/A',
  );
  const isAddressValid =
    !!project.address && !requiredValidGoogleMapAddress('route')(project.address);

  return hasAllRequiredFieldFilled && isAddressValid;
};

export const validateConsultationInfo = (
  consultationInfo: Partial<ConsultationInfo>,
): Record<string, string | undefined> => {
  if (consultationInfo.companyType === 'architecte') {
    return {};
  }

  const companyType = required(consultationInfo.companyType);
  const budgetClient = required(consultationInfo.budgetClient);
  const typesTravaux = nonEmptyArrayRequired(consultationInfo.typesTravaux);
  const insuredFor = nonEmptyArrayRequired(consultationInfo.insuredFor);

  return { companyType, typesTravaux, insuredFor, budgetClient };
};
