import { useFormState } from 'react-final-form';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { TypeBien } from '@travauxlib/shared/src/types';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  Chauffage,
  Plomberie,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

import { DeposeRadiateursEaux } from './components/DeposeRadiateursEaux';
import { LocalisationPacAInstaller } from './components/LocalisationPacAInstaller';
import { ModificationsRadiateurEau } from './components/ModificationsRadiateurEau';

import { getAlertForTypeModifChauffage } from '../../utils/getAlertForTypeModif';

type Props = {
  configuration: Configuration;
  name: string;
  value: Chauffage;
};

export const Gaz: React.FC<Props> = ({ configuration, name, value }) => {
  const locationOptions = getLocationOptions(configuration);
  const { values } = useFormState<Plomberie>();
  const isMaison = configuration.typeLogement !== TypeBien.Appartement;

  const showDeposeChaudiereLocationQuestion =
    value?.typeModifGaz === 'Gaz' ||
    value?.typeModifGaz === 'PAC' ||
    value?.typeModifGaz === 'Electrique';

  return (
    <>
      <QuestionTitle
        prefix="2"
        title="Type de modifications ?"
        alert={getAlertForTypeModifChauffage(value, values)}
      />
      <ChipListField
        name={`${name}.typeModifGaz`}
        renderingOptions={{
          containerClassName: 'mb-md flex flex-wrap gap-xs',
        }}
        options={[
          {
            label: 'Suppression, ajout ou modification de radiateur à reau',
            value: 'Radiateur',
          },
          {
            label: 'Remplacement seul de chaudière gaz',
            value: 'Gaz',
          },
          {
            label: 'Changement complet pour chauffage électrique',
            value: 'Electrique',
          },
          ...(isMaison
            ? [
                {
                  label: 'Changement pour chauffage PAC',
                  value: 'PAC',
                },
              ]
            : []),
        ]}
      />
      {value?.typeModifGaz === 'Radiateur' && (
        <ModificationsRadiateurEau configuration={configuration} name={name} />
      )}
      {showDeposeChaudiereLocationQuestion && (
        <>
          <QuestionTitle
            prefix="3"
            title={
              value?.typeModifGaz === 'Gaz'
                ? 'Localisation de la chaudière à remplacer'
                : 'Localisation de la chaudière à déposer ?'
            }
          />
          <ChipPictoVerticalListFieldConfigurateur
            name={`${name}.deposeChaudiereLocationUuid`}
            locationOptions={locationOptions}
          />
        </>
      )}

      {value?.typeModifGaz === 'Electrique' && value.deposeChaudiereLocationUuid && (
        <DeposeRadiateursEaux configuration={configuration} prefix="4" name={name} />
      )}
      {value?.typeModifGaz === 'PAC' && value?.deposeChaudiereLocationUuid && (
        <LocalisationPacAInstaller configuration={configuration} prefix="4" name={name} />
      )}
    </>
  );
};
