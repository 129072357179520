import React from 'react';

import classNames from 'classnames';

import { iconByVariant, iconClassNamesBySize, iconClassNamesByVariant } from './constants';
import { Size, Variant } from './types';

type Props = {
  variant: Variant;
  size: Size;
};

export const Icon: React.FC<Props> = ({ variant, size }) => (
  <span
    className={classNames(
      'shrink-0 flex items-center',
      iconClassNamesByVariant[variant],
      iconClassNamesBySize[size],
    )}
  >
    {iconByVariant[variant]}
  </span>
);
