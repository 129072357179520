import React, { useEffect } from 'react';

import { Fournitures, Props as FournituresProps } from '../index';

type Props = {
  doTracking: () => void;
} & FournituresProps;

export const FournituresWithTracking: React.FC<Props> = ({ doTracking, ...props }) => {
  useEffect(() => {
    doTracking();
  }, []);

  return <Fournitures {...props} />;
};
