import React, { useEffect } from 'react';

import { Outlet } from 'react-router';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { CreateLead } from './components/CreateLead';
import { LeadListing } from './components/LeadListing';
import { EditAssignmentConfig } from './features/AutomatedAssignment/components/EditAssignmentConfig';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onDashboardCPDViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.dashboard} />
      <Outlet />{' '}
    </>
  );
};

export const DashboardCDP = {
  Index,
  EditAssignmentConfig,
  CreateLead,
  LeadListing,
};
