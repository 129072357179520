export enum LostReason {
  HorsZone = 'Hors zone géographique',
  TropPetitsTravaux = 'Trop petits travaux',
  NousNeSavonsPasFaire = 'Nous ne savons pas faire',
  TestEstimateur = 'Test estimateur',
  Doublon = 'Doublon',
  BudgetInadapte = 'Budget inadapté',
  LeadRefuse = 'Lead refusé',
  ScoreTropBas = 'Score trop bas',
  ScoreIncompatible = 'Score incompatible',
  LeadNonQualifie = 'Lead non qualifié',

  // Nouveaux lost reasons
  AbandonPasDeReponse = "Abandon pas de réponse - Jamais réussi à l'avoir",
  PlusDeReponse = 'Plus de réponse du client',
  Abandon = 'Projet abandonné',
  ConseilAvantProjet = 'Conseils avant projet',
  ATrouveAutreEntreprise = 'A trouvé une autre entreprise',

  MauvaisDestinataire = 'Mauvais destinaire / Faux numéro',

  JusteSavoirLePrix = 'Juste savoir le prix / demande de devis uniquement',

  NePasRecontacter = 'Ne pas recontacter',
  BesoinDeco = 'Besoin Déco / Archi uniquement',
  IncompatibleAccompagnement = 'Incompatible avec notre accompagnement',
  PasDentrepriseDispo = 'Pas d’entreprise pour cette zone / ce type de travaux',

  ATrouveAutreArchi = 'A trouvé un autre archi',

  ProblemePRO = 'Problème PRO (feeling / réactivité / prestation)',
}
