import AutorenewCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AutorenewCircle.svg?react';
import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import Living from '@travauxlib/shared/src/components/DesignSystem/assets/LivingDuoTone.svg?react';
import Workman from '@travauxlib/shared/src/components/DesignSystem/assets/Workman.svg?react';
import { Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ChantierStatus } from '@travauxlib/shared/src/types/api/admin/ChantierStatus';

const ICON_CLASS = 'w-xmd h-xmd pr-xxs';
const INDICATOR_ICON_CLASS = 'w-sm h-sm mr-xxs';

export const ChantierStatusDetails: {
  [K in ChantierStatus]: {
    label: string;
    emoji: string;
    value: ChantierStatus;
    variant: Variant;
    style: string;
    icon: React.ReactElement;
    indicatorIcon?: React.ReactElement;
  };
} = {
  non_demarre: {
    label: 'Non démarré',
    emoji: '🚧',
    value: ChantierStatus.NonDemarre,
    variant: 'primary',
    style: 'bg-neutral-0',
    icon: <Workman className={ICON_CLASS} />,
  },
  demarre: {
    label: 'Démarré',
    emoji: '🏗️',
    value: ChantierStatus.Demarre,
    variant: 'success',
    style: 'bg-info-50',
    icon: <Workman className={ICON_CLASS} />,
    indicatorIcon: <AutorenewCircle className={`${INDICATOR_ICON_CLASS} text-info-600`} />,
  },
  termine: {
    label: 'Terminé',
    emoji: '✔️',
    value: ChantierStatus.Termine,
    variant: 'light',
    style: 'bg-success-50',
    icon: <Living className={ICON_CLASS} />,
    indicatorIcon: <CheckCircle className={`${INDICATOR_ICON_CLASS} text-success-600`} />,
  },
  annule: {
    label: 'Annulé',
    emoji: '❌',
    value: ChantierStatus.Annule,
    variant: 'error',
    style: 'bg-warning-50',
    icon: <Living className={ICON_CLASS} />,
    indicatorIcon: <CrossCircle className={`${INDICATOR_ICON_CLASS} text-warning-600 `} />,
  },
};
