import React from 'react';

import { useOutletContext } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { ProjectContext } from 'features/Project/api/useProjectContext';

import { useAdminProjectDevis } from './api/useAdminProjectDevis';
import { DevisListing } from './components/DevisListing';

export const ProjectDevis: React.FC = () => {
  const { project, clientAccounts } = useOutletContext<ProjectContext>();
  const { isLoading, data } = useAdminProjectDevis(project.uuid);
  if (isLoading || !data) {
    return <Loader testId="project-devis-loader" />;
  }

  return (
    <DevisListing
      proCompanyProjectsAndDevis={data}
      projectConformiteDevisEnabled={project.enableConformiteDevis}
      projectUuid={project.uuid}
      clientAccount={clientAccounts[0]}
    />
  );
};
