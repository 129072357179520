import React from 'react';

import dayjs from 'dayjs';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { CompanyInfo } from 'features/Entreprises/components/CompanyInfo';

type Props = {
  proCompany: ProCompanyAdminView;
};

const displayDate = (date?: string): string => {
  if (date === undefined || date === null) {
    return '-';
  }
  return dayjs(date).format('DD/MM/YYYY');
};

const OuiOuNonBadge = ({ value }: { value: boolean }): React.ReactElement => (
  <Tag
    className="!block text-center"
    size="md"
    label={value ? 'Oui' : 'Non'}
    variant={value ? 'success' : 'info'}
  />
);

export const Row: React.FC<Props> = ({ proCompany }) => {
  const {
    hasClubProForFree,
    trialPeriodEndDate,
    hasOngoingSubscription,
    isClubProPlus,
    isOptymeaAccountActivated,
  } = proCompany;
  return (
    <tr>
      <td>
        <CompanyInfo proCompany={proCompany} />
      </td>
      <td>
        <OuiOuNonBadge value={hasClubProForFree} />
      </td>
      <td className="!text-center">{displayDate(trialPeriodEndDate)}</td>
      <td>
        <OuiOuNonBadge value={hasOngoingSubscription} />
      </td>
      <td>
        <OuiOuNonBadge value={isClubProPlus} />
      </td>
      <td>
        <OuiOuNonBadge value={isOptymeaAccountActivated} />
      </td>
    </tr>
  );
};
