import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

type Props = {
  updateFormWithScrapData: () => void;
  clearScrappedData: () => void;
};

export const OverwriteReferenceFeedbackMessage: React.FC<Props> = ({
  updateFormWithScrapData,
  clearScrappedData,
}) => (
  <FeedbackMessages
    message="Voulez-vous remplacer les informations actuelles par les nouvelles données récupérées ?"
    isOpen
    title="Mise à jour de la référence"
    validateAction={{
      label: 'Valider',
      onClick: () => {
        updateFormWithScrapData();
        clearScrappedData();
      },
    }}
    cancelAction={{
      label: 'Annuler',
      onClick: clearScrappedData,
    }}
  />
);
