import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

const handleLinkClientAccount = async (payload: {
  projectUuid: string;
  clientAccountUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${payload.projectUuid}/client-account/${payload.clientAccountUuid}/link`;

  await request(requestURL, { method: 'POST' });
};

export const useLinkClientAccount = (): typeof handleLinkClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleLinkClientAccount,
    onSuccess: () => {
      toast.success('Le compte a bien été rattaché.');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
    onError: e => {
      toast.error(`${e}`);
    },
  });

  return mutateAsync;
};

const handleUnlinkClientAccount = async (payload: {
  projectUuid: string;
  clientAccountUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${payload.projectUuid}/client-account/${payload.clientAccountUuid}/unlink`;

  await request(requestURL, { method: 'POST' });
};

export const useUnlinkClientAccount = (): typeof handleUnlinkClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleUnlinkClientAccount,
    onSuccess: () => {
      toast.success('Le compte a bien été retiré.');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
    onError: e => {
      toast.error(`${e}`);
    },
  });

  return mutateAsync;
};
