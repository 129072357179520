import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { Divider } from '@travauxlib/shared/src/components/DesignSystem/components/Divider';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { ClientAccountDropdown } from 'features/Chantiers/components/MainInfo/ClientAccountDropdown';
import { useCreateClientAccount } from 'features/Project/api/useCreateClientAccount';
import {
  useLinkClientAccount,
  useUnlinkClientAccount,
} from 'features/Project/api/useLinkClientAccount';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { ClientAccountFormFields } from 'features/Project/features/ProjectPage/components/ClientAccountFormFields';

import { useOpenClientContactModal } from './useOpenClientContactModal';

type Operation =
  | {
      type: 'replace';
      clientAccount: ClientAccount;
      title: string;
      validateAction: string;
    }
  | {
      type: 'create';
      title: string;
      validateAction: string;
    };

type Props = {
  projectContext: ProjectContext;
  clientAccount?: ClientAccount;
  handleClose: () => void;
};

type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const CreateOrReplaceClientAccountModal: React.FC<Props> = ({
  projectContext,
  clientAccount,
  handleClose,
}) => {
  const [selectedAccount, setSelectedAccount] = useState<ClientAccount | undefined>(undefined);
  const createAccount = useCreateClientAccount();
  const openClientContactModal = useOpenClientContactModal();
  const linkClientAccount = useLinkClientAccount();
  const unlinkClientAccount = useUnlinkClientAccount();

  const operation: Operation = clientAccount
    ? {
        title: 'Remplacer le compte',
        type: 'replace' as const,
        validateAction: 'Remplacer le compte client',
        clientAccount,
      }
    : {
        validateAction: 'Ajouter le compte',
        type: 'create' as const,
        title: 'Ajouter un nouveau compte',
      };

  return (
    <Form<FormProps>
      onSubmit={async values =>
        await createAccount({ ...values, projectUuid: projectContext.project.uuid })
      }
    >
      {({ handleSubmit }) => (
        <ModalContent
          isScrollable
          title={operation.title}
          validateAction={{
            label: operation.validateAction,
            onClick: async () => {
              if (selectedAccount) {
                await linkClientAccount({
                  projectUuid: projectContext.project.uuid,
                  clientAccountUuid: selectedAccount.uuid,
                });
                if (operation.type === 'replace') {
                  await unlinkClientAccount({
                    projectUuid: projectContext.project.uuid,
                    clientAccountUuid: operation.clientAccount.uuid,
                  });
                }
              } else {
                await handleSubmit();
              }
              handleClose();
              openClientContactModal(projectContext.project.uuid);
            },
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: () => {
              handleClose();
              openClientContactModal(projectContext.project.uuid);
            },
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="text-neutral-800 text-b1 mb-md font-medium">
              Rattacher un compte existant
            </div>
            <div className="mb-md">
              <ClientAccountDropdown
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                onFilter={clientAccounts =>
                  clientAccounts.filter(
                    foundAcc =>
                      !projectContext.clientAccounts.map(ca => ca.uuid).includes(foundAcc.uuid),
                  )
                }
              />
            </div>
            <Divider className="!mx-0 mb-md" text="Ou" />
            <div className="text-neutral-800 text-b1 mb-md font-medium">
              Créer un nouveau compte
            </div>
            <ClientAccountFormFields />
          </form>
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenCreateOrReplaceClientAccountModal = (): ((
  projectContext: ProjectContext,
  clientAccount?: ClientAccount,
) => void) => {
  const openModal = useOpenModal();

  return (projectContext, clientAccount) =>
    openModal(CreateOrReplaceClientAccountModal, {
      closable: true,
      projectContext,
      clientAccount,
    });
};
