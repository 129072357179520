import _groupBy from 'lodash/groupBy';
import _sum from 'lodash/sum';

import { lineGenerators } from '@travauxlib/shared/src/features/Configurateur/utils/lineGenerators';
import {
  OuvrageIdentifier,
  ouvragesDesignation,
} from '@travauxlib/shared/src/features/Configurateur/utils/ouvrages';
import { UnitValue } from '@travauxlib/shared/src/types';
import {
  BaseDePrix,
  Configuration,
  QuestionId,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

type OuvrageLigne = {
  designation: string;
  quantity: number;
  unite: UnitValue;
  isOption?: boolean;
};

type Ouvrage = {
  label: string;
  lignes: OuvrageLigne[];
  priority: number;
};

export const getOuvrages = (
  baseDePrix: BaseDePrix,
  questionId: QuestionId,
  configuration: Configuration,
): Ouvrage[] => {
  const generateLines = lineGenerators[questionId];

  return Object.entries(
    _groupBy(
      generateLines(configuration).flatMap(({ id, quantity, ouvrageLabel, isOption }) => {
        const baseDePrixLigne = id && baseDePrix[id];

        if (APP_CONFIG.isTest && !baseDePrixLigne && id) {
          throw new Error(`baseDePrixLigne is undefined for id ${id}`);
        }

        if (!baseDePrixLigne || !id) {
          return [];
        }
        return [
          {
            order: baseDePrixLigne.priority || 0,
            ouvrage: ouvrageLabel,
            designation: baseDePrixLigne.designation,
            quantity,
            unite: baseDePrixLigne.unite,
            isOption,
          },
        ];
      }),
      ligne => ligne.ouvrage,
    ),
  )
    .map(([label, lignes]) => {
      const groupedLines = _groupBy(lignes, l => l.designation);
      const newLignes = Object.values(groupedLines)
        .flatMap(lignes => {
          const quantity = roundToOneDecimal(_sum(lignes.map(l => l.quantity)));

          return {
            ...lignes[0],
            quantity,
          };
        })
        .sort((a, b) => a.order - b.order);
      return {
        label,
        lignes: newLignes,
        priority: ouvragesDesignation.indexOf(label as OuvrageIdentifier),
        isOption: newLignes.some(l => l.isOption),
      };
    })
    .sort((a, b) => a.priority - b.priority);
};
