import Payment from '@travauxlib/shared/src/components/DesignSystem/assets/Payment.svg?react';
import { AdminAccountTransactionView } from '@travauxlib/shared/src/types/api/admin/AdminAccountTransactionView';

import { ChantierAdmin } from 'types';

import { createActionCreators, createBaseHistoryItem } from './baseHistoryItem';

import { FluxFinancierHistoriqueFilterType, HistoryItem } from '../types';

export const getLabelTransaction = (transaction: AdminAccountTransactionView): string => {
  if (transaction.transactionType === 'encaissement_tp') {
    return 'Encaissement Travaux Planner';
  }
  if (transaction.transactionType === 'encaissement_service_hemea') {
    return 'Encaissement Service hemea';
  }
  if (transaction.transactionType === 'encaissement_archi_interne') {
    return 'Encaissement Archi Interne';
  }
  return `Paiement vers ${transaction.creditedAccountName}`;
};

export const paiementToHistoryItem =
  (
    handleOpenModalIgnoreTransaction: (
      transaction: AdminAccountTransactionView,
      chantier: ChantierAdmin,
    ) => void,
    handleOpenModalChangeTransactionVisibility: (
      transaction: AdminAccountTransactionView,
      chantier: ChantierAdmin,
    ) => void,
    chantier: ChantierAdmin,
    isAllowedToManageTransactions: boolean,
  ) =>
  (transaction: AdminAccountTransactionView): HistoryItem =>
    createBaseHistoryItem(
      transaction,
      getLabelTransaction(transaction),
      FluxFinancierHistoriqueFilterType.Paiement,
      Payment,
      'success',
      createActionCreators(
        handleOpenModalIgnoreTransaction,
        handleOpenModalChangeTransactionVisibility,
        chantier,
        isAllowedToManageTransactions,
      ),
    );
