import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

export const getPrixTotalFromLots = (
  lots: Pick<ProDevisFactureLotView, 'items'>[],
  TTC?: boolean,
): number => {
  const price = lots
    .map(lot =>
      lot.items.reduce((previousAmount, item) => {
        if (item.type === 'ligne' && item.status !== 'option' && item.status !== 'free') {
          return previousAmount + (TTC ? item.prixTTC : item.prixHT);
        }

        return previousAmount;
      }, 0),
    )
    .reduce((prev, curr) => prev + curr, 0);

  return (price * 100) / 100;
};
