export enum TypeBien {
  Appartement = 'appartement',
  Maison = 'maison',
  MaisonBriques = 'maison_briques',
  MaisonBois = 'maison_bois',
  MaisonContainer = 'maison_container',
  Immeuble = 'immeuble',
  BienProfessionel = 'bien_professionnel',
  Bureaux = 'bureaux',
  RestauBar = 'restau_bar',
  Hotel = 'hotel',
  SalonCoiffure = 'salon_coiffure',
  CabinetMedical = 'cabinet_medical',
  Autre = 'other',
}
