import _groupBy from 'lodash/groupBy';
import _mergeWith from 'lodash/mergeWith';
import _sum from 'lodash/sum';
import { v4 as uuidV4 } from 'uuid';

import { globalLineGenerator } from '@travauxlib/shared/src/features/Configurateur/utils/lineGenerators/global';
import { BaseDePrixLigne, Fourniture } from '@travauxlib/shared/src/types';
import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

type ResultingLigne = Omit<BaseDePrixLigne, 'fournitures'> & {
  quantite: number;
  locations: Array<{ uuid: string; quantite: number }>;
  ouvrageLabel?: string;
  gamme: Gamme;
  isOption?: boolean;
  fournitures: Fourniture[];
  hasFournitures: boolean;
};

const getFournitures = (baseDePrixLigne: BaseDePrixLigne): Fourniture[] => {
  if (!!baseDePrixLigne?.hemeaRecommandations?.length) {
    return baseDePrixLigne.hemeaRecommandations.map(hemeaReco => {
      const matchingResolvedFourniture = baseDePrixLigne.resolvedFournitures?.find(
        ([resolvedFourniture]) =>
          resolvedFourniture.normalizedIdentifier === hemeaReco.fournitureIdentifier,
      );
      const fournitureName = matchingResolvedFourniture
        ? matchingResolvedFourniture[0].designation
        : undefined;

      return {
        uuid: uuidV4(),
        designation: fournitureName ?? hemeaReco.designation,
        reference: {
          uuid: uuidV4(),
          designation: hemeaReco.designation,
          description: hemeaReco.description,
          referenceLink: hemeaReco.url,
          fournisseur: hemeaReco.hemeaBrand,
          referenceNumber: hemeaReco.fabricantRef,
          images: [],
          isRecommandationHemea: true,
          recommandationImagesUrls: hemeaReco.images,
          recommandationUuid: uuidV4(),
        },
      };
    });
  }

  if (!!baseDePrixLigne.resolvedFournitures?.length) {
    return baseDePrixLigne.resolvedFournitures?.map<Fourniture>(([resolvedFourniture]) => ({
      uuid: uuidV4(),
      normalizedIdentifier: resolvedFourniture.normalizedIdentifier,
      designation: resolvedFourniture.designation,
    }));
  }

  return [];
};

export const getAllGeneratedLines = (
  baseDePrix: BaseDePrix,
  configuration: Configuration,
): ResultingLigne[] => {
  const locationsUuid = configuration.locations.map(l => l.uuid);
  const allGenerationData = globalLineGenerator(baseDePrix, configuration);

  const baseDePrixLignes = allGenerationData.flatMap(
    ({ id, quantity, locationQuantities, ouvrageLabel, isOption, gamme }) => {
      const baseDePrixLigne = id && baseDePrix[id];
      if (baseDePrixLigne && quantity) {
        return {
          baseDePrixLigne,
          quantity,
          locationQuantities,
          isOption,
          ouvrageLabel,
          gamme,
        };
      }
      return [];
    },
  );
  const linesGroupedById = _groupBy(baseDePrixLignes, ligne => [
    ligne.ouvrageLabel,
    ligne.baseDePrixLigne.normalizedIdentifier,
    ligne.isOption,
  ]);

  return Object.entries(linesGroupedById).map(([, lines]) => {
    const quantitiesMerged: {
      [K: string]: number;
    } = _mergeWith(
      {},
      ...lines.map(line => line.locationQuantities),
      (q1: number = 0, q2: number = 0) => q1 + q2,
    );
    const locations = Object.entries(quantitiesMerged)
      .map(([uuid, quantite]) => ({
        uuid,
        quantite: roundToOneDecimal(quantite),
      }))
      .filter(location => locationsUuid.includes(location.uuid));

    const baseDePrixLigne = lines[0].baseDePrixLigne;
    const fournitures = getFournitures(baseDePrixLigne);

    return {
      quantite: roundToOneDecimal(_sum(lines.map(line => line.quantity || 0))),
      locations,
      ...baseDePrixLigne,
      isOption: lines[0].isOption,
      gamme: lines[0].gamme,
      ouvrageLabel: lines[0].ouvrageLabel,
      hasFournitures: fournitures.length > 0,
      fournitures,
    };
  });
};
