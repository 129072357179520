import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { getTotalPriceConfiguration } from './getTotalPriceConfiguration';

export const getConfigurationPrixHT = (
  baseDePrix: BaseDePrix,
  configuration: Configuration,
): number =>
  getTotalPriceConfiguration({
    baseDePrix,
    configuration,
    withTVA: false,
  });
