import React from 'react';

import { Form } from 'react-final-form';
import { Link } from 'react-router';

import LeftChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { Person } from '@travauxlib/shared/src/types';
import { personRoleOptions } from '@travauxlib/shared/src/utils/constants';
import { required, useCanSubmitForm } from '@travauxlib/shared/src/utils/form';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

import { usePerson, usePersons } from 'features/Trombinoscope/api/usePersons';

import { EditCDPL } from './EditCDPL';
import { PictureComponent } from './Picture';

type Props = {
  title: string;
  personUuid?: string;
};

type FormProps = Person;

const DisplayError: React.FC<{ error: any }> = ({ error }) => {
  const existingPersonUuid = error?.response?.data?.existingPersonUuid;
  if (existingPersonUuid) {
    return (
      <div className="w-1/2 mx-auto">
        <InlineAlert variant="error">
          <Link to={`../${existingPersonUuid}/edit`}>
            Un utilisateur existe déjà avec cette adresse email
          </Link>
        </InlineAlert>
      </div>
    );
  }
  return null;
};

const SubmitButton: React.FC = () => {
  const canSubmit = useCanSubmitForm();
  return (
    <Button type="submit" size="sm" disabled={!canSubmit}>
      Sauvegarder
    </Button>
  );
};

export const EditTrombinoscope: React.FC<Props> = ({ personUuid, title }) => {
  const { isLoading, person } = usePerson(personUuid);
  const { persons, uploadPicture, createOrUpdatePerson } = usePersons();

  if (isLoading) {
    return <Loader />;
  }

  const isCDPL = personUuid && person?.role === 'cdpl';

  return (
    <Form<FormProps>
      initialValues={person}
      initialValuesEqual={() => true}
      onSubmit={async values => {
        try {
          await createOrUpdatePerson(values);
        } catch (err) {
          return err;
        }
      }}
    >
      {({ handleSubmit, submitErrors, values: { cdplInformation } }) => (
        <div className="container p-xl">
          <div className="flex justify-between items-center mb-md">
            <h1>{title}</h1>
            <ButtonLink
              className="mb-md py-0"
              variant="tertiary"
              to="/trombinoscope/edit"
              leftIcon={<LeftChevron />}
            >
              Retour
            </ButtonLink>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-md mb-md">
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField
                  label="Prénom *"
                  id="firstname-input"
                  name="firstName"
                  validate={required}
                />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField label="Nom *" id="lastname-input" name="lastName" validate={required} />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField label="Email *" id="email-input" name="email" validate={required} />
              </div>
            </div>
            <div className="flex flex-wrap -mx-md">
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField label="Surnom" id="nickname-input" name="nickname" />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField label="Url calendly" id="calendly-input" name="calendlyUrl" />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <CheckboxField label="Archivé" name="archived" />
              </div>
            </div>
            <h2>Images</h2>
            <div className="flex flex-wrap -mx-md">
              <div className="w-full sm-desktop:w-6/12 md-desktop:w-4/12 my-xs px-md relative">
                <h3>Officiel</h3>
                <PictureComponent
                  personUuid={person?.uuid}
                  imageUrl={person?.officialPicture}
                  alt="Official"
                  onUpload={uploadPicture}
                  isOfficial
                />
              </div>
              <div className="w-full sm-desktop:w-6/12 md-desktop:w-4/12 my-xs px-md relative">
                <h3>Bonus</h3>
                <PictureComponent
                  alt="Bonus"
                  personUuid={person?.uuid}
                  imageUrl={person?.bonusPicture}
                  onUpload={uploadPicture}
                />
              </div>
            </div>
            <h2>Téléphones</h2>
            <div className="flex flex-wrap -mx-md">
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField
                  label="Tel. mobile pro"
                  id="mobilePhoneNumber"
                  name="mobilePhoneNumber"
                />
              </div>
            </div>
            <h2>Job</h2>
            <div className="flex flex-wrap -mx-md">
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <InputField
                  className="mb-md"
                  label="Titre du job *"
                  id="job-title-input"
                  name="jobTitle"
                  validate={required}
                />
              </div>
              <div className="w-full sm-desktop:w-6/12 md-desktop:w-4/12 my-xs px-md relative">
                <TextareaField
                  rows={4}
                  label="Description du job"
                  id="job-description-input"
                  name="jobDescription"
                />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <DropdownField
                  label="Manager"
                  id="manager-input"
                  name="managerUuid"
                  options={persons.map(p => ({
                    label: `${p.firstName} ${p.lastName}`,
                    value: p.uuid,
                  }))}
                />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <DropdownField
                  label="Rôle"
                  id="role-input"
                  name="role"
                  options={personRoleOptions}
                />
              </div>
            </div>
            <h2>Dates</h2>
            <div className="flex flex-wrap -mx-md !relative">
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <DatePickerField
                  parse={parseDateInput}
                  label="Anniversaire"
                  id="birthDate"
                  name="birthDate"
                />
              </div>
              <div className="w-full sm-desktop:w-4/12 md-desktop:w-4/12 my-xs px-md relative">
                <DatePickerField
                  parse={parseDateInput}
                  label="Date d'arrivée chez hemea *"
                  id="arrivalDate"
                  name="arrivalDate"
                  validate={required}
                />
              </div>
            </div>
            {isCDPL && <EditCDPL siret={cdplInformation?.siret} personUuid={personUuid} />}
            <div className="text-center mt-xxl">
              <DisplayError error={submitErrors} />
              <SubmitButton />
            </div>
          </form>
        </div>
      )}
    </Form>
  );
};
