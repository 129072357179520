import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Form } from 'react-final-form';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import {
  Modal,
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { AppelDePaiementView } from '@travauxlib/shared/src/types/api/admin/AppelDePaiementView';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { useUpdateMontantAppelDePaiement } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useUpdateMontantAppelDePaiement';
import { ChantierAdmin } from 'types';

import { CHANTIER_KEY } from '../api/useUpdateChantier';

type OwnProps = {
  appelDePaiement: AppelDePaiementView;
  chantier: ChantierAdmin;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

export const ModifyAppelDePaiementModal: React.FC<Props> = ({
  appelDePaiement,
  chantier,
  handleClose,
}) => {
  const { updateMontantAppelDePaiement, isLoading: updateAppelPaiementLoading } =
    useUpdateMontantAppelDePaiement();
  const queryClient = useQueryClient();

  return (
    <Modal title="Modifier le montant de l'appel de paiement" isOpen handleClose={handleClose}>
      <Form
        initialValues={{ montant: appelDePaiement.montant }}
        onSubmit={async (values: { montant: number }) => {
          await updateMontantAppelDePaiement({
            chantier,
            appelDePaiementUuid: appelDePaiement.uuid,
            montant: values.montant,
            runSchedulerAdp: chantier.soldeCompteSequestre >= values.montant,
          });
          handleClose();
          queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY, chantier.slug] });
        }}
      >
        {({ handleSubmit, submitting, invalid, values }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            cancelAction={{ label: 'Annuler', onClick: handleClose }}
            validateAction={{
              label: 'Confirmer',
              type: 'submit',
              disabled: submitting || invalid,
              loading: updateAppelPaiementLoading || submitting,
            }}
          >
            {chantier.soldeCompteSequestre < values.montant && (
              <AlertMessage level="warning" className="mb-md">
                Le montant de l'appel de paiement est supérieur au solde disponible sur le compte
                séquestre. L'appel de paiement sera envoyé au client qu'une fois les fonds
                correspondants provisionnés.
              </AlertMessage>
            )}
            <div className="mb-md">
              Montant initial :{' '}
              <EURCurrency
                className="font-bold"
                amount={roundToTwoDecimals(appelDePaiement.montant)}
                forceFullPattern
              />
            </div>
            <NumberInputField
              name="montant"
              id="montant"
              label="Nouveau montant"
              placeholder="Exemple: 500.23"
              required
            />
            {!chantier.enableSuiviRenforce && (
              <AlertMessage level="info" className="mt-md">
                Si vous souhaitez adapter le montant de commission prélevé sur cet appel de
                paiement, il faut activer le suivi financier renforcé sur ce chantier.
              </AlertMessage>
            )}
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};

export const useModifyAppelDePaiementModal = (): ((
  appelDePaiement: AppelDePaiementView,
  chantier: ChantierAdmin,
) => void) => {
  const openModal = useOpenModal();

  const open = async (
    appelDePaiement: AppelDePaiementView,
    chantier: ChantierAdmin,
  ): Promise<void> => {
    openModal(ModifyAppelDePaiementModal, {
      appelDePaiement,
      chantier,
    });
  };

  return open;
};
