import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { FileDropzoneField } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/Field';
import {
  DownloadableFile,
  InsurancePayload,
  UploadFilesPayload,
} from '@travauxlib/shared/src/types';
import { ProInsuranceView } from '@travauxlib/shared/src/types/api/common/ProInsuranceView';
import { required } from '@travauxlib/shared/src/utils/form';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

import { InsuranceFormType } from '../utils/constants';
import { validateDateFin } from '../utils/validations';

type FormData = Omit<ProInsuranceView, 'id'> & {
  file?: DownloadableFile[];
};

type Props = {
  insuranceType: InsuranceFormType;
  initialValues?: FormData;
  uploadInsurance: (insurance: InsurancePayload) => Promise<void>;
  uploadFiles: ({
    files,
    onUpdate,
    onSuccess,
    onError,
  }: UploadFilesPayload) => Promise<DownloadableFile[]>;
  fixedFloating?: boolean;
  slug?: string;
};

export const InsuranceForm: React.FC<Props> = ({
  insuranceType,
  initialValues,
  uploadInsurance,
  uploadFiles,
  slug,
  fixedFloating,
}) => {
  const label = Object.values(InsuranceFormType).find(
    insuranceFormType => insuranceFormType === insuranceType,
  );

  return (
    <Card
      className="mb-md border-1 border-neutral-300 p-xs w-full overflow-visible"
      data-testid={insuranceType}
    >
      <div className="font-bold mb-md">Assurance {label}</div>
      <Form<FormData>
        initialValues={
          initialValues || {
            label,
          }
        }
        validate={validateDateFin}
        onSubmit={async (values, form) => {
          await uploadInsurance({ ...values, file: values?.file?.[0]!, slug });
          window.setTimeout(() => {
            form.restart();
          });
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-md">
              <div className="flex flex-col md-desktop:flex-row gap-md">
                <DatePickerField
                  data-testid="debut-validite"
                  name="dateDebutValidite"
                  parse={parseDateInput}
                  id={`dateDebutValidite-${insuranceType}`}
                  label="Début de validité"
                  validate={required}
                  containerClassName="w-full"
                  fixedFloating={fixedFloating}
                />
                <DatePickerField
                  data-testid="fin-validite"
                  name="dateFinValidite"
                  parse={parseDateInput}
                  label="Fin de validité"
                  id={`dateFinValidite-${insuranceType}`}
                  validate={required}
                  containerClassName="w-full"
                  fixedFloating={fixedFloating}
                />
              </div>
              <FileDropzoneField name="file" uploadFiles={uploadFiles} validate={required} />
              <div className="mt-md">
                <Button
                  fullwidth
                  data-testid="upload"
                  type="submit"
                  className="mb-auto"
                  loading={submitting}
                  disabled={submitting || !values.file}
                >
                  Valider
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};
