import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Cloison } from '@travauxlib/shared/src/types/api/domain/configurateur/Platrerie';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationsDimensions } from 'features/Configurateur/components/LocationsDimensions';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const Cloisons: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Cloison à monter ?" prefix="1" defaultExpanded>
    <FieldArray<Cloison> name="cloisons">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];

            const locationsSelected = configuration.locations.filter(l =>
              value.locationsUuid?.includes(l.uuid),
            );
            return (
              <div key={name}>
                <MultiLocationsPicker
                  prefix="1"
                  configuration={configuration}
                  name={`${name}.locationsUuid`}
                  onDelete={() => fields.remove(index)}
                  optionCheckbox={<OptionCheckbox fields={fields} value={value} index={index} />}
                  tooltip={
                    <div>
                      Types de cloison et d'isolants selon les gammes :
                      <br />
                      - Primo & Confort : Cloison placo standard et laine minérale 45mm
                      <br />- Premium & Exclusif : Cloisons phoniques et fibre de bois 60 mm
                    </div>
                  }
                />
                {locationsSelected.length > 0 && <QuestionTitle title="Dimensions ?" prefix="2" />}
                <>
                  <LocationsDimensions
                    configuration={configuration}
                    locations={locationsSelected}
                    getFields={locationUuid => [
                      {
                        name: `${name}.locationQuantities.${locationUuid}.longueur`,
                      },
                      {
                        defaultValue: configuration.hauteurSousPlafond,
                        name: `${name}.locationQuantities.${locationUuid}.hauteur`,
                      },
                    ]}
                  />
                </>
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
