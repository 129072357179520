import Bank from '@travauxlib/shared/src/components/DesignSystem/assets/BankFilled.svg?react';
import { AdminAccountTransactionView } from '@travauxlib/shared/src/types/api/admin/AdminAccountTransactionView';

import { ChantierAdmin } from 'types';

import { createActionCreators, createBaseHistoryItem } from './baseHistoryItem';

import { FluxFinancierHistoriqueFilterType, HistoryItem } from '../types';

export const provisionToHistoryItem =
  (
    handleOpenModalIgnoreTransaction: (
      transaction: AdminAccountTransactionView,
      chantier: ChantierAdmin,
    ) => void,
    handleOpenModalChangeTransactionVisibility: (
      transaction: AdminAccountTransactionView,
      chantier: ChantierAdmin,
    ) => void,
    chantier: ChantierAdmin,
    isAllowedToManageTransactions: boolean,
  ) =>
  (transaction: AdminAccountTransactionView): HistoryItem =>
    createBaseHistoryItem(
      transaction,
      'Provision du compte',
      FluxFinancierHistoriqueFilterType.Provision,
      Bank,
      'info',
      createActionCreators(
        handleOpenModalIgnoreTransaction,
        handleOpenModalChangeTransactionVisibility,
        chantier,
        isAllowedToManageTransactions,
      ),
    );
