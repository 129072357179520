import React, { useEffect } from 'react';

import { useLocation } from 'react-router';

export const ScrollToHashElement: React.FC = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const hashElement = document.getElementById(hash.slice(1));

        hashElement?.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
        });
      }, 0);
    }
  }, [hash]);

  return null;
};
