import _groupBy from 'lodash/groupBy';
import _sum from 'lodash/sum';
import _uniq from 'lodash/uniq';
import { v4 as uuidV4 } from 'uuid';

import { getAllGeneratedLines } from '@travauxlib/shared/src/features/Configurateur/utils/getAllGeneratedLines';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { Devis, Ouvrage } from '@travauxlib/shared/src/types';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';
import { ConfigurationView } from '@travauxlib/shared/src/types/api/common/ConfigurationView';
import {
  ProDevisFactureLotView,
  ProDevisFactureLigneView,
} from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import {
  estimateSurfaceMurs,
  perimetreWithSurfaceQuantityComputer,
} from '@travauxlib/shared/src/utils/quantityComputers';

import { DEFAULT_MARGIN, predefinedLots } from './devisConfig';
import {
  getPriceByFournitureWithCorrectGamme,
  getPriceWithCorrectGammeFourniture,
} from './getPriceWithCorrectGammeFourniture';

import { OuvrageIdentifier, ouvragesDesignation } from '../features/Configurateur/utils/ouvrages';

const generateDevisItems = (
  lignes: ReturnType<typeof getAllGeneratedLines>,
  lotUuid: string,
): (ProDevisFactureLigneView & { ouvrageLabel?: string; isOption?: boolean })[] =>
  lignes
    .sort((ligneA, ligneB) => {
      const priorityA = ligneA.priority || 0;
      const priorityB = ligneB.priority || 0;
      return priorityA - priorityB;
    })
    .map(ligne => {
      const { isOption, gamme, ...restLigne } = ligne;
      const prixAchat = roundToTwoDecimals(ligne.prixUnitaireHT / (1 + DEFAULT_MARGIN / 100));

      const prixWithCorrectFourniture = getPriceWithCorrectGammeFourniture(prixAchat, ligne, gamme);
      const prixUnitaireHT = roundToTwoDecimals(
        prixWithCorrectFourniture * (1 + DEFAULT_MARGIN / 100),
      );
      const prixHT = roundToTwoDecimals(prixUnitaireHT * ligne.quantite);

      const prixPublicFournitureHT = getPriceByFournitureWithCorrectGamme(ligne, gamme);

      return {
        ...restLigne,
        prixAchat,
        prixPublicFournitureHT,
        marge: DEFAULT_MARGIN,
        prixUnitaireHT,
        lotUuid,
        prixHT,
        prixTTC: roundToTwoDecimals(prixHT * (1 + ligne.tauxTVA / 100)),
        type: 'ligne',
        montantTVA: ligne.tauxTVA,
        uuid: uuidV4(),
        ...(isOption && {
          status: 'option',
        }),
        fournitureLignes: [],
      };
    }) as ProDevisFactureLigneView[];

const getDevisLotsAndOuvrages = (
  baseDePrix: BaseDePrix,
  configuration: Configuration,
): [ProDevisFactureLotView[], Omit<Ouvrage, 'uuid'>[]] => {
  if (!configuration.answers) {
    return [[], []];
  }
  const allGeneratedLines = getAllGeneratedLines(baseDePrix, configuration);

  const lignesByLots = _groupBy(allGeneratedLines, ligne => ligne.lot);
  const ouvragesMap: { [K: string]: string[] } = {};
  const lots = Object.entries(lignesByLots)
    .map(([lot, lignes]) => {
      const lotUuid = uuidV4();
      const items = generateDevisItems(lignes, lotUuid);
      items.forEach(({ ouvrageLabel, uuid }) => {
        if (ouvrageLabel) {
          if (ouvragesMap[ouvrageLabel]) {
            ouvragesMap[ouvrageLabel].push(uuid);
          } else {
            ouvragesMap[ouvrageLabel] = [uuid];
          }
        }
      });
      return {
        label: lot,
        uuid: lotUuid,
        items,
        prixTotalHT: roundToTwoDecimals(
          _sum(items.filter(item => !item.status).map(item => item.prixHT)),
        ),
        prixTotalTTC: roundToTwoDecimals(
          _sum(items.filter(item => !item.status).map(item => item.prixTTC)),
        ),
      };
    })
    .sort((lotA, lotB) => predefinedLots.indexOf(lotA.label) - predefinedLots.indexOf(lotB.label));

  const allOuvrageLines = lots.flatMap(lot => lot.items).filter(l => l.ouvrageLabel);
  const ouvrages = Object.entries(_groupBy(allOuvrageLines, a => a.ouvrageLabel))
    .flatMap(([ouvrageLabel, lignes]) => {
      const ligneWithoutLocations = lignes.filter(l => l.locations.length === 0);

      const allLocationsUuid = _uniq(lignes.flatMap(l => l.locations.map(loc => loc.uuid)));

      const ouvrageWithLocations = allLocationsUuid.map(uuid => ({
        localisation: uuid,
        designation: ouvrageLabel,
        priority: ouvragesDesignation.indexOf(ouvrageLabel as OuvrageIdentifier),
        devisItems: lignes.filter(l => l.locations.some(loc => loc.uuid === uuid)).map(l => l.uuid),
      }));

      if (ligneWithoutLocations.length) {
        return [
          ...ouvrageWithLocations,
          {
            designation: ouvrageLabel,
            priority: ouvragesDesignation.indexOf(ouvrageLabel as OuvrageIdentifier),
            devisItems: ligneWithoutLocations.map(l => l.uuid),
          },
        ];
      } else {
        return ouvrageWithLocations;
      }
    })
    .sort((ouvrageA, ouvrageB) => ouvrageA.priority - ouvrageB.priority)
    .map((ouvrage, index) => ({ ...ouvrage, order: index }));

  return [lots, ouvrages as Ouvrage[]];
};

const getDevisLocations = (typologieProject: ProjectView | ConfigurationView): DevisLocation[] => {
  if (!typologieProject.locations || typologieProject.hauteurSousPlafond === undefined) {
    return [];
  }
  return typologieProject.locations.map(location => {
    const locationToDevisLocation: DevisLocation = {
      ...location,
      label: getLocationLabel(typologieProject, location),
      hauteurSousPlafond: typologieProject.hauteurSousPlafond!,
      longueurMurs: perimetreWithSurfaceQuantityComputer(location.surface),
      surfaceMurs: estimateSurfaceMurs(location, typologieProject.hauteurSousPlafond!),
    } as DevisLocation;
    return locationToDevisLocation;
  });
};

export const getDevisFromProject = ({
  typologieProject,
  configurationData,
}: {
  typologieProject: ProjectView | ConfigurationView;
  configurationData?: {
    baseDePrix: BaseDePrix;
    configuration: Configuration;
  };
}): Partial<Devis> => {
  const locations = getDevisLocations(typologieProject);

  const [devisLots, ouvrages] = configurationData
    ? getDevisLotsAndOuvrages(configurationData.baseDePrix, configurationData.configuration)
    : [[], []];
  const prixTotalHT = roundToTwoDecimals(_sum(devisLots.map(lot => lot.prixTotalHT)));

  const montantsTVA = Object.values(
    _groupBy(
      devisLots.flatMap(
        lot => lot.items.filter(t => t.type === 'ligne' && !t.status) as ProDevisFactureLigneView[],
      ),
      item => item.tauxTVA,
    ),
  ).map(lignes =>
    roundToTwoDecimals((_sum(lignes.map(ligne => ligne.prixHT)) * lignes[0].tauxTVA) / 100),
  );

  const prixTotalTTC = roundToTwoDecimals(prixTotalHT + _sum(montantsTVA));

  const devis: Partial<Devis> = {
    title: 'Devis hemea',
    lots: devisLots,
    locations,
    defaultTauxTVA: 10,
    isFromConfiguration: !!configurationData,
    isPreChiffrage: true,
    defaultMargin: DEFAULT_MARGIN,
    prixTotalHT,
    prixTotalTTC,
    ouvrages: ouvrages as Ouvrage[],
  };
  return devis;
};
