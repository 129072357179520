import React from 'react';

import classNames from 'classnames';

import { ModalBody } from './ModalBody';
import { useModalContext } from './ModalContext';
import { ModalFooter } from './ModalFooter';
import { ActionConfig } from './types';
import { modalWidthBySize } from './utils';

import CrossSymbol from '../../assets/CrossSymbolFilled.svg?react';
import { IconButton } from '../Buttons/IconButton';

export type Props = {
  id?: string;
  className?: string;
  title?: React.ReactElement | string;
  handleSubmit?: (values: any) => void;
  validateAction?: ActionConfig;
  cancelAction?: ActionConfig;
  centered?: boolean;
  children?: React.ReactNode;
  titleClassName?: string;
  isScrollable?: boolean;
};

export const ModalContent: React.FC<Props> = ({
  handleSubmit,
  title: titleProp,
  validateAction: validateActionProp,
  cancelAction: cancelActionProp,
  children,
  id,
  className,
  centered,
  titleClassName,
  isScrollable,
}) => {
  const {
    isBelowDesktopSM,
    isTopShadowVisible,
    handleClose,
    title: titleContext,
    contentRef,
    isScrollable: isScrollableContext,
    isBottomShadowVisible,
    validateAction: validateActionContext,
    cancelAction: cancelActionContext,
    size,
    modalMaxHeight,
  } = useModalContext();

  const validateAction = validateActionProp || validateActionContext;
  const cancelAction = cancelActionProp || cancelActionContext;
  const isScrollableOrDefault = isScrollable ?? isScrollableContext;
  const title = titleProp || titleContext;

  const modalContent: React.ReactElement = (
    <>
      <div
        className={classNames(
          'text-h5 font-bold not-italic text-neutral-800',
          'flex items-center',
          'px-md tablet:px-lg pb-md',
          `${!!title ? 'pt-lg justify-between' : 'pt-sm justify-end'}`,
          {
            'shadow-xl': isTopShadowVisible,
            'justify-center': centered,
          },
          titleClassName,
        )}
      >
        <div className={classNames({ 'ml-auto': centered })}>{title}</div>
        {handleClose && (
          <IconButton
            className={classNames(centered ? 'ml-auto' : 'ml-xs', 'shrink-0 self-start -mr-xs')}
            onClick={handleClose}
            type="button"
          >
            <CrossSymbol data-testid="close-icon" />
          </IconButton>
        )}
      </div>
      <ModalBody contentRef={contentRef} isScrollable={isScrollableOrDefault}>
        {children}
      </ModalBody>
      <ModalFooter
        centered={centered}
        isBelowDesktopSM={isBelowDesktopSM}
        isBottomShadowVisible={isBottomShadowVisible}
        validateAction={validateAction}
        cancelAction={cancelAction}
      />
    </>
  );

  return React.createElement(handleSubmit ? 'form' : 'div', {
    ...(handleSubmit ? { onSubmit: handleSubmit } : {}),
    id,
    className: classNames(
      'flex flex-col col-span-full max-w-full',
      'bg-neutral-0',
      modalWidthBySize[size],
      isBelowDesktopSM ? 'rounded-t-xs' : 'rounded-xs',
      className,
    ),
    style: { maxHeight: modalMaxHeight },
    children: modalContent,
  });
};
