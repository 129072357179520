import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { GenerationData } from '@travauxlib/shared/src/types/api/domain/configurateur';

export const poseDouche = ({
  locationUuid,
  hasRaccordsPlomberie,
  isOption,
  gamme,
}: {
  locationUuid: string;
  hasRaccordsPlomberie: boolean;
  isOption?: boolean;
  gamme: Gamme;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  if (gamme === Gamme.confort || gamme === Gamme.primo) {
    result.push({
      ouvrageLabel: 'Douche',
      tagQuantity: 'only',
      id: 'receveur_douche_resine',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
    result.push({
      ouvrageLabel: 'Douche',
      id: 'receveur_douche_a_carreler',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
    result.push({
      ouvrageLabel: 'Douche',
      tagQuantity: 'skip',
      id: 'carrelage_receveur_de_douche',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
    result.push({
      ouvrageLabel: 'Douche',
      tagQuantity: 'skip',
      id: 'carrelage_niche_douche_bain_decoupes_45',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  result.push({
    ouvrageLabel: 'Douche',
    id: 'paroi_de_douche',
    tagQuantity: 'skip',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Douche',
    id: 'nattes_etancheite',
    tagQuantity: 'skip',
    isOption,
    quantity: 2,
    locationQuantities: {
      [locationUuid]: 2,
    },
  });

  if (gamme === Gamme.primo) {
    result.push({
      ouvrageLabel: 'Douche',
      id: 'barre_douche_mitigeur',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  if (gamme === Gamme.confort) {
    result.push({
      ouvrageLabel: 'Douche',
      id: 'colonne_douche',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }
  if (gamme === Gamme.exclusif || gamme === Gamme.premium) {
    result.push({
      ouvrageLabel: 'Douche',
      id: 'barre_douche_mitigeur_encastre',
      isOption,
      tagQuantity: 'skip',
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  if (hasRaccordsPlomberie) {
    result.push({
      ouvrageLabel: 'Douche',
      tagQuantity: 'skip',
      id: 'ec_ef_evacuation_element_cuivre',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }
  return result;
};
