import React from 'react';

import { Placement } from '@floating-ui/react';
import classNames, { Argument } from 'classnames';

import { GenericLink, GenericLinkProps, LinkSize } from './commons';

import { ActionDisabledWithTooltip } from '../Buttons/ActionDisabledWithTooltip';

const linkClassnamesByVariant: { [key in LinkVariant]: string[] } = {
  primary: ['text-primary-400', 'hover:text-primary-500', 'active:text-primary-600'],
  secondary: ['text-neutral-800', 'hover:text-neutral-800', 'active:text-primary-900'],
  darkmode: ['text-neutral-0', 'hover:text-neutral-100', 'active:text:neutral-100'],
};

const linkClassnamesBySize: { [key in LinkSize]: string } = {
  lg: 'text-b1',
  md: 'text-b2',
  sm: 'text-sm',
  xs: 'text-xs',
};
type LinkVariant = 'primary' | 'secondary' | 'darkmode';

export const makeLinkStyles = (
  variant: LinkVariant,
  size: LinkSize,
  disabled?: boolean,
  inline?: boolean,
): Argument[] => [
  ...linkClassnamesByVariant[variant],
  linkClassnamesBySize[size],
  'cursor-pointer inline-flex items-center font-medium',
  'hover:underline',
  'active:outline-0',
  'focus:outline-green-400 focus:outline focus:outline-2',
  { '!text-neutral-300 pointer-events-none': disabled },
  { underline: inline },
];

export const LinkIconContainer: React.FC<{ icon: React.ReactElement }> = ({ icon }) => (
  <span className="h-md w-md ml-xxs inline-flex justify-center">{icon}</span>
);

export type CustomLinkProps = {
  inline?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
  variant?: LinkVariant;
  size?: LinkSize;
  disabledMessageTooltip?: string;
  disabledTriggerClassName?: string;
  positionTooltip?: Placement;
};

type Props = CustomLinkProps & GenericLinkProps;

export const Link: React.FC<Props> = ({
  children,
  variant = 'primary',
  inline,
  disabled = false,
  disabledMessageTooltip,
  disabledTriggerClassName,
  positionTooltip,
  className,
  icon,
  size = 'md',
  ...rest
}) => {
  const linkClassName: string = classNames(
    className,
    ...makeLinkStyles(variant, size, disabled, inline),
  );
  const maybeIcon = icon && <LinkIconContainer icon={icon} />;

  return (
    <ActionDisabledWithTooltip
      disabled={disabled}
      disabledMessage={disabledMessageTooltip}
      position={positionTooltip}
      disabledTriggerClassName={disabledTriggerClassName}
    >
      <GenericLink classNames={linkClassName} disabled={disabled} {...rest}>
        {children}
        {maybeIcon}
      </GenericLink>
    </ActionDisabledWithTooltip>
  );
};
