import React from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';

import AlertCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AlertCircle.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import {
  ColoredCircle,
  IconVariants,
} from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { AlertVariant, BrandVariant } from '@travauxlib/shared/src/types/designSystem';
import {
  formatFrenchDate,
  formatFullFrenchDateTime,
  toCalendarFormat,
} from '@travauxlib/shared/src/utils/time';

import { borderColorByVariant } from './constants';

import { Button } from '../Buttons/Button';

type Props = {
  icon: React.ReactNode;
  title: React.ReactNode;
  className?: string;
  dueDate?: string;
  children: React.ReactNode;
  variant: AlertVariant | BrandVariant | 'beige';
  action?: {
    onClick: () => void;
    label: string;
    disabled?: boolean;
    testId?: string;
    hideIcon?: boolean;
  };
  iconVariant?: IconVariants;
  hideTitleDate?: boolean;
  fullTimeFormat?: boolean;
};

export const EventCard: React.FC<Props> = ({
  icon,
  title,
  className,
  dueDate,
  children,
  variant,
  iconVariant,
  action,
  hideTitleDate,
  fullTimeFormat,
}) => {
  const parsedDueDate = dayjs(dueDate);
  const isDueDateTodayOrInPast = parsedDueDate.startOf('day') <= dayjs().startOf('day');

  return (
    <Card
      className={classNames('border-t-8', borderColorByVariant[variant], className ?? '')}
      bodyClassNames="flex flex-col justify-between gap-sm h-full pt-xs"
    >
      <>
        <div className="flex">
          <ColoredCircle className="self-start" variant={iconVariant || variant}>
            {icon}
          </ColoredCircle>
          <div className="text-b2 grow ml-xs">
            <div className="flex items-center justify-between">
              <div className="font-bold">{title}</div>
              {!hideTitleDate && dueDate && (
                <div
                  className={classNames(
                    'text-sm flex items-center',
                    isDueDateTodayOrInPast ? 'text-warning-800' : 'text-info-800',
                  )}
                >
                  {isDueDateTodayOrInPast && <AlertCircle className="w-md h-md mr-xxs" />}
                  <span className="capitalize">{toCalendarFormat(parsedDueDate)}</span>
                </div>
              )}
            </div>
            {dueDate && (
              <div className="first-letter:capitalize">
                {fullTimeFormat
                  ? formatFullFrenchDateTime(dueDate)
                  : `le ${formatFrenchDate(dueDate)}`}
              </div>
            )}
            <div className={classNames('text-neutral-600', dueDate && 'mt-xxs')}>{children}</div>
          </div>
        </div>
        {action && (
          <Button
            className="self-end"
            variant="secondary"
            size="sm"
            onClick={action.disabled ? () => {} : action.onClick}
            rightIcon={action.hideIcon ? undefined : <ChevronRight />}
            disabled={action.disabled ?? false}
            data-testid={action.testId ?? undefined}
          >
            {action.label}
          </Button>
        )}
      </>
    </Card>
  );
};
