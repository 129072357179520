export enum ProjectStatus {
  New = 'new',
  EtudeProjet = 'etude_projet',
  ConsultationArchiSent = 'consultation_archi_sent',
  ConsultationArchiValidated = 'consultation_archi_validated',
  ConsultationProSent = 'consultation_pro_sent',
  ConsultationProValidated = 'consultation_pro_validated',
  PrestationArchiSent = 'prestation_archi_sent',
  PrestationArchiValidated = 'prestation_archi_validated',
  DevisProSent = 'devis_pro_sent',
  DealWon = 'deal_won',
  DealLost = 'deal_lost',
}

export type ProjectListView = {
  uuid: string;
  title: string;
  statuses: ProjectStatus[];
  clientName: string;
  pipedriveDealUrl?: string;
  budgetTravaux?: number;
  dealOwnerFullName?: string;
  address: string;
};
