import React, { useState } from 'react';

import { useFormState } from 'react-final-form';

import HouseComplet from '@travauxlib/shared/src/components/DesignSystem/assets/HouseCompletFilled.svg?react';
import Payments from '@travauxlib/shared/src/components/DesignSystem/assets/Payment.svg?react';
import StraightenFilled from '@travauxlib/shared/src/components/DesignSystem/assets/StraightenFilled.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { typeTravauxOptions } from '@travauxlib/shared/src/utils/constants';

import { DropdownChoice } from './DropdownChoice';
import { LocalisationsChoice } from './LocalisationsChoice';
import { LocalisationsSurfacesChoice } from './LocalisationsSurfacesChoice';
import { NumberInputChoice } from './NumberInputChoice';
import { SummaryCard } from './SummaryCard';

type Props = { isLocalisationsRequired: boolean };

export const SummaryTravaux: React.FC<Props> = ({ isLocalisationsRequired }) => {
  const { values } = useFormState();
  const [activeModal, setActiveModal] = useState<'localisations' | 'surfaces'>();
  return (
    <SummaryCard title="Les travaux">
      <DropdownChoice
        field="typeTravaux"
        options={typeTravauxOptions}
        title="Type de réalisation"
        icon={<HouseComplet />}
      />
      <LocalisationsChoice
        isModalOpen={activeModal === 'localisations'}
        setActiveModal={setActiveModal}
        isRequired={isLocalisationsRequired}
      />
      <LocalisationsSurfacesChoice
        isModalOpen={activeModal === 'surfaces'}
        setActiveModal={setActiveModal}
        isRequired={isLocalisationsRequired}
      />
      <NumberInputChoice
        field="hauteurSousPlafond"
        title="HSP"
        icon={<StraightenFilled />}
        label={`${values?.hauteurSousPlafond?.toLocaleString('fr-fr')}  m`}
        suffix="m"
        isFieldRequired={isLocalisationsRequired}
      />
      <NumberInputChoice
        field="budgetTravaux"
        title="Budget client"
        icon={<Payments />}
        label={
          <>
            <EURCurrency amount={values?.budgetTravaux} />
             TTC
          </>
        }
        suffix="€"
      />
    </SummaryCard>
  );
};
