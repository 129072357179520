import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  TypeMobilier,
  TypeRemplacement,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Cuisine';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';
import { estimateSurfaceMurs } from '@travauxlib/shared/src/utils/quantityComputers';

import { defaultHSP } from '../defaultConfigurationValues';

const planDeTravailIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'plan_de_travail_stratifie',
  [Gamme.confort]: 'plan_de_travail_stratifie',
  [Gamme.premium]: 'plan_de_travail_marbre',
  [Gamme.exclusif]: 'plan_de_travail_ceramique',
};

const deposeReseauQuantite = (cuisineSurface: number): number => {
  if (cuisineSurface <= 2) {
    return 6;
  }
  if (cuisineSurface <= 5) {
    return 8;
  }
  if (cuisineSurface <= 12) {
    return 12;
  }
  return 16;
};

const getTailleCuisine = (
  cuisineSurface: number,
): 'kitchenette' | 'petite' | 'moyenne' | 'grande' => {
  if (cuisineSurface <= 2) {
    return 'kitchenette';
  }
  if (cuisineSurface <= 5) {
    return 'petite';
  }
  if (cuisineSurface <= 12) {
    return 'moyenne';
  }
  return 'grande';
};

const getTaillePlanDeTravail = (cuisineSurface: number): number => {
  if (cuisineSurface < 2) {
    return 2;
  }
  if (cuisineSurface < 5) {
    return 4;
  }
  if (cuisineSurface < 12) {
    return 6;
  }
  return 8;
};

const evierId = (cuisineSurface: number): BaseDePrixIdentifier => {
  if (cuisineSurface <= 5) {
    return 'evier_1_bac_ceramique';
  }
  return 'evier_1_bac_egouttoir_ceramique';
};

export const cuisine = (configuration: Configuration): GenerationData[] => {
  const cuisineRoom = configuration.locations.find(l => l.typeLocation === 'Cuisine');
  const hauteurSousPlafond = configuration.hauteurSousPlafond || defaultHSP;
  const cuisine = configuration.answers?.cuisine;
  const gamme = cuisine?.gamme || configuration.mainGamme;

  if (!cuisineRoom || !cuisine) {
    return [];
  }

  const tailleCuisine = getTailleCuisine(cuisineRoom.surface);
  const taillePlanDeTravail = getTaillePlanDeTravail(cuisineRoom.surface);

  const result: GenerationData[] = [];

  for (const refection of cuisine.refections || []) {
    result.push({
      ouvrageLabel: 'Dépose cuisine',
      tagQuantity: 'skip',
      isOption: refection.isOption,
      quantity: 1,
      locationQuantities: {
        [cuisineRoom.uuid]: 1,
      },
      id: `depose_cuisine_${tailleCuisine}`,
    });

    if (refection.deplacementEquipements) {
      const reseauQuantity = deposeReseauQuantite(cuisineRoom.surface);
      result.push({
        ouvrageLabel: 'Réfection plomberie',
        isOption: refection.isOption,
      });
      result.push({
        hideTag: true,
        ouvrageLabel: "Dépose réseau d'eau",
        tagQuantity: 'only',
        quantity: reseauQuantity,
        isOption: refection.isOption,
        locationQuantities: {
          [cuisineRoom.uuid]: reseauQuantity,
        },
        id: 'depose_reseau_cuivre_fonte',
      });

      const doublageQuantity = roundToOneDecimal(
        estimateSurfaceMurs(cuisineRoom, hauteurSousPlafond) / 4,
      );
      result.push({
        ouvrageLabel: 'Doublage pour passage de réseau',
        tagQuantity: 'only',
        isOption: refection.isOption,
        quantity: doublageQuantity,
        locationQuantities: {
          [cuisineRoom.uuid]: doublageQuantity,
        },
        id: 'doublage_sur_rail_hydro',
      });
    }

    if (cuisineRoom.surface > 5) {
      result.push({
        ouvrageLabel: 'Attentes et évacuations lave-vaisselle' as any,
        hideTag: true,
        isOption: refection.isOption,
        quantity: 1,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
        id: 'ef_evacuation_element_cuivre',
      });
    }

    if (refection.deplacementEquipements === false) {
      result.push({
        ouvrageLabel: 'Plomberie existante à conserver',
      });
    }

    if (refection.hasCuisiniste) {
      result.push({
        ouvrageLabel: 'Pose mobilier par cuisiniste déjà choisi',
        isOption: refection.isOption,
      });
      if (refection.deplacementEquipements) {
        result.push({
          ouvrageLabel: 'Attentes et évacuations évier',
          hideTag: true,
          id: 'ec_ef_evacuation_element_cuivre',
          isOption: refection.isOption,
          quantity: 1,
          locationQuantities: {
            [cuisineRoom.uuid]: 1,
          },
        });
        result.push({
          ouvrageLabel: 'Attentes et évacuations lave-linge',
          hideTag: true,
          id: 'ef_evacuation_element_cuivre',
          isOption: refection.isOption,
          quantity: 1,
          locationQuantities: {
            [cuisineRoom.uuid]: 1,
          },
        });
      }
    }

    if (refection.hasCuisiniste === false || refection.projetDeMobilier === false) {
      result.push({
        id: `cuisine_${tailleCuisine}_premontee`,
        quantity: 1,
        ouvrageLabel: 'Fourniture & Pose mobilier prémonté',
        tagQuantity: 'skip',
        isOption: refection.isOption,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
      result.push({
        id: planDeTravailIdForGamme[gamme],
        quantity: taillePlanDeTravail,
        hideTag: true,
        ouvrageLabel: 'Plan de travail',
        isOption: refection.isOption,
        tagQuantity: 'skip',
        locationQuantities: {
          [cuisineRoom.uuid]: taillePlanDeTravail,
        },
      });
      result.push({
        ouvrageLabel: 'Evier',
        hideTag: true,
        isOption: refection.isOption,
        id: evierId(cuisineRoom.surface),
        quantity: 1,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
      result.push({
        ouvrageLabel: 'Evier',
        hideTag: true,
        id: 'mitigeur_evier',
        tagQuantity: 'skip',
        isOption: refection.isOption,
        quantity: 1,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
      result.push({
        ouvrageLabel: 'Evier',
        hideTag: true,
        id: 'ec_ef_evacuation_element_cuivre',
        quantity: 1,
        isOption: refection.isOption,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
    }

    if (refection.typeMobilier?.includes(TypeMobilier.DuCommerce)) {
      result.push({
        ouvrageLabel: 'Mobilier du commerce en kit à monter',
        id: `cuisine_${tailleCuisine}_pose_seule`,
        quantity: 1,
        isOption: refection.isOption,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });

      result.push({
        ouvrageLabel: 'Attentes et évacuations évier',
        hideTag: true,
        id: 'ec_ef_evacuation_element_cuivre',
        quantity: 1,
        isOption: refection.isOption,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
    }
  }

  for (const remplacement of cuisine.remplacements || []) {
    if (remplacement.typeRemplacements?.includes(TypeRemplacement.PlanDeTravail)) {
      const RATIO_DEPOSE = 0.6;
      result.push({
        ouvrageLabel: 'Remplacement plan de travail',
        tagQuantity: 'skip',
        id: 'depose_menuiseries_interieures',
        isOption: remplacement.isOption,
        quantity: taillePlanDeTravail * RATIO_DEPOSE,
        locationQuantities: {
          [cuisineRoom.uuid]: taillePlanDeTravail * RATIO_DEPOSE,
        },
      });

      result.push({
        ouvrageLabel: 'Remplacement plan de travail',
        tagQuantity: 'skip',
        id: planDeTravailIdForGamme[gamme],
        quantity: taillePlanDeTravail,
        isOption: remplacement.isOption,
        locationQuantities: {
          [cuisineRoom.uuid]: taillePlanDeTravail,
        },
      });
    }
    if (remplacement.typeRemplacements?.includes(TypeRemplacement.Evier)) {
      result.push({
        ouvrageLabel: 'Remplacement évier',
        tagQuantity: 'skip',
        id: 'depose_evier',
        isOption: remplacement.isOption,
        quantity: 1,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });

      result.push({
        ouvrageLabel: 'Remplacement évier',
        tagQuantity: 'skip',
        id: evierId(cuisineRoom.surface),
        isOption: remplacement.isOption,
        quantity: 1,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
      result.push({
        ouvrageLabel: 'Remplacement évier',
        tagQuantity: 'skip',
        id: 'mitigeur_evier',
        isOption: remplacement.isOption,
        quantity: 1,
        locationQuantities: {
          [cuisineRoom.uuid]: 1,
        },
      });
    }
  }

  return result;
};
