import { useQuery } from '@tanstack/react-query';

import { ProCompanyFournituresView } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const COMPANY_FOURNITURES_KEY = 'companyFournitures';

export const useFournituresByProCompanies = (
  projectUuid: string,
): { isLoading: boolean; proCompaniesFournitures: ProCompanyFournituresView[] } => {
  const { data, isLoading } = useQuery<{ data: ProCompanyFournituresView[] }>({
    queryKey: [COMPANY_FOURNITURES_KEY, projectUuid],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/fournitures/by-procompanies`),
  });

  return { isLoading, proCompaniesFournitures: data?.data || [] };
};
