import React, { useState } from 'react';

import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { useDebouncedValue } from '@travauxlib/shared/src/hooks/useDebouncedValue';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { useSearchClientsByEmail } from './api/useSearchClientsByEmail';

type Props = {
  selectedAccount: ClientAccount | undefined;
  setSelectedAccount: (clientAccount: ClientAccount | undefined) => void;
  onFilter?: (clientAccounts: ClientAccount[]) => ClientAccount[];
};

export const ClientAccountDropdown: React.FC<Props> = ({
  selectedAccount,
  setSelectedAccount,
  onFilter,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { foundClientAccounts } = useSearchClientsByEmail(useDebouncedValue(searchValue, 500));

  const filteredFoundClientsAccounts = onFilter
    ? onFilter(foundClientAccounts)
    : foundClientAccounts;

  const filteredClientsAccounts = filteredFoundClientsAccounts.filter(
    acc => acc.email !== selectedAccount?.email,
  );

  const options = filteredClientsAccounts.map(acc => ({
    value: acc.uuid,
    label: `${acc.firstName} ${acc.lastName} (${acc.email})`,
  }));

  return (
    <Dropdown
      id="email"
      label="Email"
      placeholder="Recherche..."
      options={options}
      onFilterFn={() => () => true}
      onChange={value => {
        const foundAccount = foundClientAccounts.find(acc => acc.uuid === value);
        setSelectedAccount(foundAccount);
      }}
      onSearchChange={setSearchValue}
      fixedFloating={false}
    />
  );
};
