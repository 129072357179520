import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { request } from '@travauxlib/shared/src/utils/request';

const handleReportResult = ({
  consultationInfoId,
  proCompany,
  detail,
}: {
  consultationInfoId: number;
  proCompany: ProCompanyAdminView;
  detail: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/consultations/${consultationInfoId}/report-result`;

  return request(requestURL, {
    method: 'POST',
    body: { proCompanyId: proCompany.id, detail },
  });
};

export const useConsultationInfoReportResult = (): {
  reportResult: typeof handleReportResult;
  isLoading: boolean;
} => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleReportResult,
    onSuccess: () => {
      toast.success('Résultat non pertinent signalé.');
    },
  });
  return { reportResult: mutateAsync, isLoading: isPending };
};
