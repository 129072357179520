import React from 'react';

import { useField } from 'react-final-form';

import { FileDropzoneField } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/Field';
import { LogicielDeDevisContextProps } from '@travauxlib/shared/src/features/LogicielDeDevis/types';

type Props = {
  formBaseName: string;
  onFournitureImageUpload: Required<LogicielDeDevisContextProps>['handleUploadFournitureImage'];
  disabled?: boolean;
};

export const ReferenceImagesField: React.FC<Props> = ({
  formBaseName,
  onFournitureImageUpload,
  disabled,
}) => {
  const {
    input: { value, onChange },
  } = useField(`${formBaseName || ''}mainImageUuid`);

  return (
    <FileDropzoneField
      name={`${formBaseName || ''}images`}
      uploadFiles={async ({ files }) => {
        const filesToUpload = files.map(file => file.body);

        return onFournitureImageUpload(filesToUpload);
      }}
      onClickFile={file => onChange(file.uuid)}
      activeFileUuid={value}
      multiple
      disabled={disabled}
      displayMode="pictures-wall"
      canRemoveFile
    />
  );
};
