import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { DownloadableFile } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export type UploadFilesPayload = {
  projectUuid: string;
  files: File[];
};

const handleUploadFournitureImages = async ({
  projectUuid,
  files,
}: UploadFilesPayload): Promise<DownloadableFile[]> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/fourniture-image`;

  const apiCalls = files.map(file => {
    const formData = new FormData();
    formData.append('fournitureImage', file);

    return request(requestURL, {
      method: 'POST',
      body: formData,
    });
  });

  return Promise.all<DownloadableFile>(apiCalls);
};

export const useUploadFournitureImages = (): typeof handleUploadFournitureImages => {
  const { mutateAsync: uploadFiles } = useMutation({
    mutationFn: handleUploadFournitureImages,
    onError: () => {
      toast.error("L'image de la fourniture n'a pas pu être ajoutée");
    },
  });
  return uploadFiles;
};
