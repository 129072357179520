import { useRef, useState } from 'react';

import {
  FloatingArrow,
  FloatingFocusManager,
  Placement,
  arrow,
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

import { OrExclusive } from '@travauxlib/shared/src/types/utils';

import RightChevron from '../../assets/ChevronRightFilled.svg?react';
import { Button } from '../Buttons/Button';
import { ButtonLink } from '../Links';

type HrefOrTo = OrExclusive<{ href: string }, { to: string }>;

export type Props = {
  title?: string;
  content: string | React.ReactElement;
  trigger: (close: () => void, referenceProps: Object) => React.ReactElement;
  position?: Placement;
  disabled?: boolean;
  offsetX?: number;
  offsetY?: number;
  label?: string;
  fixedFloating?: boolean;
  width?: number;
  onHover?: boolean;
} & HrefOrTo;

const ARROW_HEIGHT = 8;
const GAP = 3;

export const Popover: React.FC<Props> = ({
  label,
  content,
  title,
  href,
  to,
  position,
  trigger,
  fixedFloating,
  width = 275,
  onHover,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: position,
    strategy: fixedFloating ? 'fixed' : 'absolute',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: ARROW_HEIGHT + GAP,
        alignmentAxis: -16,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const toggleOpen = (): void => {
    setIsOpen(isOpen => !isOpen);
  };

  const click = useClick(context);
  const hover = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const defaultInteractions = [click, dismiss, role];
  const { getReferenceProps, getFloatingProps } = useInteractions(
    onHover ? [hover] : defaultInteractions,
  );

  const referenceProps = {
    ref: refs.setReference,
    ...getReferenceProps(),
  };

  return (
    <>
      {trigger && trigger(toggleOpen, referenceProps)}
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            className="absolute z-50 p-md bg-gray-900 text-neutral-0 rounded-xxs"
            //eslint-disable-next-line
            style={{ ...floatingStyles, width: `${width}px` }}
          >
            {title && <div className="text-b1 font-bold mb-xxs">{title}</div>}
            {typeof content === 'string' ? (
              <div className="text-b2 text-neutral-0">{content}</div>
            ) : (
              content
            )}
            {label && (
              <div className="text-right mt-sm">
                {to || href ? (
                  <ButtonLink
                    size="sm"
                    href={href}
                    to={to}
                    variant="secondary"
                    rel="noopener noreferrer nofollow"
                    rightIcon={<RightChevron />}
                    onClick={toggleOpen}
                  >
                    {label}
                  </ButtonLink>
                ) : (
                  <Button
                    className="focus:outline-0"
                    onClick={toggleOpen}
                    size="sm"
                    variant="secondary"
                  >
                    {label}
                  </Button>
                )}
              </div>
            )}
            <FloatingArrow ref={arrowRef} context={context} />
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
