import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

const isSameDefinedValue = (baseValue?: string | number, compareValue?: string | number): boolean =>
  !!baseValue && baseValue === compareValue;

export const areLinesSimilar = (
  base?: ProDevisFactureLigneView,
  compare?: ProDevisFactureLigneView,
  matchThread?: boolean,
): boolean => {
  const baseSimilar =
    isSameDefinedValue(base?.normalizedIdentifier, compare?.normalizedIdentifier) ||
    isSameDefinedValue(base?.proBaseDePrixId, compare?.proBaseDePrixId) ||
    isSameDefinedValue(base?.threadUuid, compare?.threadUuid) ||
    base?.designation === compare?.designation;

  if (matchThread) {
    // for revision if the base has a threadUuid we want to match with it
    return baseSimilar && (!base?.threadUuid || base?.threadUuid === compare?.threadUuid);
  }
  return baseSimilar;
};

export const areLigneLocationsIdentical = (
  base: ProDevisFactureLigneView,
  compare: ProDevisFactureLigneView,
  locationsBase: DevisLocation[],
  locationsCompare: DevisLocation[],
): boolean => {
  if (base.locations.length !== compare.locations.length) {
    return false;
  }
  // for every base locations we need a compare location with the same label and the same quantity
  return base.locations.every(location => {
    const label = locationsBase.find(l => l.uuid === location.uuid)?.label;
    return compare.locations.some(compareLocation => {
      if (compareLocation.quantite !== location.quantite) {
        return false;
      }
      const compareLabel = locationsCompare.find(l => l.uuid === compareLocation.uuid)?.label;
      return compareLabel === label;
    });
  });
};

export const areLinesIdentical = (
  base: ProDevisFactureLigneView,
  compare: ProDevisFactureLigneView,
  locationsBase: DevisLocation[],
  locationsCompare: DevisLocation[],
): boolean =>
  areLinesSimilar(base, compare, false) &&
  base?.prixHT === compare?.prixHT &&
  base?.designation === compare?.designation &&
  base?.tauxTVA === compare?.tauxTVA &&
  base?.quantite === compare?.quantite &&
  base?.status === compare?.status &&
  base?.unite === compare?.unite &&
  areLigneLocationsIdentical(base, compare, locationsBase, locationsCompare);

export const isLineLocalized = (ligne: ProDevisFactureLigneView): boolean =>
  ligne.locations.length !== 0;

export const areLinesMergeable = (
  base: ProDevisFactureLigneView,
  compare: ProDevisFactureLigneView,
): boolean =>
  (isSameDefinedValue(base.normalizedIdentifier, compare.normalizedIdentifier) ||
    isSameDefinedValue(base.proBaseDePrixId, compare.proBaseDePrixId) ||
    base.designation === compare.designation) &&
  isLineLocalized(base) === isLineLocalized(compare) &&
  base.status === compare.status;
