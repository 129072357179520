import { Options } from '@travauxlib/shared/src/utils/request';

type Payload = {
  seed: string;
  firstNameLead?: string;
  lastNameLead?: string;
  affiliateToken?: string;
  departement?: string;
  phoneNumber?: string;
};

const parisLocalisation = {
  address_components: [
    {
      long_name: '75015',
      short_name: '75015',
      types: ['postal_code'],
    },
    {
      long_name: 'Paris',
      short_name: 'Paris',
      types: ['locality', 'political'],
    },
    {
      long_name: 'Département de Paris',
      short_name: 'Département de Paris',
      types: ['administrative_area_level_2', 'political'],
    },
    {
      long_name: 'Île-de-France',
      short_name: 'IDF',
      types: ['administrative_area_level_1', 'political'],
    },
    {
      long_name: 'France',
      short_name: 'FR',
      types: ['country', 'political'],
    },
  ],
  formatted_address: '75015 Paris, France',
  geometry: {
    bounds: {
      south: 48.8252116,
      west: 2.2629819,
      north: 48.8582221,
      east: 2.3246822,
    },
    location: {
      lat: 48.8421616,
      lng: 2.2927665,
    },
    location_type: 'APPROXIMATE',
    viewport: {
      south: 48.8252116,
      west: 2.2629819,
      north: 48.8582221,
      east: 2.3246822,
    },
  },
  place_id: 'ChIJ9WBuGRRw5kcR4FHY4caCCxw',
  types: ['postal_code'],
};

const makeIdealLeadBody = ({
  seed,
  firstNameLead,
  lastNameLead,
  affiliateToken,
  departement,
  phoneNumber,
}: Payload): any => ({
  need: 'pas_entreprise',
  typeProjet: 'renovation_complete',
  typeBien: 'appartement',
  surfaceTotale: 130,
  stadeAchat: 'proprietaire',
  dateTravaux: '3-6',
  besoinArchitecte: 'oui',
  critereSelection: 'qualite',
  budget: '50000-100000',
  description:
    "Je suis un lead auto crée par XX le XX.\n\nSinon c'est un super beau projet.\nTout le monde dit que c'est incroyable surtout ma maman.",
  optin: false,
  firstName: firstNameLead || 'SuperTest',
  lastName: lastNameLead || 'Test',
  email: `${seed}@example.hemea.com`,
  phoneNumber: phoneNumber || '0642727269',
  cgu: true,
  googleMapsAddress: departement
    ? {
        address_components: [
          {
            long_name: `${departement}033`,
            short_name: `${departement}033`,
            types: ['postal_code'],
          },
        ],
        formatted_address: 'yolo',
      }
    : parisLocalisation,
  conversionPage: '',
  password: 'azerty',
  affiliateToken,
});

export type APIResponse = {
  createdClientAccount: { email: string; uuid: string };
  existingAccountEmail: string | undefined;
  leadToken: string;
};

export const apiCreateLead = (
  apiURL: string,
  params: Payload,
  request: (url: string, options: Options) => Promise<APIResponse>,
): Promise<APIResponse> =>
  request(`${apiURL}/client/lead/v2`, {
    method: 'POST',
    body: makeIdealLeadBody(params),
  });
