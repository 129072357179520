import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { Navigate, useNavigate, useParams } from 'react-router';

import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { OuverturesDemolition } from '@travauxlib/shared/src/types/api/domain/configurateur/OuverturesDemolition';

import { useConfiguration } from 'features/Configurateur/api/useConfiguration';
import { FormFooter } from 'features/Configurateur/components/FormFooter';
import { FormHeader } from 'features/Configurateur/components/FormHeader';

import { FauxPlafonds } from './components/FauxPlafonds';
import { OuverturesMur } from './components/OuverturesMur';

import { useCreateUpdateConfiguration } from '../../api/useCreateUpdateConfiguration';

export const ConfigurateurOuverturesDemolition: React.FC = () => {
  const navigate = useNavigate();
  const createUpdateConfiguration = useCreateUpdateConfiguration();

  const projectUuid = useParams().uuid!;
  const { configuration } = useConfiguration(projectUuid);

  if (!configuration) {
    return <Navigate to="../bien" />;
  }

  return (
    <Form<OuverturesDemolition>
      mutators={{ ...arrayMutators }}
      destroyOnUnregister
      initialValues={configuration?.answers?.ouverturesDemolition}
      onSubmit={async ouverturesDemolition => {
        const createdAnswers: Configuration = {
          ...configuration,
          answers: { ...configuration?.answers, ouverturesDemolition },
        };
        await createUpdateConfiguration(createdAnswers);
        navigate('..');
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="mb-xl w-full">
          <FormHeader questionId="ouverturesDemolition" />
          <OuverturesMur configuration={configuration} />
          <FauxPlafonds configuration={configuration} />
          <FormFooter />
        </form>
      )}
    </Form>
  );
};
