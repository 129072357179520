import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';

import { BaseDePrixLigne } from '../types';

export const getPriceByFournitureWithCorrectGamme = (
  ligne: Omit<BaseDePrixLigne, 'fournitures'>,
  gamme: Gamme = Gamme.confort,
): number => {
  let priceByFourniture = 0;

  const defaultPrice = ligne.prixConfortFournitureHT ?? 0;

  switch (gamme) {
    case Gamme.primo:
      priceByFourniture = ligne.prixPrimoFournitureHT ?? defaultPrice;
      break;
    case Gamme.confort:
      priceByFourniture = ligne.prixConfortFournitureHT ?? defaultPrice;
      break;
    case Gamme.premium:
      priceByFourniture = ligne.prixPremiumFournitureHT ?? defaultPrice;
      break;
    case Gamme.exclusif:
      priceByFourniture = ligne.prixExclusifFournitureHT ?? defaultPrice;
      break;
    default:
      priceByFourniture = defaultPrice;
  }

  return priceByFourniture;
};

export const getPriceWithCorrectGammeFourniture = (
  prixAchat: number,
  ligne: Omit<BaseDePrixLigne, 'fournitures'>,
  gamme: Gamme = Gamme.confort,
): number => {
  const priceByFourniture = getPriceByFournitureWithCorrectGamme(ligne, gamme);
  const prixWithCorrectFourniture =
    prixAchat - (ligne.prixConfortFournitureHT ?? 0) + priceByFourniture;

  return prixWithCorrectFourniture;
};
