import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { OuvertureMur } from '@travauxlib/shared/src/types/api/domain/configurateur/OuverturesDemolition';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { FormControl } from 'features/Configurateur/components/FormControl';
import { LocationsDimensions } from 'features/Configurateur/components/LocationsDimensions';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { yesNoForm } from 'features/Configurateur/utils/forms';

type Props = {
  configuration: Configuration;
};

const Locations: React.FC<{
  name: string;
  configuration: Configuration;
  prefix: string;
  value: OuvertureMur;
}> = ({ name, configuration, prefix, value }) => (
  <>
    <QuestionTitle prefix={prefix} title="Dimensions ?" />
    <LocationsDimensions
      configuration={configuration}
      locations={configuration.locations.filter(l => value.locationsUuid?.includes(l.uuid))}
      getFields={locationUuid => [
        {
          name: `${name}.locationQuantities.${locationUuid}.longueur`,
        },
        {
          name: `${name}.locationQuantities.${locationUuid}.hauteur`,
          defaultValue: configuration.hauteurSousPlafond,
        },
      ]}
    />
  </>
);

const LocationsChoice: React.FC<{
  name: string;
  value: OuvertureMur;
  configuration: Configuration;
  prefix: number;
}> = ({ name, value, configuration, prefix }) => (
  <>
    <MultiLocationsPicker
      prefix={`${prefix}`}
      name={`${name}.locationsUuid`}
      configuration={configuration}
    />
    {!!value.locationsUuid?.length && (
      <Locations configuration={configuration} name={name} prefix={`${prefix + 1}`} value={value} />
    )}
  </>
);

const EtudeStructure: React.FC<{ name: string }> = ({ name }) => (
  <>
    <QuestionTitle
      prefix="2"
      title="Étude de structure déjà effectuée ?"
      tooltip="Démolir ou ouvrir un mur porteur implique de faire appel à un bureau d’étude structure pour faire les calculs de reprise de charge."
    />
    <FormControl name={`${name}.etude`} {...yesNoForm} />
  </>
);

export const OuverturesMur: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Ouverture ou démolition de mur ?" prefix="1" defaultExpanded>
    <FieldArray<OuvertureMur> name="ouverturesMur">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type d'ouverture ou de démolition ?"
                    tooltip="Un mur porteur sonne plein tandis qu’une cloison sonne généralement creux.  Il faut faire appel à un professionnel pour confirmer qu’un mur est porteur."
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeOuverture`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Mur porteur',
                      value: 'Mur porteur',
                    },
                    {
                      label: 'Cloison',
                      value: 'Cloison',
                    },
                    {
                      label: 'Ne sait pas',
                      value: 'Ne sait pas',
                    },
                  ]}
                />
                {value.typeOuverture === 'Mur porteur' && <EtudeStructure name={name} />}
                {/* Prevent a weird state bug of the formulaire
                    destroyOnUnregister is kinda buggy if we unregister and reregister a field at the same time in a render
                  */}
                {(value.typeOuverture === 'Cloison' || value.etude !== undefined) && (
                  <LocationsChoice
                    name={name}
                    configuration={configuration}
                    value={value}
                    prefix={value.etude ? 3 : 2}
                  />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
