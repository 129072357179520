import React from 'react';

import { useNavigate, useOutletContext } from 'react-router';

import KeyFigures from '@travauxlib/shared/src/components/DesignSystem/assets/KeyFigures.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

import { useAuth } from 'api/useAuth';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { FicheProjetTracking } from 'utils/tracking/FicheProjetTracking';

import { useCreateConsultationInfo } from './api/useCreateConsultationInfo';
import { ConsultationBlock } from './components/ConsultationBlock';
import { checkRequiredFields } from './utils';

export const ProjectConsultation: React.FC = () => {
  const { project, consultations, consultationInfoList } = useOutletContext<ProjectContext>();
  const { createConsultationInfo, isLoading } = useCreateConsultationInfo();
  const { adminUserProfile } = useAuth();

  const navigate = useNavigate();

  const handleCreateConsultationInfo = (): Promise<{ id: string }> => {
    FicheProjetTracking.onConsultationCreated({
      'User Role': adminUserProfile.role,
      'Projet UUID': project.uuid,
    });

    return createConsultationInfo(project.uuid, {
      onSuccess: ({ id }) => navigate(`./${id}`),
    });
  };

  if (consultationInfoList.length === 0) {
    return (
      <EmptyState
        className="justify-center grow"
        illustration={<KeyFigures />}
        iconIllustrationSize="md"
        title="Aucune consultation en cours"
        description="Vous n'avez pas encore fait de consultation"
        primaryAction={{
          label: 'Créer une consultation',
          leftIcon: <PlusSymbol />,
          onClick: handleCreateConsultationInfo,
          loading: isLoading,
          disabled: isLoading || !project.pipedriveDealId || !checkRequiredFields({ ...project }),
          disabledMessageTooltip: !project.pipedriveDealId
            ? 'Pas de deal pipedrive associé au projet'
            : !checkRequiredFields(project) && 'Champs manquants dans la typologie de projet',
        }}
      />
    );
  }

  return (
    <div className="pt-lg">
      <div className="flex justify-end mb-md">
        <Button
          leftIcon={<PlusSymbol />}
          onClick={handleCreateConsultationInfo}
          loading={isLoading}
          disabled={isLoading || !project.pipedriveDealId || !checkRequiredFields(project)}
          disabledMessageTooltip={
            !project.pipedriveDealId
              ? 'Pas de deal pipedrive associé au projet'
              : !checkRequiredFields(project) && 'Champs manquants dans la typologie de projet'
          }
        >
          Créer une consultation
        </Button>
      </div>
      {consultationInfoList.map(consultationInfo => {
        const filteredConsultations = consultations.filter(
          c => c.consultationInfo.id === consultationInfo.id,
        );

        return (
          <ConsultationBlock
            key={consultationInfo.id}
            consultationInfo={consultationInfo}
            consultations={filteredConsultations}
            showNewCompanyLink
          />
        );
      })}
    </div>
  );
};
