import React from 'react';

import ThumbnailPicto from '@travauxlib/shared/src/components/DesignSystem/assets/Thumbnail.svg?react';

type Props = {
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
};

const sizeClasses = {
  xxs: 'h-lg w-lg', // 24px
  xs: 'h-xl w-xl', // 32px
  sm: 'h-[2.5rem] w-[2.5rem]', // 40px
  md: 'h-xxl w-xxl', // 48px
  lg: 'h-3xl w-3xl', // 64px
};

export const Thumbnail: React.FC<Props> = ({ size }) => (
  <ThumbnailPicto className={sizeClasses[size]} />
);
