import React from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { FournituresWithLocation } from '@travauxlib/shared/src/types';
import { addPlurielToWords } from '@travauxlib/shared/src/utils/wording';

export type Props = {
  fournitures: FournituresWithLocation[];
  selectedRooms: string[];
  availableRooms: string[];
  onRoomToggle: (room: string, checked: boolean) => void;
};

export const RoomsFilter: React.FC<Props> = ({
  fournitures,
  selectedRooms,
  availableRooms,
  onRoomToggle,
}) => (
  <div className="flex flex-col gap-xs">
    {fournitures
      .filter(f => availableRooms.includes(f.locationName))
      .map(({ locationName, fournitures }) => {
        const [fourniture] = addPlurielToWords(fournitures, ['fourniture']);

        return (
          <div
            key={locationName}
            className="border border-neutral-300 p-xs mb-xs last:mb-0 sm-desktop:mb-0 sm-desktop:p-0 sm-desktop:border-0 rounded-xxs sm-desktop:rounded-none"
          >
            <Checkbox
              key={locationName}
              label={locationName}
              checked={selectedRooms.includes(locationName)}
              onChange={checked => onRoomToggle(locationName, checked)}
            />
            <div className="text-sm text-neutral-700 ml-xl">
              {fournitures.length} {fourniture}
            </div>
          </div>
        );
      })}
  </div>
);
