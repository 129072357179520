import { useQueryClient, useMutation } from '@tanstack/react-query';

import { ChaleurLead } from '@travauxlib/shared/src/types/api/domain/client/ChaleurLead';
import { LeadStatus } from '@travauxlib/shared/src/types/api/domain/client/LeadStatus';
import { LostReason } from '@travauxlib/shared/src/types/api/domain/client/LostReason';
import { request } from '@travauxlib/shared/src/utils/request';

import { Lead } from 'types';

const handleClose = ({
  lead,
  nonAdressableReason,
}: {
  lead: Lead;
  nonAdressableReason: LostReason;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/leads/${lead.id}/close`;
  return request(requestURL, {
    method: 'PATCH',
    body: {
      nonAdressableReason,
    },
  });
};

const handleUpdateStatus = ({
  lead,
  status = lead.status,
  nonAdressableReason = lead.nonAdressableReason,
}: {
  lead: Lead;
  status?: LeadStatus;
  nonAdressableReason?: LostReason;
}): Promise<Lead> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/leads/${lead.id}/status`;
  return request(requestURL, {
    method: 'PATCH',
    body: {
      ...lead,
      status,
      nonAdressableReason,
    },
  });
};

const handleSetDealOwner = ({
  lead,
  dealOwner,
}: {
  lead: Lead;
  dealOwner: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/leads/${lead.id}/set-deal-owner?dealOwner=${dealOwner}`;
  return request(requestURL, { method: 'PATCH' });
};

const handleUpdateAddressable = ({
  lead,
  chaleurLead,
}: {
  lead: Lead;
  chaleurLead: ChaleurLead;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/leads/${lead.id}/addressable`;
  return request(requestURL, {
    method: 'PATCH',
    body: {
      chaleurLead,
    },
  });
};

export const useUpdateLead = (): {
  updateAdressable: typeof handleUpdateAddressable;
  updateStatus: typeof handleUpdateStatus;
  close: typeof handleClose;
  setDealOwner: typeof handleSetDealOwner;
  isUpdating: boolean;
} => {
  const queryClient = useQueryClient();
  const onSuccess = (): void => {
    queryClient.invalidateQueries({ queryKey: ['leads'] });
    queryClient.invalidateQueries({ queryKey: ['leads-clients'] });
  };

  const { mutateAsync: close, ...closeState } = useMutation({ mutationFn: handleClose, onSuccess });

  const { mutateAsync: updateStatus, ...updateStatusState } = useMutation({
    mutationFn: handleUpdateStatus,
    onSuccess,
  });

  const { mutateAsync: setDealOwner, ...setDealOwnerState } = useMutation({
    mutationFn: handleSetDealOwner,
    onSuccess,
  });

  const { mutateAsync: updateAdressable, ...updateAdressableState } = useMutation({
    mutationFn: handleUpdateAddressable,
    onSuccess,
  });

  return {
    updateAdressable,
    updateStatus,
    close,
    setDealOwner,
    isUpdating: [closeState, updateStatusState, setDealOwnerState, updateAdressableState].some(
      ({ isPending }) => isPending,
    ),
  };
};
