import React from 'react';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ProRegistration } from '@travauxlib/shared/src/types';

import { adminUrls } from 'utils/urls';

import { ProRegistationComponent } from './ProRegistration';

import { useProRegistrations } from '../api/useProRegistrations';

const splitLeadsAndRegistrations = (
  proRegistrations: ProRegistration[],
): { registrations: ProRegistration[]; leads: ProRegistration[] } => {
  const leads: ProRegistration[] = proRegistrations?.filter(item => item.isLead);
  const registrations: ProRegistration[] = proRegistrations?.filter(item => !item.isLead);
  return { registrations: registrations, leads: leads };
};

const addPluriel = (toCheck: ProRegistration[]): string => (toCheck.length > 1 ? 's' : '');

export const LeadListing: React.FC = () => {
  const proRegistrations = useProRegistrations();
  if (!proRegistrations) {
    return <Loader />;
  }
  const { registrations, leads } = splitLeadsAndRegistrations(proRegistrations);

  return (
    <div className="w-full px-md mx-auto">
      <div className="flex justify-between items-center mb-md flex-wrap">
        <h1 className="mb-0">
          {registrations.length} inscription{addPluriel(registrations)}
          {leads.length >= 1 && ` & ${leads.length} lead`}
          {addPluriel(leads)} à traiter
        </h1>
        <ButtonLink to={adminUrls.newLeadPro} leftIcon={<PlusSymbol />}>
          Créer un nouveau lead
        </ButtonLink>
      </div>
      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <th>Entreprise</th>
            <th>Contact</th>
            <th>Adressable ?</th>
            <th>Raison de la perte ?</th>
          </tr>
          {proRegistrations.map(proRegistration => (
            <ProRegistationComponent key={proRegistration.uuid} proRegistration={proRegistration} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
