import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Refection } from '@travauxlib/shared/src/types/api/domain/configurateur/Elec';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const MiseAuxNormesEelectriciteAfaire: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Mise aux normes de l'électricité à faire ?"
    prefix="1"
    defaultExpanded
  >
    <FieldArray<Refection> name="refections">
      {({ fields }) => (
        <>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type de mise aux normes"
                    tooltip="Le dossier des diagnostics de l'appartement contient le diagnostic électrique qui indique si l'électricité est aux normes et donc nécessite une réfection complète ou une mise à la Terre de certains élements."
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeRefection`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Réfection électrique complète',
                      value: 'Complète',
                    },
                    {
                      label: 'Simple mise à la Terre',
                      value: 'Mise à terre',
                    },
                    {
                      label: 'Ne sait pas',
                      value: 'Ne sait pas',
                    },
                  ]}
                />
                {value.typeRefection === 'Mise à terre' && (
                  <MultiLocationsPicker
                    prefix="2"
                    configuration={configuration}
                    name={`${name}.locationsUuid`}
                  />
                )}
                {value.typeRefection === 'Complète' && (
                  <>
                    <QuestionTitle
                      prefix="2"
                      title="Localisation du/des tableau(x) électrique(s) ?"
                      tooltip={
                        <div>
                          Type de distribution électrique en fonction de la gamme :
                          <br />- Primo : Réseau électrique apparent (goulottes)
                          <br />- Confort, Premium et Exclusif : On ajoute des faux plafonds dans
                          entrée, dégagement et pièces d'eau par défaut
                        </div>
                      }
                    />
                    {configuration.locations.map(location => {
                      const Picto = locationsPicto[location.typeLocation];
                      return (
                        <LocationCard
                          key={location.uuid}
                          className="mb-md"
                          label={getLocationLabel(configuration, location)}
                          centerIcon={<Picto />}
                        >
                          <div>
                            <div className="flex justify-end items-center mb-xs">
                              <CounterField
                                name={`${name}.locationQuantities.${location.uuid}.tableauxElectriques`}
                                id={`locationQuantities.${location.uuid}.tableauxElectriques`}
                              />
                            </div>
                          </div>
                        </LocationCard>
                      );
                    })}
                  </>
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </>
      )}
    </FieldArray>
  </Accordion>
);
