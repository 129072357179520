import React from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useProjectContext } from 'features/Project/api/useProjectContext';

import { useOpenCreateOrReplaceClientAccountModal } from './useOpenCreateOrReplaceClientAccountModal';

import { ClientAccountCard } from '../components/ClientAccountCard';

type Props = {
  projectUuid: string;
  handleClose: () => void;
};

const ClientContactModal: React.FC<Props> = ({ projectUuid, handleClose }) => {
  const { projectContext } = useProjectContext(projectUuid);
  const openCreateOrReplaceClientAccountModal = useOpenCreateOrReplaceClientAccountModal();

  if (!projectContext) {
    return <Loader />;
  }

  const { clientAccounts } = projectContext;
  const removeAccountDisabled = clientAccounts.length === 1;

  return (
    <ModalContent
      isScrollable
      title="Gérer les contacts"
      validateAction={{
        label: 'Fermer',
        onClick: handleClose,
      }}
      cancelAction={{
        label: 'Ajouter un nouveau compte',
        onClick: () => {
          handleClose();
          openCreateOrReplaceClientAccountModal(projectContext);
        },
      }}
    >
      <>
        <div className="mb-md">
          <div className="text-b2 text-neutral-700 mb-md">
            Les projets peuvent être attachés à un ou plusieurs comptes clients.
          </div>

          <div className="text-b1 text-neutral-800 font-medium mb-xs">Comptes client du projet</div>
          <div className="flex flex-col gap-md">
            {clientAccounts.map(clientAccount => (
              <ClientAccountCard
                key={clientAccount.uuid}
                clientAccount={clientAccount}
                projectContext={projectContext}
                removeAccountDisabled={removeAccountDisabled}
              />
            ))}
          </div>
        </div>
      </>
    </ModalContent>
  );
};

export const useOpenClientContactModal = (): ((projectUuid: string) => void) => {
  const openModal = useOpenModal();

  return projectUuid =>
    openModal(ClientContactModal, {
      closable: true,
      projectUuid,
    });
};
