import React from 'react';

import classNames from 'classnames';
import { Link, NavLink } from 'react-router';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import logo from '@travauxlib/shared/src/components/DesignSystem/assets/Logo.svg';
import LogoutTool from '@travauxlib/shared/src/components/DesignSystem/assets/LogoutTool.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { PersonRole } from '@travauxlib/shared/src/types';

import { useLogout, useAuth } from 'api/useAuth';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { navItems } from './utils/navItems';
type Props = {
  showMobileSidebar: boolean;
  setShowMobileSidebar: (showMobileSidebar: boolean) => void;
};

export const MobileSidebar: React.FC<Props> = ({ showMobileSidebar, setShowMobileSidebar }) => {
  const logout = useLogout();
  const { adminUserProfile } = useAuth();

  const canManageFacturation = useIsAllowed('Facturation', 'manage');

  const permissions = {
    canManageFacturation,
    isCDPL: adminUserProfile.role === PersonRole.CDPL,
  };

  const filteredNavItems = navItems.filter(item => !item.show || item.show(permissions));

  return (
    <>
      <div
        className={classNames(
          'fixed bg-neutral-800 inset-0 transition-300ms transition-[opacity] opacity-0 z-40 pointer-events-none',
          showMobileSidebar && '!pointer-events-auto !opacity-[0.64]',
        )}
        onClick={() => {
          setShowMobileSidebar(false);
        }}
      />
      <aside
        className={classNames(
          'shrink-0 w-[300px] inset-y-0 h-dvh fixed z-50 -left-[300px] transition-[left] bg-white shadow-sm',
          showMobileSidebar && 'duration-[300ms] !left-0',
        )}
      >
        <div className="flex flex-col h-full pb-md overflow-hidden">
          <div className="h-full flex flex-col overflow-hidden">
            <div className="mb-md shrink-0">
              <div className="py-xs border-b pl-md flex justify-between items-center overflow-hidden">
                <div className="overflow-hidden max-w-full flex justify-center shrink-0">
                  <Link
                    className="max-w-full flex justify-center overflow-hidden"
                    onClick={() => {
                      setShowMobileSidebar(false);
                    }}
                    to="/"
                  >
                    <img src={logo} className="h-xl w-[116px] shrink-0 bg-contain max-w-none" />
                  </Link>
                </div>
                <IconButton
                  className="sm-desktop:hidden"
                  size="lg"
                  onClick={() => setShowMobileSidebar(false)}
                >
                  <CrossSymbol />
                </IconButton>
              </div>
            </div>
            <div className="flex flex-col overflow-hidden h-full shrink justify-between">
              <div className="flex flex-col overflow-hidden">
                <div className="mb-xs pl-sm font-medium text-neutral-800 text-b2">Menu</div>
                <div className="mb-xl overflow-auto">
                  {filteredNavItems.map(({ Icon, to, label }) => (
                    <NavLink
                      onClick={() => {
                        setShowMobileSidebar(false);
                      }}
                      key={to}
                      to={to}
                      className={({ isActive }) =>
                        classNames(
                          '!no-underline text-neutral-800 text-b1 font-bold p-sm flex no-wrap overflow-hidden justify-between items-center',
                          isActive ? '!text-primary bg-neutral-100' : '!text-neutral-800',
                        )
                      }
                    >
                      <span className="flex">
                        <Icon className="w-lg h-lg mr-xxs shrink-0" />
                        <span
                          className={classNames(
                            'hidden whitespace-nowrap',
                            showMobileSidebar && '!block',
                          )}
                        >
                          {label}
                        </span>
                      </span>
                      <ChevronRight className="w-xmd h-xmd" />
                    </NavLink>
                  ))}
                </div>
              </div>
              <button
                type="button"
                onClick={logout}
                className="rounded-sm text-neutral-800 text-b1 font-bold p-sm flex no-wrap overflow-hidden bg-transparent"
              >
                <LogoutTool className="w-lg h-lg mr-xxs shrink-0" />
                <span
                  className={classNames('hidden whitespace-nowrap', showMobileSidebar && '!block')}
                >
                  Déconnexion
                </span>
              </button>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
