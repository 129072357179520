import { ChaleurLead } from '@travauxlib/shared/src/types/api/domain/client/ChaleurLead';
import { LostReason } from '@travauxlib/shared/src/types/api/domain/client/LostReason';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';
import { ProCompanyStatus } from '@travauxlib/shared/src/types/api/domain/procompany/ProCompanyStatus';

export { certifications } from '@travauxlib/shared/src/utils/constants';

export const specializationsArchitects = [
  'Aménagement extérieur',
  'Bureaux',
  'Changement de destination',
  'Commerce',
  'Construction',
  'Décoration',
  'Design de mobilier sur mesure',
  'Étude technique',
  'Extension',
  'Hôpital / cabinet médical',
  'Hôtels',
  'Immeuble',
  'Lumière - éclairage',
  'Petits espaces',
  'Rénovation appartement grande surface',
  'Rénovation appartement moyenne surface',
  'Rénovation appartement petite surface',
  'Rénovation maison',
  'Rénovation touchant à la structure',
  'Restaurants',
  'Scénographie',
  'Store / retail',
  'Surélévation',
  'Théâtre',
];

export const travailEnInterne = [
  'Couverture',
  'Charpente',
  'Climatisation',
  'Isolation intérieure',
  'Isolation extérieure',
  'Maçonnerie',
  'Menuiseries intérieures',
  'Menuiseries extérieures',
  'Meubles sur mesure',
  'Ouvertures de murs porteurs & trémies',
  'Ossature bois',
  'Peinture',
  'Plâtrerie',
  'Plomberie',
  'Serrurerie/Métallerie',
  'Sols durs - Carrelage',
  'Sols souples - Parquet',
  'Vitrerie/Miroiterie',
  'Électricité',
].map(value => ({ value, label: value }));

export const assurancesArchitecte = ['Suivi de chantier', "Maitrise d'œuvre +150 m²"];

export const lostReasonsClubPro = [
  {
    label: 'Hors zone',
    value: 'Hors zone géographique',
  },
  {
    label: 'Trop peu de corps de métiers',
    value: 'Trop peu de corps de métiers',
  },
  {
    label: 'Moins de 2 ans',
    value: 'Moins de 2 ans',
  },
  {
    label: 'Code Naf incohérent',
    value: 'Code Naf incohérent',
  },
  {
    label: 'CA insuffisant',
    value: 'CA insuffisant',
  },
  {
    label: 'Juste logiciel de devis',
    value: 'Juste logiciel de devis',
  },
  {
    label: 'Fail Corpo',
    value: 'Fail Corpo',
  },
  {
    label: "C'était un test",
    value: "C'était un test",
  },
];

export const labellisationStatusesById: {
  [key in ProCompanyStatus]: {
    label: string;
    optionLabel: string;
  };
} = {
  ACTIVEE: {
    label: 'Activées',
    optionLabel: 'Activée',
  },
  LABELLISEE: {
    label: 'Labellisées',
    optionLabel: 'Labellisée',
  },
  LABELLISATION_EN_COURS: {
    label: 'En cours de labellisation',
    optionLabel: 'En cours de labellisation',
  },
  NON_LABELLISEE: {
    label: 'Non labellisées',
    optionLabel: 'Non labellisée',
  },
  DELABELLISEE: {
    label: 'Délabellisées',
    optionLabel: 'Délabellisée',
  },
};

export const companyTypes: Array<{
  value: CompanyType;
  label: string;
}> = [
  {
    value: CompanyType.Archi,
    label: 'Architecte',
  },
  {
    value: CompanyType.Pro,
    label: 'Pro',
  },
  {
    value: CompanyType.Mandataire,
    label: 'CDPL',
  },
];

export const architecteProfile = [
  'Calme / Patient',
  'Commercial',
  'Ecoute et dit oui',
  'Force de proposition',
  'Rassurant / Pédagogue',
  'Technique',
];

export const profile = [...architecteProfile, 'Habitué à travailler avec un archi'];

export const additionalInformations = [
  'Amiante',
  'Boutiques',
  'B2B',
  'B2B Uniquement',
  'Bureaux',
  'Hôtels',
  'Plan 3D',
  'Restaurants',
  'Showroom',
  'Syndic',
];

export const insurancesType = ['Décennale + RC pro', 'Décennale', 'RC pro', 'Autre'].map(item => ({
  label: item,
  value: item,
}));

type LostReasonOption = {
  value: LostReason;
  label: string;
  disabled?: boolean;
};

export const nonAdressableReasons: Array<LostReasonOption> = [
  {
    value: LostReason.Doublon,
    label: LostReason.Doublon,
  },
  {
    value: LostReason.TestEstimateur,
    label: LostReason.TestEstimateur,
  },
  {
    value: LostReason.HorsZone,
    label: 'Hors zone',
  },
  {
    value: LostReason.TropPetitsTravaux,
    label: 'Trop petits travaux',
  },
  {
    value: LostReason.NousNeSavonsPasFaire,
    label: 'Nous ne savons pas faire',
  },
  {
    value: LostReason.BudgetInadapte,
    label: 'Budget inadapté',
  },
  {
    value: LostReason.LeadNonQualifie,
    label: 'Lead non qualifié',
  },
  {
    value: LostReason.ScoreTropBas,
    label: 'Score trop bas',
    disabled: true,
  },
  {
    value: LostReason.ScoreIncompatible,
    label: 'Score incompatible',
    disabled: true,
  },
];

export const lostReasonsMarketPlace: Array<LostReasonOption> = [
  {
    value: LostReason.AbandonPasDeReponse,
    label: "Abandon pas de réponse - Jamais réussi à l'avoir",
  },
  {
    value: LostReason.PlusDeReponse,
    label: 'Plus de réponse du client',
  },
  {
    value: LostReason.Abandon,
    label: 'Projet abandonné',
  },
  {
    value: LostReason.ConseilAvantProjet,
    label: 'Conseils avant projet',
  },
  {
    value: LostReason.ATrouveAutreEntreprise,
    label: 'A trouvé une autre entreprise',
  },
  {
    value: LostReason.MauvaisDestinataire,
    label: 'Mauvais destinaire / Faux numéro',
  },
  {
    value: LostReason.JusteSavoirLePrix,
    label: 'Juste savoir le prix / demande de devis uniquement',
  },
  {
    value: LostReason.NePasRecontacter,
    label: 'Ne pas recontacter',
  },
  {
    value: LostReason.BesoinDeco,
    label: 'Besoin Déco / Archi uniquement',
  },
  {
    value: LostReason.IncompatibleAccompagnement,
    label: 'Incompatible avec notre accompagnement',
  },
  {
    value: LostReason.PasDentrepriseDispo,
    label: 'Pas d’entreprise pour cette zone / ce type de travaux',
  },
  {
    value: LostReason.ATrouveAutreArchi,
    label: 'A trouvé un autre archi',
  },
  {
    value: LostReason.ProblemePRO,
    label: 'Problème PRO (feeling / réactivité / prestation)',
  },
  ...nonAdressableReasons.filter(na => !na.disabled),
];

export const chaleursLead: ChaleurLead[] = [ChaleurLead.Calendly, ChaleurLead.ContactTente];

export const noDealOwner = 'no_deal_owner';

export const pauseReasonLabels: { [K: string]: string } = {
  max_volume_mer: '[auto] Max volume consultation atteint',
  max_volume_signature: '[auto] Max volume Signature atteint',
  max_count_mers: '[auto] Max nombre de consultation atteint',
  max_chantiers: '[auto] Max nombre de chantiers en cours',
  max_count_mers_pro_labellise: '[auto] Max nombre de consultation pro labellisé atteint',
  waiting_for_end_of_two_first_chantiers: '[auto] En attente fin de ses 2 premiers chantiers',
  pro_overwhelmed: 'Pro débordé',
  too_many_projects: 'A reçu trop de projets',
  messed_up_chantier: 'Complication sur chantier',
  holidays: 'Vacances',
  complications_before_chantier: 'Complications avant chantier',
};

export const planTypeOptions = [
  {
    label: 'Aucun',
    value: undefined,
  },
  {
    label: 'Legacy Essentiel',
    value: 'legacy_essentiel',
  },
  {
    label: 'Legacy Plus',
    value: 'legacy_plus',
  },
  {
    label: 'Essentiel',
    value: 'essentiel',
  },
  {
    label: 'Pro',
    value: 'pro',
  },
];

export const pauseReasons = Object.entries(pauseReasonLabels)
  .map(([value, label]) => ({ value, label, disabled: label.includes('[auto]') }))
  .sort((a, b) => (a.label < b.label ? -1 : 1));

export const affiliateFilters = {
  allLeads: {
    label: 'Tous les leads',
    value: 'allLeads',
  },
  partenariats: {
    label: 'Partners offline',
    value: 'partenariatsOffline',
  },
  horsPartenariats: {
    label: 'Hors partners offline',
    value: 'horsPartenariatsOffline',
  },
};
