import React from 'react';

import { v4 as uuidV4 } from 'uuid';

import { useFileDropzone } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/hook';
import { DownloadableFile, UploadFilesPayload } from '@travauxlib/shared/src/types';

export type Props = {
  value: DownloadableFile[];
  onChange: (files: DownloadableFile[]) => void;
  onRemoveFile?: (file: DownloadableFile) => void;
  onClickFile?: (file: DownloadableFile) => void;
  uploadFiles: (props: UploadFilesPayload) => Promise<DownloadableFile[]>;
  multiple?: boolean;
  small?: boolean;
  error?: string;
  onlyPreview?: boolean;
  displayMode?: 'standard' | 'pictures-wall';
  disabled?: boolean;
  activeFileUuid?: string;
};

export const FileDropzone: React.FC<Props> = ({
  value,
  onChange,
  uploadFiles,
  multiple,
  error,
  ...rest
}) => {
  const [FileDropzone, onUpdate, onSuccess, onError] = useFileDropzone();
  const startUpload = async (files: File[]): Promise<{ files: DownloadableFile[] }> => {
    const filesWithMetadata = files.map(file => ({
      uuid: uuidV4(),
      name: file.name,
      type: file.type,
      body: file,
    }));

    const result = await uploadFiles({
      files: filesWithMetadata,
      onUpdate,
      onSuccess,
      onError,
    });
    const filesResult = result?.concat(value || []);

    onChange(filesResult);

    return {
      files: filesResult,
    };
  };
  return (
    <FileDropzone
      handleUploadFile={startUpload}
      multiple={multiple}
      files={value || []}
      error={error}
      {...rest}
    />
  );
};
