import React, { useMemo, useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { SearchBar } from '@travauxlib/shared/src/components/DesignSystem/components/SearchBar';

import { Consultation, ConsultationInfo } from 'types';

import { getDisabledReasons } from './ConsultationActions';
import { ManualModeModal } from './ManualModeModal';
import { ProCompanyRow } from './ProCompanyRow';

import { useProCompaniesForConsultation } from '../../api/useProCompaniesForConsultation';
import { useSearchConsultationCompanies } from '../../hooks/useSearchConsultationCompanies';
import { useOpenConsultationModal } from '../ConsultationModal';

type Props = {
  consultationInfo: ConsultationInfo;
  consultations: Consultation[];
  setManualMode: (manualMode: boolean) => void;
};

export const ConsultationSemiAuto: React.FC<Props> = ({
  consultationInfo,
  consultations,
  setManualMode,
}) => {
  const { proCompanies, nbResults, isFetching } = useProCompaniesForConsultation(
    consultationInfo.id!,
  );
  const openConsultationModal = useOpenConsultationModal();
  const [proCompaniesForConsultation, setProCompaniesForConsultation] = useState<number[]>([]);
  const [showManualModeModal, setShowManualModeModal] = useState(false);
  const [displayedCompaniesOffset, setDisplayedCompaniesOffset] = useState(10);
  const [displayPausedCompanies, setDisplayPausedCompanies] = useState(true);
  const [search, setSearch] = useState('');
  const offsetedCompanies = useMemo(() => {
    const displayedCompanies = displayPausedCompanies
      ? proCompanies
      : proCompanies.filter(company => !company.pauseReason);
    if (displayedCompaniesOffset <= nbResults) {
      return displayedCompanies.slice(0, displayedCompaniesOffset);
    } else {
      return displayedCompanies.slice(0, nbResults);
    }
  }, [displayedCompaniesOffset, displayPausedCompanies, proCompanies]);

  const searchResults = useSearchConsultationCompanies(proCompanies, search);

  const filteredCompanies = search !== '' ? searchResults : offsetedCompanies;

  if (isFetching) {
    return <Loader />;
  }

  const allDisabledReasons: { [K in string]: string[] } = proCompanies.reduce(
    (allDisabledReasons, proCompany) => {
      const disabledReasons = getDisabledReasons({
        proCompany,
        consultations,
      });

      return { ...allDisabledReasons, [proCompany.uuid]: disabledReasons };
    },
    {},
  );
  const companyUuidsNotDisabled = Object.entries(allDisabledReasons)
    .filter(([, disabledReasons]) => disabledReasons.length === 0)
    .map(([uuid]) => uuid);

  const maxSelectedProcompanies = Math.min(companyUuidsNotDisabled.length, 2);
  const minSelectedProcompanies = consultations.length > 0 ? 1 : maxSelectedProcompanies;

  const hasSelectedEnoughCompanies =
    proCompaniesForConsultation.length >= minSelectedProcompanies &&
    proCompaniesForConsultation.length <= maxSelectedProcompanies;

  return (
    <>
      <ManualModeModal
        isOpen={showManualModeModal}
        onSubmit={() => {
          setShowManualModeModal(false);
          setManualMode(true);
        }}
        onClose={() => setShowManualModeModal(false)}
      />
      <div className="flex items-center gap-md">
        <SearchBar value={search} onChange={setSearch} className="my-md max-w-1/2" />
        <Checkbox
          label="Afficher les entreprises en pause"
          checked={displayPausedCompanies}
          onChange={setDisplayPausedCompanies}
        />
      </div>
      <div className="mb-xl">
        <div className="text-center mb-md">
          {filteredCompanies.length} résultats parmi {nbResults} correspondant aux critères de
          recherche
        </div>

        {proCompanies.length === 0 && (
          <Card className="flex flex-col justify-center items-center text-center pt-xl p-lg h-full">
            <h3 className="m-0">
              Aucune entreprise de notre réseau n'est disponible pour réaliser ces travaux... <br />
            </h3>
            <div className="text-1 m-0">😞</div>
          </Card>
        )}
        {filteredCompanies.map((proCompany, index) => (
          <ProCompanyRow
            consultationInfo={consultationInfo}
            key={proCompany.uuid}
            proCompany={proCompany}
            rank={index + 1}
            proCompaniesForConsultation={proCompaniesForConsultation}
            setProCompaniesForConsultation={setProCompaniesForConsultation}
            isLastRow={index === proCompanies.length - 1}
            disabledReasons={allDisabledReasons[proCompany.uuid]}
          />
        ))}
      </div>
      <div className="text-center mb-md">
        <Button
          variant="tertiary"
          type="button"
          disabled={displayedCompaniesOffset >= nbResults}
          onClick={() =>
            setDisplayedCompaniesOffset(displayedCompaniesOffset => displayedCompaniesOffset + 10)
          }
        >
          Afficher les 10 entreprises suivantes
        </Button>
      </div>
      <div className="text-center mb-md">
        <Button
          variant="tertiary"
          type="button"
          onClick={() => setShowManualModeModal(true)}
          size="sm"
        >
          Ces résultats ne conviennent pas...
        </Button>
      </div>
      <Card className="text-center py-md -mx-xl sticky bottom-0 z-20">
        <Button
          type="button"
          onClick={() =>
            openConsultationModal({
              selectedProCompanies: proCompanies.filter(proCompany =>
                proCompaniesForConsultation.includes(proCompany.id),
              ),
              consultationInfo,
              proCompanies,
              companyUuidsNotDisabled,
            })
          }
          disabled={!hasSelectedEnoughCompanies}
          disabledMessageTooltip="La quantité d'entreprise selectionnée n'est pas valide"
        >
          Choisir ces entreprises ({proCompaniesForConsultation.length}/{minSelectedProcompanies})
        </Button>
      </Card>
    </>
  );
};
