import React from 'react';

import AttachBreak from '@travauxlib/shared/src/components/DesignSystem/assets/AttachBreakFilled.svg?react';
import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import Swap from '@travauxlib/shared/src/components/DesignSystem/assets/SwapFilled.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ClientAccount } from '@travauxlib/shared/src/types';

import { getProjectURL, useSignInAsClient } from 'api/useSignInAsClient';
import { useUnlinkClientAccount } from 'features/Project/api/useLinkClientAccount';
import { ProjectContext } from 'features/Project/api/useProjectContext';

import { useOpenCreateOrReplaceClientAccountModal } from '../hooks/useOpenCreateOrReplaceClientAccountModal';
import { useOpenEditClientAccountModal } from '../hooks/useOpenEditClientAccountModal';

type Props = {
  clientAccount: ClientAccount;
  projectContext: ProjectContext;
  removeAccountDisabled: boolean;
};

export const ClientAccountCard: React.FC<Props> = ({
  clientAccount,
  projectContext,
  removeAccountDisabled,
}) => {
  const signInAsClient = useSignInAsClient();
  const openEditClientAccountModal = useOpenEditClientAccountModal();
  const openCreateOrReplaceClientAccountModal = useOpenCreateOrReplaceClientAccountModal();
  const unlinkClientAccount = useUnlinkClientAccount();
  const {
    project: { uuid: projectUuid },
  } = projectContext;

  const dotsMenuItems: ActionListItem[] = [
    {
      type: 'action',
      label: 'Remplacer le compte client',
      icon: <Swap />,
      onClick: () => openCreateOrReplaceClientAccountModal(projectContext, clientAccount),
    },
    {
      type: 'action',
      label: 'Modifier les informations',
      icon: <Edit />,
      onClick: () => openEditClientAccountModal(projectUuid, clientAccount),
    },
    {
      type: 'action',
      label: 'Retirer le compte',
      icon: <AttachBreak />,
      disabled: removeAccountDisabled,
      onClick: () =>
        unlinkClientAccount({
          projectUuid,
          clientAccountUuid: clientAccount.uuid,
        }),
    },
  ];

  return (
    <Card state="outlined" bodyClassNames="pt-sm pl-sm pr-xs pb-md flex flex-col">
      <div className="flex justify-between mb-xxs">
        <div className="text-b1 text-neutral-800 font-bold flex items-center gap-xs">
          <Person className="w-lg h-lg" />
          <div>
            {clientAccount.firstName} {clientAccount.lastName}
          </div>
        </div>
        <ActionList
          items={dotsMenuItems}
          small
          trigger={(toggle, referenceProps) => (
            <IconButton onClick={toggle} {...referenceProps}>
              <DotsMenu />
            </IconButton>
          )}
        />
      </div>
      <div className="flex flex-col ml-xl gap-xxs">
        <div className="text-b2 text-neutral-700">
          <Envelope className="w-xmd h-xmd" /> {clientAccount.email}
        </div>
        {clientAccount.phoneNumber && (
          <div className="text-b2 text-neutral-700">
            <Phone className="w-xmd h-xmd" /> {clientAccount.phoneNumber}
          </div>
        )}
        <Link
          icon={<OpenTab />}
          onClick={() => signInAsClient(clientAccount.uuid, getProjectURL(projectUuid))}
        >
          Se connecter au compte
        </Link>
      </div>
    </Card>
  );
};
