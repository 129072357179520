import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';

import { poseChauffeEauThermodynamiqueId } from './ids';

export const poseChauffeEauThermodynamique = ({
  configuration,
  locationUuid,
  hasRaccordsPlomberie = true,
  isOption,
}: {
  configuration: Configuration;
  locationUuid: string;
  hasRaccordsPlomberie?: boolean;
  isOption?: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel: 'Chauffe-eau thermodynamique',
    id: poseChauffeEauThermodynamiqueId(configuration),
    quantity: 1,
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Chauffe-eau thermodynamique',
    id: 'sortie_de_cable',
    quantity: 1,
    tagQuantity: 'only',
    isOption,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  if (hasRaccordsPlomberie) {
    result.push({
      ouvrageLabel: 'Chauffe-eau thermodynamique',
      id: 'ec_ef_evacuation_element_cuivre',
      quantity: 1,
      isOption,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  return result;
};
