import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { FournitureReference } from '@travauxlib/shared/src/types';

import { ApercuReference } from '../ApercuReference';

export type Props = {
  reference: FournitureReference;
  ctaText?: string;
  isLoading?: boolean;
};
export const ApercuReferenceCard: React.FC<Props> = ({ reference, ctaText, isLoading }) => (
  <Card role="section" aria-label="Apercu Card">
    <ApercuReference reference={reference} ctaText={ctaText} isLoading={isLoading} />
  </Card>
);
