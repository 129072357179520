import React from 'react';

import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { TypeBien } from '@travauxlib/shared/src/types';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  EauChaude as EauChaudeType,
  Plomberie,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle/';

import { Aucune } from './Aucune';
import { Electrique } from './Electrique';
import { Fioul } from './Fioul';
import { Gaz } from './Gaz';

import { getAlertForTypeEnergieEauChaude } from '../../utils/getAlertForTypeEnergie';

type Props = {
  configuration: Configuration;
};

const typeEnergieAppartement = [
  {
    label: 'Électrique',
    value: 'Électrique',
  },
  {
    label: 'Gaz (intégrée au chauffage)',
    value: 'Gaz',
  },
  {
    label: 'Collectif',
    value: 'Collectif',
  },
  {
    label: "Pas d'eau Chaude existante",
    value: 'Aucune',
  },
  {
    label: 'Ne sait pas',
    value: 'Ne sait pas',
  },
];

const typeEnergieMaison = [
  {
    label: 'Électrique',
    value: 'Électrique',
  },
  {
    label: 'Gaz (intégrée au chauffage)',
    value: 'Gaz',
  },
  {
    label: 'Fioul (intégrée au chauffage)',
    value: 'Fioul',
  },
  {
    label: "Pas d'eau Chaude existante",
    value: 'Aucune',
  },
  {
    label: 'Ne sait pas',
    value: 'Ne sait pas',
  },
];

export const EauChaude: React.FC<Props> = ({ configuration }) => {
  const { values } = useFormState<Plomberie>();
  const isMaison = configuration.typeLogement !== TypeBien.Appartement;

  return (
    <Accordion
      className="mb-md"
      title="Eau chaude à modifier ou remplacer ?"
      prefix="2"
      defaultExpanded
    >
      <FieldArray<EauChaudeType> name="eauChaudes">
        {({ fields }) => (
          <div>
            {fields.map((name, index) => {
              const value = fields.value[index];
              return (
                <div key={name}>
                  <div className="flex justify-between">
                    <QuestionTitle
                      alert={getAlertForTypeEnergieEauChaude(value, values)}
                      prefix="1"
                      title="Énergie utilisée pour l'eau chaude ?"
                      tooltip={
                        isMaison
                          ? undefined
                          : "Si l'eau chaude est en collectif, on peut l'indiquer pour information mais il n'est pas possible de la modifier."
                      }
                    />
                    <PrestationDelete onDelete={() => fields.remove(index)} />
                  </div>
                  <OptionCheckbox fields={fields} value={value} index={index} />
                  <ChipListField
                    name={`${name}.typeEnergie`}
                    renderingOptions={{
                      containerClassName: 'mb-md flex gap-md flex-wrap',
                    }}
                    options={isMaison ? typeEnergieMaison : typeEnergieAppartement}
                  />

                  {value?.typeEnergie === 'Gaz' && (
                    <Gaz configuration={configuration} name={name} />
                  )}
                  {value?.typeEnergie === 'Électrique' && (
                    <Electrique configuration={configuration} eauChaude={value} name={name} />
                  )}
                  {value?.typeEnergie === 'Fioul' && (
                    <Fioul configuration={configuration} name={name} eauChaude={value} />
                  )}
                  {value?.typeEnergie === 'Aucune' && (
                    <Aucune configuration={configuration} eauChaude={value} name={name} />
                  )}
                  <PrestationsSeparator />
                </div>
              );
            })}
            <AddPrestationButton fields={fields} />
          </div>
        )}
      </FieldArray>
    </Accordion>
  );
};
