import React from 'react';

import { Outlet } from 'react-router';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { ProCompanyProfileAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyProfileAdminView';

import { CompanyHeader } from './CompanyHeader';
import { Payment } from './Payment';
import { SiteVitrine } from './SiteVitrine';

type Props = {
  proCompany: ProCompanyProfileAdminView;
};

export const EditEntreprise: React.FC<Props> = ({ proCompany }) => {
  const tabs = [
    {
      label: 'Infos générales',
      to: 'infos',
    },
    {
      label: 'Paramètres',
      to: 'settings',
    },
    {
      label: 'Assurances',
      to: 'insurances',
    },
    {
      label: 'Consultations',
      to: 'consultations',
    },
    {
      label: 'Devis',
      to: 'devis',
    },
    {
      label: 'Club pro',
      to: 'club-pro',
    },
    {
      label: 'Site vitrine',
      to: 'site',
      Component: SiteVitrine,
    },
    {
      label: 'Paiement',
      to: 'payment',
      Component: Payment,
    },
    {
      label: 'Utilisateurs',
      to: 'users',
    },
  ];

  return (
    <>
      <div className="mb-lg">
        <CompanyHeader proCompany={proCompany.company} />
      </div>
      <div className="mb-md">
        <Tabs buttonClassNames="bg-lighter" items={tabs} />
      </div>
      <div className="mb-md">
        <Outlet context={{ proCompany }} />
      </div>
    </>
  );
};
