import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import _keyBy from 'lodash/keyBy';

import { BaseDePrixLigne } from '@travauxlib/shared/src/types';
import { BaseDePrix } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { request } from '@travauxlib/shared/src/utils/request';

const BASE_DE_PRIX_KEY = 'base-de-prix';

export const useBaseDePrix = (): { baseDePrix: BaseDePrix } => {
  const { data } = useQuery<{ data: BaseDePrixLigne[] }>({
    queryKey: [BASE_DE_PRIX_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/base-de-prix`),
    staleTime: Infinity,
  });

  const baseDePrixItems = data?.data || [];
  const baseDePrix = useMemo(
    () => _keyBy(baseDePrixItems, item => item.normalizedIdentifier),
    [baseDePrixItems],
  ) as BaseDePrix;

  return {
    baseDePrix,
  };
};
