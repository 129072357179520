export enum AppelDeProvisionStatus {
  Pending = 'pending',
  Active = 'active',
  Deleted = 'deleted',
  Obsolete = 'obsolete',
}

export enum AppelDeProvisionType {
  Legacy = 'legacy',
  Pro = 'pro',
  ArchiInterne = 'archi_interne',
  ArchiBet = 'archi_bet',
  TravauxPlanner = 'travaux_planner',
  ServiceHemea = 'service_hemea',
}
