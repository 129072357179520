import React from 'react';

import classNames from 'classnames';

import { InputErrorText } from '@travauxlib/shared/src/components/DesignSystem/components/Input/commons';

import { FormControlListProps } from '../FormControl/commons';
import { RadioButton } from '../RadioButton';

export type Props<T> = FormControlListProps<T> & { label?: string };

export function RadioButtonsList<T>({
  id,
  options,
  disabled,
  onChange,
  value,
  inline,
  className,
  label,
  optionsClassName,
  error,
}: Props<T>): React.ReactElement {
  return (
    <>
      {label && (
        <label htmlFor={id} className="font-bold mb-xs">
          {label}
        </label>
      )}
      <div
        className={classNames(
          'flex',
          inline ? 'gap-xs items-center' : 'flex-col gap-md',
          className,
        )}
        id={id}
      >
        {options.map(option => {
          const idComputed = id && `${id || ''}_${option.value || ''}`;
          return (
            <div className={optionsClassName} key={`${option.value}${option.label}`}>
              <RadioButton
                onChange={() => onChange(option.value)}
                checked={option.value === value}
                id={idComputed}
                data-testid={idComputed}
                value={option.value}
                label={option.label}
                disabled={disabled || option.disabled}
              />
            </div>
          );
        })}
      </div>
      {error && <InputErrorText error={error} />}
    </>
  );
}
