import { useQuery } from '@tanstack/react-query';

import { FournituresWithLocation } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const FOURNITURES_KEY = 'fournitures';

export const buildUseFournitures =
  (apiPath: string) => (): { isLoading: boolean; fournitures: FournituresWithLocation[] } => {
    const { data, isLoading } = useQuery<{ data: FournituresWithLocation[] }>({
      queryKey: [FOURNITURES_KEY],
      queryFn: () => request(`${apiPath}/fournitures`),
    });

    return { isLoading, fournitures: data?.data || [] };
  };
