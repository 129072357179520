import React from 'react';

import { Field } from 'react-final-form';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { UploadField } from '@travauxlib/shared/src/components/UploadField';
import { parseBic, validateBic, validateIban } from '@travauxlib/shared/src/utils/form';

import { useDeleteDocumentCdpl } from 'features/Trombinoscope/api/useDeleteDocumentCdpl';
import { useUploadDocumentCdpl } from 'features/Trombinoscope/api/useUploadDocumentCdpl';

type Props = {
  personUuid: string;
};

export const FacturationCDPL: React.FC<Props> = ({ personUuid }) => {
  const uploadDocument = useUploadDocumentCdpl();
  const deleteDocument = useDeleteDocumentCdpl();

  return (
    <div className="mb-sm">
      <CheckboxField
        name="cdplInformation.asujettiTVA"
        id="asujettiTVA"
        label="Asujetti à la TVA"
        initialValue={false}
      />
      <Card className="my-md">
        <h4 className="font-bold">RIB</h4>
        <Field name="cdplInformation.ribUrl">
          {({ input }) => (
            <UploadField
              label="Ajouter votre RIB pour qu'il soit joint aux mails envoyés à la comptabilité"
              id="cdplInformation.ribUrl"
              documentType="rib"
              value={input.value}
              uploadAction={async ({ file, documentType }) => {
                const { url } = await uploadDocument({ file, documentType, personUuid });
                input.onChange(url);
              }}
              deleteAction={async ({ documentType }) => {
                await deleteDocument({ documentType, personUuid });
                input.onChange('');
              }}
            />
          )}
        </Field>
      </Card>
      <Card className="mb-lg">
        <InputField
          className="mb-md w-full"
          name="cdplInformation.bankAccountOwner"
          label="Titulaire de compte"
          id="bank-account-owner-input"
        />
        <div className="flex sm-desktop:flex-row flex-col gap-md">
          <InputField
            className="w-full"
            name="cdplInformation.iban"
            validate={validateIban}
            label="IBAN"
            id="iban-input"
          />
          <InputField
            className="w-full"
            name="cdplInformation.bic"
            validate={validateBic}
            parse={parseBic}
            label="Code BIC/SWIFT"
            id="bic-input"
          />
        </div>
      </Card>
    </div>
  );
};
