import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { ChantierAdminView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { chantier: ChantierAdminListItemView; detailledChantier: ChantierAdminView };

export class ChantierEncaisserPrestationHemea extends BaseStep<
  Input,
  void,
  StepField[],
  { chantier: ChantierAdminListItemView; detailledChantier: ChantierAdminView }
> {
  public name = 'Chantier Encaisser Prestation Hemea';

  public fields = [];

  async execute({
    apiURL,
    chantier,
    detailledChantier,
    request,
  }: ExecuteParams<Input>): Promise<void> {
    await request(`${apiURL}/admin/chantiers/${chantier.slug}/encaisser-prestation-interne`, {
      method: 'POST',
      body: {
        typePrestation: 'service_hemea',
        montantAEncaisser: detailledChantier.montantServiceHemeaTTC,
      },
    });

    super.setCreatedEntities({
      chantier: chantier,
      detailledChantier: detailledChantier,
    });

    super.log('OK');
  }
}
