import React from 'react';

import classNames from 'classnames';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';

import { TagSize, Variant } from './types';
import { makeIconContainerClassName, makeTagContainerClassName, sizeForIconButton } from './utils';

import { IconButton } from '../Buttons/IconButton';

export type { Variant } from './types';

export type Props = {
  label?: string | React.ReactElement;
  variant: Variant;
  size?: TagSize;
  icon?: React.ReactElement;
  onClose?: () => void;
  onClick?: () => void;
  rightIcon?: React.ReactElement;
  className?: string;
  isRightIconActive?: boolean;
  isBorder?: boolean;
};

export const Tag: React.FC<Props> = ({
  variant,
  size = 'md',
  label,
  icon,
  rightIcon,
  onClose = undefined,
  className,
  onClick,
  isRightIconActive,
  isBorder,
}) => (
  <span
    onClick={onClick}
    className={makeTagContainerClassName({
      variant,
      size,
      hasIcon: !!icon,
      hasClose: !!onClose,
      className,
      rightIcon: !!rightIcon,
      isBorder,
      hasLabel: !!label,
    })}
    role={onClick ? 'button' : 'contentinfo'}
    aria-label={typeof label === 'string' ? label : undefined}
  >
    {icon && (
      <span className={classNames(makeIconContainerClassName({ size }), label && 'mr-xxs')}>
        {icon}
      </span>
    )}
    {label}
    {rightIcon && (
      <span className={classNames(makeIconContainerClassName({ size }), 'ml-xxs')}>
        <IconButton
          variant={variant === 'light' ? 'white' : variant}
          size={sizeForIconButton[size]}
          isActive={isRightIconActive}
        >
          {rightIcon}
        </IconButton>
      </span>
    )}
    {onClose && (
      <span
        onClick={onClose}
        className={classNames(makeIconContainerClassName({ size }), 'ml-xxs')}
      >
        <IconButton
          variant={variant === 'light' ? 'white' : variant}
          size={sizeForIconButton[size]}
        >
          <CrossSymbol data-testid="close-icon" />
        </IconButton>
      </span>
    )}
  </span>
);
