import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { GenerationData } from '@travauxlib/shared/src/types/api/domain/configurateur';

export const poseSimpleVasque = ({
  locationUuid,
  hasRaccordsPlomberie,
  isOption,
  gamme,
}: {
  locationUuid: string;
  hasRaccordsPlomberie: boolean;
  isOption?: boolean;
  gamme: Gamme;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel: 'Meuble simple vasque',
    tagQuantity: 'only',
    id: 'meuble_sdb_simple_vasque',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Meuble simple vasque',
    id: 'mitigeur_lavabo',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Meuble simple vasque',
    id: gamme === Gamme.primo ? 'miroir_salle_de_bain' : 'miroir_salle_de_bain_lumineux',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  if (hasRaccordsPlomberie) {
    result.push({
      ouvrageLabel: 'Meuble simple vasque',
      id: 'ec_ef_evacuation_element_cuivre',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  return result;
};
