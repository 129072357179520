import React from 'react';

import _orderBy from 'lodash/orderBy';

import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Fourniture, FournituresWithLocation } from '@travauxlib/shared/src/types';
import { addPlurielToWords } from '@travauxlib/shared/src/utils/wording';

import { FournitureCard } from './FournitureCard';

import { EditAddReferenceActionProps, UserType } from '../types';

export type Props = {
  fournitures: FournituresWithLocation[];
  editOrAddReferenceAction?: EditAddReferenceActionProps;
  userType: UserType;
};

export const FournitureCardsByLocation: React.FC<Props> = ({
  fournitures,
  editOrAddReferenceAction,
  userType,
}) =>
  fournitures.map(({ locationName, locationType, fournitures }) => {
    const Picto = locationsPicto[locationType];
    const [fourniture] = addPlurielToWords(fournitures, ['fourniture']);

    return (
      <div
        key={locationName}
        className="flex-1 grow mb-md last:mb-xxl last:sm-desktop:mb-0"
        role="section"
        aria-label={locationName}
      >
        <div className="px-md mb-xs">
          <div className="flex items-center gap-xs">
            <Picto className="size-lg" />
            <h5
              className="text-h5 font-bold text-neutral-800 mb-0"
              id={encodeURIComponent(locationName)}
            >
              {locationName}
            </h5>
          </div>
          <div className="px-xl text-sm text-neutral-700">
            {fournitures.length} {fourniture}
          </div>
        </div>
        <div className="grid sm-desktop:grid-cols-2 lg-desktop:grid-cols-3 gap-md items-start">
          {_orderBy(fournitures, 'createdAt', 'desc').map((fourniture: Fourniture) => (
            <FournitureCard
              key={fourniture.uuid}
              fourniture={fourniture}
              editOrAddReferenceAction={editOrAddReferenceAction}
              userType={userType}
            />
          ))}
        </div>
      </div>
    );
  });
