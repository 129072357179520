import React from 'react';

import { Location } from 'react-router';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';

export const Header: React.FC = () => {
  const tabs = [
    {
      label: 'Interne',
      to: '/trombinoscope',
      isActive: (location: Location) => location.search === '',
    },
    {
      label: 'CDPL',
      to: '/trombinoscope?role=cdpl',
      isActive: (location: Location) => location.search === '?role=cdpl',
    },
  ];

  return (
    <div className="bg-neutral-0 pt-sm shadow-sm bg-neutral-0 sm-desktop:-mx-xl -mx-md -mt-md px-xl">
      <div className="container w-full sm-desktop:w-full">
        <h1 className="mb-md font-bold">Trombinoscope</h1>
        <div className="flex justify-around flex-wrap">
          <Tabs items={tabs} className="flex-1" hideContainerBottomBorder />
        </div>
      </div>
    </div>
  );
};
