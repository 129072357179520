import React from 'react';

import classNames from 'classnames';

import { LeadStatus } from '@travauxlib/shared/src/types/api/domain/client/LeadStatus';

import { Lead } from 'types';

import { LeadAction } from './components/LeadActions';
import { Contact } from './Contact';
import { Description } from './Description';
import { ExtraInformations } from './ExtraInformations';
import { Metadata } from './Metadata';
import { ScoreSignatureWithDetails } from './ScoreSignatureWithDetails';

type Props = {
  lead: Lead;
};

export const CDPDashboardLine: React.FC<Props> = ({ lead }) => {
  const { status: leadStatus } = lead;
  const isAdressable = leadStatus === LeadStatus.Adressable;
  const isNonAdressable = leadStatus === LeadStatus.NonAdressable;

  return (
    <tr
      data-testid="lead"
      className={classNames({
        'bg-error-50': isNonAdressable,
        'bg-success-100': isAdressable,
      })}
    >
      <td className="w-min-[20rem] w-max-[25rem]">
        <Contact lead={lead} />
        <LeadAction lead={lead} />
      </td>
      <td className="!whitespace-normal max-w-[60rem] overflow-auto">
        <Description lead={lead} />
      </td>
      <td className="!whitespace-normal max-w-[20rem]">
        <ExtraInformations lead={lead} />
      </td>
      <td className="!p-0 max-w-[30rem] overflow-auto">
        <Metadata metadata={lead.metadata} />
        {lead.scoreSignature && <ScoreSignatureWithDetails score={lead.scoreSignature} />}
      </td>
    </tr>
  );
};
