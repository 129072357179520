import React, { useState } from 'react';

import { saveAs } from 'file-saver';

import { downloadFile } from '@travauxlib/shared/src/api/useDownloadAsZip';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/DownloadFilled.svg?react';
import Spinner from '@travauxlib/shared/src/components/DesignSystem/assets/Spinner.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

type Props = {
  imgixUrl: string;
  downloadUrl: string;
  name: string;
  onClick?: () => void;
};

export const PhotoCard: React.FC<Props> = ({ imgixUrl, downloadUrl, name, onClick }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  return (
    <Card
      imageVariant="top-full"
      className="max-w-[11rem] h-[12.5rem] flex justify-between"
      image={{ url: imgixUrl, alt: name, onClick, className: 'h-[9.5rem] w-full' }}
      state="outlined"
      bodyClassNames="flex gap-xs items-center py-xs justify-end"
    >
      <span className="text-b2 truncate">{name}</span>
      <IconButton
        className="shrink-0"
        onClick={async () => {
          setIsDownloading(true);
          const downloadedFile = await downloadFile({ url: downloadUrl, index: 1 });
          setIsDownloading(false);

          return saveAs(downloadedFile.content, name);
        }}
        disabled={isDownloading}
      >
        {isDownloading ? (
          <Spinner className="animate-spin" data-testid="loading-icon" />
        ) : (
          <Download />
        )}
      </IconButton>
    </Card>
  );
};
