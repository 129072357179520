import { TypeLocation } from '@travauxlib/shared/src/types/api/common/TypeLocation';

export const rooms: Array<{
  typeLocation: TypeLocation;
  sublabel?: string;
}> = [
  {
    typeLocation: 'Entrée',
  },
  {
    typeLocation: 'Cuisine',
  },
  {
    typeLocation: 'Chambre',
  },
  {
    typeLocation: 'Dressing',
  },
  {
    typeLocation: 'Dégagement',
  },
  {
    typeLocation: 'Pièce principale',
    sublabel: '(Studio)',
  },
  {
    typeLocation: 'Salle à manger',
  },
  {
    typeLocation: "Salle d'eau",
  },
  {
    typeLocation: 'Salle de bain',
  },
  {
    typeLocation: 'Salon',
  },
  {
    typeLocation: 'WC',
  },
  {
    typeLocation: 'Autre',
  },
  {
    typeLocation: 'Bureau',
  },
];
