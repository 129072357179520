import React, { useState } from 'react';

import Search from '@travauxlib/shared/src/components/DesignSystem/assets/Search.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { requiredValidUrl } from '@travauxlib/shared/src/utils/form';

type Props = {
  onReferenceScrapped: (scrappedUrl: string) => void;
  isLoading: boolean;
  id?: string;
};

export const ScrappingForm: React.FC<Props> = ({
  onReferenceScrapped,
  isLoading,
  id = 'scrapping-url',
}) => {
  const [scrappedUrl, setScrappedUrl] = useState<string>('');

  return (
    <div className="flex items-start">
      <Input
        id={id}
        value={scrappedUrl}
        onChange={setScrappedUrl}
        className="grow"
        label="Lien de la référence"
        helperText="Vous pouvez pré-remplir les informations à l'aide d'un copier/coller du lien de la référence choisie."
        renderingOptions={{ containerClassName: 'rounded-r-none h-xxl' }}
        error={requiredValidUrl(scrappedUrl) && scrappedUrl ? 'Url invalide' : undefined}
      />
      <Button
        className="rounded-l-none pr-md"
        aria-label="Scrap link"
        rightIcon={<Search />}
        loading={isLoading}
        disabled={isLoading || !!requiredValidUrl(scrappedUrl)}
        onClick={() => onReferenceScrapped(scrappedUrl)}
      />
    </div>
  );
};
