import React from 'react';

import { useNavigate } from 'react-router';

import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';

export const EmptyConsultationTable: React.FC<{ consultationBaseUrl: string }> = ({
  consultationBaseUrl,
}) => {
  const navigate = useNavigate();

  return (
    <Table
      items={[{}]}
      columnConfigs={[
        {
          name: 'Nom du professionnel',
          hideSort: true,
          renderValue: () => (
            <span className="text-b2 text-neutral-600">Pas d'entreprise associée</span>
          ),
        },
        {
          name: 'Statut',
          hideSort: true,
          renderValue: () => <Tag variant="light" label="Brouillon" />,
        },
        {
          name: 'Envoyé le',
          hideSort: true,
          renderValue: () => <span className="text-b2 text-neutral-600">Pas encore envoyée</span>,
        },
        {
          name: 'Rendez-vous',
          hideSort: true,
        },
        {
          name: 'Feedback',
          hideSort: true,
        },
      ]}
      onRowClick={() => navigate(consultationBaseUrl)}
    />
  );
};
