import React from 'react';

import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import Settings from '@travauxlib/shared/src/components/DesignSystem/assets/Settings.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { ButtonLink, Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { LeadStatus } from '@travauxlib/shared/src/types/api/domain/client/LeadStatus';

import { useAuth } from 'api/useAuth';
import { useAttribuableEmployees } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { AffiliateFilter } from 'types';
import { affiliateFilters } from 'utils/constants';

import { DaySwitcher } from './DaySwitcher';
import { FormValues, List } from './List';
import { Search } from './Search';

export const LeadListing: React.FC = () => {
  const location = useLocation();
  const {
    adminUserProfile: { config, adminEmail },
  } = useAuth();
  const params = new URLSearchParams(location.search);

  const status = params.getAll('status') as LeadStatus[];
  const startDate =
    !params.get('startDate') && !status.includes(LeadStatus.ATraiter)
      ? dayjs().format('YYYY-MM-DD')
      : params.get('startDate') || undefined;
  const endDate = params.get('endDate') || dayjs().format('YYYY-MM-DD');

  const affiliateFilter: AffiliateFilter =
    (params.get('affiliateFilter') as AffiliateFilter) || affiliateFilters.allLeads.value;

  const isAvailable = !!config?.leadAssignment?.availability;
  const isAllowedToManageLeadAttributionRule = useIsAllowed('Lead', 'manageAttributionRules');
  const isAllowedToViewAllLeads = useIsAllowed('Lead', 'manage');
  const { persons: attribuableEmployees } = useAttribuableEmployees();

  return (
    <Form<FormValues> onSubmit={() => {}}>
      {({ values }) => (
        <>
          <div className="flex flex-wrap gap-xxs items-center mb-sm">
            {attribuableEmployees.find(person => person.email === adminEmail) && (
              <Avatar
                size="md"
                className={isAvailable ? '!bg-success-400' : '!bg-error-400'}
                title={
                  isAvailable
                    ? 'Disponible pour recevoir des leads'
                    : 'Non disponible pour recevoir des leads'
                }
              />
            )}
            <div className="text-h3">Liste des leads</div>
            <ButtonLink
              to="/dashboard-cdp/new-lead"
              leftIcon={<PlusSymbol />}
              size="sm"
              className="sm-desktop:ml-xs"
            >
              Créer un nouveau lead
            </ButtonLink>
            {isAllowedToManageLeadAttributionRule && (
              <Link
                size="lg"
                to="/dashboard-cdp/assignment-config/edit"
                className="ml-auto"
                icon={<Settings />}
              >
                Configuration de l'attribution auto
              </Link>
            )}
          </div>

          <DaySwitcher
            startDate={startDate}
            endDate={endDate}
            affiliateFilter={affiliateFilter}
            location={location as any}
          />
          <div className="!mb-sm">
            <Search displayDealOwnerDropdown={isAllowedToViewAllLeads} />
          </div>
          <List
            values={values}
            startDate={startDate}
            endDate={endDate}
            affiliateFilter={affiliateFilter}
            status={status}
          />
          <DaySwitcher
            bottomSwitcher
            startDate={startDate}
            endDate={endDate}
            affiliateFilter={affiliateFilter}
            location={location as any}
          />
        </>
      )}
    </Form>
  );
};
