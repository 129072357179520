import dayjs from 'dayjs';
import { FieldValidator } from 'final-form';
import IBAN from 'iban';
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom';
import { useFormState } from 'react-final-form';

import {
  GoogleMapsAddress,
  GoogleMapsAddressTypes,
} from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';

import { metadataFR } from './phone_metadata_fr';

export const requiredFieldErrorMessage = 'Champ obligatoire';

export const composeValidators =
  (...validators: FieldValidator<any>[]) =>
  (value: any): string | undefined =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const required = (value: any): undefined | string =>
  !value && value !== 0 && value !== false ? requiredFieldErrorMessage : undefined;

export const validateIban = (value: string): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return IBAN.isValid(value) ? undefined : 'Format invalide';
};

export const formatIban = (value?: string): string | undefined =>
  value ? IBAN.printFormat(value, ' ') : value;

export const parseIban = (value?: string): string | undefined =>
  value ? IBAN.electronicFormat(value) : value;

export const parseBic = (value: string): string =>
  value ? value.replace(/ /g, '').toUpperCase() : value;

// See https://gist.github.com/Fedik/f050c65fa6cc93973fc65df9d00357f5
export const validateBic = (value?: string): string | undefined => {
  if (value === undefined) {
    return undefined;
  }

  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(value.toUpperCase())
    ? undefined
    : 'Format invalide';
};

export const nonEmptyArrayRequired = (value: any): undefined | string =>
  !Array.isArray(value) || value.length === 0 ? requiredFieldErrorMessage : undefined;

export const validateInt = (value: any): undefined | string =>
  Number.isInteger(Number(value)) ? undefined : 'Nombre entier requis';

export const isValidNumber = (value: string): boolean =>
  isValidNumberCustom(value, 'FR', metadataFR);

export const validatePhoneNumber = (value: string | undefined): undefined | string => {
  if (value === undefined) {
    return undefined;
  }
  return isValidNumber(value) ? undefined : 'Numéro de téléphone invalide';
};

export const isValidSiret = (value: string): boolean => /^\d{14}$/.test(value);

export const validateSiret = (value: string | undefined): undefined | string => {
  if (value === undefined) {
    return undefined;
  }
  return isValidSiret(value) ? undefined : 'Veuillez saisir un numéro de Siret valide';
};

export const requiredValidSiret = composeValidators(required, validateSiret);
export const requiredValidPhoneNumber = composeValidators(required, validatePhoneNumber);

const startsWithFrenchCountryCode = (value: string): boolean => value.startsWith('+33');

const hasCountryCode = (value: string): boolean => value.startsWith('+');

/*
 * Temporary validator while we wait for the international phone input.
 * It checks if the number is valid only for French numbers.
 */
export const validateIntlPhoneNumber = (value: string | undefined): undefined | string => {
  if (value === undefined || (hasCountryCode(value) && !startsWithFrenchCountryCode(value))) {
    return undefined;
  }
  return isValidNumber(value) ? undefined : 'Numéro de téléphone invalide';
};

export const requiredValidIntlPhoneNumber = composeValidators(required, validateIntlPhoneNumber);

export const normalizeEmail = (email: string): string =>
  email ? email.replace(/\s/g, '').toLowerCase() : email;

export const validateEmail = (value: string | undefined): string | undefined => {
  if (!(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(value))) {
    return undefined;
  }
  return 'Veuillez entrer une adresse email valide.';
};

export const requiredValidEmail = composeValidators(required, validateEmail);

export const validateDate = (value: string | undefined): string | undefined => {
  const requiredResult = required(value);
  if (requiredResult === undefined) {
    return dayjs(value).isValid() ? undefined : 'Date invalide';
  }
  return requiredResult;
};

export const trim = (value?: string): string | undefined => (value ? value.trim() : value);

// See https://stackoverflow.com/a/15855457
export const validateUrl = (value: string | undefined): string | undefined => {
  if (
    !value ||
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value,
    )
  ) {
    return undefined;
  }
  return 'Url invalide';
};

export const requiredValidUrl = composeValidators(required, validateUrl);

export const checked = (value: boolean): string | undefined =>
  value ? undefined : requiredFieldErrorMessage;

export const validateConditions = (value: boolean): string | undefined => {
  if (!value) {
    return 'Vous devez accepter les conditions pour continuer.';
  }

  return undefined;
};

export const validateGoogleMapAddress =
  (minimalPrecision: GoogleMapsAddressTypes) => (address: GoogleMapsAddress) => {
    if (!address) {
      return undefined;
    }
    const errorMessage =
      minimalPrecision === 'postal_code'
        ? `Veuillez préciser le code postal`
        : "Adresse invalide, elle n'est peut être pas assez précise";

    // We have to prevent any address to be submitted without at least a locality
    // For example a department must be refused
    return address.address_components.find(addressComponent =>
      addressComponent.types.includes(minimalPrecision),
    )
      ? undefined
      : errorMessage;
  };

export const requiredValidGoogleMapAddress = (
  minimalPrecision: GoogleMapsAddressTypes,
): ((address: GoogleMapsAddress) => string | undefined) =>
  composeValidators(required, validateGoogleMapAddress(minimalPrecision));

export const extractDepartmentFromGoogleMapsAddress = (
  address?: GoogleMapsAddress,
): string | undefined =>
  address?.address_components.find(v => v.types.includes('postal_code'))?.long_name.substring(0, 2);

export const validateSixDigitsAuthenticationCode = (code: string): string | undefined => {
  const errorMessage =
    "Le code de confirmation n'est pas valide. Assurez-vous de renseigner le bon code puis réessayez.";
  const regex = /^\d{6}$/;
  const isValid = code.match(regex);
  return isValid ? undefined : errorMessage;
};

export const requiredValidSixDigitsAuthenticationCode = composeValidators(
  required,
  validateSixDigitsAuthenticationCode,
);

export const validateBirthday = (date?: string): string | undefined => {
  const errorMessage = 'Format de date incorrect. Veuillez respecter le format JJ/MM/AAAA.';
  return dayjs(date, 'DD/MM/YYYY', true).isValid() ? undefined : errorMessage;
};

export const validateName = (name: string): string | undefined => {
  const hasEmoji = name.match(/\p{Emoji_Presentation}/gu);
  const hasDigit = name.match(/\d/);
  const hasSpecial = name.match(/[$&+:;=?@#|<>.^*()%!,]/);
  if (hasSpecial || hasEmoji || hasDigit) {
    return 'Les caractères spéciaux ne sont pas autorisés.';
  }
  if (name.length < 2) {
    return 'Le champ doit contenir au minimum 2 caractères.';
  }
  return undefined;
};

export const requiredValueInDropdownOptions =
  (options: { value: string }[]) =>
  (value: string): string | undefined => {
    const optionsValues = options.map(option => option.value);

    return optionsValues.includes(value) ? undefined : 'Veuillez choisir une option.';
  };

export const canSubmitForm = (
  formState: Pick<
    ReturnType<typeof useFormState>,
    'errors' | 'touched' | 'submitErrors' | 'dirtyFieldsSinceLastSubmit' | 'submitting'
  >,
): boolean => {
  const { errors, touched, submitErrors, dirtyFieldsSinceLastSubmit, submitting } = formState;
  return (
    !submitting &&
    Object.keys(errors || {}).every(field => !touched?.[field]) &&
    (submitErrors instanceof Error ||
      Object.keys(submitErrors || {}).every(field => dirtyFieldsSinceLastSubmit[field]))
  );
};

export const useCanSubmitForm = (): boolean => canSubmitForm(useFormState());
