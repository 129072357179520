import React, { useEffect, useState } from 'react';

import { Navigate, useNavigate, useOutletContext } from 'react-router';

import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { FournituresWithTracking } from '@travauxlib/shared/src/features/Fournitures/components/FournituresWithTracking';
import { AdminEventsProperties } from '@travauxlib/shared/src/utils/tracking';

import { useAuth } from 'api/useAuth';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { useFournituresByProCompanies } from 'features/Project/features/ProjectPage/features/ProjectFourniture/api/useFournituresByProCompanies';
import { ProCompanyChip } from 'features/Project/features/ProjectPage/features/ProjectFourniture/components/ProCompanyChip';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { ListeFournituresTracking } from 'utils/tracking/ListeFournituresTracking';

export const ProjectFournitures: React.FC = () => {
  const { project, chantiers } = useOutletContext<ProjectContext>();
  const { proCompaniesFournitures, isLoading } = useFournituresByProCompanies(project.uuid);
  const [selectedProCompanyUuid, setSelectedProCompanyUuid] = useState<string | undefined>();

  const {
    adminUserProfile: { uuid },
  } = useAuth();
  const isAllowedActionsForThisFournitureList = useIsAllowed(
    'Fourniture',
    'manage',
    chantiers.some(chantier => chantier.responsableSuiviUuid === uuid) ? ['isAssignedTo'] : [],
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedProCompanyUuid && proCompaniesFournitures.length > 0) {
      setSelectedProCompanyUuid(proCompaniesFournitures[0].proCompanyUuid);
    }
  }, [proCompaniesFournitures, selectedProCompanyUuid]);

  if (isLoading) {
    return <Loader />;
  }

  if (proCompaniesFournitures.length === 0) {
    return <Navigate to=".." />;
  }

  const selectedProCompany = proCompaniesFournitures.find(
    proCompanyFourniture => proCompanyFourniture.proCompanyUuid === selectedProCompanyUuid,
  );

  return (
    <>
      {proCompaniesFournitures.length > 1 ? (
        <div className="flex overflow-x-auto no-scrollbar ml-md sm-desktop:ml-0 pt-md gap-md mr-xs sm-desktop:mr-0">
          {proCompaniesFournitures.map(proCompanyFourniture => (
            <ProCompanyChip
              key={proCompanyFourniture.proCompanyUuid}
              proCompanyFourniture={proCompanyFourniture}
              isActive={proCompanyFourniture.proCompanyUuid === selectedProCompanyUuid}
              onSelect={setSelectedProCompanyUuid}
            />
          ))}
        </div>
      ) : null}

      {selectedProCompany && (
        <FournituresWithTracking
          fournitureList={selectedProCompany.fournitures}
          containerClassName="sm-desktop:py-md"
          userType="admin"
          editOrAddReferenceAction={{
            onClick: (fournitureUuid: string) =>
              navigate(`/projects/${project.uuid}/fournitures/${fournitureUuid}/reference`),
            disabled: !isAllowedActionsForThisFournitureList,
          }}
          doTracking={() => {
            ListeFournituresTracking.onFournituresListOpened({
              [AdminEventsProperties.ProjetUUID]: project.uuid,
            });
            UserTracking.startSessionRecording();
          }}
        />
      )}
    </>
  );
};
