import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { apiCreateConfigurateur } from '../../api/configurateur';
import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { lead?: LeadAdminView };

export class CreateConfigurateur extends BaseStep<
  Input,
  void,
  StepField[],
  { configuration: Configuration }
> {
  public name: string = 'Create configurateur';

  public fields = [];

  async execute({ lead, apiURL, adminURL, request }: ExecuteParams<Input>): Promise<void> {
    if (!lead) {
      super.error('Lead is undefined. Cannot create a configurateur !');
      return Promise.reject();
    }

    super.log('Creating configurateur...');

    const projectUuid = lead.project?.uuid;

    if (!projectUuid) {
      super.error('Project is undefined. Cannot create a configurateur !');
      return Promise.reject();
    }

    await apiCreateConfigurateur(apiURL, projectUuid, request);

    super.log('Configurateur created !');

    super.log(`Configurateur URL : ${adminURL}/projects/${projectUuid}/configurateur`);

    return;
  }
}
