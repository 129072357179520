import React, { useState } from 'react';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import { Calendar, Culture, momentLocalizer, View } from 'react-big-calendar';

import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { SlotRDV } from '@travauxlib/shared/src/types';
import { SlotRendezVousView } from '@travauxlib/shared/src/types/api/admin/SlotRendezVousView';

// react-big calendar needs this
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(utc);

const localizer = momentLocalizer(dayjs);

export type Props = {
  bookedSlots: SlotRendezVousView[];
  label?: string;
  value: SlotRDV[];
  onChange: (slots: SlotRDV[]) => void;
};

const defaultView: View = 'week';
const minimalRdvDuration = 60;

export const AgendaSlots: React.FC<Props> = ({ bookedSlots = [], label, value = [], onChange }) => {
  const [date, setDate] = useState<Date>(dayjs().toDate());

  const handleSelectSlot = (slot: Pick<SlotRDV, 'start' | 'end'>): void => {
    const startMoment = dayjs(slot.start);
    const endMoment = dayjs(slot.end);
    const end =
      endMoment.diff(startMoment, 'minute') < minimalRdvDuration
        ? dayjs(startMoment).add(minimalRdvDuration, 'minute')
        : endMoment;

    const newEvent: SlotRDV = {
      start: startMoment.toDate(),
      end: end.toDate(),
      type: 'available',
    };

    onChange([...value, newEvent]);
  };

  const handleSelectEvent = (event: SlotRDV): void => {
    if (event.type !== 'booked') {
      onChange(value.filter(valueEvent => !dayjs(valueEvent.start).isSame(dayjs(event.start))));
    }
  };

  const eventPropGetter = (calendarEvent: SlotRDV): { className: string } => {
    const color = calendarEvent.type === 'booked' ? 'success' : 'info';
    return {
      className: `bg-${color} border-0`,
    };
  };

  const slots: SlotRDV[] = value.map(slotRendezVous => ({
    ...slotRendezVous,
    start: dayjs(slotRendezVous.start).toDate(),
    end: dayjs(slotRendezVous.end).toDate(),
  }));

  const booked: SlotRDV[] = bookedSlots.map(slotRendezVous => ({
    title: slotRendezVous.label,
    type: 'booked',
    start: dayjs(slotRendezVous.start).toDate(),
    end: dayjs(slotRendezVous.end).toDate(),
  }));

  return (
    <>
      {label && <FormLabel label={label} />}
      <Calendar<SlotRDV>
        selectable
        localizer={localizer}
        defaultView={defaultView}
        views={[defaultView]}
        date={date}
        events={[...booked, ...slots]}
        min={dayjs('2013-02-08 08:00').toDate()}
        max={dayjs('2013-02-08 21:00').toDate()}
        onSelecting={({ start, end }) => {
          const events = [...booked, ...slots];
          const isStartOrEndBetweenAnExistingEvent = events.reduce(
            (acc, event) =>
              acc ||
              dayjs(end).isBetween(dayjs(event.start), dayjs(event.end)) ||
              dayjs(start).isBetween(dayjs(event.start), dayjs(event.end)),
            false,
          );

          return !isStartOrEndBetweenAnExistingEvent;
        }}
        formats={{
          dayFormat: (d, culture) => localizer.format(d, 'dddd DD MMMM', culture as Culture),
          eventTimeRangeFormat: ({ start, end }, culture) =>
            `${localizer.format(start, 'LT', culture as Culture)} - ${localizer.format(
              end,
              'LT',
              culture as Culture,
            )}`,
        }}
        step={15}
        timeslots={2}
        eventPropGetter={eventPropGetter}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={({ start, end }) =>
          handleSelectSlot({ start: start as Date, end: end as Date })
        }
        onNavigate={setDate}
        messages={{
          previous: 'Précédent',
          next: 'Suivant',
          today: "Aujourd'hui",
          week: 'Semaine',
          day: 'Jour',
        }}
      />
    </>
  );
};
