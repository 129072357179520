import React, { useMemo, useState } from 'react';

import KeyFigures from '@travauxlib/shared/src/components/DesignSystem/assets/KeyFigures.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';
import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useIsAllowed } from 'hooks/useIsAllowed';
import { ChantierAdmin } from 'types';

import { CardsEtatAvancement } from './CardsEtatAvancement';
import { TableEtatAvancement } from './TableEtatAvancement';

import { useCancelSuiviChantierModal } from '../../hooks/useCancelSuiviChantierModal';
import { FluxFinancierEtatAvancementFilterType } from '../../types';

const emptyStateMessages: Record<FluxFinancierEtatAvancementFilterType, string> = {
  [FluxFinancierEtatAvancementFilterType.All]:
    "Le compte client n'a pas encore reçu d'état d'avancement sur le chantier",
  [FluxFinancierEtatAvancementFilterType.Valide]: "Aucun état d'avancement validé n'a été trouvé",
  [FluxFinancierEtatAvancementFilterType.Corrige]: "Aucun état d'avancement corrigé n'a été trouvé",
  [FluxFinancierEtatAvancementFilterType.Refuse]: "Aucun état d'avancement refusé n'a été trouvé",
};

type Props = {
  chantier: ChantierAdmin;
  suiviChantierHistoryList: SuiviChantierCycleHistory[];
};

export const FluxFinanciersEtatAvancement: React.FC<Props> = ({
  chantier,
  suiviChantierHistoryList,
}) => {
  const [fluxFinanciersFilter, setFluxFinanciersFilter] =
    useState<FluxFinancierEtatAvancementFilterType>(FluxFinancierEtatAvancementFilterType.All);
  const isTabletOrMobile = useIsTabletOrMobile();
  const handleOpenModalChangeTransactionVisibility = useCancelSuiviChantierModal();
  const isAllowedToManageSuiviChantier = useIsAllowed('Chantier', 'manage');

  const filteredItems = useMemo(
    () =>
      suiviChantierHistoryList.filter(cycle =>
        ['all', cycle.status].includes(fluxFinanciersFilter),
      ),
    [suiviChantierHistoryList, fluxFinanciersFilter],
  );

  return (
    <Card className="my-md min-h-[17.44rem]" state="outlined">
      <div className="flex sm-desktop:items-center sm-desktop:flex-row flex-col justify-between mb-md">
        <div className="text-h5 font-bold mb-xs sm-desktop:mb-0">État d'avancement</div>
        <RadioButtonsList<FluxFinancierEtatAvancementFilterType>
          value={fluxFinanciersFilter}
          onChange={newFilter => {
            setFluxFinanciersFilter(newFilter);
          }}
          inline={!isTabletOrMobile}
          options={[
            { label: 'Tout', value: FluxFinancierEtatAvancementFilterType.All },
            {
              label: 'Validé',
              value: FluxFinancierEtatAvancementFilterType.Valide,
            },
            {
              label: 'Corrigé',
              value: FluxFinancierEtatAvancementFilterType.Corrige,
            },
            {
              label: 'Refusé',
              value: FluxFinancierEtatAvancementFilterType.Refuse,
            },
          ]}
        />
      </div>

      {filteredItems.length === 0 ? (
        <EmptyState
          illustration={<KeyFigures />}
          iconIllustrationSize="md"
          className="my-md"
          title="L'état d'avancement est vide"
          description={emptyStateMessages[fluxFinanciersFilter]}
        />
      ) : (
        <>
          {isTabletOrMobile ? (
            <CardsEtatAvancement
              chantier={chantier}
              handleOpenModalChangeTransactionVisibility={
                handleOpenModalChangeTransactionVisibility
              }
              items={filteredItems}
              isAllowedToManageSuiviChantier={isAllowedToManageSuiviChantier}
            />
          ) : (
            <TableEtatAvancement
              chantier={chantier}
              handleOpenModalChangeTransactionVisibility={
                handleOpenModalChangeTransactionVisibility
              }
              items={filteredItems}
              isAllowedToManageSuiviChantier={isAllowedToManageSuiviChantier}
            />
          )}
        </>
      )}
    </Card>
  );
};
