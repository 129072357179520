import React, { useState } from 'react';

import { Form } from 'react-final-form';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import { Breadcrumb } from '@travauxlib/shared/src/components/DesignSystem/components/Breadcrumb';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { ApercuReference } from '@travauxlib/shared/src/features/Fournitures/components/ApercuReference';
import { ApercuReferenceCard } from '@travauxlib/shared/src/features/Fournitures/components/Desktop/ApercuReferenceCard';
import { OverwriteReferenceFeedbackMessage } from '@travauxlib/shared/src/features/Fournitures/components/OverwriteReferenceFeedbackMessage';
import { useFournitureDetails } from '@travauxlib/shared/src/features/Fournitures/hooks/useFournitureDetails';
import { useReferenceScraping } from '@travauxlib/shared/src/features/Fournitures/hooks/useReferenceScraping';
import { ReferenceForm } from '@travauxlib/shared/src/features/LogicielDeDevis/features/Fournitures/components/Reference/ReferenceForm';
import { ScrappingForm } from '@travauxlib/shared/src/features/LogicielDeDevis/features/Fournitures/components/Reference/ScrappingForm';
import { FournitureReference } from '@travauxlib/shared/src/types';
import {
  AdminEventsProperties,
  ListeFournituresEventProperties,
} from '@travauxlib/shared/src/utils/tracking';

import { ProjectContext } from 'features/Project/api/useProjectContext';
import { useCreateFournitureReference } from 'features/Project/features/ProjectPage/features/ProjectFourniture/api/useCreateFournitureReference';
import { useFournitures } from 'features/Project/features/ProjectPage/features/ProjectFourniture/api/useFournitures';
import { useScrapFournitureInfo } from 'features/Project/features/ProjectPage/features/ProjectFourniture/api/useScrapFournitureInfo';
import { useUploadFournitureImages } from 'features/Project/features/ProjectPage/features/ProjectFourniture/api/useUploadFournitureImages';
import { ListeFournituresTracking } from 'utils/tracking/ListeFournituresTracking';

export const CreateEditReference: React.FC = () => {
  const { project } = useOutletContext<ProjectContext>();

  const { fournitureUuid } = useParams<{ fournitureUuid: string }>();
  const navigate = useNavigate();
  const [hasScrapped, setHasScrapped] = useState<boolean>(false);

  const { fournitures: fournituresWithLocation, isLoading } = useFournitures(project.uuid);
  const handleScrapFourniture = useScrapFournitureInfo(project.uuid);

  const {
    handleReferenceScrapped,
    isLoading: isLoadingScrapping,
    scrappedData,
    clearScrappedData,
    updateFormWithScrapData,
  } = useReferenceScraping<FournitureReference>();

  const handleUploadFournitureImage = useUploadFournitureImages();

  const { createReference, isLoading: isLoadingCreateReference } = useCreateFournitureReference(
    project.uuid,
  );

  const fournitureDetails = useFournitureDetails(fournituresWithLocation, fournitureUuid);

  if (isLoading || !fournituresWithLocation) {
    return <Loader />;
  }

  if (!fournitureDetails || !fournitureUuid) {
    return <Navigate to=".." replace />;
  }

  const { locationLabel, fourniture, locationType } = fournitureDetails;

  const LocationPicto = locationsPicto[locationType];

  const hasExistingReference = !!fourniture.reference;

  const { mainText, ctaText } = {
    mainText: hasExistingReference ? 'Modifier la référence' : 'Ajouter une référence',
    ctaText: hasExistingReference ? 'Enregistrer la modification' : 'Ajouter la référence',
  };

  const doTracking = hasExistingReference
    ? ListeFournituresTracking.onFournituresListReferenceEdited
    : ListeFournituresTracking.onFournituresListReferenceAdded;

  return (
    <div className="sm-desktop:pt-md">
      <div className="px-md sm-desktop:px-0 mb-md">
        <Link
          to={`/projects/${project.uuid}/fournitures`}
          className="flex sm-desktop:hidden items-center gap-xxs my-xs !font-bold"
          variant="secondary"
        >
          <ChevronLeft className="size-lg neutral-800" />
          <div className="truncate max-w-full">Retour</div>
        </Link>
        <div className="hidden sm-desktop:flex items-center max-w-full mb-md">
          <Breadcrumb
            icon={<LocationPicto className="size-xmd" />}
            backLinkUrl={`/projects/${project.uuid}/fournitures`}
            crumbs={[
              {
                name: locationLabel,
                url: `/projects/${project.uuid}/fournitures#${encodeURIComponent(locationLabel)}`,
              },
              {
                name: fourniture.designation,
                url: `/projects/${project.uuid}/fournitures/${fourniture.uuid}/reference`,
              },
            ]}
          />
        </div>

        <h4 className="text-h4 font-bold mb-0">{mainText}</h4>
      </div>

      <Form<FournitureReference>
        initialValues={fourniture.reference}
        onSubmit={async values => {
          await createReference({
            fourniture,
            reference: values,
            hasExistingReference,
          });
          doTracking({
            [AdminEventsProperties.ProjetUUID]: project.uuid,
            [ListeFournituresEventProperties.IsFromScrap]: hasScrapped,
          });

          navigate(`/projects/${project.uuid}/fournitures#${fourniture.uuid}`);
        }}
      >
        {({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit} className="flex flex-col sm-desktop:flex-row gap-md">
            <div className="flex-1">
              <Card className="!rounded-none sm-desktop:!rounded-xs">
                <ScrappingForm
                  onReferenceScrapped={async (scrappedUrl: string) => {
                    setHasScrapped(true);

                    return handleReferenceScrapped({
                      scrappedUrl,
                      hasExistingReference,
                      form,
                      handleScrapFourniture,
                    });
                  }}
                  isLoading={isLoadingScrapping}
                />
                <div className="sm-desktop:hidden">
                  <hr />
                  <ApercuReference reference={values} />
                </div>
              </Card>

              <Card className="mt-xs !rounded-none sm-desktop:!rounded-xs">
                <ReferenceForm
                  disabled={isLoadingScrapping}
                  handleUploadFournitureImage={async (files: File[]) => {
                    const res = await handleUploadFournitureImage({
                      projectUuid: project.uuid,
                      files,
                    });

                    const newImages = [...(values.images || []), ...res];
                    form.change('images', newImages);
                    return res;
                  }}
                  userType="admin"
                />
              </Card>
            </div>
            <div className="hidden sm-desktop:block shrink-0 basis-1/4 truncate">
              <ApercuReferenceCard
                reference={values}
                isLoading={isLoadingCreateReference}
                ctaText={ctaText}
              />
            </div>
            <div className="sm-desktop:hidden sticky bottom-0 w-full p-md bg-neutral-0 shadow-xl">
              <Button
                fullwidth
                type="submit"
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
              >
                {ctaText}
              </Button>
            </div>
            {scrappedData && (
              <OverwriteReferenceFeedbackMessage
                updateFormWithScrapData={() => updateFormWithScrapData(scrappedData, { form })}
                clearScrappedData={clearScrappedData}
              />
            )}
          </form>
        )}
      </Form>
    </div>
  );
};
