import React from 'react';

import EmptyFolder from '@travauxlib/shared/src/components/DesignSystem/assets/EmptyFolder.svg?react';
import FolderListing from '@travauxlib/shared/src/components/DesignSystem/assets/FolderListing.svg?react';

import { EmptyStateWording } from '../types';

export const addedFournituresWording: EmptyStateWording = {
  client: {
    title: 'Votre liste de fournitures est actuellement vide.',
    description: 'Complétez votre liste pour faciliter le bon déroulé de votre chantier.',
    illustration: <EmptyFolder />,
  },
  other: {
    title: 'La liste de fournitures est actuellement vide.',
    description: "Contactez votre client pour l'orienter afin qu'il renseigne ses références.",
    illustration: <EmptyFolder />,
  },
};

export const missingFournituresWording: EmptyStateWording = {
  client: {
    title: 'Félicitations, votre liste de fournitures est actuellement complétée !',
    description:
      "Une fois les commandes passées par l'entreprise, il ne sera plus possible de faire de modifications.",
    illustration: <FolderListing />,
  },
  other: {
    title: 'Le client a choisit toutes ses références de fournitures !',
    description:
      'Vérifier que les éléments choisis correspondent au budget et aux contraintes du chantier.',
    illustration: <FolderListing />,
  },
};
