import mixpanel from 'mixpanel-browser';
import UAParser from 'ua-parser-js';
import { v4 as uuid } from 'uuid';

import { request } from '../utils/request';

class UserTrackingSDK {
  private static token: string;

  private static enabled: boolean;

  public static disable(): boolean {
    this.enabled = false;
    return this.enabled;
  }

  public static init(token: string, enabled: boolean): void {
    this.token = token;
    this.enabled = enabled;
  }

  public static async track(
    eventName: string,
    distinctId: string,
    messageBody?: Object,
  ): Promise<void> {
    if (!this.enabled) {
      return;
    }

    if (!this.token) {
      throw new Error('TrackingBackend.init must be called before tracking any events');
    }

    let parser = new UAParser(window.navigator.userAgent); // you need to pass the user-agent for nodejs
    let parserResults = parser.getResult();

    let enrichedMessageBody = {
      ...messageBody,
      $browser: parserResults.browser.name,
      $browser_version: parserResults.browser.version,
      $current_url: window.location.href,
      $initial_referrer: document.referrer || '$direct',
      $initial_referring_domain: document.referrer
        ? new URL(document.referrer).hostname
        : '$direct',
      $os: parserResults.os.name,
      $os_version: parserResults.os.version,
      $device: parserResults.device.model,
      $device_model: parserResults.device.type,
      $screen_height: window.innerHeight,
      $screen_width: window.innerWidth,
    };
    try {
      return request(`${(window as any)?.APP_CONFIG.apiURL}/track/event`, {
        method: 'POST',
        body: {
          token: this.token,
          distinctId,
          eventName,
          data: enrichedMessageBody,
        },
      });
    } catch {
      // eslint-disable-next-line no-console
      console.warn('Error while sending tracking');
    }
  }

  public static sendProfileUpdates(userId: string, properties: { [prop: string]: any }): void {
    if (!this.enabled) {
      return;
    }

    if (!this.token) {
      throw new Error('TrackingBackend.init must be called before tracking any events');
    }

    request(`${(window as any)?.APP_CONFIG.apiURL}/track/profile`, {
      method: 'PATCH',
      body: {
        token: this.token,
        distinctId: userId,
        data: properties,
      },
    });
  }
}

class TrackingBackendProfile {
  private userId: string;

  public properties: { [prop: string]: any } = {};

  public constructor() {
    // Let mixpanel handle the ID for non indentified requests
    this.userId = `anon_${uuid()}`; //mixpanel.get_distinct_id();
  }

  public set(prop: string | object, value?: any): void {
    if (typeof prop === 'object') {
      this.properties = {
        ...this.properties,
        ...prop,
      };
    } else {
      this.properties[prop] = value;
    }
    UserTrackingSDK.sendProfileUpdates(this.userId, this.properties);
  }

  // When a call to identify is made, in the future, we can use this to alias users
  public __identify(newDistinctId?: string): void {
    this.userId = newDistinctId ?? this.userId;
    UserTrackingSDK.sendProfileUpdates(this.userId, this.properties);
  }

  public getId(): string {
    return this.userId;
  }
}

class UserTrackingClass {
  public people: TrackingBackendProfile;

  public constructor() {
    this.people = new TrackingBackendProfile();
  }

  public init(token: string, enabled: boolean = true): void {
    if (enabled) {
      mixpanel.init(token);
    }
    UserTrackingSDK.init(token, enabled);
  }

  public startSessionRecording(): void {
    mixpanel.identify(this.people.getId());
    mixpanel.start_session_recording();
  }

  public disable(): boolean {
    return UserTrackingSDK.disable();
  }

  public identify(userId?: string): void {
    this.people.__identify(userId);
  }

  public async track(eventName: string, messageBody?: Object): Promise<void> {
    if (!this.people) {
      // eslint-disable-next-line
      console.error('Tracking: An event was sent before user was identified');
      return;
    }

    return UserTrackingSDK.track(eventName, this.people.getId(), messageBody);
  }
}

export const UserTracking = new UserTrackingClass();
