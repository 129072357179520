import React from 'react';

import classNames from 'classnames';

import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { CompanySummary } from './CompanySummary';

const getRankEmoji = (rank: number): string => {
  if (rank === 1) {
    return '🥇';
  }
  if (rank === 2) {
    return '🥈 ';
  }
  if (rank === 3) {
    return '🥉';
  }
  return '';
};

const Rank: React.FC<{ rank: number }> = ({ rank }) => {
  if (rank <= 3) {
    return <h1 className="!mb-0 !font-bold">{getRankEmoji(rank)}</h1>;
  }
  return (
    <div className="w-[xl] h-[xl] leading-[xl] rounded-full text-center font-bold bg-info">
      {rank}
    </div>
  );
};

type Props = {
  proCompany: ProCompanyAdminView;
  rank: number;
};

const getInsuranceLabelFromScore = (score: number): string => {
  if (score === 0) {
    return 'À jour';
  }
  if (score === 1) {
    return 'Il y a un mois';
  }
  if (score === 2) {
    return 'Il y a un an';
  }
  return "Il y a plus d'un an";
};

const getScoreClass = (score: number): string => {
  if (score === 0) {
    return '!text-success';
  }
  if (score === 1) {
    return '!text-info';
  }
  if (score === 2) {
    return '!text-warning';
  }
  return '!text-danger';
};

export const CompanyDetails: React.FC<Props> = ({ proCompany, rank }) => {
  const { distanceScore, lastMerScore, insurancesScore, distance, lastMerDate } = proCompany.score!;

  return (
    <>
      <div className="sm-desktop:w-1/12 sm-desktop:px-md relative !flex !items-center !justify-center">
        <Rank rank={rank} />
      </div>
      <div className="sm-desktop:w-3/12 sm-desktop:px-md relative !flex !items-center">
        <CompanySummary proCompany={proCompany} />
      </div>
      <div className="!flex !items-center sm-desktop:w-2/12 sm-desktop:px-md relative">
        <div>
          Distance :{' '}
          <span className={classNames('!font-bold', getScoreClass(distanceScore))}>
            {distance ? `${roundToTwoDecimals(distance).toLocaleString('fr-FR')} km` : 'Inconnue'}{' '}
          </span>
          <br />
          Dernière consultation :{' '}
          <span className={classNames('!font-bold', getScoreClass(lastMerScore))}>
            {lastMerDate ? formatFrenchDate(lastMerDate) : 'Aucune'}{' '}
          </span>
          <br />
          Date d'assurance :{' '}
          <span className={classNames('!font-bold', getScoreClass(insurancesScore))}>
            {getInsuranceLabelFromScore(insurancesScore)}{' '}
          </span>
        </div>
      </div>
      <div className="!flex !text-center !justify-center !items-center sm-desktop:w-4/12 sm-desktop:px-md relative">
        {proCompany.internalComment ? (
          <div>
            <MultilineParagraph text={proCompany.internalComment} />
          </div>
        ) : (
          <i>Aucun commentaire</i>
        )}
      </div>
    </>
  );
};
