import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  AdminListeFournituresSubFeatures,
  ListeFournituresEventProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class ListeFournituresTrackingClass extends Tracker<
  AdminFeatures,
  AdminListeFournituresSubFeatures
> {
  onFournituresListOpened = (properties: { [AdminEventsProperties.ProjetUUID]: string }): void => {
    this.sendTracking({
      event: TrackingEvents.Opened,
      properties,
    });
  };

  onFournituresListReferenceAdded = (properties: {
    [AdminEventsProperties.ProjetUUID]: string;
    [ListeFournituresEventProperties.IsFromScrap]: boolean;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Added,
      subFeature: AdminListeFournituresSubFeatures.Reference,
      properties,
    });
  };

  onFournituresListReferenceEdited = (properties: {
    [AdminEventsProperties.ProjetUUID]: string;
    [ListeFournituresEventProperties.IsFromScrap]: boolean;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Edited,
      subFeature: AdminListeFournituresSubFeatures.Reference,
      properties,
    });
  };
}

export const ListeFournituresTracking = new ListeFournituresTrackingClass(
  AdminFeatures.ListeFournitures,
);
