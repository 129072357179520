import { useFormState } from 'react-final-form';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { TypeBien } from '@travauxlib/shared/src/types';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  Chauffage,
  Plomberie,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { LocationsCounters } from 'features/Configurateur/components/LocationsCounters';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

import { LocalisationPacAInstaller } from './components/LocalisationPacAInstaller';

import { getAlertForTypeModifChauffage } from '../../utils/getAlertForTypeModif';

type Props = {
  configuration: Configuration;
  name: string;
  value: Chauffage;
};

export const DeposeRadiateursElectriques: React.FC<Props> = ({ configuration, name }) => (
  <>
    <QuestionTitle prefix="4" title="Pièces où déposer les radiateurs électriques ?" />
    {configuration.locations.map(location => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
        >
          <CounterField
            name={`${name}.locationQuantities.${location.uuid}.deposeRadiateurElectriques`}
            id={`${name}.locationQuantities.${location.uuid}.deposeRadiateurElectriques`}
          />
        </LocationCard>
      );
    })}
  </>
);

export const RadiateursElectriques: React.FC<Props> = ({ configuration, name }) => (
  <>
    <QuestionTitle prefix="3" title="Pièces concernées" />
    <LocationsCounters
      configuration={configuration}
      locations={configuration.locations}
      getFields={locationUuid => [
        {
          label: 'Remplacement radiateur',
          name: `${name}.locationQuantities.${locationUuid}.remplacementRadiateursElec`,
        },
        {
          label: 'Ajout seul radiateur',
          name: `${name}.locationQuantities.${locationUuid}.ajoutRadiateursElec`,
        },
        {
          label: 'Suppression seule radiateur',
          name: `${name}.locationQuantities.${locationUuid}.suppressionRadiateursElec`,
        },
      ]}
    />
  </>
);

export const ChaudiereSouhaite: React.FC<Props> = ({ configuration, value, name }) => {
  const locationOptions = getLocationOptions(configuration);
  return (
    <>
      <QuestionTitle prefix="3" title="Localisation de la chaudière à installer ?" />
      <ChipPictoVerticalListFieldConfigurateur
        name={`${name}.poseChaudiereLocationUuid`}
        locationOptions={locationOptions}
      />
      {value?.poseChaudiereLocationUuid && (
        <DeposeRadiateursElectriques configuration={configuration} name={name} value={value} />
      )}
    </>
  );
};

export const PompeAChaleurSouhaite: React.FC<Props> = ({ configuration, name, value }) => (
  <>
    <LocalisationPacAInstaller prefix="3" configuration={configuration} name={name} />
    {value.posePompeAChaleurLocationUuid && (
      <DeposeRadiateursElectriques configuration={configuration} name={name} value={value} />
    )}
  </>
);

export const Electrique: React.FC<Props> = ({ configuration, name, value }) => {
  const isMaison = configuration.typeLogement !== TypeBien.Appartement;
  const { values } = useFormState<Plomberie>();

  return (
    <>
      <QuestionTitle
        prefix="2"
        title="Type de modification ? "
        alert={getAlertForTypeModifChauffage(value, values)}
        tooltip={
          <div>
            Type d'équipement en fonction de la gamme :
            <br />- Toutes gammes confondues : Radiateurs à inertie fluide pour radiateurs
            électriques
            <br />- Primo & Confort : Radiateurs avec robinet thermostatique pour radiateurs à eau
            <br />- Premium & Exclusif : Radiateurs avec robinet thermostatique connecté et
            thermostat d'ambiance pour radiateurs à eau
          </div>
        }
      />
      <ChipListField
        name={`${name}.typeModif`}
        renderingOptions={{
          containerClassName: 'mb-md flex flex-wrap gap-xs',
        }}
        options={[
          {
            label: 'Suppression, ajout ou modification de radiateur',
            value: 'Radiateur',
          },
          {
            label: 'Changement pour chauffage gaz',
            value: 'Gaz',
          },
          ...(isMaison
            ? [
                {
                  label: 'Changement pour chauffage PAC',
                  value: 'PAC',
                },
              ]
            : []),
        ]}
      />
      {value?.typeModif === 'Radiateur' && (
        <RadiateursElectriques configuration={configuration} name={name} value={value} />
      )}
      {value?.typeModif === 'Gaz' && (
        <ChaudiereSouhaite configuration={configuration} name={name} value={value} />
      )}
      {value?.typeModif === 'PAC' && (
        <PompeAChaleurSouhaite configuration={configuration} name={name} value={value} />
      )}
    </>
  );
};
