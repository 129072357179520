import React from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import EditCircle from '@travauxlib/shared/src/components/DesignSystem/assets/EditCircle.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { ComparisonStatus } from '@travauxlib/shared/src/types';

export const getLineIcon = (comparisonStatus: ComparisonStatus): React.ReactElement | null => {
  switch (comparisonStatus) {
    case 'added':
      return (
        <span className="text-success-800 max-h-md flex">
          <PlusCircle />
        </span>
      );
    case 'missing':
      return (
        <span className="text-error-800 max-h-md flex">
          <CrossCircle />
        </span>
      );
    case 'modified':
      return (
        <span className="text-info-800 max-h-md flex">
          <EditCircle />
        </span>
      );
    default:
      return null;
  }
};
