import React from 'react';

import { Form } from 'react-final-form';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { AppelDePaiementView } from '@travauxlib/shared/src/types/api/admin/AppelDePaiementView';
import { composeValidators, required } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { ChantierAdmin } from 'types';

export type FormDataLiberationFonds = {
  montantHemea: number;
  montantPro: number;
};

type Props = {
  currentAppelDePaiement?: AppelDePaiementView;
  onSubmit: (values: FormDataLiberationFonds) => void;
  onClose: () => void;
  chantier: ChantierAdmin;
};

export const LiberationFondsForm: React.FC<Props> = ({
  currentAppelDePaiement,
  onSubmit,
  onClose,
  chantier,
}) => {
  if (!currentAppelDePaiement) {
    return null;
  }

  const { montant: montantAppelDePaiement, montantCommissionTTC } = currentAppelDePaiement;

  const minCommissionACapter = roundToTwoDecimals(
    Math.max(0, montantAppelDePaiement - chantier.montantRestantAPayerAuPro),
  );

  const maxCommissionACapter = roundToTwoDecimals(
    Math.min(currentAppelDePaiement.montant, chantier.montantCommissionRestantTTC),
  );

  const defaultCommissionACapter = montantCommissionTTC
    ? Math.min(montantCommissionTTC, chantier.montantCommissionRestantTTC)
    : Math.max(
        minCommissionACapter,
        Math.min(
          montantAppelDePaiement,
          chantier.montantCommissionRestantTTC,
          chantier.soldeCompteSequestre,
        ),
      );

  const validateCommissionCaptee = (commission: number): string | undefined =>
    commission <= maxCommissionACapter && commission >= minCommissionACapter
      ? undefined
      : 'Montant commission invalide';

  return (
    <Form<FormDataLiberationFonds>
      onSubmit={async values => {
        try {
          await onSubmit(values);
          onClose();
        } catch {}
      }}
      initialValues={{
        montantHemea: defaultCommissionACapter,
      }}
    >
      {({ handleSubmit, invalid, submitting, values }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Envoyer',
            type: 'submit',
            disabled: invalid || submitting,
            loading: submitting,
          }}
          cancelAction={{ label: 'Annuler', onClick: onClose }}
        >
          Montant minimum de commission :{' '}
          <EURCurrency amount={minCommissionACapter} data-testid="min-commission" /> <br />
          Montant maximum de commission : <EURCurrency amount={maxCommissionACapter} />
          <NumberInputField
            className="mb-md"
            name="montantHemea"
            id="montantHemea"
            label="Commission captée"
            fractionDigits={2}
            suffix="€"
            validate={composeValidators(required, validateCommissionCaptee)}
          />
          <NumberInput
            id="versement"
            disabled
            label={`Versement réel à ${chantier.proCompany.name}`}
            value={roundToTwoDecimals(montantAppelDePaiement - values.montantHemea)}
            fractionDigits={2}
            suffix="€"
            onChange={() => {}}
            readOnly
          />
        </ModalContent>
      )}
    </Form>
  );
};
