import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';

export const computeProgressBarValue = (project: ProjectView): number => {
  const fraction = 100 / 16;
  let progressBarValue = 0;

  /* Bloc Bien */
  if (project.typeLogement) {
    progressBarValue += fraction;
  }
  if (project.rooms) {
    progressBarValue += fraction;
  }
  if (project.surfaceTotale) {
    progressBarValue += fraction;
  }
  if (project.floor) {
    progressBarValue += fraction;
  }
  if (project.hasElevator !== undefined) {
    progressBarValue += fraction;
  }
  if (project.address?.formatted_address) {
    progressBarValue += fraction;
  }

  /* Bloc Projet */
  if (project.stadeAchat) {
    progressBarValue += fraction;
  }
  if (project.utilisationLogement) {
    progressBarValue += fraction;
  }
  if (project.mainGamme) {
    progressBarValue += fraction;
  }
  if (project.dpe) {
    progressBarValue += fraction;
  }
  if (project.isRenovationEnergetique !== undefined) {
    progressBarValue += fraction;
  }
  if (project.debutTravaux) {
    progressBarValue += fraction;
  }

  /* Bloc Travaux */
  if (project.typeTravaux) {
    progressBarValue += fraction;
  }
  if (project.locations?.length > 0 && project.locations?.every(l => !!l.surface)) {
    progressBarValue += fraction;
  }
  if (project.hauteurSousPlafond) {
    progressBarValue += fraction;
  }
  if (project.budgetTravaux) {
    progressBarValue += fraction;
  }

  return Math.round(progressBarValue);
};
