import React from 'react';

import { ButtonGroup } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup';
import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

type Props = {
  setIsViewByLocations: (isViewByLocations: boolean) => void;
  locations: DevisLocation[];
  lots: ProDevisFactureLotView[];
  isViewByLocations: boolean;
};

const options = [
  {
    label: 'Lots',
    value: false,
  },
  {
    label: 'Localisations',
    value: true,
  },
];

export const SwitchLotsLocations: React.FC<Props> = ({
  setIsViewByLocations,
  isViewByLocations,
}) => (
  <div className="flex">
    <ButtonGroup
      fullwidth
      options={options}
      value={isViewByLocations}
      onChange={setIsViewByLocations}
    />
  </div>
);
