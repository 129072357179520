import React, { useEffect } from 'react';

import { Form } from 'react-final-form';
import { Navigate, useNavigate, useParams } from 'react-router';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import Architecture from '@travauxlib/shared/src/components/DesignSystem/assets/Architecture.svg?react';
import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import {
  ChipMultipleListField,
  ChipPictoVerticalListField,
} from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { SlotRendezVousView } from '@travauxlib/shared/src/types/api/admin/SlotRendezVousView';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';
import {
  allCorpsEtat,
  certifications,
  proSpecializations,
} from '@travauxlib/shared/src/utils/constants';

import { useAuth } from 'api/useAuth';
import { useProjectContext } from 'features/Project/api/useProjectContext';
import { FicheProjetTracking } from 'utils/tracking/FicheProjetTracking';

import { FieldCard } from './FieldCard';
import { SlotsRendezVous } from './SlotsRendezVous';

import { useUpdateConsultationInfo } from '../../api/useUpdateConsultationInfo';
import { validateConsultationInfo } from '../../utils';

type FormValues = {
  companyType: CompanyType;
  budgetClient: number;
  descriptif?: string;
  typesTravaux: string[];
  insuredFor: string[];
  certifications?: string[];
  cadrageProjet: string[];
  slotsRendezVous: SlotRendezVousView[];
};

export const ConsultationInfo: React.FC = () => {
  const { uuid, consultationInfoId } = useParams<{ uuid: string; consultationInfoId: string }>();
  const { isLoading, projectContext } = useProjectContext(uuid!);
  const { adminUserProfile } = useAuth();
  const updateConsultationInfo = useUpdateConsultationInfo(uuid!);
  const navigate = useNavigate();

  useEffect(() => {
    FicheProjetTracking.onConsultationViewed({
      'Projet UUID': uuid!,
      'User Role': adminUserProfile.role,
    });
  }, []);

  if (isLoading || !projectContext) {
    return <Loader />;
  }

  const consultationInfo = projectContext.consultationInfoList.find(
    ficheChanter => ficheChanter.id === Number(consultationInfoId),
  );

  if (!consultationInfo) {
    return <Navigate to=".." />;
  }

  const hasAtLeastOneConsultation = !!projectContext.consultations.find(
    consultation => consultation.consultationInfo.id === Number(consultationInfoId),
  );

  return (
    <div>
      <ButtonLink
        className="mb-xxs"
        variant="tertiary"
        to=".."
        leftIcon={<ChevronLeft />}
        size="sm"
      >
        Retour
      </ButtonLink>
      <div className="text-h3 font-bold mb-xl text-neutral-800">
        Informations sur la consultation
      </div>
      <Form<FormValues>
        onSubmit={async values => {
          const validateErrors = validateConsultationInfo(values);
          await updateConsultationInfo(values);
          FicheProjetTracking.onConsultationEdited({
            'Projet UUID': uuid!,
            'User Role': adminUserProfile.role,
          });
          return validateErrors;
        }}
        initialValues={{
          ...consultationInfo,
          companyType: consultationInfo.companyType || 'pro',
        }}
        initialValuesEqual={() => true}
      >
        {({ handleSubmit, values: { companyType, slotsRendezVous }, form }) => (
          <>
            <form className="flex flex-col gap-md mb-xl" onSubmit={handleSubmit}>
              <AutoSubmitForm />
              <FieldCard
                title="Type de consultation"
                description={`Des informations supplémentaires seront demandées pour les consultations de type "Pro"`}
              >
                <ChipPictoVerticalListField
                  className="flex gap-md"
                  wrapperClassName="w-[8rem]"
                  id="companyType"
                  name="companyType"
                  options={[
                    { label: 'Pro', centerIcon: <HandyMan />, value: 'pro' },
                    {
                      label: 'Architecte',
                      centerIcon: <Architecture />,
                      value: 'architecte',
                    },
                  ]}
                  size="lg"
                  disabled={hasAtLeastOneConsultation}
                />
              </FieldCard>
              <FieldCard
                title="Rendez-vous client"
                description={
                  <>
                    Parmi les disponibilités que vous avez sélectionnées auparavant avec le client,
                    vous pouvez en désélectionner si vous ne souhaitez pas que les entreprises
                    rencontrent le client sur certains de ces créneaux. Attention pour un archi
                    interne il faut selectionner au moins deux créneaux qui se suivent.
                    <br />
                    Si vous n'avez plus de créneaux disponibles, contactez votre client pour
                    convenir de nouvelles disponibilités et les ajouter dans l'onglet informations.
                  </>
                }
              >
                <SlotsRendezVous
                  slotsRendezVous={slotsRendezVous}
                  projectSlotsRendezVous={projectContext.project?.chunkedSlotsRendezVous}
                />
              </FieldCard>
              <FieldCard title="Description du projet">
                <TextareaField
                  id="descriptif"
                  name="descriptif"
                  rows={4}
                  placeholder="Sois le plus précis possible, ne mets pas dans ce champ d'informations personnelles sur le client comme nom, numéro de tél ou email "
                  label="Descriptif des travaux"
                  renderingOptions={{ inputClassName: '!pb-0' }}
                />
              </FieldCard>
              <FieldCard
                title="Budget du client"
                description="Renseignez le budget du client en fonction du type de travaux sélectionné"
              >
                <NumberInputField
                  id="budgetClient"
                  name="budgetClient"
                  suffix="€"
                  label="Budget client"
                />
              </FieldCard>
              {companyType === 'pro' && (
                <>
                  <FieldCard
                    title="Type de travaux"
                    description="Pensez à bien sélectionner le type de travaux en fonction de la consultation que vous souhaitez réaliser"
                  >
                    <DropdownMultiField
                      name="typesTravaux"
                      id="typesTravaux"
                      label="Type de travaux"
                      options={proSpecializations.map(value => ({ value, label: value }))}
                    />
                  </FieldCard>
                  <FieldCard title="Assurances requises">
                    <ChipMultipleListField
                      renderingOptions={{ containerClassName: 'flex flex-wrap gap-xs' }}
                      id="insuredFor"
                      name="insuredFor"
                      options={allCorpsEtat.map(value => ({ value, label: value }))}
                    />
                  </FieldCard>
                  {projectContext.prechiffrages?.length > 0 && (
                    <FieldCard
                      title="Cadrage du projet"
                      description="Sélectionnez le devis du configurateur et/ou le pré-chiffrage que vous souhaitez envoyer aux pros"
                    >
                      <DropdownMultiField
                        name="cadrageProjet"
                        id="cadrageProjet"
                        label="Devis du configurateur ou pré-chiffrage"
                        options={projectContext.prechiffrages.map(prechiffrage => ({
                          value: prechiffrage.token,
                          label: prechiffrage.title,
                        }))}
                      />
                    </FieldCard>
                  )}
                  <FieldCard
                    title="Certification RGE"
                    description="Sélectionnez les certifications RGE si le projet a des lots liés à la rénovation énergétique"
                    optional
                  >
                    <DropdownMultiField
                      placeholder="Inclure uniquement les entreprises avec les certifications suivantes :"
                      id="certifications"
                      name="certifications"
                      label="Certifications"
                      options={certifications.map(value => ({ value, label: value }))}
                    />
                  </FieldCard>
                </>
              )}
            </form>
            <div className="flex gap-md justify-end mb-xl">
              <Button
                variant="secondary"
                onClick={async () => {
                  await form.submit();
                  navigate('..');
                }}
              >
                Enregistrer
              </Button>
              <Button
                onClick={async () => {
                  await form.submit();

                  return (
                    !form.getState().hasSubmitErrors && navigate(`./entreprises-${companyType}`)
                  );
                }}
              >
                Valider les informations
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
