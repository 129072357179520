import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ChantierStatusDetails } from '@travauxlib/shared/src/features/Chantiers/utils/ChantierStatusDetails';
import { useTruncate } from '@travauxlib/shared/src/hooks/useTruncate';
import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { ChantierStatus } from '@travauxlib/shared/src/types/api/admin/ChantierStatus';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';
import { truncate } from '@travauxlib/shared/src/utils/format';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { adminUrls } from 'utils/urls';

type Props = {
  chantiers: ChantierAdminListItemView[];
};

export const ChantierTable: React.FC<Props> = ({ chantiers }) => {
  const truncateWordsWithTooltip = useTruncate();
  return (
    <Table<ChantierAdminListItemView>
      itemsPerPage={50}
      columnConfigs={[
        {
          name: 'Creation',
          columnKey: 'createdAt',
          renderValue: (createdAt: string) => (
            <div data-testid="chantier-row">{formatFrenchDate(createdAt)}</div>
          ),
        },
        {
          name: 'Prestation',
          renderValue: (_, chantier) => (
            <>
              {chantier.proCompany.companyType === 'architecte' ? (
                <Tag className="mr-sm" size="md" variant="info" label="Conception" />
              ) : (
                <Tag className="mr-sm" size="md" variant="info" label="Travaux" />
              )}
              <Tag
                size="md"
                variant={typeSuiviWordings[chantier.typeSuivi].badgeStyle}
                label={truncate(
                  `${typeSuiviWordings[chantier.typeSuivi].label} ${
                    chantier.responsableSuiviName ? ` : ${chantier.responsableSuiviName}` : ''
                  }`,
                  30,
                )}
              />
            </>
          ),
        },
        {
          name: 'Status',
          columnKey: 'status',
          renderValue: (status: ChantierStatus) => {
            const { emoji, variant, label } = ChantierStatusDetails[status];
            return <Tag size="md" variant={variant} label={`${emoji} ${label}`} />;
          },
        },
        {
          name: 'Compte Client',
          columnKey: 'initialClientAccount',
          renderValue: (initialClientAccount: string, chantier) => (
            <>
              {initialClientAccount ? (
                <Link to={`/comptes-clients?email=${chantier?.initialClientAccount?.email}`}>
                  {truncateWordsWithTooltip({
                    value: `${chantier?.initialClientAccount?.firstName} ${chantier?.initialClientAccount?.lastName}`,
                    maxCharsForScreenSize: {
                      'sm-desktop': 10,
                      'md-desktop': 20,
                      'lg-desktop': 30,
                      'giga-screen': 45,
                      tablet: 10,
                    },
                  })}
                </Link>
              ) : (
                <>Aucun</>
              )}
            </>
          ),
        },
        {
          name: 'Entreprise',
          renderValue: (_, chantier) => (
            <Link to={adminUrls.editCompanyUrl(chantier.proCompany.slug)}>
              {truncateWordsWithTooltip({
                value: chantier.proCompany.name,
                maxCharsForScreenSize: {
                  'sm-desktop': 10,
                  'md-desktop': 20,
                  'lg-desktop': 30,
                  'giga-screen': 45,
                  tablet: 10,
                },
              })}
            </Link>
          ),
        },
        {
          name: 'Total',
          columnKey: 'montantTotalTTC',
          renderValue: (montantTotalTTC: number) => (
            <strong>
              <EURCurrency amount={montantTotalTTC} forceFullPattern />
            </strong>
          ),
        },
        {
          name: 'Devis',
          columnKey: 'countSignatures',
        },
        {
          name: 'Pr.',
          columnKey: 'montantTotalTTC',
          renderValue: (montantTotalTTC: number, chantier) => (
            <>
              {montantTotalTTC > 0
                ? ((chantier.totalProvisions / montantTotalTTC) * 100).toFixed(0)
                : '0'}{' '}
              %
            </>
          ),
        },
        {
          name: 'Pa.',
          columnKey: 'montantTotalTTC',
          renderValue: (montantTotalTTC: number, chantier) => (
            <>
              {montantTotalTTC > 0
                ? ((chantier.totalPaiements / montantTotalTTC) * 100).toFixed(0)
                : '0'}{' '}
              %
            </>
          ),
        },
        {
          name: 'Maj',
          columnKey: 'lastEventDate',
          renderValue: (lastEventDate: string) => <>{formatFrenchDate(lastEventDate)}</>,
        },
        {
          name: 'Action',
          columnKey: 'slug',
          renderValue: (slug: string, chantier: ChantierAdminListItemView) => (
            <>
              <Link to={`/projects/${chantier.project?.uuid}/chantier/${chantier.slug}`}>Voir</Link>
            </>
          ),
        },
      ]}
      items={chantiers}
      defaultSort={{ columnKey: 'lastEventDate', order: 'desc' }}
    />
  );
};
