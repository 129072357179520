import React from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { useProCompanies } from 'features/Entreprises/api/useProCompanies';

import { Row } from './Row';
import { SearchPayload } from './Search';

type Props = {
  search: SearchPayload;
};

export const List: React.FC<Props> = ({ search }) => {
  const { companyName, companyEmail, userEmail, siret } = search;
  const enableSearch = Object.values(search).some(value => !!value);

  const { proCompanies, isLoading } = useProCompanies(
    {
      companyName,
      companyEmail,
      userEmail,
      siret,
    },
    enableSearch,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!proCompanies?.length) {
    return <h1>Pas d&apos;entreprises à afficher, merci de renseigner au moins un des champs</h1>;
  }

  return (
    <div>
      <h1>{proCompanies.length} entreprise(s)</h1>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Gratuit illimité</th>
            <th>Date fin période d&apos;essai</th>
            <th>Prélèvement automatique activé</th>
            <th>Club pro plus</th>
            <th>Compte optymea activé</th>
          </tr>
        </thead>
        <tbody>
          {proCompanies.map((proCompany: ProCompanyAdminView) => (
            <Row key={proCompany.slug} proCompany={proCompany} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
