import { TypeOptions } from 'react-toastify';

import { Config, Level } from './types';

import AlertCircleFilled from '../../assets/AlertCircleFilled.svg?react';
import CheckCircleFilled from '../../assets/CheckCircleFilled.svg?react';
import InfoCircleFilled from '../../assets/InfoCircleFilled.svg?react';
import WarningFilled from '../../assets/WarningFilled.svg?react';

export const config: { [key in Level]: Config } = {
  info: {
    RawIcon: InfoCircleFilled,
    containerClassnames: 'border-info-800 bg-info-50',
    iconClassnames: 'text-info-800',
  },
  success: {
    RawIcon: CheckCircleFilled,
    containerClassnames: 'border-success-800 bg-success-50 ',
    iconClassnames: 'text-success-800',
  },
  warning: {
    RawIcon: AlertCircleFilled,
    containerClassnames: 'border-warning-800 bg-warning-50',
    iconClassnames: 'text-warning-800',
  },
  error: {
    RawIcon: WarningFilled,
    containerClassnames: 'border-error-800 bg-error-50',
    iconClassnames: 'text-error-800',
  },
};

export const toastContainerContextClass: { [key in TypeOptions]: string } = {
  info: config.info.containerClassnames,
  success: config.success.containerClassnames,
  warning: config.warning.containerClassnames,
  error: config.error.containerClassnames,
  default: '',
};
