import React from 'react';

import { addPlurielToWords } from '@travauxlib/shared/src/utils/wording';

export type Props = {
  displayedCount: number;
};

export const DisplayedCountFournitures: React.FC<Props> = ({ displayedCount }) => {
  const [fourniture, affichee] = addPlurielToWords(displayedCount, ['fourniture', 'affichée']);

  return (
    <div className="flex items-center gap-xxs text-b1 text-neutral-700 sm-desktop:mb-xs">
      <span className="font-bold text-neutral-800 text-b1">{displayedCount}</span> {fourniture}{' '}
      {affichee}
    </div>
  );
};
