import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  PosePiecesDeauElement,
  PoseTypePiecesDeauElement,
} from '@travauxlib/shared/src/types/api/domain/configurateur/SalleDeBain';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { FormControl } from 'features/Configurateur/components/FormControl';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { yesNoForm } from 'features/Configurateur/utils/forms';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

type Props = {
  configuration: Configuration;
};

export const PosePiecesDeauElements: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Eléments individuels de pièces d'eau à poser ?"
    prefix="3"
    defaultExpanded
  >
    <FieldArray<PosePiecesDeauElement> name="posePiecesDeauElements">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle prefix="1" title="Type d'élément à poser ?" />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typePose`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Douche',
                      value: PoseTypePiecesDeauElement.Douche,
                    },

                    {
                      label: 'Baignoire',
                      value: PoseTypePiecesDeauElement.Baignoire,
                    },
                    {
                      label: 'Meuble simple vasque',
                      value: PoseTypePiecesDeauElement.MeubleSimpleVasque,
                    },
                    {
                      label: 'Meuble double vasque',
                      value: PoseTypePiecesDeauElement.MeubleDoubleVasque,
                    },
                    {
                      label: 'Lave-main',
                      value: PoseTypePiecesDeauElement.LaveMain,
                    },
                    {
                      label: 'WC à poser',
                      value: PoseTypePiecesDeauElement.WCAPoser,
                    },
                    {
                      label: 'WC suspendu',
                      value: PoseTypePiecesDeauElement.WCSuspendu,
                    },
                  ]}
                />
                {value.typePose && (
                  <>
                    <QuestionTitle title="Raccords de plomberie à refaire ?" prefix="2" />
                    <FormControl name={`${name}.raccordsPlomberie`} {...yesNoForm} />
                  </>
                )}
                {value.raccordsPlomberie !== undefined && (
                  <>
                    <QuestionTitle title="Pièce concernée ?" prefix="3" />
                    <ChipPictoVerticalListFieldConfigurateur
                      locationOptions={getLocationOptions(configuration)}
                      name={`${name}.locationUuid`}
                    />
                  </>
                )}

                <PrestationsSeparator />
              </div>
            );
          })}

          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
