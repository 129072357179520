import Blueprint from '@travauxlib/shared/src/components/DesignSystem/assets/Blueprint.svg?react';
import Box from '@travauxlib/shared/src/components/DesignSystem/assets/Box.svg?react';
import Eco from '@travauxlib/shared/src/components/DesignSystem/assets/Eco.svg?react';
import PhotoCamera from '@travauxlib/shared/src/components/DesignSystem/assets/PhotoCamera.svg?react';
import Style from '@travauxlib/shared/src/components/DesignSystem/assets/Style.svg?react';
import { ProjectStep } from '@travauxlib/shared/src/types/api/common/ProjectStep';

import { DocumentConfig } from '../types';
import { DocumentTag } from '../types/api/domain/client/DocumentTag';

export const labelsEtapeProjet = {
  [ProjectStep.EtudeProject]: 'Etude du projet',
  [ProjectStep.EtudeArchi]: 'Conception architecte',
  [ProjectStep.Pro]: 'Consultation entreprises',
  [ProjectStep.Devis]: 'Signature du devis',
  [ProjectStep.PreChantier]: 'Préparation du chantier',
  [ProjectStep.Chantier]: 'Chantier en cours',
  [ProjectStep.PostChantier]: 'Chantier terminé',
};

export const etapesProjet = [
  ProjectStep.EtudeProject,
  ProjectStep.EtudeArchi,
  ProjectStep.Pro,
  ProjectStep.Devis,
  ProjectStep.PreChantier,
  ProjectStep.Chantier,
  ProjectStep.PostChantier,
];

export const documentsSections: { [K in DocumentTag]?: DocumentConfig } = {
  [DocumentTag.Decoration]: {
    label: 'Inspirations',
    icon: <Style />,
    description:
      'Si vous avez sélectionné l’offre Architecture d’intérieur, nos experts ont besoin de connaître vos envies concernant la décoration et les matériaux. Prenez le temps de nous partager vos inspirations à l’aide de photos, croquis ...',
  },
  [DocumentTag.DPE]: {
    label: 'DPE de mon bien',
    icon: <Eco />,
    description:
      "Le Diagnostic de Performance Énergétique (DPE) permet d’évaluer la consommation en énergie et le taux d'émission de CO2 d’un bien immobilier. Il sert à avoir une vision claire du projet et établir le cahier des charges.",
  },
  [DocumentTag.Plan]: {
    label: 'Plan de mon bien',
    icon: <Blueprint />,
    description: (
      <>
        Le plan existant de votre bien immobilier nous permet d’avoir une vision globale de votre
        espace et de concevoir plus facilement le cahier des charges.
        <br />
        Si vous ne disposez pas de plans techniques ou d'esquisses, vous pouvez nous transmettre un
        croquis avec les dimensions des pièces.
      </>
    ),
  },
  [DocumentTag.Photo]: {
    label: 'Photos de mon bien',
    icon: <PhotoCamera />,
    description:
      'Ces photos permettent de suivre l’évolution de votre chantier et de s’assurer du bon déroulement. N’hésitez pas à importer des photos de toutes les pièces à chaque grande étape du chantier (démolition, peinture, pose des sols, etc).',
  },
  [DocumentTag.Other]: {
    label: 'Autres documents',
    icon: <Box />,
    description:
      'Placez ici tous les documents pertinents, comme vos éléments d’inspiration de votre projet, le permis de construire pour réaliser les travaux ou des devis concurrentiel.\n' +
      'Penser à bien renommer vos documents avants de les ajouter sur votre compte client pour nous aider à comprendre le type de document.',
  },
};
