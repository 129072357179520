import React from 'react';

import { Navigate } from 'react-router';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { DisabledBadge } from 'features/Partners/components/DisabledBadge';
import { usePartner } from 'features/Partners/hooks/usePartner';
import { getDisabledReasons } from 'features/Partners/utils/getDisabledReasons';

import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

export const AgencyListing: React.FC = () => {
  const partner = usePartner();
  if (!partner) {
    return <Navigate to=".." />;
  }
  return (
    <PartnerDetailsContent>
      <div className="grid grid-cols-12 gap-md">
        <div className="min-h-[7.5rem] col-span-4">
          <Link data-testid="add-agency" to="new" className="h-full w-full">
            <Card
              state="outlined"
              className="h-full w-full"
              bodyClassNames="flex items-center justify-center p-0 h-full w-full"
            >
              <PlusCircle className="w-xxl h-xxl text-primary" />
            </Card>
          </Link>
        </div>
        {partner.agencies.map(agency => {
          const disabledReasons = getDisabledReasons(partner, agency);

          return (
            <div className="min-h-[7.5rem] col-span-4 relative" key={agency.name}>
              <Link className="text-center w-full h-full" to={agency.uuid}>
                {disabledReasons.length > 0 && (
                  <div className="absolute top-xs right-lg">
                    <DisabledBadge
                      label="Désactivée"
                      disabledReasons={disabledReasons}
                      labelInTooltip="Cette agence est désactivée car :"
                    />
                  </div>
                )}
                <Card
                  state="outlined"
                  className="h-full w-full h-full"
                  bodyClassNames="flex items-center justify-center w-full h-full"
                >
                  {agency.name}
                </Card>
              </Link>
            </div>
          );
        })}
      </div>
    </PartnerDetailsContent>
  );
};
