import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { CHANTIER_KEY } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';
import { ChantierAdmin } from 'types';

export type IgnoreChantierTransactionPayload = {
  chantier: ChantierAdmin;
  transactionId: number;
  ignored: boolean;
};

const handleIgnoreChantierTransaction = async ({
  chantier,
  transactionId,
  ignored,
}: IgnoreChantierTransactionPayload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${chantier.slug}/transactions/${transactionId}/ignored/${ignored}`;
  await request(requestURL, { method: 'PATCH' });
};

export const useIgnoreChantierTransaction = (): typeof handleIgnoreChantierTransaction => {
  const queryClient = useQueryClient();
  const { mutateAsync: ignoreChantierTransaction } = useMutation({
    mutationFn: handleIgnoreChantierTransaction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY] });
    },
    onError: () => {
      toast.error("L'operation a echoué");
    },
  });

  return ignoreChantierTransaction;
};
