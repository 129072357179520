import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Sol } from '@travauxlib/shared/src/types/api/domain/configurateur/Sols';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const Souhaite: React.FC<{ configuration: Configuration; name: string; value: Sol }> = ({
  configuration,
  name,
  value,
}) => (
  <>
    <QuestionTitle
      prefix="2"
      title="Revêtement souhaité ?"
      tooltip={
        <div>
          Type de finition en fonction de la gamme :
          <br />
          <br />- Primo : Pose sur carrelage existant, ragréage standard, parquet stratifié
          <br />- Confort : Démolition existant, ragréage standard, parquet contrecollé à l’anglaise
          <br />- Premium : Démolition existant, ragréage fibré, parquet massif à bâton rompu
          <br />- Exclusif : Démolition existant, chappe allégée entre lambourdes, ragréage fibré,
          parquet massif en Point de Hongrie Pour les finitions de type sol souple ou carrelage, le
          prix varie en fonction de la gamme.
        </div>
      }
    />
    <ChipListField
      name={`${name}.souhaite`}
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      options={[
        {
          label: 'Parquet neuf',
          value: 'Parquet neuf',
        },
        {
          label: 'Rénovation de parquet',
          value: 'Rénovation de parquet',
          hide: !['Parquet massif', 'Parquet flottant', 'Sols souples'].includes(value.existant!),
        },
        {
          label: 'Carrelage',
          value: 'Carrelage',
        },
        {
          label: 'Moquette',
          value: 'Moquette',
        },
        {
          label: 'Sol vinyle',
          value: 'Sol vinyle',
        },
      ].filter(o => !o.hide)}
    />
    {value.souhaite && (
      <MultiLocationsPicker
        configuration={configuration}
        name={`${name}.locationsUuid`}
        prefix="3"
      />
    )}
  </>
);

export const SolsPrestations: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Revêtements de sol à déposer ou rénover ?"
    prefix="1"
    defaultExpanded
  >
    <FieldArray<Sol> name="sols">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={index} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Revêtement existant ?"
                    tooltip={
                      <div>
                        - Parquet massif : Parquet ancien cloué ou parquet contrecollé collé, à
                        vérifier lors de la visite technique
                        <br />- Sols souples : Moquettes ou sols PVC/lino
                      </div>
                    }
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.existant`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Parquet massif',
                      value: 'Parquet massif',
                    },
                    {
                      label: 'Parquet flottant',
                      value: 'Parquet flottant',
                    },
                    {
                      label: 'Carrelage',
                      value: 'Carrelage',
                    },
                    {
                      label: 'Sols souples',
                      value: 'Sols souples',
                    },
                    {
                      label: 'Pas de revêtement existant',
                      value: 'Pas de revêtement existant',
                    },
                  ]}
                />
                {value.existant && (
                  <Souhaite name={name} configuration={configuration} value={value} />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
