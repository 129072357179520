import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import {
  Refection,
  TypeMobilier,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Cuisine';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { FormControl } from 'features/Configurateur/components/FormControl';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { yesNoForm } from 'features/Configurateur/utils/forms';

export const RefectionComplete: React.FC = () => (
  <Accordion className="mb-md" title="Réfection complète de cuisine ?" prefix="1" defaultExpanded>
    <FieldArray<Refection> name="refections">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <FormControl
                  className="mb-md"
                  prefix="1"
                  title="Déplacement des équipements sanitaires ?"
                  optionCheckbox={<OptionCheckbox fields={fields} value={value} index={index} />}
                  onDelete={() => fields.remove(index)}
                  tooltip={
                    <div>
                      Changer la disposition de la cuisine implique de déplacer les équipements
                      sanitaires et donc des modifications de réseaux de plomberie et électricité.
                      <br />
                      <br />
                      Cela implique également la création d'un doublage sur les murs où on dispose
                      le mobilier pour passer les réseaux et faciliter le travail de menuiserie par
                      la suite.
                    </div>
                  }
                  {...yesNoForm}
                  name={`${name}.deplacementEquipements`}
                />

                {value.deplacementEquipements !== undefined && (
                  <FormControl
                    className="mb-md"
                    prefix="2"
                    title="Projet de mobilier déjà défini ?"
                    tooltip={
                      <div>
                        Nous travaillons avec le cuisiniste Howdens : <br />
                        - Caissons garantis 25 ans
                        <br />
                        - Livraison sous 48h à 72h car ils ont toujours du stock (versus 8 à 10
                        semaines chez d'autres cuisinistes)
                        <br />
                        - La cuisine se conçoit avec eux ; c'est l'entrepreneur qui achète et pose
                        la cuisine afin de pouvoir bénéficier des 10% de TVA
                        <br />
                        - Grand choix de finitions
                        <br />
                        - Flexibilité sur la taille des caissons (largeurs disponibles tous les 5cm)
                        <br />
                        - Visite sur votre chantier pour une prise de côte au millimètre près avant
                        commande
                        <br />
                        Type de plan de travail selon les gammes :
                        <br />
                        Primo & Confort : Stratifié
                        <br />
                        Premium : Marbre
                        <br />
                        Exclusif : Céramique
                      </div>
                    }
                    {...yesNoForm}
                    name={`${name}.projetDeMobilier`}
                  />
                )}
                {value.projetDeMobilier && (
                  <>
                    <QuestionTitle
                      prefix="3"
                      title="Quel type de mobilier ?"
                      tooltip={
                        <div>
                          Il est possible de prévoir le montage et pose de meubles en kit.
                          <br />
                          <br />
                          Attention cependant aux approvisionnements qui ne sont pas toujours
                          possibles en une fois et peuvent entrainer des retards de chantier et donc
                          des surcoûts.
                          <br />
                          <br />
                          Attention à avoir les plans prêts le plus tôt possible pour anticiper les
                          travaux à faire.
                        </div>
                      }
                    />

                    <ChipListField
                      renderingOptions={{
                        containerClassName: 'mb-md flex flex-wrap gap-xs',
                      }}
                      options={[
                        {
                          label: 'Meubles du commerce en kit',
                          value: TypeMobilier.DuCommerce,
                        },
                        {
                          label: 'Cuisiniste',
                          value: TypeMobilier.Cuisiniste,
                        },
                      ]}
                      name={`${name}.typeMobilier`}
                    />
                  </>
                )}
                {value.typeMobilier === TypeMobilier.Cuisiniste && (
                  <FormControl
                    className="mb-md"
                    prefix="4"
                    title="Cuisiniste déjà choisi ?"
                    tooltip={
                      <div>
                        Il est possible de prévoir simplement les attentes électriques et de
                        plomberie, le cuisiniste pourra ensuite poser les meubles.
                        <br />
                        <br />
                        Attention à avoir les plans prêts le plus tôt possible pour anticiper les
                        travaux à faire.
                      </div>
                    }
                    {...yesNoForm}
                    name={`${name}.hasCuisiniste`}
                  />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
