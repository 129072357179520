import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { LocationCard } from 'features/Configurateur/components/LocationCard';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
  prefix: string;
  name: string;
};

export const DeposeRadiateursEaux: React.FC<Props> = ({ configuration, prefix, name }) => (
  <>
    <QuestionTitle prefix={prefix} title="Pièces où déposer les radiateurs à eau ?" />
    {configuration.locations.map(location => {
      const Picto = locationsPicto[location.typeLocation];
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
        >
          <CounterField
            name={`${name}.locationQuantities.${location.uuid}.deposeRadiateursEau`}
            id={`${name}.locationQuantities.${location.uuid}.deposeRadiateursEau`}
          />
        </LocationCard>
      );
    })}
  </>
);
