import React from 'react';

import { useNavigate, useParams } from 'react-router';

import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { Middle } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem/types';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ActionStatus } from '@travauxlib/shared/src/types';
import { actionIconMapper } from '@travauxlib/shared/src/utils/actionIconMapper';

import { useActions } from 'features/Project/features/ProjectPage/features/ProjectAction/api/useActions';
import { useSkipAction } from 'features/Project/features/ProjectPage/features/ProjectAction/api/useSkipAction';
import { useValidateAction } from 'features/Project/features/ProjectPage/features/ProjectAction/api/useValidateAction';

const tagByStatus: { [K in ActionStatus]: Middle['tags'] } = {
  [ActionStatus.Available]: [{ label: 'Disponible', variant: 'info' }],
  [ActionStatus.Skipped]: [{ label: 'Skippée', variant: 'warning' }],
  [ActionStatus.Validated]: [{ label: 'Validée', variant: 'success' }],
};

export const ProjectAction: React.FC = () => {
  const { uuid } = useParams();

  const navigate = useNavigate();

  const projectUuid = uuid as string;

  const { actions, isLoading } = useActions(projectUuid);
  const { validateAction } = useValidateAction();
  const { skipAction } = useSkipAction();

  if (isLoading) {
    return <Loader />;
  }

  if (actions.length === 0) {
    return (
      <EmptyState
        className="justify-center grow"
        description="Vous n'avez aucune action disponible"
      />
    );
  }

  return (
    <div>
      <div className="mt-md mb-sm mx-md sm-desktop:mx-0 text-h5 font-bold">Actions du client</div>
      {actions.map(action => (
        <ListItem
          className="last:border-0"
          onChange={() => navigate(action.to)}
          key={action.actionType}
          size="sm"
          state="filled"
          disabled={action.actionStatus !== ActionStatus.Available}
          left={{
            type: 'iconBackground',
            icon: actionIconMapper[action.actionType],
            variant: 'beige',
          }}
          middle={{
            title: <span className="text-b2 font-normal">{action.title}</span>,
            buttons: [
              {
                label: 'Valider',
                onClick: () =>
                  validateAction({
                    projectUuid,
                    actionType: action.actionType,
                    entityIdentifier: action.entityIdentifier,
                  }),
              },
              {
                label: 'Skipper',
                onClick: () =>
                  skipAction({
                    projectUuid,
                    actionType: action.actionType,
                    entityIdentifier: action.entityIdentifier,
                  }),
              },
            ],
            tags: tagByStatus[action.actionStatus],
          }}
        />
      ))}
    </div>
  );
};
