import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Ventilation } from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const Ventilations: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Ventilation à créer ou modifier ?" prefix="3" defaultExpanded>
    <FieldArray<Ventilation> name="ventilations">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type de prestation ?"
                    tooltip={
                      <div>
                        - Remplacement de bouches sur un réseau de ventilation existant
                        <br />
                        <br />
                        - Remplacement aérateur : extracteur autonome placé dans une percée donnant
                        sur mur extérieur
                        <br />
                        <br />
                        - VMC : Faisabilité de la création d’une VMC à vérifier en visite technique.
                        S’il faut percer un mur extérieur pour l’extraction de l’air, il faut alors
                        demander une autorisation de copropriété
                        <br />
                        <br />
                      </div>
                    }
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.typeVentilation`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Remplacement de bouches',
                      value: 'Remplacement de bouches',
                    },
                    {
                      label: "Remplacement d'aérateur",
                      value: 'Aérateur',
                    },
                    {
                      label: 'VMC',
                      value: 'VMC',
                    },
                    {
                      label: 'Ne sait pas',
                      value: 'Ne sait pas',
                    },
                  ]}
                />
                {value.typeVentilation && value.typeVentilation !== 'Ne sait pas' && (
                  <MultiLocationsPicker
                    prefix="2"
                    configuration={configuration}
                    name={`${name}.locationsUuid`}
                    title={
                      value.typeVentilation === 'VMC'
                        ? "Localisation des bouches d'extraction ?"
                        : 'Pièces concernées ?'
                    }
                  />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
