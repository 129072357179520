import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { required } from '@travauxlib/shared/src/utils/form';

import { useConsultationInfoReportResult } from '../../api/useConsultationInfoReportResult';

type Props = {
  isOpen: boolean;
  consultationInfoId: number;
  proCompany: ProCompanyAdminView;
  onClose: () => void;
};

export const ReportResultModal: React.FC<Props> = ({
  isOpen,
  consultationInfoId,
  proCompany,
  onClose,
}) => {
  const { reportResult } = useConsultationInfoReportResult();
  const onFormSubmit = (values: { detail: string }): void => {
    reportResult({ consultationInfoId, proCompany, detail: values.detail });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Signaler un résultat non pertinent">
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit, dirty, submitting, invalid }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Envoyer le rapport',
              type: 'submit',
              disabled: !dirty || submitting || invalid,
              loading: submitting,
            }}
          >
            <div className="mb-md">Entreprise : {proCompany.name}</div>
            <TextareaField
              name="detail"
              id="detail"
              placeholder="Préciser les raisons..."
              rows={8}
              maxRows={8}
              validate={required}
              autoFocus
            />
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
