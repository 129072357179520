import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import ArrowDown from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowDownFilled.svg?react';
import ArrowUp from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowUpFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';

import { FilterCriteria } from 'features/Entreprises/api/useProCompanies';

import { ArchitectFields } from './ArchitectFields';
import { CommonFields } from './CommonFields';
import { ProFields } from './ProFields';
import { SpecificFields } from './SpecificFields';

const defaultInitialFormValues: FilterCriteria = {
  status: ['Activées', 'Labellisées'],
  companyTypes: [CompanyType.Pro, CompanyType.Archi],
  acceptsProjects: 'indifferent',
  enPause: 'indifferent',
  validAssurance: 'indifferent',
  kyc2: 'indifferent',
};

type Props = {
  initialFormValues?: FilterCriteria;
  enableCompanyTypeAndStatus?: boolean;
  setFilterCriteria: (filterCriteria: FilterCriteria) => void;
};

export const Search: React.FC<Props> = ({
  initialFormValues,
  enableCompanyTypeAndStatus,
  setFilterCriteria,
}) => {
  const initialValues = {
    ...defaultInitialFormValues,
    ...initialFormValues,
  };
  const [expandSpecificFields, setExpandSpecificFields] = useState(false);

  return (
    <Form
      onSubmit={values => setFilterCriteria(values)}
      initialValues={initialValues}
      initialValuesEqual={() => true}
    >
      {({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} className="!mb-sm">
          <CommonFields
            enableCompanyTypeAndStatus={enableCompanyTypeAndStatus}
            onReset={() => {
              form.restart();
              setFilterCriteria(initialValues);
            }}
          />
          <Button
            variant="tertiary"
            type="button"
            onClick={() => setExpandSpecificFields(expandSpecificFields => !expandSpecificFields)}
            rightIcon={expandSpecificFields ? <ArrowUp /> : <ArrowDown />}
            size="sm"
          >
            Filtres avancés
          </Button>
          {expandSpecificFields && (
            <>
              <SpecificFields />
              {values.companyTypes.includes('pro') && <ProFields />}
              {values.companyTypes.includes('architecte') && <ArchitectFields />}
            </>
          )}
          <AutoSubmitForm skipValuesCompare />
        </form>
      )}
    </Form>
  );
};
