import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { ChantierAdminView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminView';

import { BaseStep, ExecuteParams } from '../BaseStep';

type Input = {
  chantier: ChantierAdminListItemView;
  detailledChantier: ChantierAdminView;
};

export class ChantierValidateAppelDePaiement extends BaseStep<Input> {
  public name = 'Chantier Validate Appel De Paiement';

  public fields = [];

  async execute({
    apiURL,
    chantier,
    detailledChantier,
    request,
  }: ExecuteParams<Input>): Promise<void> {
    if (!detailledChantier.appelsDePaiement[0]?.uuid) {
      super.error('No appel de paiement on detailled chantier');
      return Promise.reject();
    }
    await request(
      `${apiURL}/admin/chantiers/${chantier.slug}/appels-de-paiement/${detailledChantier.appelsDePaiement[0].uuid}/validate`,
      {
        method: 'POST',
      },
    );

    super.log('OK');
  }
}
