import React from 'react';

import classNames from 'classnames';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { UserType } from '@travauxlib/shared/src/features/Fournitures/types';
import { required, validateUrl } from '@travauxlib/shared/src/utils/form';

import { ReferenceImagesField } from './ReferenceImagesField';

type Props = {
  formBaseName?: string;
  disabled: boolean;
  handleUploadFournitureImage: any;
  userType: UserType;
  id?: string;
};

export const ReferenceForm: React.FC<Props> = ({
  formBaseName = '',
  disabled,
  handleUploadFournitureImage,
  userType,
  id = '',
}) => (
  <div className="flex flex-col gap-md text-b2">
    <div className={classNames('font-bold', userType === 'pro' ? 'text-b2' : 'text-h5')}>
      Informations de la référence
    </div>
    <div className="flex flex-col gap-xs">
      <div className="flex items-center gap-xxs">
        <span className="text-neutral-600">
          {userType === 'pro' ? 'Ajouter un aperçu' : "Modifier l'aperçu"}
        </span>
        {userType === 'pro' && (
          <WithTooltip
            trigger={
              <IconButton size="xs" className="text-neutral-700">
                <HelpCircle />
              </IconButton>
            }
            position="bottom"
          >
            <div>
              Les photos de la référence seront également visibles sur le devis. Sélectionnez celle
              que vous voulez mettre en avant.
            </div>
          </WithTooltip>
        )}
        <span className="text-sm text-neutral-600">(Facultatif)</span>
      </div>

      <ReferenceImagesField
        formBaseName={formBaseName}
        onFournitureImageUpload={handleUploadFournitureImage}
        disabled={disabled}
      />
    </div>

    <InputField
      name={`${formBaseName}designation`}
      id={`reference-designation-${id}`}
      label="Nom de la référence"
      validate={required}
      disabled={disabled}
    />
    <InputField
      name={`${formBaseName}fournisseur`}
      id={`reference-fournisseur-${id}`}
      label="Nom du fournisseur"
      validate={required}
      disabled={disabled}
    />
    <InputField
      name={`${formBaseName}referenceNumber`}
      id={`reference-number-${id}`}
      label="Numéro de référence"
      validate={required}
      disabled={disabled}
    />
    <InputField
      name={`${formBaseName}referenceLink`}
      id={`reference-link-${id}`}
      label="Site du fournisseur (Facultatif)"
      disabled={disabled}
      validate={validateUrl}
    />
    {userType === 'pro' && (
      <TextareaField
        name={`${formBaseName}description`}
        id={`reference-description-${id}`}
        data-testid="reference-description"
        label="Description (Facultatif)"
        rows={4}
        disabled={disabled}
      />
    )}
  </div>
);
