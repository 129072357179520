import { request } from '@travauxlib/shared/src/utils/request';

import { ChantierAdmin } from 'types';

import { useChantierQuery } from './useChantierQuery';

const handleLinkLwAccount = async (
  chantier: Partial<ChantierAdmin> & { slug: string },
): Promise<void> => {
  const { slug } = chantier;
  const requestURL = `${APP_CONFIG.apiURL}/admin/chantiers/${slug}/fix-lemonway-account`;
  await request(requestURL, { method: 'POST' });
};

export const useLinkLwAccount = (): {
  linkLwAccount: typeof handleLinkLwAccount;
  isLoading: boolean;
} => {
  const { query, isLoading } = useChantierQuery(
    handleLinkLwAccount,
    'de la mise à jour du chantier',
    'Le compte Lemonway a bien été rattaché au chantier',
  );

  return { linkLwAccount: query, isLoading };
};
