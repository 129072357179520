import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { ModalAppelDePaiement } from 'features/Project/features/ProjectPage/features/ProjectChantier/components/ChantierFluxFinancier/ModalAppelDePaiement';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { ChantierAdmin } from 'types';

type Props = {
  chantier: ChantierAdmin;
};

export const FluxFinanciersHeader: React.FC<Props> = ({ chantier }) => {
  const {
    totalPaiements,
    totalProvisions,
    montantTotalTTC,
    montantSignatureInitiale,
    appelsDePaiement,
    lemonwayAccountUrl,
    proCompany,
  } = chantier;

  const { archiType } = proCompany;

  const [isAppelDePaiementOpen, setIsAppelDePaiementOpen] = useState(false);

  const COMPLETED_STATUSES = ['deleted', 'processed'];
  const hasAppelDePaiementEnAttente = appelsDePaiement.some(
    adp => !COMPLETED_STATUSES.includes(adp.status),
  );
  const isAllowedToCreateAppelDePaiement = useIsAllowed('Chantier', 'createAppelPaiement');
  const canCreateAppelDePaiement =
    !!lemonwayAccountUrl && archiType !== 'interne' && !hasAppelDePaiementEnAttente;

  return (
    <>
      <div className="flex justify-between sm-desktop:flex-row flex-col sm-desktop:items-center mb-md sm-desktop:mb-0">
        <div className="text-h4 font-bold pb-md">Flux financier</div>

        <Button
          disabled={!canCreateAppelDePaiement || !isAllowedToCreateAppelDePaiement}
          positionTooltip="left"
          disabledMessageTooltip={
            !isAllowedToCreateAppelDePaiement
              ? "Permission requise : création d'appels de paiement"
              : `Il manque le rattachement à lemonway, ce chantier \n est avec un architecte interne ou un appel \n de paiement existe déjà`
          }
          variant="secondary"
          size="sm"
          onClick={() => setIsAppelDePaiementOpen(true)}
        >
          Créer un appel de paiement
        </Button>
      </div>
      <div className="flex justify-between">
        <div>
          <div className="text-b2 text-neutral-700">Montant à la signature :</div>
          <EURCurrency
            className="font-medium text-b1"
            forceFullPattern
            amount={montantSignatureInitiale}
          />
        </div>
        <div className="text-right">
          <div className="text-b2 text-neutral-700">Montant fin du chantier :</div>
          <EURCurrency className="font-medium text-b1" forceFullPattern amount={montantTotalTTC} />
        </div>
      </div>
      <ProgressBar
        className="mt-md"
        size="lg"
        max={montantTotalTTC}
        value={[
          {
            value: totalPaiements,
            variant: 'success',
            text: <EURCurrency forceFullPattern amount={totalPaiements} />,
          },
          {
            value: totalProvisions - totalPaiements,
            variant: 'info',
            text: <EURCurrency forceFullPattern amount={totalProvisions} />,
            showTooltip: true,
          },
          {
            value: montantTotalTTC - totalPaiements,
            variant: 'neutral',
            text: <EURCurrency forceFullPattern amount={montantTotalTTC - totalPaiements} />,
            showTooltip: true,
          },
        ]}
      />
      <div className="flex mt-md">
        <div className="flex items-center">
          <div className="h-sm w-sm rounded-full bg-success-400 mr-xxs" />
          <span className="text-sm">Montant total payé</span>
        </div>
        <div className="flex items-center ml-sm">
          <div className="h-sm w-sm rounded-full bg-info-400 mr-xxs" />
          <span className="text-sm">Montant total provisionné</span>
        </div>
        <div className="flex items-center ml-sm">
          <div className="h-sm w-sm rounded-full bg-neutral-400 mr-xxs" />
          <span className="text-sm">Montant restant à payer</span>
        </div>
      </div>

      <ModalAppelDePaiement
        isModalOpen={isAppelDePaiementOpen}
        onClose={() => setIsAppelDePaiementOpen(false)}
        resteAAppeler={chantier.restePaiementsAAppeler}
        proName={chantier.proCompany.name}
        chantierSlug={chantier.slug}
        hasSuiviChantier={chantier.enableSuiviChantier}
      />
    </>
  );
};
