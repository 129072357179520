import React from 'react';

import _groupBy from 'lodash/groupBy';
import _partition from 'lodash/partition';

import Box from '@travauxlib/shared/src/components/DesignSystem/assets/Box.svg?react';
import Medal from '@travauxlib/shared/src/components/DesignSystem/assets/Medal.svg?react';
import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { Fourniture, FournitureReference } from '@travauxlib/shared/src/types';

type Props = {
  fournitures: Fourniture[];
};

export const BlocReference: React.FC<{ reference: FournitureReference }> = ({ reference }) => (
  <div className="pt-xxs">
    <div className="mb-xs text-neutral-700 text-b2">{reference.designation}</div>
    {!!reference.images?.length && (
      <div className="flex gap-md flex-wrap mb-xxs">
        {reference.images.map(image => (
          <a key={image.uuid} href={image.imgixUrl} target="_blank" rel="noreferrer noopener">
            <ImgLazy
              className="rounded-xxs !w-[5rem] !h-[5rem] object-cover"
              width={175}
              url={image.downloadUrl}
              alt={image.name}
              eagerLoading
            />
          </a>
        ))}
      </div>
    )}
    {reference.description && (
      <div className="mb-xxs">
        <MultilineParagraph
          paragraphClassName="text-neutral-600 !text-sm"
          text={reference.description}
          hasLinksOpenedInNewWindow
        />
      </div>
    )}

    {reference.referenceLink && (
      <div className="text-sm mb-xxs">
        <Link
          className="flex items-center"
          size="sm"
          href={reference.referenceLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          <span className="mr-xxs">Accéder au site fournisseur</span>
          <OpenTab className="w-md h-md" />
        </Link>
      </div>
    )}
  </div>
);

export const FournitureReferences: React.FC<Props> = ({ fournitures }) => {
  const allReferences = fournitures.flatMap(f => f.reference).filter(ref => !!ref);
  if (!allReferences.length) {
    return null;
  }

  const [recommandationsHemea, otherReferences] = _partition(
    allReferences,
    ref => ref.isRecommandationHemea,
  );

  const groupedRecommandationsHemea = _groupBy(recommandationsHemea, reco => reco.fournisseur);

  return (
    <div className="flex flex-col gap-y-xs pl-xl grow">
      {Object.entries(groupedRecommandationsHemea).map(
        ([fournisseur, recommandationReferences]) => (
          <div key={fournisseur} className="bg-white border rounded-md grow py-xs">
            <div className="px-xs flex gap-xxs items-center font-medium mb-xxs">
              <Medal className="size-lg text-primary" />
              <div>hemea recommande {fournisseur}</div>
            </div>
            <div className="ml-xxs pl-xl pr-xs">
              {recommandationReferences.map((reference, index) => (
                <React.Fragment key={reference.uuid}>
                  <BlocReference reference={reference} />
                  {index !== recommandationReferences.length - 1 && (
                    <div className="my-xs h-[1px] bg-neutral-300" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ),
      )}
      {otherReferences.length > 0 && (
        <div className="bg-white border rounded-md grow py-xs">
          <div className="px-xs flex gap-xxs items-center font-medium mb-xxs">
            <Box className="size-lg" />
            <div>Fournitures & équipements</div>
          </div>
          <div className="ml-xxs pl-xl pr-xs">
            {otherReferences.map((reference, index) => (
              <React.Fragment key={reference.uuid}>
                <BlocReference reference={reference} />
                {index !== otherReferences.length - 1 && (
                  <div className="my-xs h-[1px] bg-neutral-300" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
