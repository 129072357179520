import React from 'react';

import { Outlet } from 'react-router';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';

import { CreatePerson } from './components/CreatePerson';
import { EditPerson } from './components/EditPerson';
import { EditPersonListing } from './components/EditPersonListing';
import { Person } from './components/Person';
import { PersonListing } from './components/PersonListing';

const Index: React.FC = () => (
  <>
    <PageTitle title={PageTitleEnum.trombinoscope} />
    <Outlet />
  </>
);

export const Trombinoscope = {
  Index,
  Person,
  EditPersonListing,
  PersonListing,
  CreatePerson,
  EditPerson,
};
