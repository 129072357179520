import React, { useEffect, useRef, useState } from 'react';

import { Placement } from '@floating-ui/react';
import classNames from 'classnames';

import { WithTooltip } from '../WithTooltip';

type Props = {
  className?: string;
  maxLines: number;
  text: string;
  tooltipPosition?: Placement;
};

export const ClampedText: React.FC<Props> = ({
  className,
  maxLines,
  text,
  tooltipPosition = 'top',
}) => {
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkClamping = (): void => {
      const element = textRef.current;
      if (element) {
        setIsClamped(element.scrollHeight > element.clientHeight);
      }
    };

    checkClamping();

    window.addEventListener('resize', checkClamping);
    return () => window.removeEventListener('resize', checkClamping);
  }, [text, maxLines]);

  const content = (
    <div
      ref={textRef}
      /* eslint-disable-next-line */
      style={{ WebkitLineClamp: maxLines }}
      /* line-clamp-1 needed to generate tailwind clamping classes */
      className={classNames(className, 'line-clamp-1')}
    >
      {text}
    </div>
  );

  return (
    <>
      {isClamped ? (
        <WithTooltip showDelay={200} trigger={content} position={tooltipPosition} fixedFloating>
          <span>{text}</span>
        </WithTooltip>
      ) : (
        content
      )}
    </>
  );
};
