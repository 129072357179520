import React, { useState } from 'react';

import Send from '@travauxlib/shared/src/components/DesignSystem/assets/Send.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { DevisCommentView } from '@travauxlib/shared/src/types/api/common/DevisCommentView';

type Props = {
  onSubmit: (text: string, comment?: DevisCommentView) => void;
  comment?: DevisCommentView;
  senderName: string;
};

export const InputForm: React.FC<Props> = ({ onSubmit, senderName, comment }) => {
  const [text, setText] = useState(comment?.text || '');
  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await onSubmit(text, comment);
  };
  return (
    <form className="flex items-center mr-md pt-xs" onSubmit={handleSubmit}>
      <div className="shrink-0 pl-md mr-xs">
        <Avatar text={getInitials(senderName)} />
      </div>
      <Input
        className="mr-xxs"
        name="comment"
        type="text"
        data-testid="comment"
        id="comment"
        label="Répondez au pro"
        value={text}
        onChange={setText}
        autoFocus
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          e.target.selectionStart = e.target.value.length;
        }}
        suffix={
          <IconButton data-testid="submit-comment" size="md" type="submit">
            <Send />
          </IconButton>
        }
      />
    </form>
  );
};
