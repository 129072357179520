import { chauffageGeneratedData } from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/chauffage';
import { radiateursGeneratedData } from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/radiateurs';
import { ventilationsGeneratedData } from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/ventilation';
import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';

import { eauChaudeGeneratedData } from './eauChaude';

export const plomberie = (configuration: Configuration): GenerationData[] => {
  const plomberie = configuration.answers?.plomberie;
  if (!plomberie) {
    return [];
  }
  const result: GenerationData[] = [];

  const { chauffages } = plomberie;

  for (const chauffage of chauffages || []) {
    if (chauffage?.typeEnergie === 'Collectif' && chauffage.vanneArret === false) {
      result.push({
        ouvrageLabel: 'Modification chauffage collectif par la copro',
      });
    }

    if (chauffage?.typeEnergie === 'Collectif' && chauffage.vanneArret) {
      result.push({
        ouvrageLabel: "Chauffage collectif, vanne d'arrêt individuelle présente",
      });
    }
  }

  return [
    ...result,
    ...radiateursGeneratedData(plomberie, configuration),
    ...chauffageGeneratedData(plomberie, configuration),
    ...eauChaudeGeneratedData(plomberie, configuration),
    ...ventilationsGeneratedData(plomberie),
  ];
};
