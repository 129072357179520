import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { FOURNITURES_KEY } from '@travauxlib/shared/src/features/Fournitures/api/buildUseFournitures';
import { Fourniture, FournitureReference } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export type CreateFournitureReference = Omit<FournitureReference, 'uuid'>;

type CreateReferenceParams = {
  fourniture: Fourniture;
  reference: CreateFournitureReference;
  hasExistingReference: boolean;
};

const handleCreateReference = (
  projectUuid: string,
  fournitureUuid: string,
  reference: CreateFournitureReference,
): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/projects/${projectUuid}/fournitures/${fournitureUuid}/reference`;
  return request(requestURL, {
    method: 'POST',
    body: reference,
  });
};

export const useCreateFournitureReference = (
  projectUuid: string,
): {
  createReference: (params: CreateReferenceParams) => Promise<void>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createReference, isPending } = useMutation({
    mutationFn: (params: CreateReferenceParams) =>
      handleCreateReference(projectUuid, params.fourniture.uuid, params.reference),
    onSuccess: (_, { hasExistingReference, reference, fourniture }) => {
      queryClient.invalidateQueries({ queryKey: [FOURNITURES_KEY] });
      const message = hasExistingReference
        ? `${fourniture.designation} a bien été modifiée`
        : `${reference.designation} a bien été ajoutée`;

      toast.success('Fourniture mise à jour', { message });
    },
    onError: (_, { hasExistingReference }) => {
      toast.error(
        hasExistingReference
          ? "La référence n'a pas pu être mise à jour"
          : "La référence n'a pas pu être créée",
      );
    },
  });

  return {
    createReference,
    isLoading: isPending,
  };
};
