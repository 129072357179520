import React from 'react';

import { Navigate, useLocation } from 'react-router';

export const RedirectToNewProject: React.FC = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const [, pathAfterProject] = pathName.split('/project/');

  return <Navigate replace to={`/projects/${pathAfterProject || ''}`} />;
};
