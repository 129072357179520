import { FieldArray } from 'react-final-form-arrays';

import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { AjoutAppareillage } from '@travauxlib/shared/src/types/api/domain/configurateur/Elec';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationsCounters } from 'features/Configurateur/components/LocationsCounters';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

export const AjoutDappareillagesElectriques: React.FC<Props> = ({ configuration }) => (
  <Accordion
    className="mb-md"
    title="Ajout d'appareillages électriques ?"
    prefix="2"
    defaultExpanded
  >
    <FieldArray<AjoutAppareillage> name="ajoutdAppareillages">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Type d'appareillage à ajouter ?"
                    tooltip="Vérifier en visite technique qu'il y ait l'espace suffisant sur le tableau électrique pour ajouter des appareillages."
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <LocationsCounters
                  configuration={configuration}
                  locations={configuration.locations}
                  getFields={locationUuid => [
                    {
                      label: 'Prise de courant simple',
                      name: `${name}.locationQuantities.${locationUuid}.prise`,
                    },
                    {
                      label: 'Interrupteur va-et-vient simple',
                      name: `${name}.locationQuantities.${locationUuid}.interrupteurSimpleVaEtVient`,
                    },
                    {
                      label: 'Prise électroménager 20A',
                      name: `${name}.locationQuantities.${locationUuid}.priseElectroMenager`,
                    },
                    {
                      label: 'Interrupteur va-et-vient double',
                      name: `${name}.locationQuantities.${locationUuid}.interrupteurDoubleVaEtVient`,
                    },
                    {
                      label: 'Prise cuisson 32A',
                      name: `${name}.locationQuantities.${locationUuid}.priseCuisson`,
                    },
                    {
                      label: 'Applique murale',
                      name: `${name}.locationQuantities.${locationUuid}.applique`,
                    },
                    {
                      label: 'Interrupteur simple',
                      name: `${name}.locationQuantities.${locationUuid}.interrupteur`,
                    },
                    {
                      label: 'Plafonnier',
                      name: `${name}.locationQuantities.${locationUuid}.plafonnier`,
                    },
                  ]}
                />
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
