import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { Navigate, useNavigate, useParams } from 'react-router';

import { MenuiseriesExterieures } from '@travauxlib/shared/src/types/api/domain/configurateur/MenuiseriesExterieures';

import { useConfiguration } from 'features/Configurateur/api/useConfiguration';
import { FormFooter } from 'features/Configurateur/components/FormFooter';
import { FormHeader } from 'features/Configurateur/components/FormHeader';

import { Fenetres } from './components/Fenetres';
import { FenetresToits } from './components/FenetresToits';
import { PorteEntrees } from './components/PorteEntrees';
import { Volets } from './components/Volets';

import { useCreateUpdateConfiguration } from '../../api/useCreateUpdateConfiguration';

export const ConfigurateurMenuiseriesExterieures: React.FC = () => {
  const projectUuid = useParams().uuid!;
  const { configuration } = useConfiguration(projectUuid);
  const createUpdateConfiguration = useCreateUpdateConfiguration();
  const navigate = useNavigate();

  if (!configuration) {
    return <Navigate to="../bien" />;
  }

  return (
    <Form<MenuiseriesExterieures>
      initialValues={configuration?.answers?.menuiseriesExterieures}
      mutators={{ ...arrayMutators }}
      destroyOnUnregister
      onSubmit={menuiseriesExterieures => {
        const updatedConfiguration = {
          ...configuration,
          answers: {
            ...configuration.answers,
            menuiseriesExterieures,
          },
        };
        createUpdateConfiguration(updatedConfiguration);
        navigate('..');
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormHeader questionId="menuiseriesExterieures" isRge />
          <PorteEntrees configuration={configuration} />
          <Fenetres configuration={configuration} />
          <FenetresToits configuration={configuration} />
          <Volets configuration={configuration} />
          <FormFooter />
        </form>
      )}
    </Form>
  );
};
