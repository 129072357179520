import React from 'react';

import classNames from 'classnames';
import { useFormState } from 'react-final-form';

import BlueprintFilled from '@travauxlib/shared/src/components/DesignSystem/assets/BlueprintFilled.svg?react';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';

import { LocalisationModalContent } from './LocalisationModalContent';

type Props = {
  isModalOpen: boolean;
  setActiveModal: (modal: undefined | 'localisations' | 'surfaces') => void;
  isRequired: boolean;
};

export const LocalisationsChoice: React.FC<Props> = ({
  isModalOpen,
  setActiveModal,
  isRequired,
}) => {
  const { values } = useFormState<ProjectView & { disabled: boolean; hideUnfilled: boolean }>();
  const { locations = [], disabled, hideUnfilled } = values;

  const unfilledLocation = locations.some(l => !l.surface);

  if ((locations.length === 0 || unfilledLocation) && hideUnfilled) {
    return null;
  }

  return (
    <>
      <SimpleModal
        isOpen={isModalOpen}
        title="Pièces à rénover"
        validateAction={{
          label: unfilledLocation ? 'Suivant' : 'Valider',
          onClick: () => {
            if (unfilledLocation) {
              setActiveModal('surfaces');
            } else {
              setActiveModal(undefined);
            }
          },
        }}
      >
        <LocalisationModalContent />
      </SimpleModal>
      <div className="flex mb-xs items-start justify-start">
        <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">
          <BlueprintFilled />
        </div>
        <div
          onClick={() => {
            if (!disabled) {
              setActiveModal('localisations');
            }
          }}
          className={classNames('flex items-center gap-xs', !disabled && 'cursor-pointer')}
        >
          {locations.length === 0 && <span>Pièces à rénover</span>}
          {locations.length !== 0 ? (
            <div className="flex flex-wrap gap-xxs">
              {locations.map(location => (
                <React.Fragment key={location.uuid}>
                  <span>
                    {location.typeLocation} {location.surface.toLocaleString('fr-fr')}m²
                  </span>
                  <span className="last:hidden">, </span>
                </React.Fragment>
              ))}
            </div>
          ) : isRequired ? (
            <InlineAlert variant="error">Obligatoire</InlineAlert>
          ) : (
            <InlineAlert variant="info">À définir</InlineAlert>
          )}
        </div>
      </div>
    </>
  );
};
