import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { PersonRole } from '@travauxlib/shared/src/types';

import { usePersons } from 'features/Trombinoscope/api/usePersons';

import { Header } from './Header';
import { PersonCard } from './PersonCard';

import { useObiwan } from '../hooks/useObiwan';

export const PersonListing: React.FC = () => {
  const [searchParams] = useSearchParams();

  const role = searchParams.get('role');

  useObiwan();
  const { isLoading, persons } = usePersons();

  const filteredPersons = useMemo(() => {
    const activePersons = persons.filter(p => !p.archived);

    return activePersons.filter(p => {
      if (role === 'cdpl') {
        return p.role === PersonRole.CDPL;
      }
      return p.role !== PersonRole.CDPL;
    });
  }, [persons, role]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="container pt-xl w-full lg-desktop:w-9/12">
        <div className="grid grid-cols-10 gap-lg place-items-center">
          {filteredPersons.map(person => (
            <div
              className="col-span-full sm-desktop:col-span-3 md-desktop:col-span-2"
              key={person.uuid}
            >
              <PersonCard to={`/trombinoscope/${person.uuid}`} person={person} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
