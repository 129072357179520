import React from 'react';

import classNames from 'classnames';

import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { formatFrenchDate, isInFuture } from '@travauxlib/shared/src/utils/time';

import { pauseReasonLabels } from 'utils/constants';

import { callOrTravaux, productionOrDevis } from '../../utils/wording';

type Props = {
  proCompany: ProCompanyAdminView;
  advanced: boolean;
};

export const AvailabilityStatus: React.FC<Props> = ({ proCompany, advanced }) => {
  const enPause = !!proCompany.pauseReason;
  const finDePause =
    proCompany.dontSendMersUntil && ` jusqu'au ${formatFrenchDate(proCompany.dontSendMersUntil)}`;

  return (
    <div
      className={classNames({
        '!text-warning !font-bold': enPause,
        'relative px-lg py-sm rounded bg-error-100 text-error': enPause && advanced,
      })}
    >
      {enPause && (
        <>
          <WithTooltip
            fixedFloating
            trigger={
              <span data-tip data-for={`pauseReason${proCompany.uuid}`}>
                ⏸️ En pause{finDePause}
              </span>
            }
            position="left"
          >
            <div>{proCompany.pauseReason && pauseReasonLabels[proCompany.pauseReason]}</div>
          </WithTooltip>
          <br />
        </>
      )}
      {isInFuture(proCompany.dispoTravaux) && (
        <>
          🗓️ Non dispo pour {callOrTravaux(proCompany.archiType)} jusqu'au{' '}
          {formatFrenchDate(proCompany.dispoTravaux)}
          <br />
        </>
      )}
      {isInFuture(proCompany.dispoDevis) && (
        <>
          🗓️ Non dispo pour {productionOrDevis(proCompany.archiType)} jusqu'au{' '}
          {formatFrenchDate(proCompany.dispoDevis)}
          <br />
        </>
      )}
      {advanced && !enPause && '🗓️ Pas en pause'}
    </div>
  );
};
