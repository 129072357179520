import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  Etat,
  Finition,
  FinitionMursPlafondsEnum,
  MursPlafonds,
  Peinture,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Murs';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const Locations: React.FC<{ configuration: Configuration; name: string; prefix: string }> = ({
  configuration,
  name,
  prefix,
}) => (
  <MultiLocationsPicker
    configuration={configuration}
    prefix={prefix}
    name={`${name}.locationsUuid`}
  />
);

const FinitionMurs: React.FC<{
  configuration: Configuration;
  name: string;
  value: Peinture;
  prefix: number;
}> = ({ configuration, name, value, prefix }) => (
  <>
    <QuestionTitle
      prefix={`${prefix}`}
      title="Niveau de finition souhaité ?"
      tooltip={
        <div>
          Peinture par le client: Si le client souhaite faire lui-même la peinture, on lui prépare
          le support.
          <br />
          <br />
          La préparation est ce qui représente le plus gros coût dans la peinture (environ 80% du
          prix total).
        </div>
      }
    />
    <ChipListField
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      name={`${name}.finitionMurs`}
      options={[
        {
          label: 'Peinture par le client',
          value: Finition.PretAPeindre,
        },
        {
          label: 'Peinture complète',
          value: Finition.PeintureComplete,
        },
      ]}
    />
    {value.finitionMurs && (
      <Locations configuration={configuration} name={name} prefix={`${prefix + 1}`} />
    )}
  </>
);
const FinitionMursPlafonds: React.FC<{
  configuration: Configuration;
  name: string;
  value: Peinture;
  prefix: number;
}> = ({ configuration, name, value, prefix }) => (
  <>
    <QuestionTitle
      prefix={`${prefix}`}
      title="Niveau de finition souhaité ?"
      tooltip={
        <div>
          Peinture par le client: Si le client souhaite faire lui-même la peinture, on lui prépare
          le support.
          <br />
          <br />
          La préparation est ce qui représente le plus gros coût dans la peinture (environ 80% du
          prix total).
        </div>
      }
    />
    <ChipListField
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      name={`${name}.finitionMursPlafonds`}
      options={[
        {
          label: 'Peinture par le client',
          value: FinitionMursPlafondsEnum.PretAPeindre,
        },
        {
          label: 'Peinture complète',
          value: FinitionMursPlafondsEnum.PeintureComplete,
        },
      ]}
    />
    {value.finitionMursPlafonds && (
      <Locations configuration={configuration} name={name} prefix={`${prefix + 1}`} />
    )}
  </>
);

const FinitionPlafonds: React.FC<{
  configuration: Configuration;
  name: string;
  value: Peinture;
}> = ({ configuration, name, value }) => (
  <>
    <QuestionTitle
      prefix="3"
      title="Niveau de finition souhaité ?"
      tooltip={
        <div>
          Peinture par le client : Si le client souhaite faire lui-même la peinture, on lui prépare
          le support.
          <br />
          <br />
          La préparation est ce qui représente le plus gros coût dans la peinture (environ 80% du
          prix total).
        </div>
      }
    />
    <ChipListField
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      name={`${name}.finitionPlafonds`}
      options={[
        {
          label: 'Peinture par le client',
          value: Finition.PretAPeindre,
        },
        {
          label: 'Peinture complète',
          value: Finition.PeintureComplete,
        },
      ]}
    />
    {value.finitionPlafonds && <Locations configuration={configuration} name={name} prefix="4" />}
  </>
);

const QuestionsEtatsMurs: React.FC<{ name: string }> = ({ name }) => (
  <>
    <QuestionTitle
      prefix="2"
      title="État des murs ?"
      tooltip={
        <div>
          Le support est en mauvais état s'il y a des fissures et imperfections, cela implique une
          préparation des murs plus importante avec entoilage.
          <br />
          <br />
          Si l’entoilage n’est pas fait, les fissures réapparaissent au bout de quelques temps.
        </div>
      }
    />
    <ChipListField
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      name={`${name}.etatMurs`}
      options={[
        {
          label: 'Bon/Moyen',
          value: Etat.Bon,
        },
        {
          label: 'Mauvais',
          value: Etat.Mauvais,
        },
      ]}
    />
  </>
);
const QuestionsEtatsPlafonds: React.FC<{ name: string; prefix: string }> = ({ name, prefix }) => (
  <>
    <QuestionTitle
      prefix={prefix}
      title="État des plafonds ?"
      tooltip={
        <div>
          Le support est en mauvais état s'il y a des fissures et imperfections, cela implique une
          préparation des murs plus importante avec entoilage.
          <br />
          <br />
          Si l’entoilage n’est pas fait, les fissures réapparaissent au bout de quelques temps.
        </div>
      }
    />
    <ChipListField
      className="mb-md"
      name={`${name}.etatPlafonds`}
      renderingOptions={{
        containerClassName: 'mb-md flex flex-wrap gap-xs',
      }}
      options={[
        {
          label: 'Bon/Moyen',
          value: Etat.Bon,
        },
        {
          label: 'Mauvais',
          value: Etat.Mauvais,
        },
      ]}
    />
  </>
);

const EtatMurs: React.FC<{
  configuration: Configuration;
  name: string;
  value: Peinture;
}> = ({ configuration, name, value }) => (
  <>
    <QuestionsEtatsMurs name={name} />
    {value.mursPlafonds === MursPlafonds.Murs && value.etatMurs && (
      <FinitionMurs configuration={configuration} name={name} value={value} prefix={3} />
    )}
  </>
);

const EtatPlafonds: React.FC<{
  configuration: Configuration;
  name: string;
  value: Peinture;
  prefix: string;
}> = ({ configuration, name, value, prefix }) => (
  <>
    <QuestionsEtatsPlafonds name={name} prefix={prefix} />
    {value.etatPlafonds && (
      <>
        {value.mursPlafonds === MursPlafonds.Plafonds ? (
          <FinitionPlafonds configuration={configuration} name={name} value={value} />
        ) : (
          <FinitionMursPlafonds
            configuration={configuration}
            name={name}
            value={value}
            prefix={4}
          />
        )}
      </>
    )}
  </>
);

export const Peintures: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Peinture à refaire ?" prefix="3" defaultExpanded>
    <FieldArray<Peinture> name="peintures">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            const hasMurs =
              value.mursPlafonds === MursPlafonds.Murs ||
              value.mursPlafonds === MursPlafonds.MursEtPlafonds;

            const shouldDisplayPlafonds =
              value.mursPlafonds === MursPlafonds.Plafonds ||
              (value.mursPlafonds === MursPlafonds.MursEtPlafonds && !!value.etatMurs);
            return (
              <div key={name} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle prefix="1" title="Zone à refaire ?" />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.mursPlafonds`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={[
                    {
                      label: 'Murs',
                      value: MursPlafonds.Murs,
                    },
                    {
                      label: 'Plafonds',
                      value: MursPlafonds.Plafonds,
                    },
                    {
                      label: 'Murs et plafonds',
                      value: MursPlafonds.MursEtPlafonds,
                    },
                  ]}
                />
                {hasMurs && <EtatMurs configuration={configuration} name={name} value={value} />}
                {shouldDisplayPlafonds && (
                  <EtatPlafonds
                    configuration={configuration}
                    name={name}
                    value={value}
                    prefix={value.mursPlafonds === MursPlafonds.Plafonds ? '2' : '3'}
                  />
                )}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
