import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { Chauffage } from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { FormControl } from 'features/Configurateur/components/FormControl';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { yesNoForm } from 'features/Configurateur/utils/forms';

import { ModificationsRadiateurEau } from './components/ModificationsRadiateurEau';

type Props = {
  configuration: Configuration;
  name: string;
  value: Chauffage;
};

export const Collectif: React.FC<Props> = ({ configuration, name, value }) => (
  <>
    <QuestionTitle
      prefix="2"
      title="Vanne d'arrêt individuelle ?"
      tooltip={
        <div>
          La vanne d'arrêt permet d'isoler le réseau de chauffage de l'appartement du restant de
          l'immeuble.
          <br /> <br />
          Si elle n'est pas présente, cela nécessite de couper tout le réseau de l'immeuble et donc
          une autorisation de la copropriété.
        </div>
      }
    />
    <FormControl name={`${name}.vanneArret`} {...yesNoForm} />
    {value.vanneArret && <ModificationsRadiateurEau configuration={configuration} name={name} />}
  </>
);
