import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { AppelDePaiementView } from '@travauxlib/shared/src/types/api/admin/AppelDePaiementView';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { CHANTIER_KEY } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

import { useCancelAppelDePaiement } from '../api/useCancelAppelDePaiement';

type OwnProps = {
  appelDePaiementToCancel: AppelDePaiementView;
  chantierSlug: string;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

export const CancelAppelDePaiementModal: React.FC<Props> = ({
  appelDePaiementToCancel,
  chantierSlug,
  handleClose,
}) => {
  const queryClient = useQueryClient();

  const { cancelAppelDePaiement, isLoading } = useCancelAppelDePaiement();

  return (
    <FeedbackMessages
      size="md"
      variant="error"
      title="Annuler l'appel de paiement"
      handleClose={handleClose}
      isOpen
      message={
        <div>
          Êtes-vous sûr de vouloir annuler l'appel de paiement de{' '}
          <EURCurrency
            className="font-bold"
            amount={roundToTwoDecimals(appelDePaiementToCancel.montant)}
            forceFullPattern
          />{' '}
          ?
        </div>
      }
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
      validateAction={{
        label: 'Confirmer',
        'data-testid': 'confirm-cancel-appel-de-paiement',
        type: 'submit',
        onClick: async () => {
          await cancelAppelDePaiement({
            chantierSlug,
            appelDePaiementUuid: appelDePaiementToCancel.uuid,
          });
          handleClose();
          queryClient.invalidateQueries({ queryKey: [CHANTIER_KEY, chantierSlug] });
        },
        loading: isLoading,
      }}
    />
  );
};

export const useCancelAppelDePaiementModal = (): ((
  appelDePaiement: AppelDePaiementView,
  chantierSlug: string,
) => void) => {
  const openModal = useOpenModal();

  const open = async (
    appelDePaiement: AppelDePaiementView,
    chantierSlug: string,
  ): Promise<void> => {
    openModal(CancelAppelDePaiementModal, {
      appelDePaiementToCancel: appelDePaiement,
      chantierSlug,
    });
  };

  return open;
};
