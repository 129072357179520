import Architecture from '@travauxlib/shared/src/components/DesignSystem/assets/Architecture.svg?react';
import ConstructionFilled from '@travauxlib/shared/src/components/DesignSystem/assets/ConstructionFilled.svg?react';
import { ChipPicto } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/ChipPicto';
import { useFournituresByTabAndTotals } from '@travauxlib/shared/src/features/Fournitures/hooks/useFournituresByTabAndTotals';
import { ProCompanyFournituresView } from '@travauxlib/shared/src/types';

type Props = {
  proCompanyFourniture: ProCompanyFournituresView;
  isActive: boolean;
  onSelect: (companyUuid: string) => void;
};

export const ProCompanyChip: React.FC<Props> = ({ proCompanyFourniture, isActive, onSelect }) => {
  const { total } = useFournituresByTabAndTotals(proCompanyFourniture.fournitures || []);

  return (
    <ChipPicto
      key={proCompanyFourniture.proCompanyUuid}
      leftIcon={proCompanyFourniture.isArchitecte ? <Architecture /> : <ConstructionFilled />}
      label={proCompanyFourniture.proCompanyName}
      helperText={proCompanyFourniture.isArchitecte ? 'Architecte' : 'Entreprise'}
      onClick={() => onSelect(proCompanyFourniture.proCompanyUuid)}
      isActive={isActive}
      fullColor
      className="shrink-0"
      badgeCount={total}
    />
  );
};
