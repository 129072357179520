import React from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDownFilled.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUpFilled.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

import { isTagWithIcon, TagOption, TagOptionWithIcon, TagSize } from './types';

import { Popup } from '../Popup';

import { Tag } from '.';

const RightIcon = ({ isOpen }: { isOpen: boolean }): React.ReactElement =>
  isOpen ? (
    <ChevronUp data-testid="close-tag-options" />
  ) : (
    <ChevronDown data-testid="open-tag-options" />
  );

export type Props = {
  options: TagOptionWithIcon[] | TagOption[];
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  size?: TagSize;
  isScrollable?: boolean;
  disabled?: boolean;
  fixedFloating?: boolean;
};

export const InteractiveTags: React.FC<Props> = ({
  options,
  value,
  onChange,
  placeholder,
  size = 'md',
  isScrollable = false,
  disabled = false,
  fixedFloating,
}): React.ReactElement | null => {
  const currentOption = options.find(({ value: optionValue }) => value === optionValue);

  if (!currentOption) {
    return null;
  }

  if (disabled) {
    return (
      <Tag
        className="whitespace-nowrap"
        label={currentOption.label}
        variant={currentOption.variant}
        size={size}
        icon={isTagWithIcon(currentOption) ? currentOption.icon : undefined}
      />
    );
  }

  return (
    // This div is needed to ensure popped Card is always right below Tag
    <div className="inline-block">
      <Popup
        fixedFloating={fixedFloating}
        trigger={(toggle, referenceProps, isOpen) => (
          <span onClick={toggle} {...referenceProps}>
            <Tag
              className="whitespace-nowrap"
              label={currentOption.label}
              variant={currentOption.variant}
              size={size}
              icon={isTagWithIcon(currentOption) ? currentOption.icon : undefined}
              isRightIconActive={isOpen}
              rightIcon={disabled ? undefined : <RightIcon isOpen={!!isOpen} />}
            />
          </span>
        )}
      >
        {closePopup => (
          <Card
            bodyClassNames={classNames(
              'px-0 py-xxs overflow-auto',
              size === 'sm' ? 'max-h-[4.5rem]' : 'max-h-[6rem]',
              !isScrollable && 'max-h-fit',
              isScrollable && options.length > 3 && 'pr-xxs',
            )}
          >
            {options
              .filter(
                ({ value: optionValue }) => optionValue !== placeholder && optionValue !== value,
              )
              .map(option => (
                <div
                  className="p-xxs cursor-pointer hover:bg-neutral-100 active:bg-neutral-200"
                  key={option.value}
                  onClick={() => {
                    closePopup();
                    onChange(option.value);
                  }}
                  role="button"
                  aria-label={option.label.toString()}
                >
                  <Tag
                    label={option.label}
                    className="w-full justify-center"
                    variant={option.variant}
                    icon={isTagWithIcon(option) ? option.icon : undefined}
                    size={size}
                  />
                </div>
              ))}
          </Card>
        )}
      </Popup>
    </div>
  );
};
