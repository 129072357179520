import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useAuth } from 'api/useAuth';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { ChantierAdmin } from 'types';
import { ConsultationInfoTracking } from 'utils/tracking/ConsultationInfoTracking';

import { useReopenChantier } from '../../api/useReopenChantier';

type Props = {
  chantier: ChantierAdmin;
};

export const ReopenChantier: React.FC<Props> = ({ chantier }) => {
  const { reopenChantier } = useReopenChantier();
  const { adminUserProfile, isLoading } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const onCloseModal = (): void => setModalVisible(false);

  const onSubmit = async (): Promise<void> => {
    await reopenChantier(chantier);
    ConsultationInfoTracking.onChantierOpened({
      'User Role': adminUserProfile.role,
      'Chantier UUID': chantier.uuid,
    });
    onCloseModal();
  };

  const isAllowedToStartChantier = useIsAllowed('Chantier', 'open');

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <>
      <Button
        type="button"
        disabled={!isAllowedToStartChantier}
        variant="secondary"
        onClick={() => setModalVisible(true)}
        size="sm"
      >
        Réouvrir le chantier
      </Button>
      <Modal
        isOpen={modalVisible}
        handleClose={onCloseModal}
        title="Confirmer la réouverture du chantier"
        isScrollable={false}
      >
        <Form onSubmit={async () => await onSubmit()}>
          {({ handleSubmit, submitting }) => (
            <ModalContent
              handleSubmit={handleSubmit}
              validateAction={{
                label: 'Valider',
                type: 'submit',
                disabled: submitting || !isAllowedToStartChantier,
                loading: submitting,
              }}
              cancelAction={{ label: 'Annuler', onClick: onCloseModal }}
            >
              <div className="mb-md">La date de fin du chantier renseignée sera supprimée.</div>
            </ModalContent>
          )}
        </Form>
      </Modal>
    </>
  );
};
