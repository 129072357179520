import {
  Chauffage,
  EauChaude,
  Plomberie,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

const MESSAGE_CHAUFFAGE_INCOMPLETE = "Attention, n'oubliez pas de compléter la question chauffage";
const MESSAGE_NON_MATCHING_CHAUFFAGE = 'Attention, l’eau chaude renseignée ne correspond pas';
const MESSAGE_NON_MATCHING_EAU_CHAUDE = 'Attention, le chauffage renseigné ne correspond pas';

const isChauffageMissingRadiateur = (chauffage: Chauffage, eauChaude: EauChaude): boolean => {
  const chauffageMalRenseignee =
    !chauffage?.typeEnergie || chauffage?.typeEnergie === 'Ne sait pas';

  const eauChaudeDependanteDuChauffage =
    eauChaude?.typeEnergie === 'Gaz' || eauChaude?.typeEnergie === 'Fioul';

  return chauffageMalRenseignee && eauChaudeDependanteDuChauffage;
};

const isChauffageMatchingRadiateur = (chauffage: Chauffage, eauChaude: EauChaude): boolean => {
  if (
    chauffage &&
    (chauffage.typeEnergie === 'Aucune' || chauffage.typeEnergie === 'Électrique') &&
    chauffage.typeModif === 'Radiateur' &&
    (eauChaude?.typeEnergie === 'Gaz' || eauChaude?.typeEnergie === 'Fioul')
  ) {
    return true;
  }

  if (chauffage && chauffage.typeEnergie !== 'Gaz' && eauChaude?.typeEnergie === 'Gaz') {
    return true;
  }

  if (chauffage && chauffage.typeEnergie !== 'Fioul' && eauChaude?.typeEnergie === 'Fioul') {
    return true;
  }

  return false;
};

export const getAlertForTypeEnergieChauffage = (
  chauffage: Chauffage,
  values: Plomberie,
): string | undefined => {
  if (chauffage.isOption) {
    return undefined;
  }
  const eauChaude = values.eauChaudes?.find(e => !e.isOption);
  const shouldShowMessage = eauChaude && isChauffageMissingRadiateur(chauffage, eauChaude);
  return shouldShowMessage ? MESSAGE_NON_MATCHING_CHAUFFAGE : undefined;
};

export const getAlertForTypeEnergieEauChaude = (
  eauChaude: EauChaude,
  values: Plomberie,
): string | undefined => {
  if (eauChaude.isOption) {
    return undefined;
  }
  const chauffage = values.chauffages?.find(c => !c.isOption);
  if (!chauffage) {
    return undefined;
  }
  if (isChauffageMissingRadiateur(chauffage, eauChaude)) {
    return MESSAGE_CHAUFFAGE_INCOMPLETE;
  } else if (isChauffageMatchingRadiateur(chauffage, eauChaude)) {
    return MESSAGE_NON_MATCHING_EAU_CHAUDE;
  }
};
