import React, { useState } from 'react';

import { useFormState } from 'react-final-form';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { TotalConfigurationHT } from '@travauxlib/shared/src/features/Configurateur/components/TotalConfigurationHT';
import { TotalConfigurationTTC } from '@travauxlib/shared/src/features/Configurateur/components/TotalConfigurationTTC';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { required } from '@travauxlib/shared/src/utils/form';

type Props = {
  project: ProjectView;
  configuration: Configuration;
  baseDePrix: BaseDePrix;
};

export const ProjectNameCard: React.FC<Props> = ({ project, configuration, baseDePrix }) => {
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const { valid } = useFormState();
  const overlayRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(overlayRef, () => {
    if (editTitle) {
      setEditTitle(false);
    }
  });

  return (
    <Card className="!rounded-md mb-md" state="elevated">
      <div className="flex justify-between items-center mb-xs">
        <div className="text-h4 text-neutral-800 font-bold">Nom du projet</div>
        <div className="flex items-center gap-xs flex-wrap">
          <TotalConfigurationHT configuration={configuration} baseDePrix={baseDePrix} />
          <TotalConfigurationTTC configuration={configuration} baseDePrix={baseDePrix} />
        </div>
      </div>
      <div className="flex items-center gap-xs">
        {editTitle ? (
          <div ref={overlayRef}>
            <InputField
              name="title"
              id="project-title"
              autoFocus
              validate={required}
              onKeyDown={event => {
                if (event.key === 'Enter' && valid) {
                  setEditTitle(false);
                }
              }}
            />
          </div>
        ) : (
          <>
            <div className="text-b1 text-neutral-800">{project.title}</div>
            <IconButton
              type="button"
              onClick={event => {
                event.stopPropagation();
                setEditTitle(true);
              }}
            >
              <Edit />
            </IconButton>
          </>
        )}
      </div>
    </Card>
  );
};
