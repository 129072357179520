import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { FournitureReferenceInfo } from '@travauxlib/shared/src/features/Fournitures/components/FournitureReferenceInfo';
import { FournitureReference } from '@travauxlib/shared/src/types';

export type Props = {
  reference: FournitureReference;
  ctaText?: string;
  isLoading?: boolean;
};

export const ApercuReference: React.FC<Props> = ({ reference, ctaText, isLoading }) => (
  <>
    <div className="mb-xs text-neutral-700">Aperçu de la référence</div>
    <FournitureReferenceInfo reference={reference} />
    {ctaText && (
      <Button fullwidth className="mt-xl" type="submit" variant="primary" loading={isLoading}>
        {ctaText}
      </Button>
    )}
  </>
);
