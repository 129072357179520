import React, { ComponentType } from 'react';

import Dressing from '@travauxlib/shared/src/components/DesignSystem/assets/BuanderieDuoTone.svg?react';
import Bureau from '@travauxlib/shared/src/components/DesignSystem/assets/BureauDuoTone.svg?react';
import Chambre from '@travauxlib/shared/src/components/DesignSystem/assets/ChambreDuoTone.svg?react';
import Degagement from '@travauxlib/shared/src/components/DesignSystem/assets/DegagementDuoTone.svg?react';
import Entree from '@travauxlib/shared/src/components/DesignSystem/assets/EntreeDuoTone.svg?react';
import House from '@travauxlib/shared/src/components/DesignSystem/assets/HouseDuoTone.svg?react';
import Cuisine from '@travauxlib/shared/src/components/DesignSystem/assets/KitchenDuoTone.svg?react';
import Living from '@travauxlib/shared/src/components/DesignSystem/assets/LivingDuoTone.svg?react';
import SalleAManger from '@travauxlib/shared/src/components/DesignSystem/assets/SalleAMangerDuoTone.svg?react';
import SalleDeBain from '@travauxlib/shared/src/components/DesignSystem/assets/SalleDeBainDuoTone.svg?react';
import SalleEau from '@travauxlib/shared/src/components/DesignSystem/assets/SalleEauDuoTone.svg?react';
import Studio from '@travauxlib/shared/src/components/DesignSystem/assets/StudioDuoTone.svg?react';
import Toilette from '@travauxlib/shared/src/components/DesignSystem/assets/ToiletteDuoTone.svg?react';
import { AllTypeLocation } from '@travauxlib/shared/src/types';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

type IconLocalisationLotsOptions = {
  [key in AllTypeLocation]: ComponentType<{ className?: string; height?: string; width?: string }>;
};

export const locationsPicto: IconLocalisationLotsOptions = {
  Autre: House,
  Bureau: Bureau,
  Chambre: Chambre,
  Cuisine: Cuisine,
  Dégagement: Degagement,
  Dressing: Dressing,
  'Pièce principale': Studio,
  "Salle d'eau": SalleEau,
  'Salle de bain': SalleDeBain,
  Salon: Living,
  Séjour: Living,
  'Salle à manger': SalleAManger,
  Terrasse: House,
  Entrée: Entree,
  WC: Toilette,
  WithoutLocation: House,
};

export type Props = {
  localisationLabel: string;
  devisLocations: DevisLocation[];
};

export const LocalisationIcon: React.FC<Props> = ({ localisationLabel, devisLocations }) => {
  const location = devisLocations.find(l => l.label === localisationLabel);

  const Icon = location ? locationsPicto[location.typeLocation] : House;

  return <Icon />;
};
