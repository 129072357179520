import { useQuery } from '@tanstack/react-query';

import { ClientAccount } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

export const useSearchClientsByEmail = (
  email: string,
): { isLoading: boolean; isSuccess: boolean; foundClientAccounts: ClientAccount[] } => {
  const { isLoading, fetchStatus, isSuccess, data } = useQuery<{
    data: ClientAccount[];
  }>({
    queryKey: [PROJECT_KEY],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/clients/search?email=${email}&searchType=startsWith`, {
        method: 'GET',
      }),
    enabled: email.length >= 3,
  });

  return {
    isLoading: isLoading && fetchStatus !== 'idle',
    isSuccess,
    foundClientAccounts: data?.data || [],
  };
};
