import React from 'react';

import dayjs from 'dayjs';
import { Form } from 'react-final-form';

import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { callOrTravaux, productionOrDevis } from 'features/Entreprises/utils/wording';
import { pauseReasons } from 'utils/constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  proCompany: ProCompanyAdminView;
};

export const UpdatePauseStatusModal: React.FC<Props> = ({ isOpen, onClose, proCompany }) => {
  const { slug, dontSendMersUntil, pauseReason, dispoTravaux, dispoDevis, archiType } = proCompany;
  const { update, isUpdating } = useUpdateProCompany();

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Pause" isScrollable={false}>
      <Form
        onSubmit={async values => {
          await update({
            settings: {
              ...proCompany,
              dontSendMersUntil: values.dontSendMersUntil,
              pauseReason: values.pauseReason,
              dispoTravaux: values.dispoTravaux,
              dispoDevis: values.dispoDevis,
            },
            slug,
          });
          onClose();
        }}
        initialValues={{ dontSendMersUntil, pauseReason, dispoTravaux, dispoDevis }}
      >
        {({ handleSubmit }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Valider',
              type: 'submit',
              disabled: isUpdating,
              loading: isUpdating,
            }}
            cancelAction={{ label: 'Annuler', onClick: onClose }}
          >
            <DropdownField
              className="mb-md"
              id="pauseReason"
              name="pauseReason"
              label="Raison de mise en pause"
              fixedFloating={false}
              options={[
                {
                  label: 'Aucune',
                  value: undefined,
                },
                ...pauseReasons,
              ]}
              allowNull
            />{' '}
            <DatePickerField
              className="mb-md"
              id="dontSendMersUntil"
              name="dontSendMersUntil"
              label="En pause jusqu'au"
              parse={parseDateInput}
              fixedFloating={false}
              shouldBeDisabled={(date: Date) => dayjs(date).isBefore(dayjs(), 'day')}
            />
            <DatePickerField
              className="mb-md"
              id="dispoTravaux"
              name="dispoTravaux"
              label={`Dispo pour ${callOrTravaux(archiType)} à partir du`}
              parse={parseDateInput}
              shouldBeDisabled={(date: Date) => dayjs(date).isBefore(dayjs(), 'day')}
              fixedFloating={false}
            />
            <DatePickerField
              id="dispoDevis"
              name="dispoDevis"
              label={`Dispo pour ${productionOrDevis(archiType)} à partir du`}
              parse={parseDateInput}
              shouldBeDisabled={(date: Date) => dayjs(date).isBefore(dayjs(), 'day')}
              fixedFloating={false}
            />
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
