import React from 'react';

import { useFormState } from 'react-final-form';

import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { TypeBien } from '@travauxlib/shared/src/types';
import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  EauChaude,
  Plomberie,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Plomberie';

import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle/';

import { getAlertForTypeModifEauChaude } from '../../utils/getAlertForTypeModif';

type Props = {
  configuration: Configuration;
  name: string;
  eauChaude: EauChaude;
};

export const Fioul: React.FC<Props> = ({ configuration, name, eauChaude }) => {
  const isMaison = configuration.typeLogement !== TypeBien.Appartement;
  const { values } = useFormState<Plomberie>();

  return (
    <>
      <QuestionTitle
        prefix="2"
        title="Type de modification ?"
        alert={getAlertForTypeModifEauChaude(eauChaude, values)}
        tooltip={
          <div>
            La faisabilité d'installation d'un chauffe-eau thermodynamique doit être vérifiée par un
            professionnel.
            <br />
            <br />
            Chauffe-eau thermodynamique gainé mis en option de base.
            {isMaison && (
              <>
                <br />
                <br />
                S'il est souhaité d'intégrer l'ECS à la PAC, il faut aller dans chauffage également
                pour définir que le chauffage sera PAC.
              </>
            )}
          </div>
        }
      />
      <ChipListField
        name={`${name}.typeModifFioul`}
        renderingOptions={{
          containerClassName: 'mb-md flex flex-wrap gap-xs',
        }}
        options={[
          {
            label: 'Changement pour un chauffe-eau électrique',
            value: 'Electrique',
          },
          {
            label: 'Intégrée à la chaudière gaz avec le chauffage',
            value: 'Gaz',
          },
          {
            label: 'Changement pour un chauffe-eau thermodynamique',
            value: 'Thermodynamique',
          },
          ...(isMaison
            ? [
                {
                  label: 'Intégrée à la PAC avec le chauffage',
                  value: 'PAC',
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
