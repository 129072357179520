import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleUpdateClientAccount = ({
  uuid,
  firstName,
  lastName,
  phoneNumber,
  projectUuid,
}: {
  uuid: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  projectUuid: string;
}): Promise<{ uuid: string } | undefined> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/clients/${uuid}`;

  return request(requestURL, {
    method: 'PUT',
    body: {
      firstName,
      lastName,
      phoneNumber,
      projectUuid,
    },
  });
};

export const useUpdateClientAccount = (): typeof handleUpdateClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleUpdateClientAccount,
    onSuccess: () => {
      toast.success('Le compte client a bien été mis à jour.');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
    onError: e => {
      toast.error(`Échec de la mise à jour du compte client : ${e}`);
    },
  });

  return mutateAsync;
};
