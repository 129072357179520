import { useQuery } from '@tanstack/react-query';

import { ProCompanyProfileAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyProfileAdminView';
import { request } from '@travauxlib/shared/src/utils/request';

export const PRO_COMPANY_KEY = 'pro-company';

export const useProCompany = (slug: string): ProCompanyProfileAdminView | undefined => {
  const { data } = useQuery<ProCompanyProfileAdminView>({
    queryKey: [PRO_COMPANY_KEY, slug],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies/${slug}/profile`;

      return request(requestURL);
    },
    refetchOnWindowFocus: false,
  });

  return data;
};
