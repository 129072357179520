import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { DemolitionFauxPlafond } from '@travauxlib/shared/src/types/api/domain/configurateur/OuverturesDemolition';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';

type Props = {
  configuration: Configuration;
};

export const FauxPlafonds: React.FC<Props> = ({ configuration }) => (
  <Accordion
    title="Démolition de faux plafond ?"
    prefix="2"
    className="mb-md"
    tooltip="S'il y a des spots encastrés dans le plafond, il est très probable qu'il s'agisse d'un faux plafond."
    defaultExpanded
  >
    <FieldArray<DemolitionFauxPlafond> name="demolitionFauxPlafond">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            return (
              <div key={name}>
                <MultiLocationsPicker
                  prefix="1"
                  name={`${name}.locationsUuid`}
                  configuration={configuration}
                  optionCheckbox={<OptionCheckbox fields={fields} index={index} value={value} />}
                  onDelete={() => fields.remove(index)}
                />
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
