import React from 'react';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';

import NoService from '../../assets/NoService.svg?react';

type Props = {
  name: string;
  description: string;
};

export const PaymentStatusDisabled: React.FC<Props> = ({ name, description }) => (
  <div className="px-md py-sm flex bg-neutral-0 sm-desktop:ml-md rounded-md sm-desktop:w-[20.5rem]">
    <NoService className="w-3xl h-3xl size-3xl text-neutral-600 shrink-0" />
    <div className="ml-xs w-full">
      <div className="text-b1 font-bold flex items-center">
        <span>{name}</span>
        <HelpCircle className="text-neutral-300 w-md h-md ml-xxs" />
      </div>
      <div className="text-neutral-600 text-b2">{description}</div>
    </div>
  </div>
);
