import { useCallback, useContext, useEffect } from 'react';

import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';

// Stolen from here
// https://stackoverflow.com/questions/71572678/react-router-v-6-useprompt-typescript/71587163#71587163

// Woops, it wouldn't work anymore
// new steal: https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
function useConfirmExit(confirmExit: () => boolean, when = true): void {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

type Payload = {
  when: boolean;
  message: string;
};

export function usePrompt({ message, when = true }: Payload): void {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
