import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';

type Props = {
  valueToCopy: string;
  copyText?: string;
  timeout?: number;
};

const DEFAULT_TIMEOUT = 5000;
export const CopyToClipboardButton: React.FC<Props> = ({
  valueToCopy,
  copyText = 'Copier',
  timeout = DEFAULT_TIMEOUT,
}) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timeoutId = window.setTimeout(() => {
        setCopied(false);
      }, timeout);
      return () => window.clearInterval(timeoutId);
    }
    return () => {};
  }, [copied]);
  return (
    <CopyToClipboard text={valueToCopy} onCopy={() => setCopied(true)}>
      <button
        data-testid="copy"
        className={classNames('!font-bold !ml-xxs text-primary bg-transparent underline', {
          '!text-success !no-underline': copied,
        })}
        disabled={copied}
        type="button"
      >
        {copied ? (
          <>
            <CheckSymbol className="w-md h-md inline" /> Copié
          </>
        ) : (
          copyText
        )}
      </button>
    </CopyToClipboard>
  );
};
