import React, { useMemo } from 'react';

import { Form } from 'react-final-form';
import { useLocation, useSearchParams } from 'react-router';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';
import { getUrlParams, stringifyParams } from '@travauxlib/shared/src/utils/urls';

import { useAuth } from 'api/useAuth';

import { ChantierTable } from './ChantierTable';

import { useChantiers } from '../api/useChantiers';
import { useCustomerCareAndTravauxPlannerMembers } from '../hooks/useSuiviByOptions';
import { CurrentTab, Filters, MoneyFilterValue } from '../types';
import { getFilteredChantiers } from '../utils/getFilteredChantiers';

const MoneyFilterOptions: { label: string; value: MoneyFilterValue }[] = [
  { label: 'Indifférent', value: 'indifferent' },
  { label: 'En totalité', value: 'en_totalite' },
  { label: 'En partie', value: 'en_partie' },
  { label: 'Pas du tout', value: 'pas_du_tout' },
  { label: 'Non applicable', value: 'n/a' },
];

type InjectedProps = {
  chantiers: ChantierAdminListItemView[];
};

type OwnProps = {
  debounce?: number;
};

const ChantiersTable: React.FC<InjectedProps & OwnProps> = ({ chantiers, debounce }) => {
  const [, setSearchParams] = useSearchParams();
  const urlFilters = getUrlParams(useLocation());
  const {
    adminUserProfile: { uuid: activePersonUuid },
  } = useAuth();

  const numChantiersSuiviByMe = chantiers.filter(
    c => c.responsableSuiviUuid === activePersonUuid,
  ).length;

  const DefaultFilters: Filters = {
    tab: 'en_cours',
    prestation: 'tous',
    suiviBy: numChantiersSuiviByMe > 0 ? activePersonUuid : 'tous',
    commissionCaptee: 'indifferent',
    prestationHemeaEncaissee: 'indifferent',
    versementALentreprise: 'indifferent',
    demarre: 'indifferent',
  };

  const filters: Filters = {
    ...DefaultFilters,
    ...urlFilters,
  } as Filters;

  const filteredChantiers = useMemo(() => getFilteredChantiers(chantiers, filters), [filters]);

  const onFilter = (values: Filters): void => {
    const newFilters = {
      ...values,
      suivi: values.prestation === 'travaux' ? values.suivi : undefined,
    };
    const searchParams = new URLSearchParams();
    for (let [key, value] of Object.entries(newFilters)) {
      if (DefaultFilters[key as keyof Filters] !== value && !!value) {
        searchParams.set(key, value);
      }
    }
    setSearchParams(searchParams);
  };

  const tabs = [
    ['En cours', 'en_cours'],
    ['En attente de provision', 'provision'],
    ['En attente de validation', 'validation'],
    ['En attente de libération', 'liberation'],
    ['Terminés / Annulés', 'termines'],
    ['Tous', 'tous'],
  ].map(([label, tab]: [string, CurrentTab]) => ({
    label: `${label} (${getFilteredChantiers(chantiers, { ...filters, tab }).length})`,
    to: `?${stringifyParams({ ...urlFilters, tab })}`,
    isActive: () => filters.tab === tab,
  }));

  const customerCareAndTravauxPlannerMembers = useCustomerCareAndTravauxPlannerMembers();
  return (
    <>
      <Form<Filters> initialValues={filters} initialValuesEqual={() => true} onSubmit={onFilter}>
        {() => (
          <>
            <form>
              <Card className="mb-md overflow-visible">
                <AutoSubmitForm debounce={debounce} skipValuesCompare />
                <div className="flex flex-wrap -mx-md md-desktop:mb-md">
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs px-md relative">
                    <DropdownField
                      label="Affectation interne"
                      name="suiviBy"
                      id="suiviBy"
                      options={customerCareAndTravauxPlannerMembers}
                    />
                  </div>
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs px-md relative">
                    <DropdownField
                      label="Prestation"
                      name="prestation"
                      id="prestation"
                      options={[
                        { label: 'Tous', value: 'tous' },
                        { label: 'Travaux', value: 'travaux' },
                        { label: 'Conception', value: 'conception' },
                      ]}
                    />
                  </div>
                  {filters.prestation === 'travaux' && (
                    <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs flex-grow px-md relative">
                      <DropdownField
                        label="Suivi"
                        name="suivi"
                        id="suivi"
                        options={[
                          { label: 'Tous', value: 'tous' },
                          typeSuiviWordings.customer_care,
                          typeSuiviWordings.travaux_planner,
                          typeSuiviWordings.architecte,
                        ]}
                      />
                    </div>
                  )}
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs px-md relative">
                    <InputField
                      label="ID deal Pipedrive"
                      name="pipedriveDealId"
                      id="pipedriveDealId"
                    />
                  </div>
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs flex-grow px-md relative">
                    <InputField
                      label="Compte client"
                      name="clientAccount"
                      id="clientAccount"
                      placeholder="Nom, prénom ou email"
                    />
                  </div>
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs flex-grow px-md relative">
                    <InputField label="Entreprise" name="proCompanyName" id="proCompanyName" />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-md md-desktop:!mb-lg">
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs flex-grow px-md relative">
                    <DropdownField
                      label="Prestation hemea encaissée"
                      name="prestationHemeaEncaissee"
                      id="prestationHemeaEncaissee"
                      options={MoneyFilterOptions}
                    />
                  </div>
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs flex-grow px-md relative">
                    <DropdownField
                      label="Commission captée"
                      name="commissionCaptee"
                      id="commissionCaptee"
                      options={MoneyFilterOptions}
                    />
                  </div>
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs flex-grow px-md relative">
                    <DropdownField
                      label="Versement à l'entreprise"
                      name="versementALentreprise"
                      id="versementALentreprise"
                      options={[
                        { label: 'Indifférent', value: 'indifferent' },
                        { label: 'Au moins un', value: 'au_moins_un' },
                        { label: 'Aucun', value: 'aucun' },
                        { label: 'Non applicable', value: 'n/a' },
                      ]}
                    />
                  </div>
                  <div className="w-full sm-desktop:w-3/12 md-desktop:w-2/12 my-xs px-md relative">
                    <DropdownField
                      label="Démarré"
                      name="demarre"
                      id="demarre"
                      options={[
                        { label: 'Indifférent', value: 'indifferent' },
                        { label: 'Oui', value: 'oui' },
                        { label: 'Non', value: 'non' },
                      ]}
                    />
                  </div>
                </div>
              </Card>
            </form>
          </>
        )}
      </Form>
      <div className="mb-md">
        <Tabs items={tabs} buttonClassNames="bg-lighter" />
      </div>
      <ChantierTable chantiers={filteredChantiers} />
    </>
  );
};

export const ChantierListing: React.FC<OwnProps> = ({ debounce }) => {
  const { chantiers, isLoading } = useChantiers();
  if (isLoading) {
    return <Loader />;
  }
  return <ChantiersTable chantiers={chantiers} debounce={debounce} />;
};
