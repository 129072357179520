import { create } from 'zustand';

import { Answers, HubClosed } from '@travauxlib/shared/src/types/api/domain/configurateur';

type ConfigurationStore = {
  hubClosed: HubClosed;
  setHubClosed: (hubId: keyof Answers, close: boolean) => void;
};

export const useConfigurationStore = create<ConfigurationStore>()(set => ({
  hubClosed: {},
  setHubClosed: (hubId, close) =>
    set(state => ({ hubClosed: { ...state.hubClosed, [hubId]: close } })),
}));
