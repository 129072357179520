import React from 'react';

import Warning from '@travauxlib/shared/src/components/DesignSystem/assets/Warning.svg?react';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { useAuth } from 'api/useAuth';
import { usePerson } from 'features/Trombinoscope/api/usePersons';

type OwnProps = {};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

const InformationRow = ({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactElement | undefined;
}): React.ReactElement => (
  <div className="flex items-center mb-sm">
    <div className="mr-xs">{label} :</div>
    {value ? (
      <div className="font-medium">{value}</div>
    ) : (
      <div className="flex items-center gap-xxs">
        <Warning className="size-md text-warning-800" />
        <span className='className="text-neutral-700'>Non renseigné</span>
      </div>
    )}
  </div>
);

const asujettiTVAValue = (asujettiTVA?: boolean): string | undefined => {
  if (asujettiTVA === undefined) {
    return undefined;
  }
  return asujettiTVA ? 'Oui' : 'Non';
};

export const InformationsFacturationModal: React.FC<Props> = ({ handleClose }) => {
  const {
    adminUserProfile: { uuid },
  } = useAuth();
  const { isLoading, person } = usePerson(uuid);

  return (
    <ModalContent
      validateAction={{
        label: 'Fermer',
        onClick: handleClose,
      }}
      title="Informations facturation"
    >
      {!uuid || isLoading ? (
        <Loader />
      ) : (
        <div>
          <InformationRow label="Numéro de TVA" value={person?.cdplInformation?.numeroTVA} />
          <InformationRow label="Siret" value={person?.cdplInformation?.siret} />
          <InformationRow
            label="Date de création"
            value={person?.cdplInformation?.companyCreationDate}
          />
          <InformationRow label="Ville du RCS" value={person?.cdplInformation?.RCS} />
          <InformationRow label="Statut" value={person?.cdplInformation?.statutEntreprise} />
          <InformationRow
            label="Capital"
            value={<EURCurrency amount={person?.cdplInformation?.capital} />}
          />
          <InformationRow
            label="Titulaire du compte"
            value={person?.cdplInformation?.bankAccountOwner}
          />
          <InformationRow label="IBAN" value={person?.cdplInformation?.iban} />
          <InformationRow label="Code BIC/SWIFT" value={person?.cdplInformation?.bic} />
          <InformationRow
            label="Assujetti à la tva"
            value={asujettiTVAValue(person?.cdplInformation?.asujettiTVA)}
          />
        </div>
      )}
    </ModalContent>
  );
};

export const useInformationsFacturation = (): (() => void) => {
  const openModal = useOpenModal();

  return () =>
    openModal(InformationsFacturationModal, {
      closable: true,
    });
};
