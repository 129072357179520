import React from 'react';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

type Props = {
  createPreChiffrage: () => void;
  createConfiguration: () => void;
};

export const EmptyStateCadrage: React.FC<Props> = ({ createPreChiffrage, createConfiguration }) => (
  <div className="flex flex-col items-center justify-center grow">
    <EmptyState
      illustration={<ProjectPicto />}
      iconIllustrationSize="md"
      description="Vous n'avez pas encore créé de configuration ou fait de préchiffrage"
    />
    <div className="flex flex-col sm-desktop:flex-row mt-md">
      <Button className="rounded-r-none" onClick={createPreChiffrage} leftIcon={<PlusSymbol />}>
        Créer un préchiffrage
      </Button>
      <Button
        onClick={createConfiguration}
        className="rounded-l-none sm-desktop:ml-[1px] sm-desktop:mt-0 mt-md mb-md sm-desktop:mb-0"
        leftIcon={<PlusSymbol />}
      >
        Créer la configuration
      </Button>
    </div>
  </div>
);
