import React, { useState } from 'react';

import { Link } from 'react-router';

import SearchEmptyState from '@travauxlib/shared/src/components/DesignSystem/assets/SearchEmptyState.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { FactureStatuses } from '@travauxlib/shared/src/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { CDPLFacture } from '../types';

type ActionButtonProps = {
  onClick: (selectedTokens: string[]) => void;
  label: string;
};

export type CDPLFactureTableProps = {
  factures: CDPLFacture[];
  archiveAction?: (token: string) => void;
  status?: FactureStatuses;
  showPerson?: boolean;
  action?: ActionButtonProps;
  submitting?: boolean;
};

export const CDPLFactureTable: React.FC<CDPLFactureTableProps> = ({
  factures,
  archiveAction,
  status = FactureStatuses.draft,
  showPerson = true,
  action,
  submitting,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const shouldShowDateColumn = factures.length > 0 && !factures[0]?.archived;

  const dateColumnConfig: ColumnConfig<CDPLFacture> | undefined = shouldShowDateColumn
    ? status === FactureStatuses.paid
      ? {
          name: 'Date de règlement',
          columnKey: 'paidAt',
          renderValue: (paidAt: string) => <div>{formatFrenchDate(paidAt)}</div>,
        }
      : {
          name: 'Date de demande de paiement',
          columnKey: 'createdAt',
          renderValue: (createdAt: string) => <div>{formatFrenchDate(createdAt)}</div>,
        }
    : undefined;

  const handleClickItem = (token: string): void => {
    const isChecked = selectedItems.includes(token);
    if (isChecked) {
      setSelectedItems(selectedItems.filter(t => t !== token));
    } else {
      setSelectedItems([...selectedItems, token]);
    }
  };

  const checkboxColumn = {
    hideSort: true,
    name: 'Sélectionnée',
    renderValue: (_: unknown, item: CDPLFacture) => (
      <Checkbox
        onClick={e => {
          handleClickItem(item.token);
          e.stopPropagation();
        }}
        data-testid="select-item"
        checked={selectedItems.includes(item.token)}
      />
    ),
  };

  return (
    <Card state="filled" className="my-md">
      {factures.length === 0 ? (
        <EmptyState description="Aucune facture" illustration={<SearchEmptyState />} />
      ) : (
        <div>
          <Table<CDPLFacture>
            itemsPerPage={14}
            columnConfigs={[
              ...(action ? [checkboxColumn] : []),
              {
                name: 'Numéro',
                columnKey: 'numero',
              },
              ...(showPerson
                ? [
                    {
                      name: 'CDPL',
                      columnKey: 'personFullName' as keyof CDPLFacture,
                    },
                  ]
                : []),
              {
                name: 'Désignation',
                columnKey: 'title',
              },
              {
                name: 'Chantier',
                renderValue: (_, facture: CDPLFacture) => (
                  <Link to={`/chantiers/${facture.chantierSlug}`}>{facture.chantierTitle}</Link>
                ),
              },
              ...(dateColumnConfig ? [dateColumnConfig] : []),
              {
                name: 'Montant TTC',
                columnKey: 'montantTTC',
                renderValue: (montantTTC: number) => <EURCurrency amount={montantTTC} />,
              },
            ]}
            rowActions={({ token }) => [
              {
                label: 'Voir',
                action: () => window.open(`${APP_CONFIG.appURL}/factures/${token}`),
              },
              ...(archiveAction
                ? [
                    {
                      label: 'Archiver',
                      action: () => archiveAction(token),
                    },
                  ]
                : []),
            ]}
            items={factures}
            defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
          />

          {action && (
            <div className="text-right mt-md">
              <Button
                type="button"
                loading={submitting}
                disabled={selectedItems.length === 0}
                onClick={() => action.onClick(selectedItems)}
              >
                {action.label}
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
