import React, { useEffect } from 'react';

import { useLocation } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { getUrlParams } from '@travauxlib/shared/src/utils/urls';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { Table } from 'features/DashboardCDP/components/Table';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { useLeadClients } from './api/useLeadClients';
import { LeadForm } from './components/Form';

export const Leads: React.FC = () => {
  const location = useLocation();
  const criteria = getUrlParams(location);
  const { leads, isLoading } = useLeadClients(criteria);

  useEffect(() => {
    NavigationTracking.onLeadsViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.leads} />
      <div>
        <LeadForm initialValues={criteria} />
        {isLoading ? <Loader /> : <Table leads={leads} />}
      </div>
    </>
  );
};
