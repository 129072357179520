import React, { useRef } from 'react';

import Architecture from '@travauxlib/shared/src/components/DesignSystem/assets/Architecture.svg?react';
import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/ConstructionFilled.svg?react';
import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ChipPicto } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/ChipPicto';
import {
  ColumnConfig,
  Table,
} from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/types';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';
import { ClientAccount, DevisStatuses } from '@travauxlib/shared/src/types';
import { scrollFirstChild } from '@travauxlib/shared/src/utils/dom';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useSignInAsClient } from 'api/useSignInAsClient';
import Chantiers from 'assets/Chantiers.svg?react';

import { useToggleConformiteDevis } from '../api/useToggleConformiteDevis';
import { ProjectAdminDevisProCompanyView, ProjectAdminDevisView } from '../types';

type Props = {
  proCompanyProjectsAndDevis: ProjectAdminDevisProCompanyView[];
  projectConformiteDevisEnabled: boolean;
  projectUuid: string;
  clientAccount?: ClientAccount;
};

const tagOptions: { [Status in DevisStatuses]: { label: string; variant: Variant } } = {
  sent_hemea: {
    variant: 'info',
    label: 'Devis à relire',
  },
  sent: {
    variant: 'success',
    label: 'Envoyé au client',
  },
  rejected_hemea: {
    variant: 'warning',
    label: 'Rejeté par hemea',
  },
  to_be_revised_hemea: {
    variant: 'warning',
    label: "Renvoyé à l'entreprise",
  },
  signed: {
    variant: 'success',
    label: 'Devis signé',
  },
  to_be_revised: {
    variant: 'light',
    label: 'Commentaires consultés',
  },
  canceled: {
    variant: 'error',
    label: 'Annulé',
  },
  rejected: {
    variant: 'error',
    label: 'Rejeté',
  },
  draft: {
    variant: 'light',
    label: 'Brouillon',
  },
};

const columnConfig: ColumnConfig<ProjectAdminDevisView>[] = [
  {
    name: 'Numéro',
    columnKey: 'numero',
    renderValue: (numero: number, row) => (
      <div className="flex items-center">
        {row.status === DevisStatuses.sent_hemea ? (
          <Badge size="xs" className="mr-xs" variant="error" value={1} />
        ) : (
          <></>
        )}
        {numero}
      </div>
    ),
  },
  {
    name: 'Nom',
    columnKey: 'title',
  },
  {
    name: 'Montant HT',
    columnKey: 'montantHtTotal',
    renderValue: (amount: number) => <EURCurrency amount={amount} />,
  },
  {
    name: 'Marge',
    columnKey: 'montantMargeTotale',
    renderValue: (amount: number) => <EURCurrency amount={amount} />,
  },
  {
    name: 'Status',
    columnKey: 'status',
    renderValue: (status: DevisStatuses) => <Tag {...tagOptions[status]} />,
  },
  {
    name: 'Date',
    columnKey: 'createdAt',
    renderValue: (_, element) => (
      // TODO: Handle all the cases (sent, created at etc)
      <>Crée le {formatFrenchDate(element.createdAt)}</>
    ),
  },
];

export const DevisListing: React.FC<Props> = ({
  proCompanyProjectsAndDevis,
  projectConformiteDevisEnabled,
  projectUuid,
  clientAccount,
}) => {
  const handleSignIn = useSignInAsClient();
  const [selectedProCompanyUuid, setSelectedProCompanyUuid] = React.useState<string | undefined>();
  const scrollingContainer = useRef<HTMLDivElement>(null);
  const { isScrollLeft, isScrollRight } = useScrollStatus(scrollingContainer);

  React.useEffect(() => {
    if (!selectedProCompanyUuid) {
      const fromQueryProCompanyUuid = proCompanyProjectsAndDevis?.[0]?.uuid;
      if (fromQueryProCompanyUuid) {
        setSelectedProCompanyUuid(fromQueryProCompanyUuid);
      }
    }
  }, [JSON.stringify(proCompanyProjectsAndDevis)]);

  const selectedProCompanyEntity = proCompanyProjectsAndDevis.find(
    p => p.uuid === selectedProCompanyUuid,
  );

  const devisToDisplay = selectedProCompanyEntity?.proProjects ?? [];

  const toggleConformiteDevis = useToggleConformiteDevis(projectUuid);

  const onPrevButtonClick = (): void => {
    if (!scrollingContainer?.current) {
      return;
    }
    scrollFirstChild(scrollingContainer.current, 'prev');
  };

  const onNextButtonClick = (): void => {
    if (!scrollingContainer?.current) {
      return;
    }
    scrollFirstChild(scrollingContainer.current, 'next');
  };

  return (
    <div className="pt-lg">
      <div className="flex justify-between items-center mb-md">
        {!isScrollLeft && (
          <IconButton
            className="mr-xs"
            type="button"
            variant="white"
            dataTestId="prev-button"
            onClick={() => onPrevButtonClick()}
            size="lg"
          >
            <ChevronLeft />
          </IconButton>
        )}
        <div
          className="flex gap-md overflow-x-auto flex-nowrap w-full no-scrollbar pt-md pb-md"
          ref={scrollingContainer}
        >
          {proCompanyProjectsAndDevis.map(proCompany => (
            <ChipPicto
              key={proCompany.uuid}
              leftIcon={proCompany.isArchitecte ? <Architecture /> : <Construction />}
              label={proCompany.name}
              helperText={proCompany.isArchitecte ? 'Architecte' : 'Entreprise'}
              onClick={() => setSelectedProCompanyUuid(proCompany.uuid)}
              isActive={proCompany.uuid === selectedProCompanyUuid}
              fullColor
              className="pr-md shrink-0"
              badgeCount={
                proCompany.proProjects
                  .flatMap(pp => pp.devis)
                  ?.filter(d => d.status === DevisStatuses.sent_hemea)?.length
              }
            />
          ))}
        </div>
        {!isScrollRight && (
          <IconButton
            className="ml-xs"
            type="button"
            dataTestId="next-button"
            onClick={() => onNextButtonClick()}
            size="lg"
          >
            <ChevronRight />
          </IconButton>
        )}
        <div className="flex items-center">
          <div>Validation des devis avant envoi</div>
          <div className="ml-xs flex items-center">
            <Toggle
              checked={projectConformiteDevisEnabled}
              onClick={() => toggleConformiteDevis({ newValue: !projectConformiteDevisEnabled })}
              data-testid="toggle-conformite"
            />
          </div>
        </div>
      </div>
      <div className="h-full">
        {devisToDisplay.length === 0 && (
          <div className="h-full w-full flex items-center justify-center">
            <div className="flex flex-col justify-center items-center">
              <div>
                <Chantiers />
              </div>
              <div className="font-bold text-b1">Aucun devis</div>
              <div className="text-b2 text-neutral-600">
                L'entreprise n'a pas encore envoyé de devis
              </div>
            </div>
          </div>
        )}
        {devisToDisplay.map(proProject => (
          <Card key={proProject.proProjectUuid} className="mb-md">
            <div>
              <span className="text-h5 font-bold">{proProject.title}</span>
            </div>
            <div>
              <span className="text-b1 text-neutral-800">
                {proProject.devis[0]?.isTs ? 'Avenant' : 'Devis initial'}
              </span>
              <span className="ml-xs text-b2 text-neutral-600">
                {Math.max(...proProject.devis.map(d => d.version))} version du devis
              </span>
            </div>
            <Table<ProjectAdminDevisView>
              columnConfigs={columnConfig}
              items={proProject.devis}
              onRowClick={async item => {
                if (clientAccount) {
                  await handleSignIn(clientAccount.uuid, `pro/devis/${item.token}`);
                }
              }}
            />
          </Card>
        ))}
      </div>
    </div>
  );
};
