import React from 'react';

import classNames from 'classnames';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';

type Props = {
  title?: string;
  description?: string;
  tooltip?: string | React.ReactElement;
  prefix?: string;
  alert?: string;
};

export const QuestionTitle: React.FC<Props> = ({ title, description, tooltip, prefix, alert }) => {
  if (!title) {
    return null;
  }
  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          'relative flex items-center',
          description || alert ? 'mb-xxs' : 'mb-md',
        )}
      >
        {prefix && (
          <ColoredCircle variant="secondary" size="xs" className="absolute -left-xl mr-xs">
            {prefix}
          </ColoredCircle>
        )}
        <div className="text-b1 font-bold text-neutral-800 mr-xxs">{title}</div>
        {tooltip && (
          <WithTooltip
            width="50vw"
            position="bottom"
            trigger={
              <span>
                <HelpCircle className="text-neutral-600 w-md h-lg" />
              </span>
            }
          >
            <div>{tooltip}</div>
          </WithTooltip>
        )}
      </div>
      {description && <div className="text-b1 mb-md text-neutral-700">{description}</div>}
      {alert && (
        <div className="mb-md">
          <InlineAlert variant="warning" className="py-0">
            {alert}
          </InlineAlert>
        </div>
      )}
    </div>
  );
};
