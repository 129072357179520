import React from 'react';

import { useMatch } from 'react-router';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';

import { PartnerAgency } from 'types';

export const usePartnerTabs = (
  agency?: PartnerAgency,
): Array<{ to: string; label: string; modalTitle: string }> => {
  const match = useMatch('partners/:uuid/*');

  const organizationTab = {
    to: `${match?.pathnameBase}`,
    label: 'Partenaire',
    modalTitle: 'Modifier le partenaire',
    end: true,
  };
  const agenciesTab = {
    to: `${match?.pathnameBase}/agencies`,
    label: 'Agences',
    modalTitle: 'Agences',
    end: true,
  };
  const agencyTab = {
    to: `${match?.pathnameBase}/agencies/${agency?.uuid}`,
    label: `Agence ${agency?.name}`,
    modalTitle: "Modifier l'agence",
    end: true,
  };
  const allAccountsTab = {
    to: `${match?.pathnameBase}/accounts`,
    label: 'Tous les comptes',
    modalTitle: 'Tous les comptes',
    end: true,
  };
  const agencyAccountsTab = {
    to: `${match?.pathnameBase}/agencies/${agency?.uuid}/accounts`,
    label: `Comptes de l'agence ${agency?.name}`,
    modalTitle: "Les comptes de l'agence",
    end: true,
  };

  const constantTabs = [organizationTab, agenciesTab, allAccountsTab];

  return agency ? [...constantTabs, agencyTab, agencyAccountsTab] : constantTabs;
};

type Props = {
  agency?: PartnerAgency;
};

export const PartnerTabs: React.FC<Props> = ({ agency }) => {
  const tabs = usePartnerTabs(agency);

  return (
    <div className="mb-md">
      <Tabs items={tabs} />
    </div>
  );
};
