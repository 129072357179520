import React from 'react';

import { Outlet, useOutletContext } from 'react-router';

import { ProjectContext } from 'features/Project/api/useProjectContext';

export const ProjectFournitureLayout: React.FC = () => {
  const context = useOutletContext<ProjectContext>();

  return (
    <div className="-mx-md sm-desktop:-mx-xl sm-desktop:pl-[3.25rem] sm-desktop:pr-[7rem] sm-desktop:mb-xl">
      <Outlet context={context} />
    </div>
  );
};
