import { useQuery } from '@tanstack/react-query';

import { ChantierAdminListItemView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminListItemView';
import { request } from '@travauxlib/shared/src/utils/request';

type RequestResult = {
  data: ChantierAdminListItemView[];
};

const fetchChantiers = (): Promise<RequestResult> =>
  request(`${APP_CONFIG.apiURL}/admin/chantiers`);

export const useChantiers = (): {
  isLoading: boolean;
  chantiers: ChantierAdminListItemView[];
} => {
  const { isLoading, data } = useQuery<RequestResult>({
    queryKey: ['chantiers'],
    queryFn: fetchChantiers,
  });
  return { isLoading, chantiers: data?.data || [] };
};
