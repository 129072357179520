import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { ChipPictoVerticalListFieldConfigurateur } from 'features/Configurateur/components/ChipPictoVerticalListFieldConfigurateur';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';
import { getLocationOptions } from 'features/Configurateur/utils/getLocationOptions';

type Props = {
  configuration: Configuration;
  prefix: string;
  name: string;
};

export const LocalisationPacAInstaller: React.FC<Props> = ({ configuration, prefix, name }) => {
  const locationOptions = getLocationOptions(configuration);
  return (
    <>
      <QuestionTitle prefix={prefix} title="Localisation de la PAC à installer ?" />
      <ChipPictoVerticalListFieldConfigurateur
        name={`${name}.posePompeAChaleurLocationUuid`}
        locationOptions={locationOptions}
      />
    </>
  );
};
