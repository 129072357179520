import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { LeadStatus } from '@travauxlib/shared/src/types/api/domain/client/LeadStatus';
import { request } from '@travauxlib/shared/src/utils/request';

import { AffiliateFilter, Department, Lead } from 'types';

export const LEADS_KEY = 'leads';

export type FetchPayload = {
  startDate?: string;
  endDate: string;
  allDepartments: Department[];
  affiliateFilter: AffiliateFilter;
  status: LeadStatus[];
  dealOwners?: string[];
  firstClickSources?: string[];
  departments?: string[];
};

const fetchLeads = ({
  queryKey: [, payload],
}: QueryFunctionContext<[string, FetchPayload]>): Promise<{ data: Lead[] }> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/leads`;
  return request(requestURL, {
    method: 'GET',
    params: payload,
  });
};

export const useLeads = ({
  departments,
  allDepartments,
  ...payload
}: FetchPayload): { isLoading: boolean; leads: Lead[] } => {
  const sentDepartments =
    departments?.flatMap(
      value => allDepartments.find(dep => dep.value === value)?.departments || [value],
    ) || [];

  const { isLoading, data } = useQuery<{
    data: Lead[];
  }>({
    queryKey: [
      LEADS_KEY,
      {
        ...payload,
        departments: sentDepartments,
      },
    ],
    queryFn: fetchLeads,
    refetchInterval: 4000,
  });

  return { isLoading, leads: data?.data || [] };
};
