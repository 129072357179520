import React from 'react';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import PhotoCamera from '@travauxlib/shared/src/components/DesignSystem/assets/PhotoCamera.svg?react';
import Style from '@travauxlib/shared/src/components/DesignSystem/assets/Style.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { DownloadableFileWithTag } from '@travauxlib/shared/src/types';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';
import { documentsSections } from '@travauxlib/shared/src/utils/project';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { DownloadAllButton } from 'features/Project/features/ProjectDocumentsViewer/components/DownloadAllButton';

import { DocumentMenu } from './DocumentMenu';
import { ImagesCarousel } from './ImagesCarousel';

type Props = {
  documents: DownloadableFileWithTag[];
};

export const DocumentsSection: React.FC<Props> = ({ documents }) => {
  const inspirations = documents.filter(document => document.tag === DocumentTag.Decoration);
  const photos = documents.filter(document => document.tag === DocumentTag.Photo);
  const { decoration, photo, ...otherDocuments } = documentsSections;

  return (
    <Card className="!rounded-md" state="elevated">
      <div className="flex grow items-center gap-xs mb-md flex-wrap">
        <span className="text-h4 font-bold">Documents clients</span>
        <Link className="!text-b1" to="../documents" disabled={documents.length < 1} inline>
          Voir plus
        </Link>
        <DownloadAllButton
          className="ml-auto"
          zipName="images"
          urlsToDownload={documents.map(document => document.downloadUrl)}
        />
      </div>
      <ImagesCarousel
        title="Inspirations"
        images={inspirations}
        icon={<Style />}
        emptyState={
          <div className="pt-[3.75rem] pb-md">
            <EmptyState
              title="Aucune inspiration déposée"
              description="Votre client n’a pas encore déposé d’inspiration sur son espace client"
              icon={<Style />}
            />
          </div>
        }
      />
      <ImagesCarousel
        title="Photos du bien"
        images={photos}
        icon={<PhotoCamera />}
        emptyState={
          <div className="pt-[3.75rem] pb-md">
            <EmptyState
              title="Aucune photo déposée"
              description="Votre client n'a pas encore déposé de photos sur son espace client"
              icon={<PhotoCamera />}
            />
          </div>
        }
      />
      {Object.entries(otherDocuments).map(([tag, { icon, label }]) => {
        const parsedTag = tag as DocumentTag;
        const isMandatory = parsedTag !== DocumentTag.Other;
        const documentsForTag = documents.filter(document => document.tag === tag);
        const documentUploadedAt = documentsForTag?.[0]?.createdAt;
        const hasDocuments = documentsForTag.length > 0;

        return (
          <ListItem
            className="last:border-b-0 text-b2 !rounded-none"
            key={tag}
            state="filled"
            size="sm"
            left={{ type: 'iconBackground', icon, variant: 'beige', className: 'mt-xxs' }}
            middle={{ title: <span className="font-normal">{label}</span> }}
            right={{
              type: 'jsx',
              children: (
                <DocumentMenu
                  disabled={!hasDocuments}
                  tag={parsedTag}
                  urlsToDownload={documentsForTag.map(document => document.downloadUrl)}
                />
              ),
            }}
          >
            {documentUploadedAt ? (
              <div className="-mt-xs text-neutral-600">
                Ajouté le{' '}
                <span className="text-neutral-800">{formatFrenchDate(documentUploadedAt)}</span>
              </div>
            ) : isMandatory ? (
              <>
                <div className="flex items-center gap-xxs -mt-xs text-info-800">
                  <InfoCircle className="w-xmd h-xmd" />
                  Manquant
                </div>
              </>
            ) : (
              <div className="-mt-xs text-neutral-600">Facultatif</div>
            )}
          </ListItem>
        );
      })}
    </Card>
  );
};
