import React from 'react';

import Preview from '@travauxlib/shared/src/components/DesignSystem/assets/Preview.svg?react';
import { FileDropzonePreview } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/FileDropzonePreview';
import { Thumbnail } from '@travauxlib/shared/src/components/DesignSystem/components/Thumbnail';
import { FournitureReference } from '@travauxlib/shared/src/types';

export type Props = {
  reference?: FournitureReference;
};

export const FournitureReferenceInfo: React.FC<Props> = ({
  reference = {} as FournitureReference,
}) => {
  const { fournisseur, referenceNumber, designation, images = [], mainImageUuid } = reference;

  if (!referenceNumber || !designation || !fournisseur) {
    return <Preview className="sm-desktop:w-full" />;
  }

  const imageToDisplay = images.find(image => image.uuid === mainImageUuid) || images?.[0];

  return (
    <div className="flex gap-sm w-full">
      {imageToDisplay ? (
        <FileDropzonePreview file={imageToDisplay} displayMode="pictures-wall" small />
      ) : (
        <Thumbnail size="lg" />
      )}
      <div className="flex flex-col gap-3xs grow truncate">
        <div className="text-b2 font-medium text-neutral-800 truncate">{designation}</div>
        <div className="flex justify-between gap-xxs">
          <div className="text-b2 text-neutral-700">Fournisseur</div>
          <div className="text-b2 text-neutral-800 truncate">{fournisseur}</div>
        </div>
        <div className="flex justify-between gap-xxs">
          <div className="text-b2 text-neutral-700">N° de référence</div>
          <div className="text-b2 text-neutral-800 truncate">{referenceNumber}</div>
        </div>
      </div>
    </div>
  );
};
