import React from 'react';

import classNames from 'classnames';

import { textClassNamesBySize, textClassNamesByVariant } from './constants';
import { Icon } from './Icon';
import { Size, Variant } from './types';

type Props = {
  children: React.ReactNode;
  variant: Variant;
  size?: Size;
  className?: string;
};

export const InlineAlert: React.FC<Props> = ({ children, variant, size = 'sm', className }) => (
  <div className={classNames('flex items-center gap-xxs grow', className)}>
    <Icon variant={variant} size={size} />
    <div
      className={classNames(
        'w-full flex font-normal',
        textClassNamesByVariant[variant],
        textClassNamesBySize[size],
      )}
    >
      {children}
    </div>
  </div>
);
