import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

import { useSignInAsPro } from 'features/Entreprises/api/useSignInAsPro';

type Props = {
  proCompany: ProCompanyAdminView;
};

export const ProUsers: React.FC<Props> = ({ proCompany }) => {
  const { proUsers = [] } = proCompany;
  const handleSignInAsPro = useSignInAsPro();

  return (
    <div>
      {proUsers
        .sort((a, b) => +b.isProCompanyAdmin - +a.isProCompanyAdmin)
        .map(user => (
          <div key={user.id} className="!mb-sm">
            {user.fullName}
            {user.isProCompanyAdmin && (
              <small className="ml-sm uppercase bg-success text-white px-sm">Admin</small>
            )}
            <br />
            {user.email}
            <br />
            {user.phoneNumber}
            <Button
              variant="tertiary"
              type="button"
              onClick={() => handleSignInAsPro(user.id!)}
              size="sm"
            >
              Se connecter à son compte
            </Button>
          </div>
        ))}
      <div className="!mb-md">
        <Link to={`/entreprises/${proCompany.slug}/users`}>
          <Button variant="tertiary" size="sm">
            Inviter un nouvel utilisateur
          </Button>
        </Link>
      </div>
    </div>
  );
};
