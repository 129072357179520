import React from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'react-router';

import { FournituresWithLocation } from '@travauxlib/shared/src/types';

import { FournitureCardsAndFilter } from './components/FournitureCardsAndFilter';
import { useFournituresByTabAndTotals } from './hooks/useFournituresByTabAndTotals';
import { EditAddReferenceActionProps, UserType } from './types';

import { Tabs } from '../../components/DesignSystem/components/Tabs';
import { ScrollToHashElement } from '../../components/ScrollToHashElement';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';

export type Props = {
  containerClassName?: string;
  editOrAddReferenceAction?: EditAddReferenceActionProps;
  fournitureList: FournituresWithLocation[];
  userType: UserType;
};

export const Fournitures: React.FC<Props> = ({
  editOrAddReferenceAction,
  containerClassName,
  fournitureList,
  userType,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  const [searchParams] = useSearchParams();
  const activeReferenceTab = searchParams.get('type');
  const isAddedReferencesTab = activeReferenceTab === 'ajoutees' || !activeReferenceTab;

  const { fournituresWithRef, fournituresWithoutRef, ...totals } =
    useFournituresByTabAndTotals(fournitureList);
  const { withRefCount, withoutRefCount, total } = totals;
  const completionPercentage = (withRefCount / total) * 100;

  const fournituresByTab = isAddedReferencesTab ? fournituresWithRef : fournituresWithoutRef;

  return (
    <div className={classNames('bg-neutral-100 flex flex-col grow', containerClassName)}>
      <ScrollToHashElement />
      <FournitureCardsAndFilter
        allFournitures={fournitureList}
        fournituresByTab={fournituresByTab}
        isAddedReferencesTab={isAddedReferencesTab}
        editOrAddReferenceAction={editOrAddReferenceAction}
        userType={userType}
        totals={totals}
        completionPercentage={completionPercentage}
        tabs={
          <Tabs
            className="mb-md sm-desktop:!w-fit"
            fullWidth={isTabletOrMobile}
            items={[
              {
                label: 'Ajoutées',
                badge: {
                  value: withRefCount,
                  size: 'sm',
                  variant: isAddedReferencesTab ? 'primary' : 'light',
                },
                to: { search: 'type=ajoutees' },
                isActive: () => isAddedReferencesTab,
              },
              {
                label: 'Manquantes',
                badge: {
                  value: withoutRefCount,
                  size: 'sm',
                  variant: isAddedReferencesTab
                    ? withoutRefCount > 0
                      ? 'error'
                      : 'light'
                    : 'primary',
                },
                to: { search: 'type=manquantes' },
                isActive: () => !isAddedReferencesTab,
              },
            ]}
          />
        }
      />
    </div>
  );
};
