import React from 'react';

import classNames from 'classnames';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { safeFormat } from '@travauxlib/shared/src/utils/time';

import { HistoryItem } from '../../types';
import { getAuthorDisplay } from '../../utils';

type Props = {
  historyItem: HistoryItem;
};

export const FluxFinanciersHistoriqueItem: React.FC<Props> = ({ historyItem }) => {
  const {
    date,
    author,
    label,
    Icon,
    variant,
    actions,
    amount,
    cancellationInfo,
    validationInfo,
    key,
    isIgnored,
    isUserVisible,
    pendingInfo,
  } = historyItem;

  return (
    <div className="flex sm-desktop:items-center sm-desktop:flex-row flex-col justify-between border-b-1 border-gray-300 last:border-b-0 sm-desktop:pl-xs py-[6px]">
      <div className="flex sm-desktop:flex-row flex-col items-center">
        <ColoredCircle size="sm" variant={variant} disabled={!!cancellationInfo}>
          <Icon />
        </ColoredCircle>
        <div className="py-xxs sm-desktop:pl-xs text-center sm-desktop:text-start">
          <div className="text-b2 flex sm-desktop:flex-row flex-col sm-desktop:items-center">
            <span className={classNames(cancellationInfo && 'line-through')}>{label}</span>
            {isIgnored && (
              <div className="ml-xxs">
                <Tag label="Opération technique" variant="light" size="sm" />
                {isUserVisible !== undefined && (
                  <span className="italic text-sm text-neutral-600 ml-xxs">{`(${isUserVisible ? 'Visible' : 'Cachée'})`}</span>
                )}
              </div>
            )}
            {pendingInfo && (
              <WithTooltip
                width="20.5rem"
                trigger={
                  <div>
                    <Tag
                      size="sm"
                      className="ml-xxs"
                      rightIcon={<HelpCircle />}
                      label={pendingInfo.label}
                      variant="info"
                    />
                  </div>
                }
              >
                <div>{pendingInfo.tooltipMessage}</div>
              </WithTooltip>
            )}

            {cancellationInfo && (
              <div>
                <Tag label="Supprimé" size="sm" className="mx-xxs my-xxs" variant="warning" />
                <span className="text-neutral-600 italic text-sm">
                  (Annulé {cancellationInfo.date}
                  {getAuthorDisplay(cancellationInfo.author)})
                </span>
              </div>
            )}
            {validationInfo && (
              <div>
                <span className="text-neutral-600 italic text-sm mx-xxs">
                  (Validé {validationInfo.date}
                  {getAuthorDisplay(validationInfo.author)})
                </span>
              </div>
            )}
          </div>
          <div className="text-sm flex sm-desktop:flex-row flex-col gap-xxs">
            <span className={classNames('text-neutral-700', cancellationInfo && 'line-through')}>
              {safeFormat(date, '[à] HH:mm')}
              {getAuthorDisplay(author)}
            </span>
            <div className="flex">
              {actions.map(
                (
                  { label: labelAction, variant = 'secondary', disabledMessage, ...rest },
                  index,
                ) => (
                  <Link
                    key={`${key}-actions-${index}`}
                    variant={variant}
                    className="text-sm !p-0 ml-xxs underline font-normal"
                    disabledMessageTooltip={disabledMessage}
                    {...rest}
                  >
                    {labelAction}
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sm-desktop:mx-md text-center sm-desktop:text-start">
        <Tag
          label={<EURCurrency amount={amount} forceFullPattern />}
          variant={cancellationInfo ? 'light' : variant}
        />
      </div>
    </div>
  );
};
