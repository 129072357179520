import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ModalitePaiementView } from '@travauxlib/shared/src/types/api/common/ModalitePaiementView';
import { request } from '@travauxlib/shared/src/utils/request';

const devisModalitesPaymentDefault = (devisToken: string): Promise<ModalitePaiementView[]> =>
  request(`${APP_CONFIG.apiURL}/admin/devis/${devisToken}/modalites-payment/default`, {
    method: 'PATCH',
  });

export const useDevisModalitesPaymentDefault: () => (
  devisToken: string,
) => Promise<ModalitePaiementView[]> = () => {
  const { mutateAsync: query } = useMutation({
    mutationFn: devisModalitesPaymentDefault,
    onError: () =>
      toast.error('Erreur lors de la récuparation des modalités de paiement par défaut'),
  });
  return query;
};
