import { Budget } from './Budget';

export type PersonConfig = {
  leadAssignment: LeadAssignmentConfig;
};

export type LeadAssignmentConfig = {
  availability?: boolean;
  departments?: string[];
  budgets?: Budget[];
  acceptPaid?: boolean;
  acceptBarnes?: boolean;
  acceptHemea?: boolean;
  acceptPartner?: boolean;
  cityManagerOf?: string[];
};

export type CDPLInformationView = {
  uuid?: string;
  numeroTVA?: string;
  siret?: string;
  asujettiTVA: boolean;
  bankAccountOwner?: string;
  iban?: string;
  bic?: string;
  ribUrl?: string;
  statutEntreprise?: string;
  companyCreationDate?: string;
  capital?: number;
  RCS?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type PersonView = {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname?: string;
  fullName: string;
  calendlyUrl?: string;
  fixedPhoneNumber?: string;
  mobilePhoneNumber?: string;
  jobTitle: string;
  role?: PersonRole;
  jobDescription?: string;
  officialPicture?: string;
  bonusPicture?: string;
  arrivalDate: string;
  birthDate?: string;
  archived: boolean;
  config: PersonConfig;
  managerUuid?: string;
  recruiterUuid?: string;
  cdplInformation?: CDPLInformationView;
};

export enum PersonRole {
  Admin = 'admin',
  TeamLeadPartenariat = 'team_lead_partenariat',
  ResponsablePartenariat = 'responsable_partenariat',
  TeamLeadQualite = 'team_lead_qualite',
  CustomerSuccessManager = 'customer_success_manager',
  CustomerCare = 'customer_care',
  TravauxPlannerInterne = 'travaux_planner_interne',
  TravauxPlannerExterne = 'travaux_planner_externe',
  TeamLeadCDP = 'team_lead_cdp',
  CDP = 'cdp',
  CDPL = 'cdpl',
  Compta = 'compta',
  RH = 'rh',
  Recruteur = 'recruteur',
  TeamLeadMarketing = 'team_lead_marketing',
  ResponsableAcquisition = 'responsable_acquisition',
  NoRole = 'no_role',
  ArchitecteInterne = 'architecte_interne',
}
