import React from 'react';

import Help from '@travauxlib/shared/src/components/DesignSystem/assets/HelpFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { FournitureReferenceInfo } from '@travauxlib/shared/src/features/Fournitures/components/FournitureReferenceInfo';
import { FournitureReferenceLink } from '@travauxlib/shared/src/features/Fournitures/components/FournitureReferenceLink';
import {
  EditAddReferenceActionProps,
  UserType,
} from '@travauxlib/shared/src/features/Fournitures/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { Fourniture } from '@travauxlib/shared/src/types';

type Props = {
  fourniture: Fourniture;
  editOrAddReferenceAction?: EditAddReferenceActionProps;
  userType: UserType;
};

export const FournitureCard: React.FC<Props> = ({
  fourniture,
  editOrAddReferenceAction,
  userType,
}) => {
  const { designation, reference, uuid } = fourniture;
  const isTabletOrMobile = useIsTabletOrMobile();
  const wording =
    userType === 'client'
      ? 'Ajouter la référence souhaitée pour votre projet.'
      : "Le client n'a pas encore ajouté sa référence.";

  return (
    <Card className="!rounded-none sm-desktop:!rounded-xxs" aria-labelledby={uuid}>
      <div className="text-b1 font-bold text-neutral-800 mb-md truncate" id={uuid}>
        {designation}
      </div>
      {reference ? (
        <>
          <div className="flex justify-between items-center mb-xs">
            <div className="text-b2 font-medium text-neutral-700">Référence sélectionnée</div>
            {editOrAddReferenceAction && (
              <Link
                variant="secondary"
                size="md"
                inline
                onClick={() => editOrAddReferenceAction.onClick(uuid!)}
                disabled={editOrAddReferenceAction.disabled}
                disabledTriggerClassName="text-b2"
                disabledMessageTooltip={`Vous n'avez pas les droits\npour modifier la liste.`}
                positionTooltip="left"
              >
                Modifier
              </Link>
            )}
          </div>
          <FournitureReferenceInfo reference={reference} />
          <FournitureReferenceLink referenceLink={reference.referenceLink} />
        </>
      ) : (
        <>
          <div className="flex gap-sm">
            <ColoredCircle size="xxl" variant="warning" children={<Help />} />
            <div className="flex flex-col w-[15.75rem]">
              <div className="text-b2 font-medium text-neutral-700">Référence manquante</div>
              <div className="text-sm text-neutral-600">
                La référence de la fourniture n'a pas été définie dans le devis. {wording}
              </div>
            </div>
          </div>

          {editOrAddReferenceAction && (
            <Button
              variant="secondary"
              className="mt-md flex sm-desktop:ml-auto"
              size="sm"
              disabled={editOrAddReferenceAction.disabled}
              disabledMessageTooltip={`Vous n'avez pas les droits\npour modifier la liste.`}
              disabledTriggerClassName="sm-desktop:w-fit ml-auto"
              fullwidth={isTabletOrMobile}
              onClick={() => editOrAddReferenceAction.onClick(uuid!)}
            >
              Ajouter une référence
            </Button>
          )}
        </>
      )}
    </Card>
  );
};
