import dayjs from 'dayjs';
import { v4 as uuidV4 } from 'uuid';

import {
  Devis,
  Fourniture,
  FournitureReference,
  LigneLocation,
} from '@travauxlib/shared/src/types';
import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { devis: Devis; dealUuid: string; email?: string };

export class ProUpdateDevis extends BaseStep<
  Input,
  { devis: Devis },
  StepField[],
  { devis: Devis }
> {
  public name = 'Pro update devis';

  public fields = [];

  async execute({
    apiURL,
    dealUuid,
    devis,
    request,
  }: ExecuteParams<Input>): Promise<{ devis: Devis }> {
    const dn = dayjs(Date.now());
    const de = dayjs(dn).add(1, 'month');

    const getItemDataByLotLabel = (
      lotLabel: string,
    ): {
      locations: LigneLocation[];
      fourniture: Fourniture | undefined;
    } => {
      switch (lotLabel) {
        case 'Installation de chantier - Nettoyage':
          return {
            locations: [
              {
                uuid: '473d150c-4f21-478a-a440-4bc7d29cf43f', // Chambre
                quantite: 1,
              },
            ],
            fourniture: {
              uuid: uuidV4(),
              designation: 'Test fourniture',
              reference: {
                uuid: uuidV4(),
                designation: 'Test reference',
                fournisseur: 'test',
                referenceNumber: '007',
              } as FournitureReference,
            },
          };
        case 'Façade':
          return {
            locations: [],
            fourniture: {
              uuid: uuidV4(),
              designation: 'Portail coulissant un vantail en PVC',
              createdAt: '2025-02-03T14:26:47.072372+01:00[Europe/Paris]',
            },
          };
        case 'Plomberie':
          return {
            locations: [
              {
                uuid: '1f0a3487-8daa-42b7-b59e-901149f0e0fa', // Dégagement
                quantite: 4,
              },
            ],
            fourniture: {
              uuid: uuidV4(),
              designation: 'Peinture acrylique finition blanc Satin',
              reference: {
                uuid: uuidV4(),
                designation: 'Peinture extérieure appui de fenêtre , LUXENS blanc satiné 2.5l',
                fournisseur: 'yolo',
                referenceNumber: '01',
                referenceLink:
                  'https://www.leroymerlin.fr/produits/revetement-sol-et-mur/peinture-lasure-et-traitement-exterieur/peinture-facade/peinture-appuis-de-fenetre/peinture-exterieure-appui-de-fenetre-luxens-blanc-satine-2-5l-85063372.html',
                images: [
                  {
                    uuid: '4452ae8f-711a-4630-996a-5c86966951d2',
                    name: 'fourniture-images/Y5vestumXRpAytM.png',
                    downloadUrl:
                      'https://recette.images.hemea.com/fourniture-images/Y5vestumXRpAytM.png',
                    key: 'fourniture-images/Y5vestumXRpAytM.png',
                    createdAt: '2025-02-03T14:19:54.866876+01:00',
                    imgixUrl:
                      'https://recette.images.hemea.com/fourniture-images/Y5vestumXRpAytM.png',
                  },
                ],
                mainImageUuid: '4452ae8f-711a-4630-996a-5c86966951d2',
                description: 'Peinture Appuis de Fenêtre LUXENS...',
                isRecommandationHemea: false,
              },
              createdAt: '2025-02-03T14:18:46.155319+01:00[Europe/Paris]',
            },
          };
        default:
          return {
            locations: [],
            fourniture: undefined,
          };
      }
    };

    const addFournituresToExistingLots = (
      lots: ProDevisFactureLotView[],
    ): ProDevisFactureLotView[] =>
      lots.map(lot => {
        if (!lot.items || lot.items.length === 0) {
          return lot;
        }

        const { locations, fourniture } = getItemDataByLotLabel(lot.label);

        const updatedItems = lot.items.map(item => ({
          ...item,
          locations,
          fournitures: fourniture ? [fourniture] : [],
        }));

        return {
          ...lot,
          items: updatedItems,
        };
      });

    const updatedDevisRoute = {
      ...devis,
      dateFin: `${de.format(`YYYY-MM-DD`)}`,
      dateDebut: `${dn.format(`YYYY-MM-DD`)}`,
      dureeChantier: {
        // Ca sert a que dalle nan ?
        quantity: 1,
        unit: 'mois',
      },
      locations: [
        {
          uuid: '473d150c-4f21-478a-a440-4bc7d29cf43f',
          label: 'Chambre',
          surface: 1,
          hauteurSousPlafond: 2,
          longueurMurs: 4.4,
          surfaceMurs: 8.8,
          typeLocation: 'Chambre',
          devisToken: 'K3HoPoQ13wip041HSRWXPHqIuziZAM',
          createdAt: '2025-02-03T14:12:37.91087+01:00[Europe/Paris]',
          updatedAt: '2025-02-03T14:12:37.994398+01:00[Europe/Paris]',
        },
        {
          uuid: '1f0a3487-8daa-42b7-b59e-901149f0e0fa',
          label: 'Dégagement',
          surface: 1,
          hauteurSousPlafond: 2,
          longueurMurs: 4.4,
          surfaceMurs: 8.8,
          typeLocation: 'Dégagement',
          devisToken: 'K3HoPoQ13wip041HSRWXPHqIuziZAM',
          createdAt: '2025-02-03T14:12:37.910916+01:00[Europe/Paris]',
          updatedAt: '2025-02-03T14:12:37.99891+01:00[Europe/Paris]',
        },
      ],
      dureeValidite: {
        quantity: 1,
        unit: 'mois',
      },
      emailContent:
        'Lmao mais ca va dans tous les sens ces routes devis ou quoi ? Elle sert a quoi envoyer mail ptdr',
      introductionLetter: 'Blablabla',
      lots: addFournituresToExistingLots(devis.lots),
    };

    // On s'en fous de son retour "Envoyer mail" renvoie un devis. Oui c'est succulent haja le top
    const devisUpdatedWithoutEmailSent = await request<Devis>(
      `${apiURL}/pro/deals/${dealUuid}/devis/${devis.token}`,
      {
        method: 'PUT',
        body: updatedDevisRoute,
      },
    );

    super.log('OK');

    super.setCreatedEntities({
      devis: devisUpdatedWithoutEmailSent,
    });

    return { devis: devisUpdatedWithoutEmailSent };
  }
}
