import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { isConflict, request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from 'features/Project/api/useProjectContext';

const handleCreateClientAccount = (payload: {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  projectUuid: string;
}): Promise<{ uuid: string } | undefined> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/clients`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useCreateClientAccount = (): typeof handleCreateClientAccount => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleCreateClientAccount,
    onError: e => {
      if (isConflict(e)) {
        toast.error('Un compte client existe déjà avec cette adresse email.');
      } else {
        toast.error('Une erreur est survenue lors de la création du compte client.');
      }
    },
    onSuccess: () => {
      toast.success('Le compte a bien été créé.');
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
  });

  return mutateAsync;
};
