import React, { useEffect } from 'react';

import { useParams, Outlet, Navigate } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useAuth } from 'api/useAuth';
import { useProjectContext } from 'features/Project/api/useProjectContext';
import { FicheProjetTracking } from 'utils/tracking/FicheProjetTracking';

import { Header } from './components/Header';

export const ProjectPage: React.FC = () => {
  const { uuid } = useParams();
  const { projectContext, isLoading } = useProjectContext(uuid!);
  const { adminUserProfile } = useAuth();

  useEffect(() => {
    FicheProjetTracking.onFicheProjetViewed({
      'User Role': adminUserProfile.role,
      'Projet UUID': uuid!,
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (!projectContext) {
    return <Navigate to="/projects" />;
  }

  return (
    <div className="flex flex-col h-full">
      <Header projectContext={projectContext} />
      <Outlet context={projectContext} />
    </div>
  );
};
