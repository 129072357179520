import { Location } from '@travauxlib/shared/src/types';
import {
  Configuration,
  GenerationData,
} from '@travauxlib/shared/src/types/api/domain/configurateur';
import {
  Etat,
  Finition,
  FinitionMursPlafondsEnum,
  MursPlafonds,
  Peinture,
  TypeRevetement,
} from '@travauxlib/shared/src/types/api/domain/configurateur/Murs';
import {
  estimateSurfaceMurs,
  perimetreWithSurfaceQuantityComputer,
} from '@travauxlib/shared/src/utils/quantityComputers';

import { defaultHSP } from '../defaultConfigurationValues';

const HAUTEUR_CREDENCE = 0.6;

const addPeinturePlafonds = ({
  peinture,
  location,
  isPretAPeindre,
}: {
  peinture: Peinture;
  location: Location;
  isPretAPeindre: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];
  const isOption = peinture.isOption;
  const idPreparation =
    peinture.etatPlafonds === Etat.Bon
      ? 'preparation_plafonds_standard'
      : 'preparation_plafonds_entoilage';

  if (isPretAPeindre) {
    const ouvrageLabel = 'Préparation plafonds - peinture par le client';
    result.push({
      ouvrageLabel,
      id: idPreparation,
      quantity: location.surface,
      isOption,
      locationQuantities: {
        [location.uuid]: location.surface,
      },
    });
  } else {
    const ouvrageLabel = 'Peinture plafonds';
    result.push({
      ouvrageLabel,
      id: idPreparation,
      tagQuantity: 'only',
      isOption,
      quantity: location.surface,
      locationQuantities: {
        [location.uuid]: location.surface,
      },
    });
    result.push({
      ouvrageLabel,
      id: 'peinture_plafonds',
      isOption,
      quantity: location.surface,
      locationQuantities: {
        [location.uuid]: location.surface,
      },
    });
  }

  return result;
};

const addPeintureMurs = ({
  configuration,
  peinture,
  location,
  isPretAPeindre,
}: {
  configuration: Configuration;
  peinture: Peinture;
  location: Location;
  isPretAPeindre: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];
  const isOption = peinture.isOption;
  const idPreparation =
    peinture.etatMurs === Etat.Bon ? 'preparation_murs_standard' : 'preparation_murs_entoilage';

  const quantity = estimateSurfaceMurs(location, configuration.hauteurSousPlafond || defaultHSP);
  if (isPretAPeindre) {
    const ouvrageLabel = 'Préparation murs - peinture par le client';
    result.push({
      ouvrageLabel,
      id: idPreparation,
      quantity: quantity,
      isOption,
      locationQuantities: {
        [location.uuid]: quantity,
      },
    });
  } else {
    const ouvrageLabel = 'Peinture murs';
    result.push({
      ouvrageLabel,
      id: idPreparation,
      tagQuantity: 'only',
      isOption,
      quantity,
      locationQuantities: {
        [location.uuid]: quantity,
      },
    });
    result.push({
      ouvrageLabel,
      id: 'peinture_murale',
      isOption,
      quantity,
      locationQuantities: {
        [location.uuid]: quantity,
      },
    });
  }

  return result;
};

export const murs = (configuration: Configuration): GenerationData[] => {
  const murs = configuration.answers?.murs;
  const hauteurSousPlafond = configuration.hauteurSousPlafond || defaultHSP;
  const cuisine = configuration.locations.find(l => l.typeLocation === 'Cuisine');
  if (!murs) {
    return [];
  }
  const result: GenerationData[] = [];

  const mursADeposer = murs.deposeMurs || [];

  for (let murADepose of mursADeposer) {
    const isOption = murADepose.isOption;
    for (let [locationUuid, nbMurs] of Object.entries(murADepose.locationQuantities || {})) {
      const currentLocation = configuration.locations.find(l => l.uuid === locationUuid)!;
      if (!currentLocation) {
        continue;
      }
      const surfaceTotalMurs = estimateSurfaceMurs(currentLocation, hauteurSousPlafond);
      const surface = (surfaceTotalMurs * nbMurs) / 4;
      if (murADepose.typeDepose === TypeRevetement.FaienceMurale) {
        result.push({
          ouvrageLabel: 'Dépose faïence',
          tagQuantity: 'only',
          id: 'depose_mur_carrelage',
          isOption,
          quantity: surface,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
      } else if (murADepose.typeDepose === TypeRevetement.PapierPeint) {
        result.push({
          ouvrageLabel: 'Dépose papier peint',
          id: 'depose_mur_papier_peint',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
      } else {
        result.push({
          ouvrageLabel: 'Dépose toile de verre',
          id: 'depose_mur_toile_verre',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
      }
    }

    if (murADepose.typeDepose === TypeRevetement.CredenceCuisine && cuisine) {
      const surfaceCredence =
        (perimetreWithSurfaceQuantityComputer(cuisine.surface) / 4) * HAUTEUR_CREDENCE;

      result.push({
        ouvrageLabel: 'Dépose crédence cuisine',
        id: 'depose_mur_carrelage',
        quantity: surfaceCredence,
        tagQuantity: 'only',
        isOption,
        locationQuantities: {
          [cuisine.uuid]: surfaceCredence,
        },
      });
      result.push({
        ouvrageLabel: 'Dépose crédence cuisine',
        id: 'reprise_mur_placo',
        isOption,
        quantity: surfaceCredence,
        locationQuantities: {
          [cuisine.uuid]: surfaceCredence,
        },
      });
    }
  }

  const peintures = murs.peintures || [];

  for (let peinture of peintures) {
    const locations = configuration.locations.filter(l => peinture.locationsUuid?.includes(l.uuid));
    for (let location of locations) {
      if (peinture.mursPlafonds === MursPlafonds.Murs) {
        const generatedData = addPeintureMurs({
          configuration,
          peinture,
          location,
          isPretAPeindre: peinture.finitionMurs === Finition.PretAPeindre,
        });

        result.push(...generatedData);
      } else if (peinture.mursPlafonds === MursPlafonds.Plafonds) {
        const generatedData = addPeinturePlafonds({
          peinture,
          location,
          isPretAPeindre: peinture.finitionMurs === Finition.PretAPeindre,
        });

        result.push(...generatedData);
      } else {
        const generatedDataMurs = addPeintureMurs({
          configuration,
          peinture,
          location,
          isPretAPeindre: peinture.finitionMursPlafonds === FinitionMursPlafondsEnum.PretAPeindre,
        });
        const generatedDataPlafonds = addPeinturePlafonds({
          peinture,
          location,
          isPretAPeindre: peinture.finitionMursPlafonds === FinitionMursPlafondsEnum.PretAPeindre,
        });

        result.push(...generatedDataMurs, ...generatedDataPlafonds);
      }
    }
  }

  const mursAPoser = murs.poseMurs || [];

  for (let murAPoser of mursAPoser) {
    const isOption = murAPoser.isOption;
    for (let [locationUuid, nbMurs] of Object.entries(murAPoser.locationQuantities || {})) {
      const currentLocation = configuration.locations.find(l => l.uuid === locationUuid)!;
      if (!currentLocation) {
        continue;
      }
      const surfaceTotalMurs = estimateSurfaceMurs(currentLocation, hauteurSousPlafond);
      const surface = (surfaceTotalMurs * nbMurs) / 4;

      if (murAPoser.typePose === TypeRevetement.FaienceMurale) {
        result.push({
          ouvrageLabel: 'Faïence',
          id: 'preparation_mur_carrelage',
          tagQuantity: 'only',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Faïence',
          id: 'carrelage_mur_inf_30',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Faïence',
          id: 'primaire_accrochage_murs',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
      } else {
        result.push({
          ouvrageLabel: 'Papier peint',
          id: 'preparation_murs_standard',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Papier peint',
          id: 'papier_peint',
          tagQuantity: 'only',
          quantity: surface,
          isOption,
          locationQuantities: {
            [locationUuid]: surface,
          },
        });
      }
    }

    if (murAPoser.typePose === TypeRevetement.CredenceCuisine && cuisine) {
      const surfaceCredence =
        (perimetreWithSurfaceQuantityComputer(cuisine.surface) / 4) * HAUTEUR_CREDENCE;

      result.push({
        ouvrageLabel: 'Pose crédence cuisine',
        id: 'preparation_mur_carrelage',
        tagQuantity: 'only',
        quantity: surfaceCredence,
        isOption,
        locationQuantities: {
          [cuisine.uuid]: surfaceCredence,
        },
      });
      result.push({
        ouvrageLabel: 'Pose crédence cuisine',
        id: 'carrelage_mur_inf_30',
        quantity: surfaceCredence,
        isOption,
        locationQuantities: {
          [cuisine.uuid]: surfaceCredence,
        },
      });
      result.push({
        ouvrageLabel: 'Pose crédence cuisine',
        id: 'primaire_accrochage_murs',
        isOption,
        quantity: surfaceCredence,
        locationQuantities: {
          [cuisine.uuid]: surfaceCredence,
        },
      });
    }
  }

  return result;
};
