import React from 'react';

import { FournituresWithLocation } from '@travauxlib/shared/src/types';

import { Totals } from '../../types';
import { DisplayedCountFournitures } from '../DisplayedCountFournitures';
import { ProgressReference } from '../ProgressReference';
import { RoomsFilter } from '../RoomsFilter';

type Props = {
  fournitures: FournituresWithLocation[];
  selectedRooms: string[];
  availableRooms: string[];
  onRoomToggle: (room: string, checked: boolean) => void;
  totals: Totals;
  completionPercentage: number;
  displayedCount: number;
};

export const FilterRoomsDesktop: React.FC<Props> = ({
  fournitures,
  selectedRooms,
  availableRooms,
  onRoomToggle,
  totals,
  completionPercentage,
  displayedCount,
}) => (
  <div className="basis-[31%] shrink-0 hidden sm-desktop:block">
    <ProgressReference totals={totals} completionPercentage={completionPercentage} />
    <hr />
    <DisplayedCountFournitures displayedCount={displayedCount} />
    <RoomsFilter
      availableRooms={availableRooms}
      fournitures={fournitures}
      selectedRooms={selectedRooms}
      onRoomToggle={onRoomToggle}
    />
  </div>
);
